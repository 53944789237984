import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
	const [token, setToken] = useState(() => window.sessionStorage.getItem('token'));
	const [userID, setUserID] = useState(() => window.sessionStorage.getItem('id'));
	const [userData, setUserData] = useState({});

	const values = useMemo(
		() => ({
			token,
			setToken,
			userData,
			setUserData,
			userID,
			setUserID,
		}),
		[token, userData, userID],
	);

	return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default UserContext;
