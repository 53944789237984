import * as React from 'react';

const EnvelopeSVG = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 24 24'
		// style={{
		// 	enableBackground: 'new 0 0 512 512',
		// }}
		xmlSpace='preserve'
		width='24px'
		height='24px'
		className='svg-icon'
		{...props}>
		<path
			fill='#0092E4'
			d='M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z'
		/>
	</svg>
);

export default EnvelopeSVG;
