import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import USERS from '../../common/data/userDummyData';
import Avatar from '../../components/Avatar';
import { UilUsdCircle, UilCheck, UilCamera } from '@iconscout/react-unicons';
import { Cake } from 'phosphor-react';
import Check from '../../components/Check';
const CardInmuebleMobile = ({ type }) => {
	const types = ['Reserva inmueble', 'Alta de ficha', 'Suspensión de inmueble', 'Baja de ficha'];
	const [selectedEstates, setSelectedEstates] = useState([]);
	const imagenURL =
		'https://images.pexels.com/photos/2360673/pexels-photo-2360673.jpeg?cs=srgb&dl=pexels-tobias-bj%C3%B8rkli-2360673.jpg&fm=jpg';
	return (
		<>
			<div className='card rounded-1 p-2  d-flex justify-content-center align-items-center'>
				<div className='row  d-flex justify-content-center align-items-center'>
					<div className='col-2 mt-1'>
						<div class='avatar-full d-flex'>
							<Avatar
								className='border-50'
								srcSet={USERS.CHLOE.srcSet}
								src={USERS.CHLOE.src}
								size={60}
								color={USERS.CHLOE.color}
							/>
							<div
								class='round bg-blue-500 d-flex justify-content-center align-items-center me-5 ms-3'
								style={{ width: '30px', height: '30px' }}
							>
								<Cake size={18} className='mt-0' color='#FFFFFF' />
							</div>
						</div>
					</div>
					<div className='col-9 ms-4 d-flex  align-items-center'>
						<div className='d-flex  align-items-center'>
							El 3 de Mayo fue el cumpleaños de Luciana Rodriguez. ¡Felicitala!
						</div>
					</div>
					<div className='col-12'>
						<div className='float-end'>
							<p className='float-end me-2 mt-n3 fs-6  fw-500'>12:18</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardInmuebleMobile;
