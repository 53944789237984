import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UilPlay, UilUsersAlt, UilSearch } from '@iconscout/react-unicons';
import { demoPages } from '../../menu';
import Button from '../../components/bootstrap/Button';
import ThemeContext from '../../contexts/themeContext';
import { useNavigate } from 'react-router-dom';

const SubheaderNew = ({
	breadCrumbs,
	filtersFunc,
	filtersStatus,
	newButtonUrl,
	hideButtons,
	withFiltersButton,
	withNewButton,
	withExportButton,
	withSearchButton,
	className= ''
}) => {
	const { mobileDesign } = useContext(ThemeContext);
	const navigate = useNavigate();

	return (
		<div
			isLight
			borderWidth={0}
			className={`d-flex ${mobileDesign && 'flex-column'} ${
				mobileDesign ? 'align-items-center' : 'align-items-center shadow-subheader rounded'
			} col-12 justify-content-between py-3 mb-4 `}
			isDismissible>
			{mobileDesign ? (
				<div className='d-flex flex-column w-100'>
					<div className='d-flex justify-content-center titulo-alerta py-2 px-4 w-100'>
						<UilUsersAlt size={50} color='#506CF9' />
						<h2 className='m-0 px-3 text-color-lblue'>
							{breadCrumbs[0]?.name.toUpperCase()}
						</h2>
					</div>

					<div className='black-gradient w-100 p-2'></div>
				</div>
			) : (
				<div className='d-flex align-items-center px-4'>
					<span className='bc-subheader-black'>Home</span>
					{breadCrumbs?.map((bc , i) => {
						return (
							<div
								key={i}
								className='d-flex align-items-center cursor-pointer'
								onClick={() => navigate(bc.url)}>
								<UilPlay color='#B7B8BC' size={15} className='mx-2' />
								<span className='bc-subheader-blue'>{bc.name}</span>
							</div>
						);
					})}
				</div>
			)}

			{!mobileDesign ? (
				<div
					className={`botones-alerta d-flex px-3 mx-1 ${
						mobileDesign && 'w-100 justify-content-between px-4 mt-3'
					} `}>
					{!hideButtons && (
						<>
							{withFiltersButton && (
								<Button
									size='sm'
									onClick={() => filtersFunc(!filtersStatus)}
									icon='CustomFilter'
									className={`${!mobileDesign && 'mx-1'}`}
									color='primary'
									isLight>
									Filtros
								</Button>
							)}
							{withNewButton && (
								<Button
									size='sm'
									icon='CustomUserPlus'
									className={`${!mobileDesign && 'mx-1'}`}
									color='info'
									isLight
									tag='a'
									// to={`../${demoPages.editPages.subMenu.editWizard.path}`}>
									to={newButtonUrl}>
									Nuevo
								</Button>
							)}
							{withExportButton && (
								<Button
									size='sm'
									icon='CustomShareBox'
									className={`${!mobileDesign && 'mx-1'}`}
									color='info'
									isLight
									tag='a'
									to={`../${demoPages.editPages.subMenu.editWizard.path}`}>
									Exportar
								</Button>
							)}
						</>
					)}
				</div>
			) : (
				!filtersStatus &&
				!hideButtons && (
					<div
						className={`botones-alerta d-flex mx-1 ${
							mobileDesign && `w-100  mt-3 ${className ? className  : 'justify-content-around'} `
						} `}>
						{withFiltersButton && (
							<Button
								// size='sm'
								onClick={() => filtersFunc(!filtersStatus)}
								icon='CustomFilter'
								className={`${!mobileDesign && 'mx-1'}`}
								color='primary'
								isLight>
								Filtros
							</Button>
						)}

						{withNewButton && (
							<Button
								// size='sm'
								icon='CustomUserPlus'
								className={`${!mobileDesign && 'mx-1'}`}
								color='info'
								isLight
								tag='a'
								// to={`/personas/interesados/crear`}>
								to={newButtonUrl}>
								Nuevo
							</Button>
						)}
						{withExportButton && (
							<Button
								// size='sm'
								icon='CustomShareBox'
								className={`${!mobileDesign && 'mx-1'}`}
								color='info'
								isLight
								tag='a'
								to={`../${demoPages.editPages.subMenu.editWizard.path}`}>
								Exportar
							</Button>
						)}

						{withSearchButton && (
							<div className='d-flex justify-content-center align-items-center btn'>
								<UilSearch color='#fff' />
							</div>
						)}
					</div>
				)
			)}
		</div>
	);
};

SubheaderNew.propTypes = {
	breadCrumbs: PropTypes.array,
	filtersFunc: PropTypes.func,
	filtersStatus: PropTypes.bool,
	newButtonUrl: PropTypes.string,
	hideButtons: PropTypes.bool,
};

SubheaderNew.defaultProps = {
	filtersFunc: null,
	filtersStatus: false,
	newButtonUrl: '/',
	hideButtons: false,
};

export default SubheaderNew;
