import React, { useState , useContext } from 'react';

import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import ThemeContext from '../../contexts/themeContext';
import ChildrenSelector from './ChildrenSelector';
import Footer from './Footer';

import CreateMobile from './CreateMobile';
import Toast from '../../components/Toast/Toast';



const OperatorsCreate = () => {
	const [selectedTab, setSelectedTab] = useState('OPERADOR');
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const [userData, setUserData] = useState({});
	

	

	const tabs = [
		{ name: 'OPERADOR' },
		{ name: 'CONFIGURACIÓN' },
		{ name: 'CORREO ELECTRÓNICO' },
	];
	return (
		<PageWrapper title='Alta Operador'>
			{ !mobileDesign ? (
			<Page container='fluid'>
				
				<SubheaderNew
					breadCrumbs={[
						{ name: 'Operadores', url: '/personas/operadores/Crear' },
					]}
					hideButtons
				/>
				<div className='d-flex flex-column w-100 h-100'>
					{/* Tabs */}
					<div className='d-flex fw-bold'>
						{tabs.map((tab) => (
							<div
								key={tab.name}
								className={`${
									selectedTab === tab.name ? 'bg-lblue' : 'bg-lblue-065'
								}  px-4 py-3 me-1 rounded-top-lg cursor-pointer`}
								onClick={() => setSelectedTab(tab.name)}>
								{tab.name}
							</div>
						))}
					</div>
					{/* Tabs */}
					<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
						<ChildrenSelector selectedTab={selectedTab} userData={userData} setUserData={setUserData} />
						{/* <Footer /> */}
						<Footer userData={userData} setUserData={setUserData} />
					</div>
				</div>
			
			</Page>
				) : (
					 <CreateMobile />
					)}
		</PageWrapper>
					
	);
};

export default OperatorsCreate;
