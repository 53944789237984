import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Notepad } from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { UilCog, UilEnvelopeAlt, UilUser, UilWhatsapp, UilEstate } from '@iconscout/react-unicons';
import SearchFilter from '../../components/Owner/SearchFilter';
import { Form, Formik } from 'formik';
import Check from '../../components/Check';
import Switcher from 'react-switcher-rc';

const PaginaWeb = ({ userData, setUserData }) => {
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);
	const [switcherState, setSwitcherState] = useState(false);
	const onHandleChange = (e) => setSwitcherState(e.target.checked);

	// onFileChange = (event) => {
	// 		// onChange={(e) => setFile(URL.createObjectURL(e.target.files[0]),checkFile())}
	// 		//setPreviewImage(URL.createObjectURL(e.target.files[0]);
	// 	// Update the state
	// 	//setFile(event.target.files[0]);

	//   }

	//   onFileUpload = () => {

	// 	// Create an object of formData
	// 	const formData = new FormData();

	// 	// Update the formData object
	// 	formData.append(
	// 	  "profile_image",
	// 	  file,
	// 	  file.name
	// 	);

	// 	// Request made to the backend api
	// 	// Send formData object
	// 	axios.post("api/uploadfile", formData);
	//   };

	function checkFile() {
		// URL.createObjectURL(file)
		// // setPreview(objectUrl)
		// console.log(window.URL.createObjectURL(dataURLToBlob(file)))
	}

	function dataURLToBlob(dataurl) {
		let arr = dataurl.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='row d-flex align-items-center mt-5'>
							<div className='col-2 d-flex align-items-center'>
								<h4 className='fs-5 mt-4 fw-700 mb-4'>PAGINA WEB</h4>
							</div>
							<div className='col d-flex w-100 border-3 border-bottom bg-black-button start-30'></div>
						</div>
						<div className='d-flex flex-row bg-lblue w-100 rounded-right-and-bottom me-3'>
							<Card className='d-flex w-50'>
								<CardHeader className='border-bottom'>
									<CardTitle tag='h4' className='h5 text-color-lblue ms-3'>
										<div className='d-flex align-items-center'>
											<UilEnvelopeAlt className='' />
											<p className='my-0 mx-2'>GENERALES</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody className='p-0 py-2'>
									<div className='d-flex flex-column  justify-content-center align-items-center mb-3'>
										<div className='col-10 mt-4'>
											<InputFloating
												className='input-filters-menu rounded-2 shadow-inputs col-2 p-3'
												label='API -KEY de google maps'
												name='apiley-maps'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'API -KEY de google maps'}
											/>
										</div>
										<div className='col-10 mt-4'>
											<InputFloating
												className='input-filters-menu rounded-2 shadow-inputs col-2 p-3'
												label='Código para descargar fotos en página web'
												name='photo_code'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={
													'Código para descargar fotos en página web'
												}
											/>
										</div>
										<div className='col-10 mt-4'>
											<InputFloating
												className='input-filters-menu rounded-2 shadow-inputs col-2 p-3'
												label='Número de WhatsApp'
												name='apiley-maps'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Número de WhatsApp'}
											/>
										</div>
										<div className='col-10 mt-4'>
											<div className='col-8 d-flex'>
												<div className='col-8 ms-1'>
													Muestra imagen de operador en ficha
												</div>
												<Check />
											</div>
										</div>
										<div className='col-10 mt-4 py-2'>
											<div className='col-8 d-flex'>
												<div className='col-8 ms-1'>
													Redondea dirección de propiedad
												</div>
												<Check />
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
							<Card className=' ms-3 w-50'>
								<CardHeader className='border-bottom'>
									<CardTitle tag='h4' className='h5 text-color-lblue ms-3'>
										<div className='d-flex align-items-center'>
											<UilEstate className='' />
											<p className='my-0 mx-2'>DATOS SUCURSAL</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody className='p-0 py-2'>
									<div className='d-flex flex-column  justify-content-center align-items-center mb-3'>
										<div className='col-10 mt-4'>
											<InputFloating
												className='input-filters-menu rounded-2 shadow-inputs col-2 p-3'
												label='Nombre'
												name='nombre'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Nombre'}
											/>
										</div>
										<div className='col-10 mt-4'>
											<InputFloating
												className='input-filters-menu rounded-2 shadow-inputs col-2 p-3'
												label='Dirección'
												name='direccion'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Dirección'}
											/>
										</div>
										<div className='col-10 mt-4'>
											<InputFloating
												className='input-filters-menu rounded-2 shadow-inputs col-2 p-3'
												label='Correo electrónico'
												name='email'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Correo electrónico'}
											/>
										</div>
										<div className='col-10 mt-4'>
											<div className='col-12 d-flex'>
												<div className='col-3 ms-1 me-4'>
													<InputFloating
														className='input-filters-menu rounded-2 shadow-inputs col-2 p-3'
														label='Código de área'
														name='codigo_area'
														type='number'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Código de área'}
													/>
												</div>
												<InputFloating
													className='input-filters-menu rounded-2 shadow-inputs'
													label='Teléfono'
													name='Telefono'
													type='number'
													setFunction={setUserData}
													previousValue={userData}
													touched={touched}
													required
													errorMessage={'Teléfono'}
												/>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default PaginaWeb;
