import React from 'react';
import CustomDropdown from '../../components/CustomDropdown';
import { UilBuilding } from '@iconscout/react-unicons';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Check from '../../components/Check';

export default function NewEstateMobile() {
	const [estateData, setEstateData] = useState({
		operationType: [],
	});

	const operationTypeItems = [
		{
			label: 'Venta',
			id: 21,
		},
		{
			label: 'Alquiler',
			id: 22,
		},
		{
			label: 'Alquiler temporario',
			id: 23,
		},
	];

	const branches = [
		{
			name: 'Sucursal 1',
			id: 99,
		},
		{
			name: 'Sucursal 2',
			id: 98,
		},
		{
			name: 'Sucursal 3',
			id: 97,
		},
		{
			name: 'Sucursal 4',
			id: 96,
		},
	];

	const tipologyItems = [
		{
			label: 'Departamento',
			id: 11,
		},
		{
			label: 'Casa',
			id: 12,
		},
		{
			label: 'Campo',
			id: 13,
		},
		{
			label: 'Galpón',
			id: 14,
		},
		{
			label: 'Local',
			id: 15,
		},
		{
			label: 'Negocio',
			id: 16,
		},
		{
			label: 'Cochera',
			id: 17,
		},
		{
			label: 'Oficina',
			id: 18,
		},
		{
			label: 'Lote',
			id: 19,
		},
		{
			label: 'Edificio',
			id: 20,
		},
		{
			label: 'Quinta',
			id: 21,
		},
	];

	const handleSubmit = (values) => {
		console.log(values);
	};

	const navigate = useNavigate();

	console.log(estateData);

	return (
		<Formik
			initialValues={{
				location: '',
				street: '',
				number: '',
				floor: '',
			}}>
			{({ touched, values }) => (
				<div className='d-flex flex-column align-items-center w-100'>
					<div className='d-flex align-items-center justify-content-center w-100 pb-4'>
						<UilBuilding size={44} color='#506CF9' />
						<h3 className='text-color-lblue fw-bold ps-2 m-0'>NUEVO INMUEBLE</h3>
					</div>

					<Form className='w-100'>
						<div className='d-flex flex-column align-items-center w-100 p-4 rounded shadow'>
							<CustomDropdown
								label={'Sucursal'}
								classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
								dropdownClassname={
									'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom px-4 position-absolute w-100'
								}
								margin='mb-4'
								width='w-100'>
								{branches.map((item) => (
									<div
										className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'
										key={item.id}>
										{item.name}
									</div>
								))}
							</CustomDropdown>

							<CustomDropdown
								label={'Tipo de operación'}
								classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
								dropdownClassname={
									'd-flex flex-column align-items-start justify-content-center rounded-bottom position-absolute w-100'
								}
								margin='mb-4'
								width='w-100'>
								{operationTypeItems.map((item, index) => (
									<div
										className={`d-flex justify-content-between align-items-center bg-lblue text-color-lblue py-3 custom-dropdown-item fs-5 fw-bold w-100 px-4
										${index === operationTypeItems.length - 1 && 'rounded-bottom'}
										`}
										key={item.id}
										onClick={() => {
											if (estateData.operationType.includes(item.id)) {
												setEstateData((estateData) => ({
													...estateData,
													operationType: estateData.operationType.filter(
														(type) => type !== item.id,
													),
												}));
											} else {
												setEstateData((estateData) => ({
													...estateData,
													operationType: [
														...estateData.operationType,
														item.id,
													],
												}));
											}
										}}>
										{item.label}
										<Check
											checked={estateData.operationType.includes(item.id)}
										/>
									</div>
								))}
							</CustomDropdown>

							<CustomDropdown
								label={'Tipología'}
								classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
								dropdownClassname={
									'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom px-4 position-absolute w-100'
								}
								margin='mb-4'
								width='w-100'>
								{tipologyItems.map((item) => (
									<div
										className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'
										key={item.id}>
										{item.label}
									</div>
								))}
							</CustomDropdown>

							<Field
								className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 fw-600 blue-placeholder no-outline mb-4'
								placeholder='Ubicación'
								name='location'
							/>

							<Field
								className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 fw-600 blue-placeholder no-outline mb-4'
								placeholder='Calle'
								name='street'
							/>

							<div className='d-flex align-items-center w-100'>
								<Field
									className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 fw-600 blue-placeholder no-outline me-2 col'
									placeholder='Número'
									name='number'
								/>
								<Field
									className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 fw-600 blue-placeholder no-outline ms-2 col'
									placeholder='Piso'
									name='floor'
								/>
							</div>
						</div>
					</Form>

					<div className='d-flex justify-content-end w-100 mt-5'>
						<div
							className='text-color-white bg-blue-300 fs-5 rounded-1 px-5 py-3'
							onClick={() => {
								handleSubmit(values);
								navigate('/propiedades/inmuebles/editar/:id');
							}}>
							Crear propiedad
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
}
