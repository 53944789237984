import React, { useContext } from 'react';
import ThemeContext from '../../../contexts/themeContext';
import {
	UilLocationPoint,
	UilBookmark,
	UilBed,
	UilMoneyBill,
	UilCheck,
} from '@iconscout/react-unicons';
import { Popup } from 'react-leaflet';

export default function EstatePopupMultiple({ item, selectedEstates, setSelectedEstates }) {
	const { mobileDesign } = useContext(ThemeContext);

	return (
		<Popup>
			<div className='d-flex flex-column align-items-center w-100'>
				<div className='d-flex justify-content-start align-items-center w-100 bg-blue-300 text-color-white py-4 px-5 rounded-top-2 sticky-top'>
					<h4 className='mb-0 fw-bold'>{item.neighborhood.toUpperCase()}</h4>
					<h6 className='my-0 ms-3'> | {item.estateCount} unidades en venta</h6>
				</div>

				<div className='d-flex flex-column align-items-center w-100'>
					{/* estate map */}
					<div className='d-flex align-items-center w-100 justify-content-between p-4 border-bottom'>
						<div
							className='rounded'
							style={{
								backgroundImage: `url(${item.photo})`,
								backgroundSize: 'cover',
								height: '83px',
								width: '126px',
								position: 'relative',
							}}>
							<div
								className='bg-blue-300 py-1 px-2 text-color-white'
								style={{
									position: 'absolute',
									right: 10,
									top: 10,
									borderRadius: '5px',
								}}>
								<p className='m-0'>{item.id}</p>
							</div>
						</div>

						<div className='d-flex flex-column align-items-start justify-content-start ps-3'>
							<div className='d-flex justify-content-start align-items-center my-2'>
								<UilLocationPoint color='#506CF9' />
								<p className={`fs-6 px-2 fw-normal-bold m-0`}>{item.location}</p>
							</div>
							<div className='d-flex align-items-center'>
								<UilMoneyBill color='#506CF9' />
								<p className={`fs-6 px-2 fw-bold m-0`}>
									{item.currency} {item.value}
								</p>
							</div>
							<div className='d-flex align-items-center'>
								<UilBed color='#506CF9' />
								<p className={`fs-6 px-2 fw-bold m-0`}>{item.rooms} ambientes</p>
							</div>
						</div>

						<label
							className='custom-checkbox'
							onClick={() => {
								if (selectedEstates.some((estate) => estate.id === item.id)) {
									setSelectedEstates(
										selectedEstates.filter((estate) => estate.id !== item.id),
									);
								} else {
									setSelectedEstates((selectedEstates) => [
										...selectedEstates,
										item,
									]);
								}
							}}>
							<UilCheck
								color={
									selectedEstates.some((estate) => estate.id === item.id)
										? '#506CF9'
										: '#fff'
								}
								size={20}
							/>
						</label>
					</div>
					<div className='d-flex align-items-center w-100 justify-content-between p-4 border-bottom'>
						<div
							className='rounded'
							style={{
								backgroundImage: `url(${item.photo})`,
								backgroundSize: 'cover',
								height: '83px',
								width: '126px',
								position: 'relative',
							}}>
							<div
								className='bg-blue-300 py-1 px-2 text-color-white'
								style={{
									position: 'absolute',
									right: 10,
									top: 10,
									borderRadius: '5px',
								}}>
								<p className='m-0'>{item.id}</p>
							</div>
						</div>

						<div className='d-flex flex-column align-items-start justify-content-start ps-3'>
							<div className='d-flex justify-content-start align-items-center my-2'>
								<UilLocationPoint color='#506CF9' />
								<p className={`fs-6 px-2 fw-normal-bold m-0`}>{item.location}</p>
							</div>
							<div className='d-flex align-items-center'>
								<UilMoneyBill color='#506CF9' />
								<p className={`fs-6 px-2 fw-bold m-0`}>
									{item.currency} {item.value}
								</p>
							</div>
							<div className='d-flex align-items-center'>
								<UilBed color='#506CF9' />
								<p className={`fs-6 px-2 fw-bold m-0`}>{item.rooms} ambientes</p>
							</div>
						</div>

						<label
							className='custom-checkbox'
							onClick={() => {
								if (selectedEstates.some((estate) => estate.id === item.id)) {
									setSelectedEstates(
										selectedEstates.filter((estate) => estate.id !== item.id),
									);
								} else {
									setSelectedEstates((selectedEstates) => [
										...selectedEstates,
										item,
									]);
								}
							}}>
							<UilCheck
								color={
									selectedEstates.some((estate) => estate.id === item.id)
										? '#506CF9'
										: '#fff'
								}
								size={20}
							/>
						</label>
					</div>
					<div className='d-flex align-items-center w-100 justify-content-between p-4 border-bottom'>
						<div
							className='rounded'
							style={{
								backgroundImage: `url(${item.photo})`,
								backgroundSize: 'cover',
								height: '83px',
								width: '126px',
								position: 'relative',
							}}>
							<div
								className='bg-blue-300 py-1 px-2 text-color-white'
								style={{
									position: 'absolute',
									right: 10,
									top: 10,
									borderRadius: '5px',
								}}>
								<p className='m-0'>{item.id}</p>
							</div>
						</div>

						<div className='d-flex flex-column align-items-start justify-content-start ps-3'>
							<div className='d-flex justify-content-start align-items-center my-2'>
								<UilLocationPoint color='#506CF9' />
								<p className={`fs-6 px-2 fw-normal-bold m-0`}>{item.location}</p>
							</div>
							<div className='d-flex align-items-center'>
								<UilMoneyBill color='#506CF9' />
								<p className={`fs-6 px-2 fw-bold m-0`}>
									{item.currency} {item.value}
								</p>
							</div>
							<div className='d-flex align-items-center'>
								<UilBed color='#506CF9' />
								<p className={`fs-6 px-2 fw-bold m-0`}>{item.rooms} ambientes</p>
							</div>
						</div>

						<label
							className='custom-checkbox'
							onClick={() => {
								if (selectedEstates.some((estate) => estate.id === item.id)) {
									setSelectedEstates(
										selectedEstates.filter((estate) => estate.id !== item.id),
									);
								} else {
									setSelectedEstates((selectedEstates) => [
										...selectedEstates,
										item,
									]);
								}
							}}>
							<UilCheck
								color={
									selectedEstates.some((estate) => estate.id === item.id)
										? '#506CF9'
										: '#fff'
								}
								size={20}
							/>
						</label>
					</div>
					<div className='d-flex align-items-center w-100 justify-content-between p-4 border-bottom'>
						<div
							className='rounded'
							style={{
								backgroundImage: `url(${item.photo})`,
								backgroundSize: 'cover',
								height: '83px',
								width: '126px',
								position: 'relative',
							}}>
							<div
								className='bg-blue-300 py-1 px-2 text-color-white'
								style={{
									position: 'absolute',
									right: 10,
									top: 10,
									borderRadius: '5px',
								}}>
								<p className='m-0'>{item.id}</p>
							</div>
						</div>

						<div className='d-flex flex-column align-items-start justify-content-start ps-3'>
							<div className='d-flex justify-content-start align-items-center my-2'>
								<UilLocationPoint color='#506CF9' />
								<p className={`fs-6 px-2 fw-normal-bold m-0`}>{item.location}</p>
							</div>
							<div className='d-flex align-items-center'>
								<UilMoneyBill color='#506CF9' />
								<p className={`fs-6 px-2 fw-bold m-0`}>
									{item.currency} {item.value}
								</p>
							</div>
							<div className='d-flex align-items-center'>
								<UilBed color='#506CF9' />
								<p className={`fs-6 px-2 fw-bold m-0`}>{item.rooms} ambientes</p>
							</div>
						</div>

						<label
							className='custom-checkbox'
							onClick={() => {
								if (selectedEstates.some((estate) => estate.id === item.id)) {
									setSelectedEstates(
										selectedEstates.filter((estate) => estate.id !== item.id),
									);
								} else {
									setSelectedEstates((selectedEstates) => [
										...selectedEstates,
										item,
									]);
								}
							}}>
							<UilCheck
								color={
									selectedEstates.some((estate) => estate.id === item.id)
										? '#506CF9'
										: '#fff'
								}
								size={20}
							/>
						</label>
					</div>
					{/* estate map */}
				</div>
			</div>
		</Popup>
	);
}
