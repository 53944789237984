import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import capitalizeFirstCharacter from '../../helpers/capitalizeFirstCharacter'
import Toast from '../../components/Toast/Toast'
import Page from '../../layout/Page/Page'
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew'
import ThemeContext from '../../contexts/themeContext'
import classNames from 'classnames';
import Card, { CardActions, CardBody, CardHeader } from '../../components/bootstrap/Card'
import { Swiper, SwiperSlide } from "swiper/react";
import useFilters from '../../hooks/useFilters';
import FiltersMenu from '../customers/FiltersMenu'
import Input from '../../components/bootstrap/forms/Input'
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from "swiper/modules";
import {
    UilUsersAlt,
    UilSearch,
    UilMultiply,
    UilSubject,
    UilAngleDown,
    UilAngleUp,
    UilPlus,
    UilUserLocation,
    UilAngleRight,

} from '@iconscout/react-unicons';
import InfoCustomer from './InfoCustomer'
import getOperadores from '../../services/operatorsService'
import ModalCreateStatus from './ModalCreateStatus'
import { changeMultipleSellers, createNewStatus, getAllCustomerStatus, getDataCustomerId , changeMultipleStatus} from '../../services/customersServices'
import UserContext from '../../contexts/userContext'
import { showToast } from '../../components/Toast/toastUtils/toastUtils'
import { getBranches } from '../../services/branchesServices'
import { getEventType, getLeadIncomeTypes } from '../../services/sistemServices'
import Check from '../../components/Check'
import CommonFilterHeadStatusCustomers from '../common/Headers/CommonFilterHeadStatusCustomers'
import InfoAccordionCustomer from './InfoAccordionCustomer'

const ClientStatusPage = () => {
    const [measuresSwiperHorizontal, setMeasuresSwiperHorizontal] = useState({});
    const [widthWindows, setWidth] = useState({
        winWhidth: window.innerWidth,
    })
    const [statusCustomers, setStatusCustomers] = useState({});
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [collectSelectedTab, setCollectSelectedTab] = useState([]);

    const [list, setList] = useState([])
    const [activeFilters, setActiveFilters] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState({
        sort: {
            field: 'join_date',
            order: 'desc',
        }
    });
    const [verticalView, setVerticalView] = useState(false)
    const [openIndexes, setOpenIndexes] = useState([]);
    const [changeIcon, setChangeIcon] = useState(false)
    const [viewModalInfoCustomer, setViewModalInfoCustomer] = useState({
        isShow: false,
        itemData: null
    })
    const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
    const [operatorList, setOperatorsList] = useState([]);
    const [listCustomerId, setListCustomerId] = useState([]);
    const [incomeTypes, setIncomeTypes] = useState([]);
    const [viewModalCreateStatus, setViewModalCreateStatus] = useState(false)
    const [reason, setReason] = useState([]);
    const [status, setStatus] = useState({});
    const { token } = useContext(UserContext);

    const debounceRef = useRef(null);
    const swiperRefH = useRef(null)
    const filters = useFilters('customers');

    const mappingColor = ['#4d69fa', '#f35421', '#ffcf52', '#20B21D', '#1f2128', '#e7eef8']

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, {
        template: '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner custom-tooltip shadow-custom-card px-3 fs-6 bg-white text-black "></div></div>'
    })
})

    const isFullCustomers = (indexStatus) => {
        const maxCustomers = statusCustomers.data[indexStatus].customers?.length || -1
        const countCustomersList = listCustomerId.find(item => item.indexStatus === indexStatus)
        if (countCustomersList.customersIDS.length == maxCustomers) {
            return true
        }
        return false
    }


    const scrollToStatus = (index) => {
        const element = document.getElementById(`accordionPanelsStayOpenExample${index}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            const accordion = document.getElementById(`accordionElement${index}`)

            accordion.setAttribute('aria-expanded', 'true')

            const accordionItems = document.getElementById(`panelsStayOpen-collapse${index}`)
            accordionItems.classList.add('show');

            setOpenIndexes([...openIndexes, index])
        }
    };


    const handleCheckbox = ({ customerId, indexStatus }) => {
        const allCustomersIds = statusCustomers.data[indexStatus].customers.map(customer => customer.id);
        const existingEntry = listCustomerId.find(entry => entry.indexStatus === indexStatus);

        if (!customerId) {
            const maxCustomers = allCustomersIds.length;
            const maxCustomersSection = existingEntry.customersIDS.length
            maxCustomers === maxCustomersSection ?
                setListCustomerId(prevList => prevList.filter(item => item.indexStatus !== indexStatus))
                :
                setListCustomerId(prevList => {
                    const updatedList = prevList.map(item => {
                        if (item.indexStatus === indexStatus) {
                            return {
                                ...item,
                                customersIDS: allCustomersIds
                            };
                        }
                        return item;
                    });
                    return updatedList;
                });
        } else {
            const newCustomersIDS = [customerId];
            const updatedCustomersIDS = existingEntry ?
                existingEntry.customersIDS.includes(customerId) ?
                    existingEntry.customersIDS.filter(id => id !== customerId) :
                    [...existingEntry.customersIDS, ...newCustomersIDS] :
                newCustomersIDS;

            const updatedEntry = { indexStatus, customersIDS: updatedCustomersIDS };
            const updatedList = existingEntry ?
                listCustomerId.map(entry => entry.indexStatus === indexStatus ? updatedEntry : entry) :
                [...listCustomerId, updatedEntry];

            setListCustomerId(updatedList.filter(entry => entry.customersIDS.length > 0));
        }

    }

    const detectWidth = () => {
        setWidth({
            winWhidth: window.innerWidth,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', detectWidth)
        return (() => {
            window.removeEventListener("resize", detectWidth)
        })
    }, [widthWindows])

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getAllCustomerStatus(filtersApplied, token);
            setStatusCustomers({
                data: result,
            });
        } catch (error) {
            console.error('Error al obtener datos:', error);
        } finally {
            setOpenIndexes([])
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        setOpenIndexes([])
    }, [filtersApplied]);



    
    useEffect(() => {
        Promise.all([getOperadores(), getBranches(token), getLeadIncomeTypes(token), getEventType(token)])
            .then(([operators, branches, incomeTypes , eventTypes]) => {
                setOperatorsList(operators);
                setBranches(branches);
                setIncomeTypes(incomeTypes);
			    setReason(eventTypes);
            })
            .catch(error => console.error('Error al obtener datos:', error));
    }, []);

    const handleSubmitStatus = () => {
        createNewStatus(status, token).then((res) => {
            if (res.status == 200) {
                const { message, data } = res.data
                showToast(message, setList, 'success')
                fetchData();
                setStatus({})
                setViewModalCreateStatus(false)
                } else{
                setViewModalCreateStatus(false)
                showToast('error', setList, 'danger')
                 }
        })
    }

    const changeSellersMan = ({ indexStatus, operatorId, customerId }) => {

        let data = {}

        if (customerId && operatorId) {
            data = {
                seller: operatorId,
                customers: customerId
            }
        }

            changeMultipleSellers(data, token).then((result) => {
                if (result.status === 200) {

                    setStatusCustomers(prevStatusCustomers => {

                        const updatedStatusCustomers = { ...prevStatusCustomers };

                        updatedStatusCustomers.data[indexStatus].customers.forEach(customer => {
                            if (customerId.includes(customer.id)) {
                                customer.seller = operatorId;
                            }
                        });
                        return updatedStatusCustomers;
                    });

                    setListCustomerId(prevList => prevList.filter(entry => entry.indexStatus !== indexStatus))
                    showToast(result.data.message, setList, 'success')
                }
            })
            .catch((err) => console.log(err))
    }

    const changeMultipleCustomerStatus = ({indexStatus , statusId , customerId}) => {
        let data = {}

        if (customerId && statusId) {
            data = {
                status: statusId,
                customers: customerId
            }
        }

        const response = changeMultipleStatus(data , token)
        .then((result) => {
            if (result.status === 200){
                fetchData()
                setListCustomerId(prevList => prevList.filter(entry => entry.indexStatus !== indexStatus))
                showToast('Cambio estado realizado con exito', setList, 'success')
            }
        })
        .catch((err) => {
            showToast(response.message, setList, 'danger')
        }
    )}

    const getHeightSwiper = () => {
        const getHeight = () => {
            if (swiperRefH.current) {
                const height = swiperRefH.current.clientHeight;
                setMeasuresSwiperHorizontal({
                    height: height,
                });

            }
        };
        const timeoutId = setTimeout(getHeight, 100);

        return () => clearTimeout(timeoutId);
    }

    const slidersViewVertical = (e, cuantitiSatus) => {
        const { height } = measuresSwiperHorizontal;
        const widthTotal = widthWindows.winWhidth - 264
        const personQuantity = e.customers.length;
        const letterHeight = 120;
        const separation = 10;

        let w, h;

        const sliderWidth = (widthTotal - 100) / cuantitiSatus;


        if (mobileDesign) {
            w = '100%';
        } else {
            if (verticalView) {
            }
            if (sliderWidth < 260) {
                w = verticalView ? '350px' : '253px';
            } else {
                w = `${verticalView ? sliderWidth : sliderWidth - 5}px`;
            }
        }
        if (!verticalView) {
            w = `${parseFloat(w + 50)}px`; 
            h = '120px';
            return { width: w, height: h };
        }

        if (personQuantity * letterHeight + letterHeight + separation * personQuantity + 10 > height) {
            h = '95%';
        } else {
            if (personQuantity === 1) {
                h = `${personQuantity * letterHeight + letterHeight + 10}px`;
            } else {
                h = `${personQuantity * letterHeight + letterHeight + separation * personQuantity + 10}px`;
            }
        }
        return { width: w, height: h };
    }

    const handleToggleAccordion = (index) => {
        if (openIndexes.includes(index)) {
            setOpenIndexes(openIndexes.filter((item) => item !== index));
        } else {
            setOpenIndexes([...openIndexes, index]);
        }
    };


    // Funcion del buscador del header de la carta
    const onQueryChange = (value) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            setFiltersApplied((prevFiltersApplied) => ({
                ...prevFiltersApplied,
                    query: value,
            }));
        }, 500);
    };
    // Funcion del buscador del header de la carta HASTA ACA

    return (
        <PageWrapper
            className={`${mobileDesign && 'p-0'} `} title={capitalizeFirstCharacter('Estado de Clientes')}>
            <Toast toastlist={list} position="top-right" setList={setList} />
            <Page container={mobileDesign ? 'fluid p-0' : 'fluid'}>
                {/* Menu Estado de Cliente Filtros Exportar */}
                <SubheaderNew
                    className={'justify-content-center gap-3 '}
                    breadCrumbs={[
                        {
                            name: capitalizeFirstCharacter('Estado de Clientes'),
                            url: `/estado-cliente`,
                        },
                    ]}
                    filtersFunc={setActiveFilters}
                    filtersStatus={activeFilters}
                    withFiltersButton
                    withExportButton={mobileDesign ? false : true}
                    withSearchButton
                />
                {/* Menu Estado de Cliente Filtros Exportar HASTA AQUI */}


                {/* Section de filtros Cuando se apreta en filtros */}
                {activeFilters && mobileDesign && (
                    <div
                        className='d-flex align-items-center justify-content-center w-100'
                        style={{
                            height: '10rem',
                            borderRadius: '2rem 2rem 0rem 0rem',
                            background:
                                'linear-gradient(180deg, #DCE5FF 0%, rgba(220, 229, 255, 0) 100%)',
                            position: 'relative',
                            bottom: '1.8rem',
                        }}>
                        <h4 className='text-color-dark mb-4 fw-bold'>Filtro de Búsqueda</h4>
                        <UilMultiply
                            color='#506CF9'
                            style={{ position: 'absolute', top: '10%', right: '3%' }}
                            onClick={() => setActiveFilters(false)}
                        />
                    </div>
                )}
                {/* Section de filtros Cuando se apreta en filtros HASTA ACA */}

                {/* Esto es la Carta y el contenido */}
                <Card
                    stretch
                    style={{
                        position: mobileDesign && activeFilters && 'relative',
                        bottom: mobileDesign && activeFilters && '3rem',
                    }}>

                    {mobileDesign ?
                        (<div className='d-flex flex-column w-100 list-mobile'>
                            {activeFilters ? (
                                <FiltersMenu
                                    filtersApplied={filtersApplied}
                                    setFiltersApplied={setFiltersApplied}
                                />
                            ) : (<div></div>)}
                        </div>)
                        :
                        (<>
                            {/* Esto es el headeer de la carta con el buscador */}
                            <CardHeader

                                style={{ 'border-bottom': '1px solid rgba(183, 184, 188, 0.5)' }}>
                                <div className='titulo-alerta my-2'>
                                    <UilUsersAlt size={32} color='#506CF9' />
                                    <span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>
                                        ESTADO DE CLIENTE
                                    </span>
                                </div>
                                {!activeFilters && (
                                    <CardActions>
                                        {/* Search input */}
                                        <div
                                            className={classNames(
                                                'd-flex align-items-center rounded-pill py-1 px-2',
                                                { 'search-input-header-dark': darkModeStatus },
                                                { 'search-input-header': !darkModeStatus },
                                            )}>
                                            <label
                                                className='border-0 bg-transparent cursor-pointer me-0 ms-2'
                                                htmlFor='searchInput'>
                                                <UilSearch
                                                    color='#506CF9'
                                                    size={12}
                                                />
                                            </label>
                                            <Input
                                                id='searchInput'
                                                type='search'
                                                className='border-0 shadow-none bg-transparent input-search-customers'
                                                placeholder='Buscar...'
                                                onChange={(e) => onQueryChange(e.target.value, 'Query')}
                                            />
                                        </div>
                                        {/* Search input */}
                                    </CardActions>
                                )}
                            </CardHeader>
                            {/* Esto es el headeer de la carta con el buscador HASTA ACA */}
                        </>)}

                    {/* Esto es el esto es el cardBody*/}
                    <CardBody className={`table-responsive  d-flex flex-column ${verticalView && 'card-body-scrollable'}`}>

                        <div className={`d-flex flex-column  ${verticalView && 'h-100'}`}>
                            {!mobileDesign && !activeFilters &&
                                <div className='d-flex justify-content-between mx-4 my-4 mt-5' >
                                    {/* section para las vistas de canbas */}
                                    <div className='cursor-pointer d-flex'>
                                        <div onClick={() => setVerticalView(false)} className={`px-2 py-1 ${!verticalView ? 'bg-info' : 'bg-custom-newGray'}  custom-orden-list`}>
                                            <UilSubject size={24} color={`${!verticalView ? '#fff' : '#506cf9'}`} className='m-1 ' />
                                        </div>
                                        <div onClick={() => {
                                            getHeightSwiper()
                                            setOpenIndexes([])
                                            setListCustomerId([])
                                            setVerticalView(true)
                                        }}
                                            className={`px-2 custom-orden-cards ${verticalView ? 'bg-info' : 'bg-custom-newGray'}  py-1`}>
                                            <UilSubject size={24} color={`${verticalView ? '#fff' : '#506cf9'}`} transform="rotate(-90)" className='m-1' />
                                        </div>
                                        {/* section para las vistas de canbas HASTA ACA */}
                                    </div>

                                    <CommonFilterHeadStatusCustomers filtersApplied={filtersApplied} setFiltersApplied={setFiltersApplied} branches={branches} operatorList={operatorList} incomeTypes={incomeTypes} sort={true} />
                                </div>
                            }

                            {activeFilters ? (
                                <FiltersMenu
                                    filtersApplied={filtersApplied}
                                    setFiltersApplied={setFiltersApplied}/>
                            ) : loading ? (
                                <div className='w-100 d-flex my-5 justify-content-center'>
                                    <h2>Cargando...</h2>
                                </div>
                            ) : (
                                <>
                                    {!mobileDesign && (
                                        <div className={`h-100 d-flex ${mobileDesign ? 'flex-column gap-2 pt-3 ' : 'card-body-scrollable'} align-items-center mx-4`}>
                                            <Swiper
                                                ref={swiperRefH}
                                                grabCursor={true}
                                                slidesPerView={'auto'}
                                                spaceBetween={verticalView ? 20 : 13}
                                                scrollbar={{hide: false,}}
                                                modules={[Scrollbar]}
                                                className="mySwiper w-100 h-100 m-0"
                                                style={{}}>
                                                {statusCustomers.data?.map((e, i) => {
                                                    let res = slidersViewVertical(e, statusCustomers.data.length)
                                                    return (
                                                        <SwiperSlide
                                                            key={i}
                                                            style={res}
                                                            onClick={() => scrollToStatus(i)}
                                                            className={` d-flex flex-column custom-rounded px-4 pt-4 ${!verticalView && 'pb-2'}  shadow-custom-card bg-custom-newGray mb-2`}>
                                                            <div className={`w-100 d-flex flex-column ${!verticalView ? 'h-100 justify-content-between' : 'justify-content-around '} fs-5`}>
                                                                <div style={{ 'height': '9px' }} className={`progress`}>
                                                                    <div className="progress-bar" style={{ 'width': `${100 / statusCustomers.data?.length * (i + 1)}%`, 'backgroundColor': `${e.statusData?.color ? e.statusData.color : mappingColor[i]}` }} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div className={` d-flex flex-column custom-leter-swiper ${verticalView && 'mb-5 mt-4'} `}>
                                                                    <strong className='fs-4 '>{e.name} {verticalView && <span className='fw-normal  ms-2 '>({e.customersCount || 0})</span>}</strong>
                                                                    {!verticalView && <label className='fs-6 m-0 p-0 '> <span className='fs-5'> {e.customersCount}</span> Interesados</label>}
                                                                </div>
                                                            </div>

                                                            {verticalView &&
                                                                <div className='custom-class-swiper-vertical d-grid overflow-hidden '>
                                                                    <Swiper
                                                                        grabCursor={true}
                                                                        direction='vertical'
                                                                        modules={[Scrollbar]}
                                                                        cssMode={true}
                                                                        className='mySwiper m-0 p-0 '>
                                                                        {e.customers.map((item, index) => {
                                                                            return (
                                                                                <div key={i + index} className='d-flex flex-column'>
                                                                                    <SwiperSlide className='bg-white p-3 px-4 mb-3 shadow-custom-card custom-rounded custom-card-swiper' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                    {/* <!-- Button trigger modal --> */}
                                                                                        <div onClick={() => {
                                                                                            setViewModalInfoCustomer({
                                                                                                isShow: true,
                                                                                                itemData: item
                                                                                            })
                                                                                        }} className=' w-100 h-100  d-flex position-relative flex-column'>
                                                                                            <div class="fs-5 text-black position-relative m-0 fw-bold d-inline text-truncate p-0">{item.summary.name}</div>
                                                                                            <div className='d-flex'>
                                                                                                <div className='fs-5 py-2'>
                                                                                                    {item.address.length > 0 &&
                                                                                                        <>
                                                                                                            <UilUserLocation className='me-2 ' size={26} color='#506cf9' />
                                                                                                            {item.address[0]}
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className=''>
                                                                                                    <img src="https://avatar.iran.liara.run/public/38" alt="Avatar" style={{
                                                                                                        'position': 'absolute',
                                                                                                        'right': '0px',
                                                                                                        'bottom': '30px',
                                                                                                        'width': '35px',
                                                                                                        'height': '35px',
                                                                                                    }} class="card-avatar " /></div>
                                                                                            </div>
                                                                                            <div className='position-absolute bottom-0 text-white bg-primary custom-rounded text-uppercase p-1 px-2'>{item.portal?.name}</div>
                                                                                        </div>
                                                                                    </SwiperSlide>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </Swiper>
                                                                </div>
                                                            }
                                                        </SwiperSlide>
                                                    )
                                                })}

                                                {verticalView ?
                                                    <SwiperSlide key={'uilPul'} className=' h-100 d-flex align-items-center width-custom-swipoerSlide  justify-content-center custom-rounded custom-card-swiper'>
                                                        <div onClick={() => setViewModalCreateStatus(true)} className='d-flex bg-custom-newGray mt-auto mb-auto rounded-custom-addStatus justify-content-center align-items-center'>
                                                            <UilPlus size={!verticalView ? '20' : '20'} color={'#506CF9'} />
                                                        </div>
                                                    </SwiperSlide>
                                                    :
                                                    <div className='p-2'>
                                                    </div>
                                                }
                                            </Swiper>
                                            {!verticalView && !mobileDesign &&
                                                <div onClick={() => setViewModalCreateStatus(true)} style={{ 'minWidth': '41px', 'marginLeft': '15px' }} className='d-flex bg-custom-newGray rounded-custom-addStatus justify-content-center align-items-center'>
                                                    <UilPlus size={!verticalView ? '20' : '20'} color={'#506CF9'} />
                                                </div>
                                            }
                                        </div>
                                    )}
                                    {viewModalCreateStatus && <ModalCreateStatus statusCustomers={statusCustomers} viewModalCreateStatus={viewModalCreateStatus} handleSubmitStatus={handleSubmitStatus} setViewModalCreateStatus={setViewModalCreateStatus} status={status} setStatus={setStatus}/>}
                                    
                                     <div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                                        <div class="modal-dialog modal-dialog-centered modal-dialog-custom ">
                                            <div class="modal-content">
                                            {viewModalInfoCustomer.isShow && 
                                                <>
                                                    <div class="modal-header"><h4 class="modal-title">OPORTUNIDAD: {viewModalInfoCustomer.itemData.name}</h4></div>
                                                    <div class="modal-body">
                                                    <InfoCustomer reason={reason} statusCustomers={statusCustomers.data}  filters={filters.data} viewModalInfoCustomer={viewModalInfoCustomer} customer={viewModalInfoCustomer.itemData}/>
                                                    </div>
                                                </>
                                                 }
                                            </div>
                                        </div>
                                    </div>
                                    {/* accordion part */}
                                    {!verticalView &&
                                        <div className='gap-0 my-4 gap-3 d-flex flex-column'>

                                            {statusCustomers.data?.map((status, indexStatus) => {
                                                return (
                                                    <div key={indexStatus} className="accordion" id={`accordionPanelsStayOpenExample${indexStatus}`}>
                                                        <div className="accordion-item accordion-item-custom">
                                                            <h2 className={`accordion-header rounded-1 accordion-header-custom mx-4`} id={`panelsStayOpen-heading${indexStatus}`} style={{ 'backgroundColor': `${status.statusData.color ? status.statusData.color : mappingColor[indexStatus]}` }}>
                                                                <div
                                                                    onClick={()=>{handleToggleAccordion(indexStatus)}}
                                                                    id={`accordionElement${indexStatus}`}
                                                                    className="accordion-button p-0 rounded-0 text-black gap-2" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${indexStatus}`} aria-expanded="false" aria-controls={`panelsStayOpen-collapse${indexStatus}`} style={{ 'backgroundColor': '#F5F6F8' }}>
                                                                    {openIndexes.includes(indexStatus) ? <UilAngleDown size={30} /> : <UilAngleRight size={30} />} 
                                                                    <span className={`d-flex ${mobileDesign && 'w-100 justify-content-between '}`}>
                                                                    <div>{status.name}</div> 
                                                                    {mobileDesign &&
                                                                    <div className='fw-light fs-6 me-3 '>Interesados ({status.customersCount})</div>
                                                                    }
                                                                    </span>
                                                                    {!mobileDesign && listCustomerId.length > 0 && listCustomerId.some(entry => entry.customersIDS.some(customerID => status.customers?.some(customer => customer.id === customerID))) && (
                                                                        <div
                                                                            onClick={(e) => e.stopPropagation()}
                                                                            className='d-flex'>
                                                                            <div onClick={(e) => e.stopPropagation()} className=' justify-content-center d-flex ps-3  align-items-center' data-bs-toggle="collapse">
                                                                                <Check checked={isFullCustomers(indexStatus)} clickHandler={(e) => handleCheckbox({ indexStatus })} />
                                                                            </div>
                                                                            <div className='justify-content-center d-flex ps-5' data-bs-toggle="collapse" data-bs-target>
                                                                                <div
                                                                                    rounded={1}
                                                                                    data-bs-toggle='dropdown'
                                                                                    aria-expanded='false'
                                                                                    className='cursor-pointer bg-white custom-rounded px-2 py-1'
                                                                                    style={{ 'width': '180px' }}>
                                                                                    <div className='d-flex align-items-center cursor-pointer justify-content-between'>
                                                                                        <p className='user-select-none my-0 fw-normal text-truncate'>Cambiar estado</p>
                                                                                        {changeIcon ? <UilAngleUp size={24} color='#506cf9' /> : <UilAngleDown size={24} color='#506cf9' />}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter' style={{ width: '15rem' }}>
                                                                                    {statusCustomers.data?.map((status, index) => (
                                                                                        
                                                                                        <div onClick={(e) => changeMultipleCustomerStatus({statusId : status.statusId , customerId : listCustomerId.find(entry => entry.indexStatus === indexStatus)?.customersIDS || [] , indexStatus : indexStatus })} key={index} className='d-flex align-items-center p-2 fs-6 cursor-pointer custom-dropdown-item'>
                                                                                            <p className='m-0 ps-2'>{status.name}</p>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>

                                                                            <div className=' justify-content-center d-flex ps-5' data-bs-toggle="collapse" data-bs-target>
                                                                                <div
                                                                                    rounded={1}
                                                                                    data-bs-toggle='dropdown'
                                                                                    aria-expanded='false'
                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                    className='cursor-pointer bg-white custom-rounded px-2 py-1'
                                                                                    style={{ 'width': '180px' }}>
                                                                                    <div className='d-flex align-items-center cursor-pointer justify-content-between'>
                                                                                        <p className='user-select-none my-0 fw-normal text-truncate'>Enviar e-mail</p>
                                                                                        {changeIcon ? <UilAngleUp size={24} color='#506cf9' /> : <UilAngleDown size={24} color='#506cf9' />}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter' style={{ width: '15rem' }}>
                                                                                </div>
                                                                            </div>
                                                                            <div className='justify-content-center d-flex ps-5' data-bs-toggle="collapse" data-bs-target>
                                                                                <div
                                                                                    rounded={1}
                                                                                    data-bs-toggle='dropdown'
                                                                                    aria-expanded='false'
                                                                                    className='cursor-pointer bg-white custom-rounded px-2 py-1'
                                                                                    style={{ 'width': '180px' }}>
                                                                                    <div className='d-flex align-items-center cursor-pointer justify-content-between'>
                                                                                        <p className='user-select-none my-0 fw-normal text-truncate'>Asignar vendedor</p>
                                                                                        {changeIcon ? <UilAngleUp size={24} color='#506cf9' /> : <UilAngleDown size={24} color='#506cf9' />}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter' style={{ width: '15rem' }}>
                                                                                    {operatorList?.map((operator, index) => (
                                                                                        <div onClick={(e) => changeSellersMan({ operatorId: operator.id, customerId: listCustomerId.find(entry => entry.indexStatus === indexStatus)?.customersIDS || [], indexStatus: indexStatus })} key={index} className='d-flex align-items-center p-2 fs-6 cursor-pointer custom-dropdown-item'>
                                                                                            <p className='m-0 ps-2'>{operator.name} {operator.surname}</p>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </h2>
                                                            <div id={`panelsStayOpen-collapse${indexStatus}`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-heading${indexStatus}`}>
                                                                <div className="accordion-body gap-3 d-flex flex-column accordion-body-custom">
                                                                    {status.customers?.map((customer, indexCustomer) => {
                                                                        const isChecked = listCustomerId.some(entry => entry.indexStatus === indexStatus && entry.customersIDS.includes(customer.id))
                                                                        return (
                                                                            <InfoAccordionCustomer key={indexCustomer} customer={customer} operatorList={operatorList} changeSellersMan={changeSellersMan} isChecked={isChecked} filters={filters} reason={reason} handleCheckbox={handleCheckbox} indexStatus={indexStatus} status={status} statusCustomers={statusCustomers.data}/>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    {/* accordion part HASTA ACA */}
                                </>

                            )}
                        </div>
                    </CardBody>

                </Card>
                {/* Esto es la Carta y el contenido HASTA ACA */}
            </Page>

        </PageWrapper>
    )
}

export default ClientStatusPage