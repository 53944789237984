import React from 'react';
import Tab1 from './tab1';
import Tab2 from './tab2';
import Tab3 from './tab3';


const Tabselector = ({ selectedTab }) => {
    console.log(selectedTab)
	switch (selectedTab) {
		case 'CONSULTAS POR CANAL':
			return <Tab1/>;

		case 'CONSULTAS POR OPERACION':
			return <Tab2 />;

		case 'CONVERSIÓN POR CANAL':
			return <Tab3 />
		default:
			break;
	}
};

export default Tabselector;