import React, { useState } from 'react';
import {
	UilTrashAlt,
	UilLink,
	UilPlus,
	UilRotate360,
	UilAngleUp,
	UilAngleDown,
} from '@iconscout/react-unicons';
import { FilePdf, YoutubeLogo } from 'phosphor-react';
import CustomDropdown from '../../components/CustomDropdown';

export default function Multimedia() {
	const [showMore, setShowMore] = useState(false);

	return (
		<>
			{showMore && (
				<div className='d-flex flex-column align-items-center pt-5 w-100'>
					<div className='d-flex justify-content-center border border-blue-300 rounded bg-white px-4 py-3 text-color-lblue fw-bold mb-5 w-100 fs-5'>
						Seleccione imagen
					</div>

					<div
						style={{
							backgroundImage: `url(https://www.architecturalrecord.com/ext/resources/Issues/2022/04-April/House-in-Colima-01.jpg)`,
							backgroundSize: 'cover',
							height: '197px',
							width: '90%',
							position: 'relative',
						}}
						className='rounded m-4'>
						<div className='d-flex align-items-center justify-content-center bg-white text-color-lblue fw-bold px-4 py-2 rounded-5px position-absolute top-5 start-5 col-1'>
							001
						</div>

						<div className='d-flex align-items-center justify-content-center bg-white text-color-lblue fw-bold p-1 rounded-5px position-absolute top-5 end-5 cursor-pointer'>
							<UilTrashAlt color='#EF2206' size={24} />
						</div>
					</div>

					<CustomDropdown
						label={'Videos, enlaces y más'}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-white shadow p-4 text-color-lblue rounded-bottom px-4 w-100'
						}
						margin='mb-4'
						width='w-100'>
						<div className='d-flex flex-column align-items-center w-100'>
							<div className='d-flex align-items-center col px-3 py-1'>
								<div className='d-flex align-items-center p-3 bg-lblue rounded-start innerShadowBlue w-100'>
									<UilLink color='#506CF9' size={24} />
									<input
										className='no-outline text-color-lblue border-0 fs-5 add-field-input bg-transparent'
										placeholder='URL'
									/>
								</div>
								<div className='bg-blue-300 p-3 rounded-end ms-2'>
									<UilPlus color='#fff' size={24} />
								</div>
							</div>
							<div className='d-flex align-items-center col px-3 py-1'>
								<div className='d-flex align-items-center p-3 bg-lblue rounded-start innerShadowBlue w-100'>
									<UilRotate360 color='#506CF9' size={24} />
									<input
										className='no-outline text-color-lblue border-0 fs-5 add-field-input bg-transparent'
										placeholder='Tour 360º'
									/>
								</div>
								<div className='bg-blue-300 p-3 rounded-end ms-2'>
									<UilPlus color='#fff' size={24} />
								</div>
							</div>
							<div className='d-flex align-items-center col px-3 py-1'>
								<div className='d-flex align-items-center p-3 bg-lblue rounded-start innerShadowBlue w-100'>
									<FilePdf color='#506CF9' size={24} />
									<input
										className='no-outline text-color-lblue border-0 fs-5 add-field-input bg-transparent'
										placeholder='Seleccionar archivo'
									/>
								</div>
								<div className='bg-blue-300 p-3 rounded-end ms-2'>
									<UilPlus color='#fff' size={24} />
								</div>
							</div>
							<div className='d-flex align-items-center col px-3 py-1'>
								<div className='d-flex align-items-center p-3 bg-lblue rounded-start innerShadowBlue w-100'>
									<YoutubeLogo color='#506CF9' size={24} />
									<input
										className='no-outline text-color-lblue border-0 fs-5 add-field-input bg-transparent'
										placeholder='Enlace de Youtube'
									/>
								</div>
								<div className='bg-blue-300 p-3 rounded-end ms-2'>
									<UilPlus color='#fff' size={24} />
								</div>
							</div>
						</div>
					</CustomDropdown>
				</div>
			)}
			<div
				className='d-flex justify-content-center w-100'
				onClick={() => setShowMore(!showMore)}>
				{showMore ? (
					<UilAngleUp size={50} color='#506CF9' />
				) : (
					<UilAngleDown size={50} color='#506CF9' />
				)}
			</div>
		</>
	);
}
