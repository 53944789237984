import React from 'react';
import Generales from './Generales';
import Inmuebles from './Inmuebles';
import Clientes from './Clientes';
import TareaNovedades from './TareaNovedades';
import Cobranzas from './Cobranzas';
import Etiquetas from './Etiquetas';
import PaginaWeb from './PaginaWeb';
import Integraciones from './Integraciones';
const ChildrenSelector = ({ selectedTab, setUserData, userData }) => {
	switch (selectedTab) {
		case 'GENERALES':
			return <Generales userData={userData} setUserData={setUserData} />;

		case 'INMUEBLES':
			return <Inmuebles userData={userData} setUserData={setUserData} />;

		case 'CLIENTES':
			return <Clientes userData={userData} setUserData={setUserData} />;
		case 'TAREA - NOVEDADES':
			return <TareaNovedades userData={userData} setUserData={setUserData} />;
		case 'INTEGRACIONES':
			return <Integraciones userData={userData} setUserData={setUserData} />;
		case 'PAGINA WEB':
			return <PaginaWeb userData={userData} setUserData={setUserData} />;
		case 'COBRANZAS':
			return <Cobranzas userData={userData} setUserData={setUserData} />;
		case 'ETIQUETAS':
			return <Etiquetas userData={userData} setUserData={setUserData} />;

		default:
			break;
	}
};

export default ChildrenSelector;
