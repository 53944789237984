import axios from 'axios';
const baseURL = process.env.REACT_APP_ENDPOINT;

export const getFilters = async (type, token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const data = await axios({
		method: 'GET',
		url: `${baseURL}/app/filters/${type}`,
		headers: header,
	}).then((res) => res.data);

	return data;
};
// export const getProperties = async (token) => {
// 	const header = {
// 		Authorization: `Bearer ${token}`,
// 	};

// 	const response = await axios({
// 		method: 'POST',
// 		url: `${baseURL}/properties`,
// 		headers: header,
// 	}).then((res) => {
// 		return res
// 	});

// }

export const getProperties = async ( searchProfile = {} ,token) => {

	const header = {
		Authorization: `Bearer ${token}`,
	};
	
	const body = {
		filters: {
			"query": null,
			"propertyType": searchProfile.propertyTypes,
			"operation": searchProfile.operations,
			"valueMin":  searchProfile.valueMin,
			"valueMax": searchProfile.valueMax,
			"currency": searchProfile.currency,
			"neighborhood": searchProfile.neighborhoods,
			"project": searchProfile.project,
			"location": searchProfile.location,
			"suitableCredit": searchProfile.suitableCredit,
			"activity": null,
			"antiqueness": searchProfile.antiquity,
			"garage":searchProfile.garage,
			"maxArea": searchProfile.maxArea,
			"rooms" : searchProfile.rooms,
			"page": null,
			// "customerType": searchProfile,
			"limit": 5,
			"sort": {
			  "field": "surname",
			  "order": "desc"
			}
		  }
	};


	const data = await axios({
		method: 'POST',
		url: `${baseURL}/properties`,
		headers: header,
		data: body,
	}).then((res) => res.data);


	return data;
};
