import { UilArrowCircleUp, UilEllipsisH, UilCheck, UilEdit } from '@iconscout/react-unicons';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
// import NoPostsBanner from './NoPostsBanner';
import PaginationButtons from '../../components/PaginationButtons';


export default function NotPublishedTable({
	selectedEstates,
	setSelectedEstates,
	portalName,
	posts,
	filters,
	setFilters,

}) {
	const thList = [
		{
			name: 'Foto',
			nameEng: 'photo',
			isSortable: false,
		},
		{
			name: 'Tipología',
			nameEng: 'type',
			isSortable: false,
		},
		{
			name: 'Dirección',
			nameEng: 'location',
			isSortable: false,
		},

		{
			name: 'Ubicación',
			nameEng: 'neighborhood',
			isSortable: false,
		},
		{
			name: 'Motivo',
			nameEng: 'reason',
			isSortable: false,
		},
	];

	if (posts.isLoading || posts.isFetching)
		return (
			<div className='d-flex w-100 justify-content-center py-5'>
				<h3>Cargando publicaciones...</h3>
			</div>
		);

	// if (posts.data.data?.length === 0) return <NoPostsBanner portal={portalName} />;

	return (
		<table>
			<thead className='bg-lblue'>
				<tr className='d-flex'>
					<th className='col py-2 text-color-lblue fw-bold'>
						<div className='d-flex align-items-center justify-content-center w-100 h-100'>
							<label
								className='custom-checkbox'
								onClick={() => {
									if (selectedEstates.length === posts.data?.data?.length) {
										setSelectedEstates([]);
									} else {
										setSelectedEstates(posts.data?.data);
									}
								}}>
								<UilCheck
									color={
										selectedEstates.length === posts.data?.data?.length &&
										posts.data?.data?.length !== 0
											? '#506CF9'
											: '#fff'
									}
									size={20}
								/>
							</label>
						</div>
					</th>
					{thList.map((th) => (
						<th className='col py-4 text-color-lblue fw-bold estateTh'>
							{
								<div
									className={`d-flex align-items-center justify-content-start w-100 h-100 ${
										th.isSortable && 'cursor-pointer text-decoration-underline'
									}`}
									// onClick={() =>
									// 	sortHandler(th.isSortable && th.nameEng)
									// }
								>
									{th.name}
								</div>
								// )
							}
						</th>
					))}
				</tr>
			</thead>
			{/* <tbody> */}
			{posts.data?.data?.length === 0 ? (
				<tr>
					<div className='d-flex justify-content-center w-100 fs-3 fw-bold py-5'>
						No hay inmuebles sin publicar
					</div>
				</tr>
			) : (
				posts.data.data?.map((item) => (
					<tr className='d-flex py-2 align-items-center border-bottom'>
						<td className='col pe-2'>
							<div
								className='d-flex align-items-center justify-content-center w-100 h-100 position-relative'
								style={{ left: 3 }}>
								<label
									className='mx-4 custom-checkbox'
									onClick={() => {
										if (
											selectedEstates.some((estate) => estate.id === item.id)
										) {
											setSelectedEstates(
												selectedEstates.filter(
													(estate) => estate.id !== item.id,
												),
											);
										} else {
											setSelectedEstates((selectedEstates) => [
												...selectedEstates,
												item,
											]);
										}
									}}>
									<UilCheck
										color={
											selectedEstates.some((estate) => estate.id === item.id)
												? '#506CF9'
												: '#fff'
										}
										size={20}
									/>
								</label>

								<div className='position-absolute' style={{ marginLeft: '6rem' }}>
									<UilEllipsisH
										color='#506CF9'
										size={25}
										className='cursor-pointer'
										id={`a${item.id}`}
										data-bs-toggle='dropdown'
										aria-expanded='false'
									/>
									{/* Dropdown table row */}
									<div
										className='bg-white-dropdown rounded dropdown-menu dropdown-menu-lg shadow text-color-black'
										aria-labelledby={`a${item.id}`}>
										<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-top'>
											<UilEdit size={26} />{' '}
											<p className='m-0 ps-2'>Administrar destaques</p>
										</div>
										<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-bottom'>
											<UilArrowCircleUp size={26} />{' '}
											<p className='m-0 ps-2'>Publicar inmueble</p>
										</div>
									</div>
									{/* Dropdown table row */}
								</div>
							</div>
						</td>
						<td className='col pe-2 fw-normal-bold fs-5'>
							<div className='d-flex flex-column align-items-start py-2'>
								<div
									className='rounded'
									style={{
										backgroundImage: `url(${
											item.photo ||
											'https://placehold.co/60x100?text=Sin+Imagen'
										})`,
										backgroundSize: 'cover',
										height: '5vw',
										width: '8vw',
										position: 'relative',
									}}>
									<div
										className='bg-blue-300 py-1 px-2 text-color-white'
										style={{
											position: 'absolute',
											right: 10,
											top: 10,
											borderRadius: '5px',
											fontSize: '10px',
										}}>
										<p className='m-0'>{item.id}</p>
									</div>
								</div>
							</div>
						</td>
						<td className='col pe-2 fw-normal-bold fs-5'>{item.tipology}</td>
						<td className='col pe-2 fw-normal-bold fs-5'>{item.address}</td>
						<td className='col pe-2 fw-normal-bold fs-5'>{item.location}</td>
						<td className='col pe-2 fw-normal-bold fs-5'>
							<div className='d-flex flex-column align-items-start justify-content-center'>
								<p
									className='m-0 text-color-white bg-danger py-2 px-3 rounded fs-6'
									data-tooltip-id='my-tooltip'
									data-tooltip-content={item.errorDescription || 'No disponible'}>
									{item.errorReason || 'No disponible'}
								</p>
								<Tooltip id='my-tooltip' />
							</div>
						</td>
					</tr>
				))
			)}

			<PaginationButtons
				label='items'
				currentPage={posts.data?.paginator.page}
				filtersApplied={filters}
				setFiltersApplied={setFilters}
				totalPage={posts.data?.paginator.total}
			/>

		</table>
	);
}
