import { ArrowCounterClockwise, ArrowLeft, FloppyDisk, Trash } from 'phosphor-react';
import React, { useState , useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/bootstrap/Button';
import { saveOperador } from '../../services/operatorsService';
import Swal from 'sweetalert2';
import Toast from '../../components/Toast/Toast';
import { showToast } from '../../components/Toast/toastUtils/toastUtils';


const Footer = ({ userData, setUserData }) => {
	const navigate = useNavigate();
	const [list, setList] = useState([]);
	  

	function save() {

		saveOperador(userData,setUserData).then((res) => {
			if (res.status == 201) {
				showToast(list, setList,'success');
				setUserData(res.data.user);
			} else {
				let error = JSON.parse(JSON.stringify(res.data.errors));
				let msg;
				for (const property in error) {
					msg = error[property];
				}
				showToast(list, setList,'danger');
				
			}
		});
	}

	return (
		<>
			<div className='d-flex justify-content-between align-items-center w-100'>
			<Toast toastlist={list} position="top-right" setList={setList} />
				<div className='d-flex align-items-center	'>
					{/* <ArrowLeft
						size={32}
						color='#506cf9'
						className='cursor-pointer'
						onClick={() => navigate('/personas/interesados')}
					/> */}
					<Button
						rounded={1}
						className='blue-gradient-buttons mx-0 border-0'
						onClick={(e) => save()}>
						<div className='d-flex align-items-center'>
							<FloppyDisk size={24} color='#ffffff' />
							<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
						</div>
					</Button>
				</div>
			</div>
		</>
	);
};

export default Footer;
