import React, { useState } from 'react';
import './Contador.css';

const Contador = () => {
	const [count, setCount] = useState(0);

	const handleSubtract = () => {
		setCount(count - 1);
	};

	const handleAdd = () => {
		setCount(count + 1);
	};

	return (
		<div className='contador px-1 py-1 d-flex'>
			<button
				className='btn btn-sm btn-primary bg-white rounded-circle border-2 fs-7'
				onClick={handleSubtract}
			>
				<span className='text-color-lblue'>-</span>
			</button>
			<span style={{ margin: '0 10px' }} className='fw-600'>
				{count}
			</span>
			<button
				className='btn btn-sm btn-primary rounded-circle bg-white border-2'
				onClick={handleAdd}
			>
				<span className='text-color-lblue'>+</span>
			</button>
		</div>
	);
};

export default Contador;
