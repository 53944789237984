import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import UserContext from '../contexts/userContext';
import { getBranches } from '../services/branchesServices';

export default function useBranches() {
	const { token } = useContext(UserContext);

	const branches = useQuery({
		queryKey: ['branches'],
		queryFn: () => getBranches(token),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});

	return branches;
}
