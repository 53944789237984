import React from 'react'

const ProrgessBar = ({progress}) => {
  return (
    <div className="progress" style={{height: '20px'}}>
    <div
        className="progress-bar "
        role="progressbar"
        style={{ width: `${progress}%` }}
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
    ></div>
</div>
  )
}

export default ProrgessBar