import React, { useContext } from 'react';
import { UilMinus, UilPlus } from '@iconscout/react-unicons';
import ThemeContext from '../contexts/themeContext';

export default function InputCounter({
	label,
	labelClassname = 'm-0 text-color-lblue',
	handleIncrease,
	handleDecrease,
	value,
}) {
	const { mobileDesign } = useContext(ThemeContext);

	return (
		<div className='d-flex align-items-center justify-content-between w-100'>
			<p style={{ fontSize: mobileDesign ? '1rem' : '1vw' }} className={`${labelClassname}`}>
				{label}
			</p>
			<div className='d-flex align-items-center'>
				{/* Decrease Button */}
				<div className='p-2 bg-lblue-065 rounded' onClick={handleDecrease}>
					<UilMinus color='#506cf9' size={24} />
				</div>
				{/* Decrease Button */}
				<h5 className='my-0 mx-4 fw-bold text-color-lblue'>{value || 0}</h5>
				{/* Increase Button */}
				<div className='p-2 bg-lblue-065 rounded' onClick={handleIncrease}>
					<UilPlus color='#506cf9' size={24} />
				</div>
				{/* Increase Button */}
			</div>
		</div>
	);
}
