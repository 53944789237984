import React, { useState, useContext } from 'react';
import {
	UilEdit,
	UilUsersAlt,
	UilTrash,
} from '@iconscout/react-unicons';
import classNames from 'classnames';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import Card, {
	CardBody,
	CardHeader,
} from '../../components/bootstrap/Card';
import ThemeContext from '../../contexts/themeContext';
// import { getCustomers } from '../../services/customersService';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';

const Profiles = () => {
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	return (
		<PageWrapper title='Perfiles'>
				<Page container='fluid'>
					<SubheaderNew
						breadCrumbs={[
							{ name: 'Perfiles', url: '/personas/interesados' },
						]}
						newButtonUrl='/perfiles/crear'
						withNewButton
					/>
				{!mobileDesign && (
				
				
					<div className='d-flex flex-column w-100 h-100'>
						{/* Tabs */}
					
						<Card
											stretch
											style={{
												position: mobileDesign && activeFilters && 'relative',
												bottom: mobileDesign && activeFilters && '3rem',
											}}
										>
											<CardHeader
												style={{ 'border-bottom': '1px solid rgba(183, 184, 188, 0.5)' }}
											>
												<div className='titulo-alerta my-2'>
													<UilUsersAlt className='text-color-lblue' size={30} />
													<span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>
														PERFILES
													</span>
												</div>
											</CardHeader>
											<CardBody className='table-responsive hidden-scroll' isScrollable>
												<table className='w-100'>
													<thead style={{ position: 'sticky', top: '0px' }}>
														{/* <tr className='text-white blue-table-header'> */}
														<tr className='text-dark'>
															<th>{null}</th>
															<th className='sortable'>PERFIL</th>
														</tr>
													</thead>
													<tbody>
														{/* {dataPagination(items, currentPage, perPage).map(
																(item, index) => ( */}
														<tr className='bg-new-gray'>
															<td style={{ width: '30%' }}>
																<div className='d-flex justify-content-start'>
																	<div className='bg-blue-300 rounded-circle p-2 mx-1'>
																	<a href="perfiles/editar"><UilEdit color='#fff' size={20} /></a>
																	</div>
																	<div className='bg-blue-300 rounded-circle p-2 mx-1'>
																		<UilTrash color='#fff' size={20} />
																	</div>
																</div>
															</td>
															<td>Doe</td>
														</tr>
														{/* )} */}
													</tbody>
												</table>
												{/* <table className='table table-modern'> */}
											</CardBody>
										</Card>
					</div>
															
					)}
					{/* Tabs */}

					{mobileDesign && (
						<>
						
						<Card className="w-100 d-flex">
							<CardBody className='w-100'>
								<div>
								<table className='w-100  table  table-striped '>
									<tbody className='w-100 '>
										{/* {dataPagination(items, currentPage, perPage).map(
												(item, index) => ( */}
										<tr className=''>
											<td className='border-0' style={{width:"50%"}}>Adminsitrador</td>
											<td className='border-0'>
												<div className='d-flex justify-content-start'>
													<div className='bg-blue-300 rounded-circle p-2 mx-1'>
														<UilEdit color='#fff' size={20} />
													</div>
													<div className='bg-blue-300 rounded-circle p-2 mx-1'>
														<UilTrash color='#fff' size={20} />
													</div>
												</div>
											</td>
										</tr>
										<tr className='w-100'>
											<td style={{width:"75%"}}>Asesor</td>
											<td>
												<div className='d-flex justify-content-start'>
													<div className='bg-blue-300 rounded-circle p-2 mx-1'>
														<a href="mantenimiento/perfiles/editar/1"><UilEdit color='#fff' size={20} /></a>
														
													</div>
													<div className='bg-blue-300 rounded-circle p-2 mx-1'>
														<UilTrash color='#fff' size={20} />
													</div>
												</div>
											</td>
										</tr>
										{/* )} */}
									</tbody>
								</table>
								</div>
								
								{/* <table className='table table-modern'> */}
							</CardBody>
						</Card>
								
							
						</>
					)}
				
				</Page>	
		</PageWrapper>
		// <PageWrapper title='Alta Operador'>
		// 	<Page container='fluid'>
		// 		{!mobileDesign ? (
		// 			<div className='d-flex flex-column w-100 h-100'>
		// 				<SubheaderNew
		// 					breadCrumbs={[{ name: 'Perfiles', url: '/Mantenimiento/Perfiles' }]}
		// 					newButtonUrl='/Mantenimiento/Perfiles/Crear'
		// 				/>
		// 				<Card
		// 					stretch
		// 					style={{
		// 						position: mobileDesign && activeFilters && 'relative',
		// 						bottom: mobileDesign && activeFilters && '3rem',
		// 					}}
		// 				>
		// 					<CardHeader
		// 						style={{ 'border-bottom': '1px solid rgba(183, 184, 188, 0.5)' }}
		// 					>
		// 						<div className='titulo-alerta my-2'>
		// 							<UilUsersAlt className='text-color-lblue' size={30} />
		// 							<span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>
		// 								PERFILES
		// 							</span>
		// 						</div>
		// 					</CardHeader>
		// 					<CardBody className='table-responsive hidden-scroll' isScrollable>
		// 						<table className='w-100'>
		// 							<thead style={{ position: 'sticky', top: '0px' }}>
		// 								{/* <tr className='text-white blue-table-header'> */}
		// 								<tr className='text-dark'>
		// 									<th>{null}</th>
		// 									<th className='sortable'>PERFIL</th>
		// 								</tr>
		// 							</thead>
		// 							<tbody>
		// 								{/* {dataPagination(items, currentPage, perPage).map(
		// 										(item, index) => ( */}
		// 								<tr className='bg-new-gray'>
		// 									<td style={{ width: '30%' }}>
		// 										<div className='d-flex justify-content-start'>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilEdit color='#fff' size={20} />
		// 											</div>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilTrash color='#fff' size={20} />
		// 											</div>
		// 										</div>
		// 									</td>
		// 									<td>Doe</td>
		// 								</tr>
		// 								{/* )} */}
		// 							</tbody>
		// 						</table>
		// 						{/* <table className='table table-modern'> */}
		// 					</CardBody>
		// 				</Card>
		// 			</div>
		// 		) : (
		// 			<div>
		// 				{/* <SubheaderNew
		// 			breadCrumbs={[{ name: 'Perfiles', url: '/Mantenimiento/Perfiles' }]}
		// 			newButtonUrl='/Mantenimiento/Perfiles/Crear'
		// 		/> */}
		// 			{/* Search input */}
		// 			<div
		// 								className={classNames(
		// 									'd-flex align-items-center rounded-pill py-1 px-2',
		// 									{ 'search-input-header-dark': darkModeStatus },
		// 									{ 'search-input-header': !darkModeStatus },
		// 								)}>
		// 								<label
		// 									className='border-0 bg-transparent cursor-pointer me-0 ms-2'
		// 									htmlFor='searchInput'>
		// 									{/* <Icon icon='Search' size='2x' color='blue-300' /> */}
		// 									<UilSearch
		// 										// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
		// 										color='#506CF9'
		// 										size={12}
		// 									/>
		// 								</label>
		// 								<Input
		// 									id='searchInput'
		// 									type='search'
		// 									className='border-0 shadow-none bg-transparent input-search-customers'
		// 									placeholder='Buscar...'
		// 								/>
		// 							</div>
		// 							{/* Search input */}
		// 				<div className='titulo-alerta my-2 justify-content-center mt-5 mb-3'>
		// 					<UilUsersAlt className='text-color-lblue' size={30} />
		// 					<span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>PERFILES</span>
							
		// 				</div>
		// 				<hr className=''/>
		// 				<div className='text-center mb-5'>
		// 				<Button
		// 						size='sm'
		// 						icon='CustomUserPlus'
		// 						className={``}
		// 						color='info'
		// 						isLight
		// 						tag='a'
		// 						to={""}>
		// 						Nuevo
		// 					</Button>	
		// 				</div>
							

		// 				<Card className="w-100 d-flex">
		// 					<CardBody className='w-100'>
		// 						<table className='w-100  table  table-striped '>
		// 							<tbody className='w-100 '>
		// 								{/* {dataPagination(items, currentPage, perPage).map(
		// 										(item, index) => ( */}
		// 								<tr className=''>
		// 									<td className='border-0' style={{width:"50%"}}>Doe</td>
		// 									<td className='border-0'>
		// 										<div className='d-flex justify-content-start'>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilEdit color='#fff' size={20} />
		// 											</div>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilTrash color='#fff' size={20} />
		// 											</div>
		// 										</div>
		// 									</td>
		// 								</tr>
		// 								<tr className=''>
		// 									<td style={{width:"50%"}}>Doe</td>
		// 									<td>
		// 										<div className='d-flex justify-content-start'>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilEdit color='#fff' size={20} />
		// 											</div>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilTrash color='#fff' size={20} />
		// 											</div>
		// 										</div>
		// 									</td>
		// 								</tr>
		// 								{/* )} */}
		// 							</tbody>
		// 						</table>
		// 						{/* <table className='table table-modern'> */}
		// 					</CardBody>
		// 				</Card>
		// 			</div>
		// 		)}
		// 	</Page>
		// </PageWrapper>
	);
};

export default Profiles;
