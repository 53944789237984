import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Notepad } from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { UilCog, UilEstate } from '@iconscout/react-unicons';
import { Form, Formik } from 'formik';
import Check from '../../components/Check';

const Generales = ({ userData, setUserData }) => {
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);

	// onFileChange = (event) => {
	// 		// onChange={(e) => setFile(URL.createObjectURL(e.target.files[0]),checkFile())}
	// 		//setPreviewImage(URL.createObjectURL(e.target.files[0]);
	// 	// Update the state
	// 	//setFile(event.target.files[0]);

	//   }

	//   onFileUpload = () => {

	// 	// Create an object of formData
	// 	const formData = new FormData();

	// 	// Update the formData object
	// 	formData.append(
	// 	  "profile_image",
	// 	  file,
	// 	  file.name
	// 	);

	// 	// Request made to the backend api
	// 	// Send formData object
	// 	axios.post("api/uploadfile", formData);
	//   };

	function checkFile() {
		// URL.createObjectURL(file)
		// // setPreview(objectUrl)
		// console.log(window.URL.createObjectURL(dataURLToBlob(file)))
	}

	function dataURLToBlob(dataurl) {
		let arr = dataurl.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							<Card className='p-2'>
								<CardHeader>
									<CardTitle tag='h4' className='h5 text-color-lblue'>
										<div className='d-flex align-items-center'>
											<UilCog />
											<p className='my-0 mx-2'>GENERALES</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className='d-flex col-3 '>
											<div className='d-flex flex-column justify-content-center  col-8'>
												<InputFloating
													className='input-filters-menu rounded shadow-inputs col p-3'
													label='Moneda'
													name='moneda'
													type='text'
													setFunction={setUserData}
													previousValue={userData}
													touched={touched}
													required
													errorMessage={'Moneda Requerido'}
												/>
											</div>
										</div>
										<div className='d-flex col-3'>
											<div className='d-flex flex-column justify-content-center  col-8'>
												<InputFloating
													className='input-filters-menu rounded shadow-inputs col p-3'
													label='Fecha'
													name='fecha'
													type='date'
													setFunction={setUserData}
													previousValue={userData}
													touched={touched}
													required
													errorMessage={'Fecha Requerido'}
												/>
											</div>
										</div>
										<div className='d-flex col-3'>
											<div className='d-flex flex-column justify-content-center  col-8'>
												<InputFloating
													className='input-filters-menu rounded shadow-inputs col p-3'
													label='Paridad'
													name='paridad'
													type='text'
													setFunction={setUserData}
													previousValue={userData}
													touched={touched}
													required
													errorMessage={'Moneda Requerido'}
												/>
											</div>
										</div>
										<div className='d-flex col-3'>
											<div className='d-flex flex-column justify-content-center  col-8'>
												<InputFloating
													className='input-filters-menu rounded shadow-inputs col p-3'
													label='Valor IVA'
													name='IVA'
													type='number'
													setFunction={setUserData}
													previousValue={userData}
													touched={touched}
													required
													errorMessage={'IVA Requerido'}
												/>
											</div>
										</div>
									</div>
									<div className='row mt-4 px-1 me-1'>
										<div className='col-11'>
											<div className='float-end d-flex align-items-center justify-content-center'>
												<span className='me-4'>
													Utilizar paridad en filtros
												</span>
												<Check />
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader>
											<CardTitle tag='h4' className='h5'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilEstate className='me-2' />
													<p className='my-0 mx-2'>SUCURSALES</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='p-0 table-font-12 '>
											<div className='d-flex w-100 bg-new-gray py-2'>
												<div className='col-6 d-flex'>
													<div className='col-8 ms-5'>
														Visualiza propiedades de todas las
														sucursales
													</div>
													<Check />
												</div>
												<div className='col-6 d-flex'>
													<div className='col-9 '>
														Visualiza la caja de todas las sucursales
													</div>
													<Check />
												</div>
											</div>
											<div className='d-flex w-100 py-2'>
												<div className='col-6 d-flex'>
													<div className='col-8 ms-5'>
														Visualiza vendedores de todas las sucursales
													</div>
													<Check />
												</div>
												<div className='col-6 d-flex'>
													<div className='col-9'>
														Visualiza correos realizados de todas las
														sucursales
													</div>
													<Check />
												</div>
											</div>
											<div className='d-flex w-100 bg-new-gray py-2'>
												<div className='col-6 d-flex'>
													<div className='col-8 ms-5'>
														Visualiza personas de todas las sucursales
													</div>
													<Check />
												</div>
												<div className='col-6 d-flex'>
													<div className='col-9'>
														Visualiza tareas de todas las sucursales
													</div>
													<Check />
												</div>
											</div>
											<div className='d-flex w-100 py-2'>
												<div className='col-6 d-flex'>
													<div className='col-8 ms-5'>
														Muestra clientes inactivos en listado
													</div>
													<Check />
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							{/* Footer */}
							{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Generales;
