import { Gear, ListBullets, MapTrifold, SlidersHorizontal } from 'phosphor-react';

import React, { useContext, useEffect, useState, useRef } from 'react';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import ThemeContext from '../../../contexts/themeContext';
import {
	UilSearch,
	UilEstate,
	UilSearchAlt,
	UilGlobe,
	UilEllipsisH,
	UilCheck,
	UilLocationPoint,
	UilBookmark,
	UilBed,
	UilMoneyBill,
	UilEnvelopeUploadAlt,
	UilWhatsapp,
	UilDesktop,
	UilImport,
	UilClipboardNotes,
	UilUserSquare,
	UilWindow,
	UilEdit,
	UilTrash,
	UilExternalLinkAlt,
	UilSortAmountDown,
	UilSortAmountUp,
} from '@iconscout/react-unicons';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import MapLayout from './MapLayout';
import ReactApexChart from 'react-apexcharts';
import FiltersMenu from './FiltersMenu';
import UserContext from '../../../contexts/userContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getEstatesFiltered } from '../../../services/estateServices';
import PaginationButtons from '../../../components/PaginationButtons';
import useEstates from '../../../hooks/useEstates';

function Active({ filtersMenu, setFiltersMenu }) {
	const menuItems = [
		{
			name: 'Foto',
			nameEng: 'photo',
			isActive: true,
			isSortable: false,
		},
		{
			name: ['Operación', 'Valor'],
			nameEng: ['operation', 'prices'],
			isActive: true,
			isSortable: [false, true],
		},
		{
			name: ['Dirección', 'Barrio'],
			nameEng: ['location', 'neighborhood'],
			isActive: true,
			isSortable: [false, false],
		},
		{
			name: ['Dormitorios', 'Baños'],
			nameEng: ['bedrooms', 'bathrooms'],
			isActive: true,
			isSortable: [true, true],
		},
		{
			name: ['Superficie', 'Ambientes'],
			nameEng: ['area', 'rooms'],
			isActive: true,
			isSortable: [true, true],
		},
		{
			name: 'Nivel de calidad',
			nameEng: 'quality',
			isActive: true,
			isSortable: true,
		},
		{
			name: 'Fecha de ingreso',
			nameEng: 'registeredAt',
			isActive: false,
			isSortable: true,
		},
		{
			name: 'Tipo de inmueble',
			nameEng: 'estateType',
			isActive: false,
			isSortable: false,
		},
		{
			name: 'Superficie cubierta',
			nameEng: 'roofArea',
			isActive: false,
			isSortable: true,
		},
	];

	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const [selectedEstates, setSelectedEstates] = useState([]);

	const [showMap, setShowMap] = useState(false);

	const [thList, setThList] = useState(menuItems);

	const clickHandler = (item) => {
		const menu = [...thList];
		const index = menu.indexOf(item);
		menu[index] = { ...item, isActive: !item.isActive };
		setThList(menu);
	};

	const getChartOptions = (value) => {
		return {
			chart: {
				type: 'radialBar',
				// width: 25,
				// height: 25,
				sparkline: {
					enabled: true,
				},
			},
			dataLabels: {
				enabled: false,
			},
			title: {
				text: `${value}%`,
				align: 'center',
				justify: 'center',
				margin: 0,
				offsetX: 0,
				offsetY: 42,
				floating: true,
				style: {
					fontSize: '12px',
					fontWeight: 'bold',
					fontFamily: 'Montserrat',
					color: '#000',
				},
			},
			plotOptions: {
				// radar: {
				// 	size: 500,
				// },
				radialBar: {
					hollow: {
						margin: 0,
						size: '55%',
					},
					// track: {
					// 	margin: 0,
					// },
					dataLabels: {
						show: false,
					},
				},
			},

			stroke: {
				lineCap: 'round',
			},
			colors: ['#506cf9'],
		};
	};

	const sortHandler = (item) => {
		if (!item) {
			return;
		} else if (filtersApplied.sort.field !== item) {
			setFiltersApplied((prev) => ({ ...prev, sort: { field: item, order: 'asc' } }));
		} else if (filtersApplied.sort.field === item && filtersApplied.sort.order === 'asc') {
			setFiltersApplied((prev) => ({ ...prev, sort: { field: item, order: 'desc' } }));
		} else if (filtersApplied.sort.field === item && filtersApplied.sort.order === 'desc') {
			setFiltersApplied((prev) => ({ ...prev, sort: { field: null, order: null } }));
		}
	};

	const getSortIcon = (item) => {
		if (filtersApplied.sort.field === item && filtersApplied.sort.order === 'asc') {
			return <UilSortAmountDown size={20} color='#506cf9' />;
		} else if (filtersApplied.sort.field === item && filtersApplied.sort.order === 'desc') {
			return <UilSortAmountUp size={20} color='#506cf9' />;
		} else {
			return null;
		}
	};

	const [filtersApplied, setFiltersApplied] = useState({
		query: null,
		sort: {
			field: null,
			order: null,
		},
		page: 1,
		limit: 5,
	});

	const estates = useEstates(filtersApplied);

	const queryClient = useQueryClient();

	useEffect(() => {
		queryClient.invalidateQueries({
			queryKey: [filtersApplied],
			exact: true,
		});
	}, [filtersApplied]);

	const debounceRef = useRef(null);

	const changeHandler = (value, time = 0) => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}

		debounceRef.current = setTimeout(() => {
			setFiltersApplied((prev) => ({ ...prev, query: value }));
		}, time);
	};

	return filtersMenu ? (
		<FiltersMenu
			filtersApplied={filtersApplied}
			setFiltersApplied={setFiltersApplied}
			setFiltersMenu={setFiltersMenu}
		/>
	) : (
		<div className='d-flex flex-column'>
			{/* Buttons */}
			{!mobileDesign && (
				<div className='d-flex align-items-center justify-content-between w-100'>
					<div className='d-flex align-items-center ms-4'>
						{selectedEstates.length > 0 && (
							<>
								<Button
									className=''
									color='primary'
									rounded='1'
									data-bs-toggle='dropdown'
									aria-expanded='false'>
									<div className='d-flex align-items-center'>
										<Gear color='#fff' size={20} />
										<p className='my-0 ms-2'>Acciones</p>
									</div>
								</Button>

								<div
									className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
									style={{ width: '20rem' }}>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilEnvelopeUploadAlt />{' '}
										<p className='m-0 ps-2'>Enviar mail</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilWhatsapp /> <p className='m-0 ps-2'>Enviar Whatsapp</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilDesktop />{' '}
										<p className='m-0 ps-2'>Generar cartelera dinámica</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilImport />{' '}
										<p className='m-0 ps-2'>Descargar / exportar</p>
									</div>
									<div
										onClick={() => setModalStatus(!modalStatus)}
										className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilClipboardNotes />{' '}
										<p className='m-0 ps-2'>Generar tarea</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilUserSquare />{' '}
										<p className='m-0 ps-2'>Ver interesados</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilWindow />{' '}
										<p className='m-0 ps-2'>Publicar en portales</p>
									</div>
								</div>
							</>
						)}
						{/* Search input */}
						<div
							className={`d-flex align-items-center rounded-pill py-1 px-2 ${
								selectedEstates.length > 0 && 'ms-4'
							} bg-white`}>
							<label
								className='border-0 bg-white cursor-pointer me-0 ms-2'
								htmlFor='searchInput'>
								<UilSearch color='#506CF9' size={18} />
							</label>
							<Input
								id='searchInput'
								type='search'
								className='border-0 shadow-none input-search-customers bg-white'
								placeholder='Buscar...'
								onChange={(event) => changeHandler(event.target.value, 500)}
							/>
						</div>
						{/* Search input */}
					</div>
					<div className='d-flex align-items-center me-4'>
						{!showMap && (
							<Button
								className=''
								color='primary'
								rounded={1}
								data-bs-toggle='dropdown'
								onClick={() => setOffcanvasStatus(!offcanvasStatus)}
								aria-expanded='false'>
								<div className='d-flex align-items-center'>
									<SlidersHorizontal color='#fff' size={20} />
									<p className='my-0 ms-2'>Personalizar</p>
								</div>
							</Button>
						)}
						{showMap ? (
							<Button
								className='ms-2'
								color='primary'
								rounded={1}
								data-bs-toggle='dropdown'
								aria-expanded='false'
								onClick={() => setShowMap(false)}>
								<div className='d-flex align-items-center'>
									<ListBullets color='#fff' size={20} />
									<p className='my-0 ms-2'>Listado</p>
								</div>
							</Button>
						) : (
							<Button
								className='ms-2'
								color='primary'
								rounded={1}
								data-bs-toggle='dropdown'
								aria-expanded='false'
								onClick={() => setShowMap(true)}>
								<div className='d-flex align-items-center'>
									<MapTrifold color='#fff' size={20} />
									<p className='my-0 ms-2'>Mapa</p>
								</div>
							</Button>
						)}
					</div>
					{/* Drawer right */}
					<OffCanvas
						id='notificationCanvas'
						titleId='offcanvasExampleLabel'
						placement='end'
						isOpen={offcanvasStatus}
						setOpen={setOffcanvasStatus}>
						<OffCanvasHeader setOpen={setOffcanvasStatus} className='bg-blue-300 p-4'>
							<div className='w-100 d-flex justify-content-center'>
								<h4 className='m-0 text-color-white fw-bold'>Mostrar / Ocultar</h4>
							</div>
						</OffCanvasHeader>
						<OffCanvasBody className='p-0'>
							{/* Search input */}
							<div className='d-flex align-items-center py-4 px-3 w-100 search-input-drawer'>
								<label
									className='border-0 bg-transparent cursor-pointer me-0'
									htmlFor='searchInput'>
									<UilSearch
										color={darkModeStatus ? '#A9B5D7' : '#01227d'}
										size={20}
									/>
								</label>
								<Input
									id='searchInput'
									type='search'
									className='border-0 shadow-none bg-transparent input-search'
									placeholder='Buscar...'
								/>
							</div>
							{/* Search input */}
							<div className='d-flex align-items-center w-100 bg-blue-100 p-3'>
								<h5 className='m-0 text-color-lblue fw-bold'>Mostrar</h5>
							</div>
							<div className='d-flex flex-column w-100 p-3'>
								{thList
									.filter((item) => item.isActive === true)
									.map((item) => (
										<div
											onClick={() => clickHandler(item)}
											key={item.name}
											className='d-flex align-items-center justify-content-between my-2 w-100 cursor-pointer'>
											<div className='d-flex align-items-center'>
												{/* {item.icon(true)} */}
												<p className='m-0 text-color-lblue fs-5 fw-bold px-2 mb-0'>
													{typeof item.name === 'object' ? (
														<>
															{item.name[0]} / {item.name[1]}
														</>
													) : (
														item.name
													)}
													{/* {item.name} */}
												</p>
											</div>
											<UilCheck color='#506CF9' size={35} />
										</div>
									))}
							</div>

							<div className='d-flex align-items-center w-100 bg-new-gray p-3'>
								<h5 className='m-0 text-color-dark fw-bold'>Añadir</h5>
							</div>
							<div className='d-flex flex-column w-100 p-3'>
								{thList
									.filter((i) => i.isActive === false)
									.map((menuItem) => (
										<div
											onClick={() => clickHandler(menuItem)}
											key={menuItem.name}
											className='d-flex align-items-center justify-content-between my-3 w-100 cursor-pointer'>
											<div className='d-flex align-items-center'>
												{/* {menuItem.icon(false)} */}
												<p className='m-0 text-color-dark fs-5 fw-bold px-2 mb-0'>
													{menuItem.name}
												</p>
											</div>
										</div>
									))}
							</div>
						</OffCanvasBody>
					</OffCanvas>
					{/* Drawer right */}
				</div>
			)}
			{/* Buttons */}

			<div className='d-flex flex-column min-vh-50 bg-white mt-4 mx-4 rounded-3 shadow'>
				{/* Inmuebles, resultados, red compartida */}
				{!mobileDesign && !showMap && (
					<div className='d-flex align-items-center justify-content-between position-relative mx-4 my-3 col-xl-6 col-xxl-5'>
						<div className='d-flex align-items-center ms-4'>
							<UilEstate color='#506cf9' size={30} />
							<h5 className='fw-bold m-0 text-color-lblue'>INMUEBLES</h5>
						</div>

						<div
							className='d-flex align-items-center position-relative'
							style={{ top: '-1rem' }}>
							<div className='d-flex align-items-center justify-content-center bg-blue-300 py-2 px-3 rounded-bottom mx-2 shadow-blue'>
								<UilSearchAlt size={25} color='#95B1FE' />
								<h6 className='fw-bold m-0 text-color-white'>
									{estates.data?.paginator.totalRecords} RESULTADOS
								</h6>
							</div>

							<div className='d-flex align-items-center justify-content-center bg-orange py-2 px-3 rounded-bottom mx-2 shadow-orange'>
								<UilGlobe size={25} color='#FFBA69' />
								<h6 className='fw-bold m-0 text-color-white'>RED COMPARTIDA</h6>
							</div>
						</div>
					</div>
				)}
				{/* Inmuebles, resultados, red compartida */}
				{/* Tabla */}

				{showMap ? (
					<div className='w-100 h-100'>
						<MapLayout
							selectedEstates={selectedEstates}
							setSelectedEstates={setSelectedEstates}
							data={estates.data.data}
						/>
					</div>
				) : mobileDesign ? (
					estates.data?.data.map((estate) => (
						<div className={`d-flex my-4 position-relative`} key={estate.location}>
							<div className='d-flex flex-column align-items-center w-100'>
								<div className='d-flex align-items-start justify-content-center px-4 w-100'>
									<div
										className='rounded'
										style={{
											backgroundImage: `url(${estate.photo})`,
											backgroundSize: 'cover',
											// height: mobileDesign ? '146px' : '184px',
											height: '184px',
											// width: '242px',
											width: '100%',
											position: 'relative',
										}}>
										<div
											className='bg-blue-300 py-1 px-2 text-color-white'
											style={{
												position: 'absolute',
												left: 10,
												top: 10,
												borderRadius: '5px',
											}}>
											<p className='m-0'>{estate.id}</p>
										</div>

										<div
											className='d-flex justify-content-center position-absolute bg-blue-300 rounded-5px px-1'
											style={{
												right: 10,
												top: 10,
											}}>
											<UilEllipsisH
												color='#fff'
												size={25}
												id={`a${estate.id}`}
												data-bs-toggle='dropdown'
												aria-expanded='false'
											/>
											{/* Dropdown */}
											<div
												className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
												aria-labelledby={`a${estate.id}`}>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilEdit /> <p className='m-0 ps-2'>Editar</p>
												</div>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilTrash /> <p className='m-0 ps-2'>Borrar</p>
												</div>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilExternalLinkAlt />{' '}
													<p className='m-0 ps-2'>Ver mas</p>
												</div>
											</div>
											{/* Dropdown */}
										</div>
									</div>
								</div>
								<div className='d-flex justify-content-start align-items-center w-100 my-2 ps-5'>
									<UilLocationPoint color='#506CF9' />
									<p className={`fs-6 px-2 fw-bold m-0`}>{estate.location}</p>
								</div>
								<div className='d-flex align-items-center justify-content-start ps-5 my-1 w-100'>
									<div className='d-flex align-items-center'>
										<UilBookmark color='#506CF9' />
										<p
											className={`fs-6 px-2 ${
												mobileDesign ? 'me-2' : 'me-3'
											} fw-bold m-0`}>
											{estate.operation}
										</p>
									</div>
									<div className='d-flex align-items-center'>
										<UilBed color='#506CF9' />
										<p
											className={`fs-6 px-2 ${
												mobileDesign ? 'me-2' : 'me-3'
											} fw-bold m-0`}>
											{estate.rooms}
										</p>
									</div>
									<div className='d-flex align-items-center'>
										<UilMoneyBill color='#506CF9' />
										<p
											className={`${
												mobileDesign ? 'fs-6' : 'fs-6 me-3'
											} px-2 fw-bold m-0`}>
											{estate.value}
										</p>
									</div>
								</div>
							</div>
						</div>
					))
				) : (
					<table>
						<thead className='bg-lblue'>
							<tr className='d-flex'>
								<th
									className='col py-2 text-color-lblue fw-bold'
									// style={{ width: '15rem' }}
								>
									<div className='d-flex align-items-center justify-content-center w-100 h-100'>
										{/* <Checks /> */}
										<label
											className='custom-checkbox'
											onClick={() => {
												if (
													selectedEstates.length ===
													estates.data?.data.length
												) {
													setSelectedEstates([]);
												} else {
													setSelectedEstates(estates.data?.data);
												}
											}}>
											<UilCheck
												color={
													selectedEstates.length ===
													estates.data?.data.length
														? '#506CF9'
														: '#fff'
												}
												size={20}
											/>
										</label>
									</div>
								</th>
								{thList
									.filter((th) => th.isActive === true)
									.map((th) => (
										<th className='col py-2 text-color-lblue fw-bold estateTh'>
											{typeof th.name === 'object' ? (
												<div className='d-flex flex-column align-items-start justify-content-center'>
													<p
														className={`m-0 ${
															th.isSortable[0] &&
															'cursor-pointer text-decoration-underline'
														}`}
														onClick={() =>
															sortHandler(
																th.isSortable[0] && th.nameEng[0],
															)
														}>
														{th.name[0]}{' '}
														{getSortIcon(
															th.isSortable[0] && th.nameEng[0],
														)}
													</p>
													<p
														className={`m-0 ${
															th.isSortable[1] &&
															'cursor-pointer text-decoration-underline'
														}`}
														onClick={() =>
															sortHandler(
																th.isSortable[1] && th.nameEng[1],
															)
														}>
														{th.name[1]}{' '}
														{getSortIcon(
															th.isSortable[1] && th.nameEng[1],
														)}
													</p>
												</div>
											) : (
												<div
													className={`d-flex align-items-center justify-content-start w-100 h-100 ${
														th.isSortable &&
														'cursor-pointer text-decoration-underline'
													}`}
													onClick={() =>
														sortHandler(th.isSortable && th.nameEng)
													}>
													{th.name}{' '}
													{getSortIcon(th.isSortable && th.nameEng)}
												</div>
											)}
										</th>
									))}
							</tr>
						</thead>
						{/* <tbody> */}
						{estates.isLoading ? (
							<div className='d-flex align-items-center justify-content-center min-vh-50'>
								<h3>Cargando inmuebles...</h3>
							</div>
						) : (
							estates.data?.data.map((item) => (
								<tr className='d-flex py-2 align-items-center border-bottom'>
									<td className='col'>
										<div className='d-flex align-items-center justify-content-center w-100 h-100 position-relative'>
											<label
												className='mx-4 custom-checkbox'
												onClick={() => {
													if (
														selectedEstates.some(
															(estate) => estate.id === item.id,
														)
													) {
														setSelectedEstates(
															selectedEstates.filter(
																(estate) => estate.id !== item.id,
															),
														);
													} else {
														setSelectedEstates((selectedEstates) => [
															...selectedEstates,
															item,
														]);
													}
												}}>
												<UilCheck
													color={
														selectedEstates.some(
															(estate) => estate.id === item.id,
														)
															? '#506CF9'
															: '#fff'
													}
													size={20}
												/>
											</label>

											<div
												className='position-absolute'
												style={{ marginLeft: '6rem' }}>
												<UilEllipsisH
													color='#506CF9'
													size={25}
													className='cursor-pointer'
													id={`a${item.id}`}
													data-bs-toggle='dropdown'
													aria-expanded='false'
												/>
												{/* Dropdown table row */}
												<div
													className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
													// style={{ minWidth: '10rem !important' }}
													aria-labelledby={`a${item.id}`}>
													<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
														<UilEdit />{' '}
														<p className='m-0 ps-2'>Editar</p>
													</div>
													<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
														<UilTrash />{' '}
														<p className='m-0 ps-2'>Borrar</p>
													</div>
													<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
														<UilExternalLinkAlt />{' '}
														<p className='m-0 ps-2'>Ver mas</p>
													</div>
												</div>
												{/* Dropdown table row */}
											</div>
										</div>
									</td>
									{thList
										.filter((th) => th.isActive === true)
										.map((th) => (
											<td className='col'>
												{typeof th.name === 'object' ? (
													<div className='d-flex flex-column align-items-start justify-content-center'>
														<p className='m-0 fs-5 fw-bold'>
															{item[th.nameEng[0]]}
														</p>
														<p className='m-0 fs-5'>
															{item[th.nameEng[1]]}
														</p>
													</div>
												) : th.nameEng === 'photo' ? (
													// <img
													// 	src={item.photo}
													// 	className='rounded'
													// 	style={{ height: '6vw', width: '8vw' }}
													// />
													<div className='d-flex flex-column align-items-start'>
														<div
															className='rounded'
															style={{
																backgroundImage: `url(${
																	item.photo ||
																	'https://placehold.co/110x70'
																})`,
																backgroundSize: 'cover',
																height: '5vw',
																width: '8vw',
																position: 'relative',
															}}>
															<div
																className='bg-blue-300 py-1 px-2 text-color-white'
																style={{
																	position: 'absolute',
																	right: 10,
																	top: 10,
																	borderRadius: '5px',
																	fontSize: '10px',
																}}>
																<p className='m-0'>{item.id}</p>
															</div>
														</div>
														<div
															className='d-flex justify-content-center align-items-center'
															style={{ width: '8vw' }}>
															<p className='mt-1 mb-0'>
																Sucursal CNT
															</p>
														</div>
													</div>
												) : th.nameEng === 'quality' ? (
													<ReactApexChart
														series={[item.quality || 0]}
														options={getChartOptions([
															item.quality || 0,
														])}
														type='radialBar'
														height={100}
														width={100}
														// className='me-3'
													/>
												) : (
													item[th.nameEng]
												)}
											</td>
										))}
								</tr>
							))
						)}
						{/* </tbody> */}
					</table>
				)}

				{!showMap && (
					<PaginationButtons
						label='items'
						// setCurrentPage={setCurrentPage}
						currentPage={estates.data?.paginator.page}
						filtersApplied={filtersApplied}
						setFiltersApplied={setFiltersApplied}
						totalPage={estates.data?.paginator.total}
					/>
				)}

				{/* Tabla */}
			</div>
			<div className='d-flex justify-content-center w-100'>
				{showMap && (
					<>
						<div className='d-flex justify-content-center align-items-center mx-2'>
							<p className='m-0 text-color-lblue fs-5 bg-white px-5 py-2 rounded-bottom shadow'>
								Mostrando 86 propiedades
							</p>
						</div>
					</>
				)}

				{selectedEstates.length > 0 && (
					<div className='d-flex justify-content-center align-items-center mx-2'>
						<p className='m-0 text-color-white fs-5 bg-blue-300 px-5 py-2 rounded-bottom shadow'>
							{selectedEstates.length} propiedades seleccionadas
						</p>
					</div>
				)}
			</div>
		</div>
	);
}

export default Active;
