import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import Button from '../../components/bootstrap/Button';
import { UilUser, UilUsdCircle, UilHdd, UilExclamationCircle } from '@iconscout/react-unicons';
import { Form, Formik } from 'formik';
import Checks from '../../components/bootstrap/forms/Checks';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Coins } from 'phosphor-react';
import Icon from '../../components/icon/Icon';

import InputFloating from '../../components/InputFloating';

const Configuration = () => {
	const [userFirst, setDataFirst] = useState({
		dni: 0,
		password: '',
		name: '',
		surname: '',
		birthday: '',
	});
	return (
		<>
			<Formik initialValues={userFirst}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							<div className='row align-items-stretch h-100'>
								<div className='d-flex mt-3'>
									<div className='col-3 rounded-1 p-2 d-flex'>
										<Card className='p-2 w-100'>
											<CardTitle tag='h4' className='h5'>
												<div className='d-flex align-items-center mx-3 mt-2'>
													<UilUser />
													<p className='my-0 mx-2'>Perfil</p>
												</div>
											</CardTitle>
											<CardBody className='d-flex align-items-center mx-3 mt-2 '>
												<div className='d-flex justify-content-center align-items-center col-12 py-2'>
													<select className='input-filters-menu rounded-5 p-2 col-xl-7 col-xxl-9 p-3'>
														<option>Administrador</option>
														<option>Empleado</option>
														<option>Otro</option>
													</select>
												</div>
											</CardBody>
										</Card>
									</div>
									<div className='col-3 rounded-1 d-flex  p-2'>
										<Card className='p-2'>
											<CardTitle tag='h4' className='h5'>
												<div className='d-flex align-items-center mx-3 mt-2 '>
													<Coins size={41} />
													<p className='my-0 mx-2'>Comisiones</p>
												</div>
											</CardTitle>
											<CardBody className='d-flex justify-content-center '>
												<div className='d-flex align-items-center  col-12 p-3'>
													<div className='d-flex col-6 align-items-center justify-content-center '>
														{/* <label
												className='border-0 fs-14-500 bg-transparent cursor-pointer me-2 ms-2 '
												htmlFor='searchInput'>
												Venta
											</label>
											<input
												id='searchInput'
												type='text'
												className='input-filters-menu rounded shadow-inputs col-6 p-3'
												placeholder='%'
											/> */}
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col p-3'
															label='Venta'
															name='venta'
															type='text'
															setFunction={setDataFirst}
															previousValue={userFirst}
															touched={touched}
															required
															errorMessage={'Venta Requerido'}
															onChange={(e) => {
																save(e);
															}}
														/>
													</div>
													<div className='d-flex col-6 align-items-center justify-content-center me-5'>
														{/* <label
												className='border-0 fs-14-500 bg-transparent  cursor-pointer me-2 ms-2 '
												htmlFor='searchInput'>
												Alquiler
											</label>
											<input
												id='searchInput'
												type='text'
												className='input-filters-menu rounded shadow-inputs col-6 p-3'
												placeholder='%'
											/> */}
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col p-3'
															label='Alquiler'
															name='alquiler'
															type='text'
															setFunction={setDataFirst}
															previousValue={userFirst}
															touched={touched}
															required
															errorMessage={'Alquiler Requerido'}
															onChange={(e) => {
																save(e);
															}}
														/>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
									<div className='col rounded-1 p-2'>
										<Card className='p-2'>
											<CardTitle tag='h4' className='h5'>
												<div className='d-flex align-items-center mx-3 mt-2'>
													<UilHdd />
													<p className='my-0 mx-2'>SMTP</p>
												</div>
											</CardTitle>
											<CardBody className='d-flex justify-content-center w-100 '>
												<div className='d-flex'>
													<div className='d-flex justify-content-center aling-items-center flex-column  p-3'>
														<div className='d-flex col-12 align-items-center justify-content-center '>
															{/* <label
													className='border-0 fs-14-500 bg-transparent cursor-pointer me-2 ms-2 '
													htmlFor='searchInput'>
													Servidor
												</label>
												<input
													id='searchInput'
													type='text'
													className='input-filters-menu shadow-inputs d-flex rounded p-3'
													placeholder='%'
												/> */}
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Servidor'
																name='server'
																type='text'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={'Servidor Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
														<div className='d-flex col-12 align-items-center justify-content-center mt-3'>
															{/* <label
													className='border-0 d-flex fs-14-500 bg-transparent cursor-pointer me-4 '
													htmlFor='searchInput'>
													Contraseña
												</label>
												<input
													id='searchInput'
													type='text'
													className='input-filters-menu shadow-inputs d-flex rounded  p-3 me-4'
													placeholder='%'
												/> */}
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Contraseña'
																name='serverPass'
																type='text'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={
																	'Contraseña Requerido'
																}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
													</div>
												</div>
												<div className='d-flex'>
													<div className='d-flex justify-content-center aling-items-center flex-column col-12 p-3'>
														<div className='d-flex col-12 align-items-center justify-content-center '>
															{/* <label
													className='border-0 fs-14-500 bg-transparent  cursor-pointer me-2 ms-2 '
													htmlFor='searchInput'>
													Usuario
												</label>
												<input
													id='searchInput'
													type='text'
													className='input-filters-menu  shadow-inputs rounded p-3'
													placeholder='%'
												/> */}
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Usuario'
																name='serverUser'
																type='text'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={'Usuario Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
														<div className='d-flex col-12 align-items-center justify-content-center mt-3'>
															{/* <label
													className='border-0 fs-14-500 bg-transparent cursor-pointer me-4 '
													htmlFor='searchInput'>
													Puerto
												</label>
												<input
													id='searchInput'
													type='text'
													className='input-filters-menu rounded shadow-inputs p-3'
													placeholder='%'
												/> */}
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Puerto'
																name='serverPuerto'
																type='text'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={'Puerto Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</div>
								<div>
									<div className='col rounded-1 p-2'>
										<Card className='p-2'>
											<CardTitle tag='h4' className='h5'>
												<div className='d-flex align-items-center mx-3 mt-2'>
													<UilUser />
													<p className='my-0 mx-2'>Permisos</p>
												</div>
											</CardTitle>
											<CardBody>
												<div className='row p-5'>
													<div className='col me-5 filterBox-permission p-*'>
														<div className=' '>
															<UilExclamationCircle className='me-3 icon-blue' />
															<label className='me-5'>Permiso1</label>
															<div className='float-end'>
																<Checks
																	className='float-end'
																	id='1'
																	name='2'
																	checked='true'
																	isInline='true'
																	onChange={() =>
																		console.log('click check')
																	}
																/>
															</div>
														</div>
													</div>
													<div className='col me-5 filterBox-permission'>
														<div className=' '>
															<UilExclamationCircle className='me-3 icon-blue' />
															<label className='me-5'>Permiso1</label>
															<div className='float-end'>
																<Checks
																	className='float-end'
																	id='1'
																	name='2'
																	checked='true'
																	isInline='true'
																	onChange={() =>
																		console.log('click check')
																	}
																/>
															</div>
														</div>
													</div>
													<div className='col me-5 filterBox-permission'>
														<div className=' '>
															<UilExclamationCircle className='me-3 icon-blue' />
															<label className='me-5'>Permiso1</label>
															<div className='float-end'>
																<Checks
																	className='float-end'
																	id='1'
																	name='2'
																	checked='true'
																	isInline='true'
																	onChange={() =>
																		console.log('click check')
																	}
																/>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Configuration;
