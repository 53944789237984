import React, { useState } from 'react';
import Check from '../../components/Check';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';

export default function Features() {
	const items = [
		{
			name: 'airConditioning',
			id: 1,
			label: 'Aire acondicionado',
		},
		{
			name: 'scale',
			id: 2,
			label: 'Balanza',
		},
		{
			name: 'basketballCourt',
			id: 3,
			label: 'Cancha de básquet',
		},
		{
			name: 'hockeyCourt',
			id: 4,
			label: 'Cancha de hockey',
		},
		{
			name: 'squashCourt',
			id: 5,
			label: 'Cancha de squash',
		},
		{
			name: 'healthCare',
			id: 6,
			label: 'Centro médico',
		},
		{
			name: 'sportsSchool',
			id: 7,
			label: 'Escuela deportiva',
		},
		{
			name: 'generator',
			id: 8,
			label: 'Grupo electrógeno',
		},
		{
			name: 'forkLift',
			id: 9,
			label: 'Montacarga',
		},
		{
			name: 'ridingTrack',
			id: 10,
			label: 'Pista de equitación',
		},
		{
			name: 'reinforcedDoor',
			id: 11,
			label: 'Puerta blindada',
		},
		{
			name: 'sauna',
			id: 12,
			label: 'Sauna',
		},
		{
			name: 'fireTank',
			id: 13,
			label: 'Tanque de incendio',
		},
		{
			name: 'loft',
			id: 14,
			label: 'Altillo',
		},
		{
			name: 'dinningRoom',
			id: 15,
			label: 'Comedor',
		},
		{
			name: 'desk',
			id: 16,
			label: 'Escritorio',
		},
		{
			name: 'alarm',
			id: 17,
			label: 'Alarma',
		},
		{
			name: 'waterPump',
			id: 18,
			label: 'Bombeador',
		},
		{
			name: 'bocceCourt',
			id: 19,
			label: 'Cancha de bochas',
		},
		{
			name: 'paddleCourt',
			id: 20,
			label: 'Cancha de paddle',
		},
		{
			name: 'tennisCourt',
			id: 21,
			label: 'Cancha de tenis',
		},
		{
			name: 'houseClubElders',
			id: 22,
			label: 'Club house mayores',
		},
		{
			name: 'drivingForce',
			id: 23,
			label: 'Fuerza motriz',
		},
		{
			name: 'whirlpool',
			id: 24,
			label: 'Hidromasaje',
		},
		{
			name: 'engines',
			id: 25,
			label: 'Motores',
		},
		{
			name: 'playSquare',
			id: 26,
			label: 'Plaza de juegos',
		},
		{
			name: 'quincho',
			id: 27,
			label: 'Quincho',
		},
		{
			name: 'solarium',
			id: 28,
			label: 'Solarium',
		},
		{
			name: 'hotWaterTank',
			id: 29,
			label: 'Termotanque',
		},
		{
			name: 'balcony',
			id: 30,
			label: 'Balcón',
		},
		{
			name: 'diningRoomDiary',
			id: 31,
			label: 'Comedor diario',
		},
		{
			name: 'hall',
			id: 32,
			label: 'Hall',
		},
		{
			name: 'withFurniture',
			id: 33,
			label: 'Amoblado',
		},
		{
			name: 'boiler',
			id: 34,
			label: 'Caldera',
		},
		{
			name: 'boiler',
			id: 35,
			label: 'Caldera',
		},
		{
			name: 'footballField',
			id: 36,
			label: 'Cancha de fútbol',
		},
		{
			name: 'poloField',
			id: 37,
			label: 'Cancha de polo',
		},
		{
			name: 'volleyField',
			id: 38,
			label: 'Cancha de volley',
		},
		{
			name: 'houseClubKids',
			id: 39,
			label: 'Club house niños',
		},
		{
			name: 'gym',
			id: 40,
			label: 'Gimnasio',
		},
		{
			name: 'grillInstalation',
			id: 41,
			label: 'Instalación parrilla',
		},
		{
			name: 'grill',
			id: 42,
			label: 'Parrilla',
		},
		{
			name: 'maneuveringSquare',
			id: 43,
			label: 'Plaza de maniobras',
		},
		{
			name: 'restaurant',
			id: 44,
			label: 'Restaurante',
		},
		{
			name: 'SUM',
			id: 45,
			label: 'SUM',
		},
		{
			name: 'videoclub',
			id: 46,
			label: 'Videoclub',
		},
		{
			name: 'trunk',
			id: 47,
			label: 'Baulera',
		},
		{
			name: 'serviceDependency',
			id: 48,
			label: 'Dependencia de servicio',
		},
		{
			name: 'garden',
			id: 49,
			label: 'Jardín',
		},
		{
			name: 'commercialArea',
			id: 50,
			label: 'Área comercial',
		},
		{
			name: 'heating',
			id: 51,
			label: 'Calefacción',
		},
		{
			name: 'golfField',
			id: 52,
			label: 'Campo de golf',
		},
		{
			name: 'rugbyField',
			id: 53,
			label: 'Cancha de rugby',
		},
		{
			name: 'sportsCourt',
			id: 54,
			label: 'Cancha de deportes',
		},
		{
			name: 'school',
			id: 55,
			label: 'Escuela',
		},
		{
			name: 'crane',
			id: 56,
			label: 'Grúa',
		},
		{
			name: 'laundry',
			id: 57,
			label: 'Laundry',
		},
		{
			name: 'pool',
			id: 58,
			label: 'Piscina',
		},
		{
			name: 'gate',
			id: 59,
			label: 'Portón',
		},
		{
			name: 'gameRoom',
			id: 60,
			label: 'Portón',
		},
		{
			name: 'waterTank',
			id: 61,
			label: 'Tanque de agua',
		},
		{
			name: 'surveillance',
			id: 62,
			label: 'Vigilancia',
		},
		{
			name: 'kitchen',
			id: 63,
			label: 'Cocina',
		},
		{
			name: 'suiteBedroom',
			id: 64,
			label: 'Dormitorio en suite',
		},
		{
			name: 'laundryRoom',
			id: 65,
			label: 'Lavadero',
		},
	];

	const [showMore, setShowMore] = useState(false);

	return (
		<>
			{items.map(
				(item, index) =>
					showMore &&
					index < 10 && (
						<div className='d-flex align-items-center col-6 py-3' key={item.id}>
							<Check />
							<p className='mb-0 ms-2 fs-6 fw-normal-bold text-color-dark'>
								{item.label}
							</p>
						</div>
					),
			)}

			{showMore && (
				<div className='d-flex flex-wrap justify-content-start'>
					<h5 className='text-color-lblue fw-bold py-4 mb-0 mt-5'>
						OTRAS CARACTERÍSTICAS
					</h5>
					{items.map(
						(item, index) =>
							showMore &&
							index > 10 && (
								<div className='d-flex align-items-center col-6 py-3' key={item.id}>
									<Check />
									<p className='mb-0 ms-2 fs-6 fw-normal-bold text-color-dark'>
										{item.label}
									</p>
								</div>
							),
					)}
				</div>
			)}

			<div
				className='d-flex justify-content-center w-100'
				onClick={() => setShowMore(!showMore)}>
				{showMore ? (
					<UilAngleUp size={50} color='#506CF9' />
				) : (
					<UilAngleDown size={50} color='#506CF9' />
				)}
			</div>
		</>
	);
}
