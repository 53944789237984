import { Form, Formik } from 'formik';
import React from 'react';
import SearchFilter from '../../components/Owner/SearchFilter';
import InputFloating from '../../components/InputFloating';
import {
	UilRulerCombined,
	UilCalender,
	UilCar,
	UilAngleRightB,
	UilAngleLeftB,
} from '@iconscout/react-unicons';
import InputCounter from '../../components/InputCounter';
import CustomSelect from '../../components/CustomSelect';
import { Bank, SkipForward, Toilet } from 'phosphor-react';

export default function GeneralInfo({ estateData, setEstateData, setSelectedStep }) {
	const handleChangeDirection = (value, name) => {
		setEstateData((prev) => ({
			...prev,
			operationType: { ...prev.operationType, [name]: value },
		}));
	};

	const changeHandler = (value, name) => {
		setEstateData((prev) => ({
			...prev,
			general: { ...prev.general, [name]: value },
		}));
	};

	return (
		<div className='d-flex flex-column align-items-start w-100 p-5'>
			<Formik className='h-100'>
				{({ touched }) => (
					<Form className='w-100 h-100'>
						<h5 className='mb-3'>Ubicación</h5>
						{/* ubicación */}
						<div className='row'>
							<div className='col'>
								<InputFloating
									changeHandler={handleChangeDirection}
									label='Calle'
									name='streetName'
									type='text'
									previousValue={estateData.operationType}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={handleChangeDirection}
									label='Número'
									name='streetNumber'
									type='text'
									previousValue={estateData.operationType}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={changeHandler}
									label='Piso'
									name='floorLevel'
									type='text'
									previousValue={estateData}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={changeHandler}
									label='Departamento'
									name='floorName'
									type='text'
									previousValue={estateData}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={handleChangeDirection}
									label='Localidad'
									name='city'
									type='text'
									previousValue={estateData.operationType}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={handleChangeDirection}
									label='Barrio'
									name='neighborhood'
									type='text'
									previousValue={estateData.operationType}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
						</div>
						<div className='row my-4 pb-5 border-bottom'>
							<div className='col'>
								<InputFloating
									changeHandler={changeHandler}
									label='Entre'
									name='firstStreet'
									type='text'
									previousValue={estateData}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={changeHandler}
									label='Y entre'
									name='secondStreet'
									type='text'
									previousValue={estateData}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<CustomSelect
									data={estateData.general}
									text={'Tipo'}
									name={'Tipo'}
									nameEng={'subtype'}
									changeHandler={changeHandler}
									className='rounded'
									values={[
										{
											id: 1,
											label: 'Subtipo 1',
										},
										{
											id: 2,
											label: 'Subtipo 2',
										},
										{
											id: 3,
											label: 'Subtipo 3',
										},
									]}
								/>
							</div>
							<div className='col'>
								<CustomSelect
									data={estateData.general}
									text={'Ubicación'}
									name={'Ubicación'}
									nameEng={'location'}
									changeHandler={changeHandler}
									className='rounded'
									values={[
										{
											id: 1,
											label: 'Frente',
										},
										{
											id: 2,
											label: 'Interno',
										},
										{
											id: 3,
											label: 'Contrafrente',
										},
										{
											id: 4,
											label: 'Lateral',
										},
									]}
								/>
							</div>
							<div className='col'>
								<CustomSelect
									data={estateData.general}
									text={'Orientación'}
									name={'Orientación'}
									nameEng={'orientation'}
									changeHandler={changeHandler}
									className='rounded'
									values={[
										{
											id: 1,
											label: 'Norte',
										},
										{
											id: 2,
											label: 'Noreste',
										},
										{
											id: 3,
											label: 'Este',
										},
										{
											id: 4,
											label: 'Sureste',
										},
										{
											id: 5,
											label: 'Sur',
										},
										{
											id: 6,
											label: 'Suroeste',
										},
										{
											id: 7,
											label: 'Oeste',
										},
										{
											id: 8,
											label: 'Noroeste',
										},
									]}
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={changeHandler}
									label='Código ubicación'
									name='postalCode'
									type='text'
									previousValue={estateData.general}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
						</div>
						{/* ubicación */}

						<h5 className='mb-3'>Superficie</h5>
						{/* superficie */}
						<div className='row pb-5 my-4 border-bottom'>
							<div className='d-flex align-items-center col'>
								<UilRulerCombined color='#506CF9' size={24} />
								<InputCounter label={'Ambientes'} />
							</div>

							<div className='col'>
								<InputFloating
									changeHandler={(e) => console.log(e.target.value)}
									label='Superficie libre (m²)'
									name='freeArea'
									type='text'
									previousValue={estateData}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={(e) => console.log(e.target.value)}
									label='Superficie cubierta (m²)'
									name='roofedArea'
									type='text'
									previousValue={estateData}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={(e) => console.log(e.target.value)}
									label='Superficie semi cubierta (m²)'
									name='semiCoveredArea'
									type='text'
									previousValue={estateData}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
							<div className='col'>
								<InputFloating
									changeHandler={(e) => console.log(e.target.value)}
									label='Superficie total (m²)'
									name='totalArea'
									type='text'
									previousValue={estateData}
									touched={touched}
									withDebounce={false}
									// className='rounded-0 rounded-start'
								/>
							</div>
						</div>
						{/* superficie */}

						<h5 className='mb-3'>Precios</h5>
						{/* precios */}
						<div className='row pb-5 border-bottom'>
							<div className='d-flex align-items-center col'>
								<div className='col-2'>
									<CustomSelect
										data={estateData}
										text={'Moneda'}
										name={'Moneda'}
										nameEng={'sellCurrency'}
										className='rounded-start'
									/>
								</div>
								<div className='col-8 px-2'>
									<InputFloating
										changeHandler={(e) => console.log(e.target.value)}
										label='Valor venta'
										name='sellingPrice'
										type='text'
										previousValue={estateData}
										touched={touched}
										withDebounce={false}
										className='rounded-0 rounded-end'
									/>
								</div>
								<div className='d-flex align-items-center rounded bg-blue-300 px-3 ms-2 h-100'>
									<Bank color='#fff' size={24} />
								</div>
							</div>
							<div className='d-flex align-items-center col px-3'>
								<div className='col-2'>
									<CustomSelect
										data={estateData}
										text={'Moneda'}
										name={'rentalCurrency'}
										nameEng={'rentalCurrency'}
										className='rounded-start'
									/>
								</div>
								<div className='col-2 mx-2'>
									<CustomSelect
										data={estateData}
										text={'P'}
										name={'P'}
										nameEng={'p'}
										className='rounded-0'
									/>
								</div>
								<div className='col-8'>
									<InputFloating
										changeHandler={(e) => console.log(e.target.value)}
										label='Valor renta'
										name='rentalPrice'
										type='text'
										previousValue={estateData}
										touched={touched}
										withDebounce={false}
										className='rounded-0 rounded-end'
									/>
								</div>
							</div>
							<div className='d-flex align-items-center col px-3'>
								<div className='col-2'>
									<CustomSelect
										data={estateData}
										text={'Moneda'}
										name={'taxCurrency'}
										nameEng={'taxCurrency'}
										className='rounded-start'
									/>
								</div>
								<div className='col-10 px-2'>
									<InputFloating
										changeHandler={(e) => console.log(e.target.value)}
										label='Valor impuestos'
										name='taxPrice'
										type='text'
										previousValue={estateData}
										touched={touched}
										withDebounce={false}
										className='rounded-0 rounded-end'
									/>
								</div>
							</div>
							<div className='d-flex align-items-center col'>
								<div className='col-2'>
									<CustomSelect
										data={estateData}
										text={'Moneda'}
										name={'expensesValue'}
										nameEng={'expensesValue'}
										className='rounded-start'
									/>
								</div>
								<div className='col-10 ps-2'>
									<InputFloating
										changeHandler={(e) => console.log(e.target.value)}
										label='Valor expensas'
										name='expensesValue'
										type='text'
										previousValue={estateData}
										touched={touched}
										withDebounce={false}
										className='rounded-0 rounded-end'
									/>
								</div>
							</div>
						</div>
						{/* precios */}

						<h5 className='my-4'>Generales</h5>
						{/* Generales */}
						<div className=''>
							<div className='d-flex justify-content-between my-4'>
								<div className='d-flex align-items-center col pe-4'>
									<UilCalender color='#506CF9' className='me-2' size={24} />
									<InputCounter label={'Antiguedad'} />
								</div>
								<div className='d-flex align-items-center col px-4'>
									<UilRulerCombined color='#506CF9' className='me-2' size={24} />
									<InputCounter label={'Plantas'} />
								</div>
								<div className='d-flex align-items-center col px-4'>
									<Toilet color='#506CF9' className='me-2' size={24} />
									<InputCounter label={'Baños'} />
								</div>
								<div className='d-flex align-items-center col px-4'>
									<UilCar color='#506CF9' className='me-2' size={24} />
									<InputCounter label={'Garage'} />
								</div>
							</div>
						</div>
						{/* Generales */}

						{/* Otros / Vendedor */}
						<div className='d-flex align-items-center justify-content-between w-100 py-4'>
							{/* Otros */}
							<div className='d-flex flex-column align-items-start col border-end pb-5 pe-5'>
								<h5 className='mb-4'>Otros</h5>
								<div className='d-flex align-items-center w-100'>
									<div className='col pe-4'>
										<InputFloating
											changeHandler={(e) => console.log(e.target.value)}
											label='Estilo'
											name='style'
											type='text'
											previousValue={estateData}
											touched={touched}
											withDebounce={false}
											// className='rounded-0 rounded-start'
										/>
									</div>
									<div className='col pe-4'>
										<InputFloating
											changeHandler={(e) => console.log(e.target.value)}
											label='Año'
											name='year'
											type='text'
											previousValue={estateData}
											touched={touched}
											withDebounce={false}
											// className='rounded-0 rounded-start'
										/>
									</div>
									<div className='col'>
										<SearchFilter
											text={'Estado'}
											name={'Estado'}
											nameEng={'state'}
											// values={null}
											changeHandlerMultipleSelect={(e) =>
												console.log(e.target.value)
											}
											data={estateData}
											withSearch={false}
										/>
									</div>
								</div>
							</div>
							{/* Otros */}

							{/* Vendedor */}
							<div className='d-flex flex-column align-items-start col pb-5 ps-5'>
								<h5 className='mb-4'>Vendedor</h5>
								<div className='d-flex align-items-center w-100'>
									<div className='col pe-5'>
										<SearchFilter
											text={'Vendedor'}
											name={'Vendedor'}
											nameEng={'salesman'}
											// values={null}
											changeHandlerMultipleSelect={(e) =>
												console.log(e.target.value)
											}
											data={estateData}
											withSearch={false}
										/>
									</div>
									<div className='col pe-5'>
										<SearchFilter
											text={'Emprendimiento'}
											name={'Emprendimiento'}
											nameEng={'venture'}
											// values={null}
											changeHandlerMultipleSelect={(e) =>
												console.log(e.target.value)
											}
											data={estateData}
											withSearch={false}
										/>
									</div>
								</div>
							</div>
							{/* Vendedor */}
						</div>

						{/* Otros / Vendedor */}

						<div className='d-flex align-items-center justify-content-between'>
							{/* <div
								className='d-flex align-items-center justify-content-center py-2 px-4 blue-gradient-buttons rounded cursor-pointer'
								onClick={() => setSelectedStep('description')}>
								<SkipForward color='#fff' size={24} />
								<p className='mb-0 ms-2 text-color-white fs-5'>Omitir</p>
							</div> */}
							<div
								className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
								onClick={() => setSelectedStep('operationType')}>
								<UilAngleLeftB color='#fff' size={24} />
								<p className='mb-0 text-color-white fw-bold'>Atrás</p>
							</div>
							<div
								className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
								onClick={() => setSelectedStep('description')}>
								<p className='mb-0 text-color-white fw-bold'>Siguiente</p>
								<UilAngleRightB color='#fff' size={24} />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
