import {
	LineSegments,
	Note,
	CalendarBlank,
	Envelope,
	WhatsappLogo,
	ArrowRight,
	ArrowClockwise,
} from 'phosphor-react';
import { UilEllipsisH, UilMessage, UilPlus } from '@iconscout/react-unicons';
import { FloppyDisk, Trash, XCircle } from 'phosphor-react';

import React, { useState, useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import ThemeContext from '../../../contexts/themeContext';
import Button from '../../../components/bootstrap/Button';
import ChronologyItemSelector from './ChronologyItemSelector';
import { createNoteCustomer , deleteNoteCustomer ,postEvent , deleteEventCustomer, getStatus , sendCorreo , updateNoteCustomer ,updateEventCustomer} from '../../../services/customersServices'
import { confirmationSwal } from '../../../utils/confirmationSwal'
import UserContext from '../../../contexts/userContext';
import Toast from '../../../components/Toast/Toast';
import { showToast } from '../../../components/Toast/toastUtils/toastUtils';


const Chronology = ({reason , properties , summary , setSummary, chronology, setChronology }) => {
	const [formInitialValues, setFormInitialValues] = useState({ content: '', number: '', message: '' });
	const [ statusList , setStatusList] = useState([])
	const [activeItem, setActiveItem] = useState('Notas');
	const [ itemEdit , setItemEdit] = useState({})
	const [contact , setContact] = useState('');
	const [ edit, setEdit] = useState(false);
	const [ view, setView] = useState(false);
	const [task, setTask] = useState({ properties: []});
	const [list, setList] = useState([])
	const { mobileDesign } = useContext(ThemeContext);
	const { token } = useContext(UserContext);
	const { customerId } = summary

	  const actionTypes = {
		Correo: 3,
		Whatsapp: 5
	  }
	
	// const [entries, setEntries] = useState([
	// 	{
	// 		icon: <CalendarBlank size={32} color='#506cf9' />,
	// 		title: 'Negocio creado el 11 de Sep.',
	// 		content: 'Macarena Belamendía',
	// 	},
	// 	{
	// 		icon: <WhatsappLogo size={32} color='#506cf9' />,
	// 		title: 'Hace 6 minutos',
	// 		content: (
	// 			<div className='d-flex align-items-center'>
	// 				<p className='m-0 me-2'>Macarena Belamendía</p>
	// 				<ArrowRight size={20} color='#506cf9' />
	// 				<p className='m-0 ms-2'>+54 9 11 3153-1054</p>
	// 			</div>
	// 		),
	// 	},
	// ]);


	const mappingError = (error) => {
	
		if (error.note && error.note.text) {
			callsAllert(error.note.text);
		}
		
		const handleEventError = (event) => {
			if (event.startTime) {
				callsAllert(event.startTime);
			}
			if (event.type) {
				callsAllert(event.type);
			}
		};
	
		if (error.event) {
			handleEventError(error.event);
		}
	};

	const callsAllert = (array) => {
		if (!Array.isArray(array)) {
			return;
		}
	
		for (let i = 0; i < array.length; i++) {
			const errorMessage = array[i];
			showToast(errorMessage, setList, 'danger');
		}
	};


	useEffect(() => {
		const storedStatusList = localStorage.getItem('customerListStatus');
	  
		const updateStatusList = (id, data) => {
		  const updatedStatus = { id, statusList: data };
		  localStorage.setItem('customerListStatus', JSON.stringify(updatedStatus));
		  setStatusList(data);
		};
	  
		if (!storedStatusList) {
		  getStatus(token).then(( data ) => {
			updateStatusList(customerId, data);
		  });
		} else {
		  const { id, statusList } = JSON.parse(storedStatusList);
		  if (id !== customerId) {
			getStatus(token).then((data) => {
			  updateStatusList(customerId, data);
			});
		  } else {
			setStatusList(statusList);
		  }
	  
		}

	
	  }, []);

	const contactMapping = {
		Whatsapp :`${summary.areaCode || ''}${summary.mobile ? summary.mobile : ''}`,
		Correo : `${summary.email || ''}`,
	}

	useEffect(() => {
		setContact(contactMapping[activeItem])
	},[activeItem])

	const icon = (item) => {
		const itemType = item.type ? item.type.toLowerCase() : activeItem.toLowerCase();

		
		if (item.to !== undefined && item.to !== null) {
			if (itemType === 'envio de mail') {
				return <Envelope size={32} color={'#506cf9'} />
			} else if (itemType === 'envio de whatsapp') {
				return <WhatsappLogo size={32} color={'#506cf9'} />
			}
		} else {
			if (itemType === 'nota') {
				return <Note size={32} color={'#506cf9'} />;
			} else {
				return <CalendarBlank size={32} color={'#506cf9'} />;
			}
		}
	};

	const content = (type, to = '') => {
		const userData = JSON.parse(
			sessionStorage.getItem('userData') || localStorage.getItem('userData'),
		);

		if (to) {
			return (
				<div className='d-flex align-items-center'>
					<p className='m-0 me-2'>
						{userData.name} {userData.surname}
					</p>
					<ArrowRight size={20} color='#506cf9' />
					{/* <p className='m-0 ms-2'>{userData.phone}</p> */}
					<p className='m-0 ms-2'>{to}</p>
				</div>
			);
		} else {
			return `${userData.name} ${userData.surname}`;
		}
	};

	const handleSubmit = async (value, resetForm) => {
		const getIcon = () => {
			switch (activeItem) {
				case 'Notas':
					return <Note size={32} color={'#506cf9'} />;
				case 'Actividad':
					return <CalendarBlank size={32} color={'#506cf9'} />;
				case 'Correo':
					return <Envelope size={32} color={'#506cf9'} />;
				case 'Whatsapp':
					return <WhatsappLogo size={32} color={'#506cf9'} />;
				default:
					break;
			}
		};
	
		let newEntry = {
			icon: '',
			details: '',
			creator: '',
			id: 0,
			type: '',
			isEditable: false,
			timestamp: '',
			timeDiff: '',
			properties: [],
		};
	
		if (edit) {
			let response;
			if (activeItem !== 'Actividad') {
				const valor = { text: value.content };
				response = await updateNoteCustomer(valor, itemEdit.id, customerId, token);
			} else {
				const infoForm = formatInfo(task);
				response = await updateEventCustomer(infoForm, itemEdit.id, customerId, token);
			}
	
			if (response.status === 200) {
				showToast(response.data.message, setList, 'success');
				const { data } = response.data;
				newEntry = {
					details: activeItem !== 'Actividad' ? data.note : data.observations,
					id: data.id,
					type: activeItem !== 'Actividad' ? 'Nota' : reason[data.type - 1].name,
					timeDiff: 'Editado hace un momento',
					isEditable: true,
					timestamp: data.startTime || '',
					properties: data.properties ?? [],
				};
				setChronology([newEntry, ...chronology]);
				setEdit(false);
				setItemEdit({});
				setTask({});
			} else {
				mappingError(response.error);
			}
		} else {
			const actionHandler = actionHandlers[activeItem];
			if (actionHandler) {
				const res = await actionHandler(value);
				if (res.status === 200) {
					showToast(res.data.message, setList, 'success');
					const { data } = res.data;
					newEntry = {
						icon: getIcon(),
						details: data.note || data.observations,
						creator: content(reason && reason[data.type - 1] ? reason[data.type - 1].name : 'Nota'),
						id: data.id,
						type: reason && reason[data.type - 1] ? reason[data.type - 1].name : 'Nota',
						isEditable: activeItem === 'Actividad' || activeItem === 'Notas',
						timestamp: data.startTime ? data.startTime : '',
						to: data.to ? data.to : null,
						timeDiff: activeItem === 'Notas' ? 'Nota creada hace un momento' : 'Actividad creada hace un momento',
						properties: data.properties ?? [],
					};
					setChronology([newEntry, ...chronology]);
					if (activeItem === 'Actividad') {
						setTask({ properties: [] });
					} else {
						resetForm();
					}
				} else {
					mappingError(res.error);
				}
			}
		}
	};
	

	const formatInfo = (value) => {

		let object = {
		};

		if(activeItem === 'Actividad'){


			if(!value.date || !value.motivo || value.properties.length == 0 || !value.obs){
				return
			} else {
				let formattedTime = value.time.split(':').map(part => part.padStart(2, '0')).join(':');
				let formattedDate = `${value.date.year}-${String(value.date.month).padStart(2, '0')}-${String(value.date.day).padStart(2, '0')} ${formattedTime}:00`;
				
				object = {
					type: value.motivo || 0,
					startTime: formattedDate || '',
					observations: value.obs || '',
					properties: value.properties || []
				}
			}
			
		} else {

			if(!value.message) {
				return
			}
			
			let startTime = new Date();
			
			let formattedStartTime = startTime
			.toISOString()
			.replace('T', ' ')
			.replace(/\.\d+Z$/, '');


			object = {
				type: actionTypes[activeItem],
				startTime: formattedStartTime,
				to: value.number || null,
				observations: value.details || value.subject
			}
		}
	  
		return object;
	  };
	  
	  
	  

	const actionHandlers = {
		Notas:(value) => {

			let valor = {
				text:value.content,
				id: "",
			}
			
			return createNoteCustomer(valor ,customerId,token)
		},

		Whatsapp: async (value) => {
			let infoForm = formatInfo(value)
			let response = await postEvent( customerId, infoForm , token)
			
			const findWhiteSpacesRegex = / /g;
			const parsedMessage = value.message.replace(findWhiteSpacesRegex, '%20');
			window.open(`https://wa.me/${value.number}/?text=${parsedMessage}`, '_blank');
			return response
		},
		Correo:  (value) => {
			let infoForm = formatInfo(value)
			return postEvent(customerId, infoForm, token)
		},

		Actividad: () => {
			let infoForm = formatInfo(task)
		  return postEvent(customerId, infoForm, token)
		}
	}


	const handleDeleteHistory = async(item) => {

		const swalInstance = confirmationSwal("Eliminar historia" ,'¿Está seguro que desea eliminar la historia seleccionada ?', 'warning', 'Eliminar'); 

		try {
			const result = await swalInstance;
			if (result.isConfirmed) {
				
				if(item.type == 'Nota' || item.type  == 'note'){
					const response = await deleteNoteCustomer(item.id , customerId, token)
					showToast(response.data.message, setList,'success');
					
				} else {
					const response = await deleteEventCustomer(customerId , item.id , token)
					showToast(response.data.message, setList,'success');
				}
				setChronology(chronology.filter((entry) => entry.id !== item.id || entry.details !== item.details))
			}
		  } catch (error) {
			showToast(list, setList,'danger');
			
		  }
	}

	const chargeHistory = (item) => {
		if(item.type == "Nota"){
			setActiveItem("Notas");
		} else {

			let time; 

			if(item.timestamp.includes('T')){
				time = getTime(item.timestamp , 1)
			} else {
				time = getTime(item.timestamp , 2)
			}

			const date = new Date(item.timestamp);
			const formattedDate = {
			date: {
				day: date.getDate(),
				month: date.getMonth() + 1,
				year: date.getFullYear(),
			},
			time: time,
			};
			const motivoEncontrado = reason.find((motivo) => motivo.name === item.type)
			const motivoID = motivoEncontrado ? motivoEncontrado.id : 1
			
			
			setActiveItem("Actividad")
			setTask({
				obs: item.details,
				motivo: motivoID,
				motivoName: item.type,
				date: formattedDate.date,
				time: formattedDate.time,
				properties: [item.properties[0]],
				propertiesName: properties[item.properties[0] - 1].location,
			  });

		}
		setChronology(chronology.filter((entry) => entry.id !== item.id || entry.details !== item.details))

	}

	const handleEditHistory = async(item) => {
		setFormInitialValues({ content: item.details, number: contact, message: '' })
		setItemEdit(item)
		setEdit(true)
		chargeHistory(item)

	}

	const handleViewHistory = (item) => {
		setItemEdit(item)
		setFormInitialValues({ content: item.details, number: contact, message: '' })
		setView(true)
		chargeHistory(item)

	}

	const getTime = (timestamp, forma) => {

		if(forma == 1){
			const fecha = new Date(timestamp);
			const hora = fecha.getUTCHours();
			const minutos = fecha.getUTCMinutes();
			const horaRedondeada = minutos >= 30 ? hora + 1 : hora;
		  
			return `${('0' + horaRedondeada).slice(-2)}:00`;
		} else {
			const [fecha, hora] = timestamp.split(' ');
			const [year, month, day] = fecha.split('-');
			const [hours, minutes] = hora.split(':');

			const fechaObj = new Date(year, month - 1, day, hours, minutes);
			const horaExacta = fechaObj.getHours();
			const minutos = fechaObj.getMinutes();

			return `${('0' + horaExacta).slice(-2)}:${('0' + minutos).slice(-2)}`;
		}
	}

	const cancelEdit = () => {
		setChronology((chronology) => [itemEdit, ...chronology]);
		setItemEdit({})
		setTask({})
		setEdit(false)
		setView(false)
	}

	const chronologyItems = [
		{
			name: 'Notas',
			icon: <Note size={20} color={activeItem === 'Notas' ? '#506cf9' : '#000'} />,
		},
		{
			name: 'Actividad',
			icon: (
				<CalendarBlank size={20} color={activeItem === 'Actividad' ? '#506cf9' : '#000'} />
			),
		},
		{
			name: 'Correo',
			icon: <Envelope size={20} color={activeItem === 'Correo' ? '#506cf9' : '#000'} />,
		},
		{
			name: 'Whatsapp',
			icon: <WhatsappLogo size={20} color={activeItem === 'Whatsapp' ? '#506cf9' : '#000'} />,
		},
	];

	return (
		<div className={`d-flex flex-column w-100 ${!mobileDesign && 'p-4'}`}>
			<Toast toastlist={list} position="top-right" setList={setList} />
			{/* Customer state */}
			<div className='d-flex align-items-center col-12 mb-5'>
				{!mobileDesign &&
					statusList?.map((item, index) => (
						<div
							className={`d-flex justify-content-center col p-2 fs-5 fw-normal-bold 
						 ${
								summary.status === item.id
									? 'bg-blue-300 text-color-white'
									: 'bg-white text-color-dark cursor-pointer'
							} ${index === 0 && 'rounded-start'} ${
								index === statusList.length - 1 && 'rounded-end'
							}`}
							style={{ margin: '0px 1px' }}
							onClick={() =>
								setSummary((summary) => ({
									...summary,
									status: item.id,
								}))
							}
							key={item.name}>
							{item.name}
						</div>
					))}
			</div>
			{/* Customer state */}

			{/* Header */}
			{!mobileDesign && (
				<div className='d-flex align-items-center justify-content-between col-12'>
					<div className='d-flex align-items-center col-4'>
						<div className='d-flex align-items-center px-4 py-3 me-1 rounded-top-lg bg-white text-color-lblue fs-6 fw-bold'>
							<LineSegments size={24} color='#506cf9' />
							<p className='my-0 mx-2'>Cronología</p>
						</div>
					</div>
				</div>
			)}
			{/* Header */}
			<div className='d-flex flex-column rounded-right-and-bottom bg-white col-12'>
				<div className={`${!mobileDesign && 'p-5'}`}>
					<Formik
						enableReinitialize={true}
						// onSubmit={handleSubmit}
						onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
						initialValues={{ content: edit || view ? itemEdit.details : '' , number: contact, message: '' }}
						// initialValues={formInitialValues}
						>
						<Form>
							<div className='d-flex flex-column field-chronology-action bg-white h-75  shadow-black'
								style={mobileDesign ? { minHeight: '298px' } : {}}>
								{/* Tabs */}
								<div className='d-flex align-items-center border-bottom col-12'>
									{chronologyItems.map((item, index) => (
										<div
											className={`d-flex align-items-center 
											${
												!mobileDesign ? 'p-4 pb-3' : 'px-2 py-4 col flex-column'
											} fs-6 fw-normal-bold ${
												activeItem === item.name
													? 'bg-lblue text-color-lblue border-bottom  border-2 border-primary '
													: 'text-color-black border-2 border-transparent border-bottom'
											} ${index === 0 && 'rounded-tabs-chronology'} ${
												index === chronologyItems.length - 1 &&
												'rounded-top-right'
											} cursor-pointer`}
											key={item.name}
											onClick={() => {
												setActiveItem(item.name);
												if(activeItem !== item.name && (edit || view)){
													setChronology((chronology) => [itemEdit, ...chronology]);
													setFormInitialValues({ content: '', number: '', message: '' })
													setTask({})
													setItemEdit({})
													setEdit(false)
													setView(false)
												}
												
											}}>
											{item.icon}
											<strong><p className='my-0 mx-1'> 
												 {item.name} </p>
											</strong>
										</div>
									))}
								</div>
								{/* Tabs */}


								<div className='border-none py-2'>
									<ChronologyItemSelector view={view} reason={reason} task={task} setTask={setTask} properties={properties} activeItem={activeItem} />
								</div>
							</div>

							{/* Send button */}
							{activeItem === 'Whatsapp' || activeItem === 'Correo' ? (
								<div
									className={`d-flex justify-content-end mt-4 ${
										mobileDesign && 'my-4'
									}`}>
									<Button
										rounded={1}
										className='bg-blue-300 mx-4 border-0'
										type='submit'>
										<div className='d-flex align-items-center'>
											<UilMessage size={24} color='#ffffff' />
											<p className='my-0 mx-2 fs-6 fw-normal text-color-white'>
												Enviar
											</p>
										</div>
									</Button>
								</div>
							) : (
								<div
									className={`d-flex justify-content-end mt-4 ${
										mobileDesign && 'my-4'
									}`}>
									{edit || view ?
									<Button
										rounded={1}
										className='bg-danger mx-2 border-0'
										onClick={cancelEdit}>
										<div className='d-flex align-items-center'>
											<XCircle size={24} color='#ffffff'/>
											<p className='my-0 mx-2 fs-6 fw-light text-color-white'>
												Cancelar
											</p>
										</div>
									</Button>
									: 
									<></>
									}
									
									{!view &&
											<Button
											rounded={1}
											className='bg-blue-300 mx-2 border-0'
											type='submit'>
											<div className='d-flex align-items-center'>
												{edit ? <ArrowClockwise size={24}   color='#ffffff' /> : <UilPlus size={24} color='#ffffff' /> }
												
												<p className='my-0 mx-2 fs-6 fw-light text-color-white'>
													{edit ? "Actualizar"  : "Agregar"}
												</p>
											</div>
										</Button>
										}
								</div>
							)}
							{/* Send button */}
						</Form>
					</Formik>
				</div>

				{/* Entries */}
				<div className='d-flex flex-column col-12 my-2'>
					{chronology?.map((item, index) => (
						<div
							// key={item.title}
							key={index}
							className={`d-flex align-items-center justify-content-between w-100 ${
								!mobileDesign ? 'px-5' : 'px-4'
							} py-3 ${index % 2 === 0 && 'bg-new-gray'}`}>
							<div className='d-flex align-items-center'>
								{item.icon ?  item.icon : icon(item)  }
								<div className={`d-flex ${mobileDesign && 'flex-column'}`}>
									{/* <p className='fs-6 fw-bold my-0 mx-2'>{item.title}</p> */}
									<p className='fs-6  my-0 mx-2'> <strong> {item.timeDiff} </strong>  
									{/* {item.details} */}
									</p>
									<div className='mx-2'>{content(item.type, item.to)}</div>
								</div>
							</div>

							<div className='bg-blue-300 dropdown rounded-circle p-2 mx-1 cursor-pointer'>
								<div
									className='cursor-pointer'
									data-bs-toggle='dropdown'
									aria-expanded='false'
									// id={item.details.replace(/ /g, '')}>
									id={item.id}>
									<UilEllipsisH color='#fff' size={20} />
								</div>
								<div
									className='dropdown-menu dropdown-content'
									// aria-labelledby={item.details.replace(/ /g, '')}>
									aria-labelledby={item.details}>

									{item.isEditable &&
									 !edit &&
									 <>
										<div 
											className='dropdown-item'
											onClick={()=> handleEditHistory(item)}>
												Editar
										</div>
										<div
											className='dropdown-item'
											onClick={() => handleViewHistory(item)}>
											Ver
										</div>
									</>
											}
										<div
											className='dropdown-item'
											onClick={() => handleDeleteHistory(item)}>
											Borrar
										</div>
										

								</div>
							</div>
						</div>
					))}
				</div>
				{/* Entries */}
			</div>
		</div>
	);
};

export default Chronology;
