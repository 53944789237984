import { useContext } from 'react';
import UserContext from '../contexts/userContext';
import { useQuery } from '@tanstack/react-query';
import { getFilters } from '../services/filtersServices';

export default function useFilters(section) {
	const { token } = useContext(UserContext);

	const filters = useQuery({
		queryKey: ['filters', section],
		queryFn: () => getFilters(section, token),
		staleTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});

	return filters;
}
