import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import USERS from '../../common/data/userDummyData';
import Avatar from '../../components/Avatar';
import { UilUsdCircle, UilMapMarker, UilBuilding, UilMoneyBill } from '@iconscout/react-unicons';
import Check from '../../components/Check';

const CardInmuebleMobile = ({ type }) => {
	const types = ['Reserva inmueble', 'Alta de ficha', 'Suspensión de inmueble', 'Baja de ficha'];
	const [selectedEstates, setSelectedEstates] = useState([]);
	const imagenURL =
		'https://images.pexels.com/photos/2360673/pexels-photo-2360673.jpeg?cs=srgb&dl=pexels-tobias-bj%C3%B8rkli-2360673.jpg&fm=jpg';
	return (
		<>
			<div className='card rounded-1 p-2 px-4 d-flex  '>
				<div className='row mt-3'>
					<div className='col-12 d-flex position-relative'>
						<div className='row position-absolute'>
							<div className='ms-3 mt-3'>
								<span className='rounded-1 px-3 py-1 bg-primary text-white'>
									540
								</span>
							</div>
						</div>
						<div className='row position-absolute end-0'>
							<div className='mt-3'>
								{type - 1 == 0 && (
									<span className='radius-7p px-3 py-1 bg-orange text-white me-4'>
										{types[type - 1]}
									</span>
								)}
								{type - 1 == 1 && (
									<span className='radius-7p px-3 py-1 bg-violet text-white me-4 '>
										{types[type - 1]}
									</span>
								)}
								{type - 1 == 2 && (
									<span className='radius-7p px-3 py-1 bg-warning text-white me-4'>
										{types[type - 1]}
									</span>
								)}
								{type - 1 == 3 && (
									<span className='radius-7p px-3 py-1 bg-danger text-white me-4	'>
										{types[type - 1]}
									</span>
								)}
							</div>
						</div>

						<img
							src={imagenURL}
							alt='Imagen'
							className='imagen-full w-100 rounded-1'
							style={{ height: '166px' }}
						/>
					</div>
				</div>
				<div className='row mt-1 ms-2'>
					<div className='col-9'>
						<div className='row mt-4'>
							<div className='col-6 p-0 '>
								<div className='float-start'>
									<UilMapMarker color='#506CF9' />
									<span className='ms-1 fw-500'>Cafatye 626</span>
								</div>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-6 p-0'>
								<div className='d-flex'>
									<UilBuilding color='#506CF9' />
									<span className=' fs-6 fw-500'>Departamento</span>
								</div>
							</div>
							{type - 1 == 3 && (
								<div className='col-6 p-0'>
									<div className='d-flex p-0'>
										<UilMoneyBill color='#506CF9' />
										<span className='fs-6 fw-500'>USD 250000</span>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className='col-2 ms-3 mt-4'>
						<div className='float-end'>
							<Avatar
								className=' float-end me-1'
								srcSet={USERS.CHLOE.srcSet}
								src={USERS.CHLOE.src}
								size={32}
								color={USERS.CHLOE.color}
							/>
							<p className='float-end me-2 mt-2  fw-500'>12:18</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardInmuebleMobile;
