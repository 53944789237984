import React from 'react';
import Configuration from './Configuration';
import CorreoElectronico from './CorreElectronico';
import Create from './Create';

const ChildrenSelector = ({ selectedTab, setUserData, userData }) => {
	switch (selectedTab) {
		case 'OPERADOR':
			return <Create userData={userData} setUserData={setUserData} />;

		case 'CONFIGURACIÓN':
			return <Configuration />;

		case 'CORREO ELECTRÓNICO':
			return <CorreoElectronico />;
		default:
			break;
	}
};

export default ChildrenSelector;
