import React from 'react';

const CustomSliders = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1.5rem'
			height='1.5rem'
			fill='#ffffff'
			viewBox='0 0 256 256'>
			<rect width='256' height='256' fill='none'></rect>
			<line
				x1='128'
				y1='108'
				x2='128'
				y2='216'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></line>
			<line
				x1='128'
				y1='40'
				x2='128'
				y2='68'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></line>
			<circle
				cx='128'
				cy='88'
				r='20'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></circle>
			<line
				x1='200'
				y1='188'
				x2='200'
				y2='216'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></line>
			<line
				x1='200'
				y1='40'
				x2='200'
				y2='148'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></line>
			<circle
				cx='200'
				cy='168'
				r='20'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></circle>
			<line
				x1='56'
				y1='156'
				x2='56'
				y2='216'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></line>
			<line
				x1='56'
				y1='40'
				x2='56'
				y2='116'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></line>
			<circle
				cx='56'
				cy='136'
				r='20'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></circle>
		</svg>
	);
};

export default CustomSliders;
