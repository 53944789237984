import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { UilArrowToRight, UilLeftArrowFromLeft } from '@iconscout/react-unicons';
// import Icon from '../../components/icon/Icon';
// import Logo from '../../components/Logo';
// import XintelLogo from '../../assets/logos/XintelLogo.png';

const Brand = ({ asideStatus, setAsideStatus }) => {
	return (
		<div className='d-flex flex-column w-100 align-items-center'>
			{/* <div className='brand'> */}
			<div className='d-flex w-100 justify-content-end p-1'>
				<button
					type='button'
					className='btn brand-aside-toggle'
					aria-label='Toggle Aside'
					onClick={() => setAsideStatus(!asideStatus)}>
					{/* <Icon icon='FirstPage' className='brand-aside-toggle-close' />
					<Icon icon='LastPage' className='brand-aside-toggle-open' /> */}
					{asideStatus ? (
						<UilLeftArrowFromLeft size={30} />
					) : (
						<UilArrowToRight size={30} />
					)}
				</button>
			</div>
			{/* <div className='brand-logo w-100'>
				<h1 className='brand-title '>
					<Link to='/' aria-label='Logo' alt='Xintel'>
						<img src={XintelLogo} alt='Logo Xintel' />
					</Link>
				</h1>
			</div> */}
			{/* </div> */}
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
