import React, { useContext, useState } from 'react'
import Modal ,{ModalBody,ModalFooter,ModalHeader, ModalTitle} from '../../components/bootstrap/Modal'
import InputFloating from '../../components/InputFloating'
import { Field, Form, Formik } from 'formik'
import CustomSelect from '../../components/CustomSelect'
import Button from '../../components/bootstrap/Button'
import { FloppyDisk } from 'phosphor-react';
import {
  UilPen
    
  
} from '@iconscout/react-unicons';
import ThemeContext from '../../contexts/themeContext'

const ModalCreateStatus = ({ statusCustomers ,viewModalCreateStatus,setViewModalCreateStatus ,handleSubmitStatus, status , setStatus}) => {
    const { mobileDesign, darkModeStatus } = useContext(ThemeContext);

    const handleInputChange = (e, name) => {
              setStatus({
                ...status,
                [name] : e
              })
	  };



  return (
    <Modal
        id={ '1' }
        // titleId={ String }
        isOpen={ viewModalCreateStatus }
        setIsOpen={ setViewModalCreateStatus }
        isStaticBackdrop={ true }
        isScrollable={ false }
        isCentered={ true }
        size={ 'lg' }
        fullScreen={ mobileDesign? true : false }
        isAnimation={ true }
        >
        <div className={` ${mobileDesign ? 'w-100 h-100' : 'conteiner' } m-0 p-5  bg-custom-newGray rounded `}>

            <ModalHeader 
                className={'pb-3 p-0 '}
                setIsOpen={ setViewModalCreateStatus} // Example: setState
                >
                <ModalTitle className='text-color-lblue fw-bold' id={ '1' }> <UilPen  size={25} color='#506CF9' /> NUEVO ESTADO DE CLIENTE</ModalTitle>
            </ModalHeader>
            <ModalBody className={'m-0 pt-4 pb-5 px-0  '}>
                <Formik initialValues={status}>
                {({ touched }) => (
                    <Form>
                        <div className='row'>
                            <div className='d-flex align-items-center col-6'>
                                <InputFloating
                                    label='Nombre del estado'
                                    name='name'
                                    type='text'
                                    changeHandler={(e)=>handleInputChange( e ,'name')}
                                    previousValue={status}
                                    errorMessage='Ingrese un nombre'
                                    required
                                    touched={touched}
                                    className='custom-shadow-input'/>
                            </div>
                            <div onClick={() => document.getElementById('colorPicker').click()} className='d-flex align-items-center position-relative col-6'>
                                {/* <ColorPicker/> */}
                                <InputFloating
                                    label='Color del estado'
                                    name='color'
                                    type='text'
                                    changeHandler={(e)=>handleInputChange( e ,'color')}
                                    previousValue={status}
                                    errorMessage='Ingrese un color'
                                    required
                                    touched={touched}
                                    />
                                <input className='hidden-color-picker' type='color' id='colorPicker' value={status.color ? status.color : ''}
                                 onChange={(e) => handleInputChange( e.target.value ,'color')} />
                            </div>
                        </div>
                        <div className='row py-4'>
                            <div className='d-flex align-items-center col-12'>
                                <div class='form-floating w-100'>
                                    <Field
                                        as='textarea'
                                        className={`form-control bg-lblue  min-vh-10 inner-shadow-blue rounded-1  input-no-outline text-color-lblue `}
                                        name={'description'}
                                        id={'description'}
                                        value={status['description']}
                                        placeholder={'description'}
                                        onChange={(e) => setStatus({
                                            ...status,
                                            description: e.target.value
                                        })}
                                    />
                                    <label className='text-color-lblue'>
                                        Descripción
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row pb-5'>
                                <div className='d-flex align-items-center col-6'>
                                    <CustomSelect
                                        data={status}
                                        text={'Orden del estado'}
                                        name={'order'}
                                        nameEng={'order'}
                                        changeHandler={(e)=>handleInputChange( e ,'order')}
                                        values={
                                            [...statusCustomers.data?.map(status => ({
                                              id: status.statusData.order,
                                              label: status.statusData.order
                                            })), { id: statusCustomers.data.length + 1, label: statusCustomers.data.length + 1 }]
                                          }
                                        required
                                    />
                                
                                </div>
                                <div className='d-flex flex-column position-relative  align-items-center col-6'>
                                    <InputFloating
                                            label='Dias'
                                            name='days'
                                            type='number'
                                            changeHandler={(e)=>handleInputChange( e ,'days')}
                                            previousValue={status}
                                            errorMessage='Ingrese un numero de días'
                                            required
                                            touched={touched}
                                            />
                                    <p className='position-absolute label-position-InputFloating text-info '>(Cada x día pasa al siguiente estado)</p>
                                </div>
                        </div>
                        <div className='row p-3 px-0'>
                            <div className='d-flex align-items-center position-relative col-6'>
                                <CustomSelect
                                    data={status}
                                    text={'Estado a pasar'}
                                    name={'statusIdDays'}
                                    nameEng={'statusIdDays'}
                                    changeHandler={(e)=>handleInputChange( e ,'statusIdDays')}
                                    values={statusCustomers.data?.map(status => ({
                                        id: status.statusId,
                                        label: status.name
                                    }))}
                                    required
                                />
                                <p className='position-absolute label-position-InputFloating text-info '>(Obligarorio ingresar los días)</p>
                            </div>
                            <div className='d-flex align-items-center position-relative col-6'>
                                <CustomSelect
                                        data={status}
                                        text={'Estado a pasar'}
                                        name={'statusIdAction'}
                                        nameEng={'statusIdAction'}
                                        changeHandler={(e)=>handleInputChange( e ,'statusIdAction')}
                                        values={statusCustomers.data?.map(status => ({
                                            id: status.statusId,
                                            label: status.name
                                        }))}
                                        required
                                    />
                                <p className='position-absolute label-position-InputFloating text-info  '>(Si se realiza una accíon)</p>
                            </div>
                        </div>
                    </Form>
                )}
                </Formik>
            </ModalBody>
            <ModalFooter className={'p-0'}>
                <Button
                    rounded={1}
                    className='blue-gradient-buttons border-0'
                    onClick={() => handleSubmitStatus()}>
                    <div className='d-flex align-items-center'>
                        <FloppyDisk size={24} color='#ffffff' />
                        <p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
                    </div>
                </Button>
            </ModalFooter>
        </div>

    </Modal>
  )
}

export default ModalCreateStatus