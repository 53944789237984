import React, { useState } from 'react';
import {
	UilRulerCombined,
	UilRuler,
	UilTape,
	UilTrees,
	UilBed,
	UilRestaurant,
	UilAngleUp,
	UilAngleDown,
} from '@iconscout/react-unicons';
import {
	Armchair,
	Bathtub,
	CoatHanger,
	CookingPot,
	Door,
	Drop,
	ForkKnife,
	Toilet,
} from 'phosphor-react';

export default function Measures() {
	const items = [
		{
			name: 'coveredArea',
			label: 'Sup. cubierta',
			icon: <UilRulerCombined color='#506CF9' size={24} />,
		},
		{
			name: 'semiCoveredArea',
			label: 'Sup. semi cubierta',
			icon: <UilRuler color='#506CF9' size={24} />,
		},
		{
			name: 'totalBuilt',
			label: 'Total construído',
			icon: <UilTape color='#506CF9' size={24} />,
		},
		{
			name: 'front',
			label: 'Frente',
			icon: <UilRulerCombined color='#506CF9' size={24} />,
		},
		{
			name: 'garden',
			label: 'Patio',
			icon: <UilTrees color='#506CF9' size={24} />,
		},
		{
			name: 'living',
			label: 'Living',
			icon: <Armchair color='#506CF9' size={24} />,
		},
		{
			name: 'diningRoom',
			label: 'Comedor',
			icon: <ForkKnife color='#506CF9' size={24} />,
		},
		{
			name: 'bathrooms',
			label: 'Baños',
			icon: <Bathtub color='#506CF9' size={24} />,
		},
		{
			name: 'kitchen',
			label: 'Cocina',
			icon: <CookingPot color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom1',
			label: 'Dormitorio 1',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom2',
			label: 'Dormitorio 2',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom3',
			label: 'Dormitorio 3',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom4',
			label: 'Dormitorio 4',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom5',
			label: 'Dormitorio 5',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'diaryDiningRoom',
			label: 'Comedor diario',
			icon: <UilRestaurant color='#506CF9' size={24} />,
		},
		{
			name: 'servDep',
			label: 'Dep. de serv.',
			icon: <Door color='#506CF9' size={24} />,
		},
		{
			name: 'servToilet',
			label: 'Toilette serv.',
			icon: <Toilet color='#506CF9' size={24} />,
		},
		{
			name: 'laundry',
			label: 'Lavadero',
			icon: <Drop color='#506CF9' size={24} />,
		},
		{
			name: 'placards',
			label: 'Placards',
			icon: <CoatHanger color='#506CF9' size={24} />,
		},
		{
			name: 'toilet',
			label: 'Toilette',
			icon: <Toilet color='#506CF9' size={24} />,
		},
	];

	const [showMore, setShowMore] = useState(false);

	return (
		<div className='d-flex flex-column'>
			{items.map(
				(item) =>
					showMore && (
						<div
							className='d-flex align-items-center justify-content-between py-3'
							key={item.name}>
							<div className='d-flex align-items-center w-50'>
								{item.icon}
								<p
									className='text-color-lblue mb-0 ms-3 fs-5 fw-normal-bold'
									// style={{ fontSize: '1vw' }}
								>
									{item.label}
								</p>
							</div>

							<div className='d-flex align-items-center w-50'>
								<input
									className='text-color-lblue bg-blue-100 fs-5 py-3 no-outline border-0 rounded w-50 fw-bold'
									style={{ textAlign: 'center' }}
								/>
								<div className='d-flex align-items-center justify-content-center py-3 px-4 text-color-lblue border-0 bg-blue-100 rounded fw-bold fs-5 ms-3 w-50'>
									m²
								</div>
							</div>
						</div>
					),
			)}

			<div
				className='d-flex justify-content-center w-100'
				onClick={() => setShowMore(!showMore)}>
				{showMore ? (
					<UilAngleUp size={50} color='#506CF9' />
				) : (
					<UilAngleDown size={50} color='#506CF9' />
				)}
			</div>
		</div>
	);
}
