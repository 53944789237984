import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Notepad } from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import {
	UilCog,
	UilEnvelopeAlt,
	UilUser,
	UilWhatsapp,
	UilMoneyBill,
	UilEstate,
	UilFile,
	UilExchange,
	UilPlusCircle,
} from '@iconscout/react-unicons';
import SearchFilter from '../../components/Owner/SearchFilter';
import { Form, Formik } from 'formik';
import Check from '../../components/Check';
import Switcher from 'react-switcher-rc';

const Cobranzas = ({ userData, setUserData }) => {
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);
	const [switcherState, setSwitcherState] = useState(false);
	const onHandleChange = (e) => setSwitcherState(e.target.checked);

	// onFileChange = (event) => {
	// 		// onChange={(e) => setFile(URL.createObjectURL(e.target.files[0]),checkFile())}
	// 		//setPreviewImage(URL.createObjectURL(e.target.files[0]);
	// 	// Update the state
	// 	//setFile(event.target.files[0]);

	//   }

	//   onFileUpload = () => {

	// 	// Create an object of formData
	// 	const formData = new FormData();

	// 	// Update the formData object
	// 	formData.append(
	// 	  "profile_image",
	// 	  file,
	// 	  file.name
	// 	);

	// 	// Request made to the backend api
	// 	// Send formData object
	// 	axios.post("api/uploadfile", formData);
	//   };

	function checkFile() {
		// URL.createObjectURL(file)
		// // setPreview(objectUrl)
		// console.log(window.URL.createObjectURL(dataURLToBlob(file)))
	}

	function dataURLToBlob(dataurl) {
		let arr = dataurl.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							{/* <Card className=''>
								<CardHeader className='border-bottom'>
									<CardTitle tag='h4' className='h5 text-color-lblue ms-3'>
										<div className='d-flex align-items-center'>
											<UilFile className='' />
											<p className='my-0 mx-2'>DATOS DE FACTURACION</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody className='p-0'>
									<div className='row p-3'>
										<div className='col-4 p-3'>
											{' '}
											<InputFloating
												className='input-filters-menu rounded shadow-inputs col p-3'
												label='Razon Social'
												name='Razon_social'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Razon Social Requerido'}
											/>
										</div>
										<div className='col-4 p-3'>
											<InputFloating
												className='input-filters-menu rounded shadow-inputs col p-3'
												label='Ingresos brutos'
												name='ingresos_brutos'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Ingresos brutos Requerido'}
											/>
										</div>
										<div className='col-4 p-3'>
											<InputFloating
												className='input-filters-menu rounded shadow-inputs col p-3'
												label='Gastos Liq.Banc'
												name='liq_banc'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Gastos Requerido'}
											/>
										</div>
									</div>
									<div className='row p-3'>
										<div className='col-4 p-3'>
											{' '}
											<InputFloating
												className='input-filters-menu rounded shadow-inputs col p-3'
												label='Posición ante el IVA'
												name='Pos_IVA'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Moneda Requerido'}
											/>
										</div>
										<div className='col-4 p-3'>
											<InputFloating
												className='input-filters-menu rounded shadow-inputs col p-3'
												label='Inicio de actividades'
												name='moneda'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Moneda Requerido'}
											/>
										</div>
										<div className='col-4 p-3 d-flex align-items-center'>
											<label className='me-3'>CRT</label>
											<InputFloating
												className='input-filters-menu rounded shadow-inputs p-3'
												label=''
												name=''
												type='file'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Moneda Requerido'}
											/>
										</div>
									</div>
									<div className='row p-3'>
										<div className='col-4 p-3'>
											{' '}
											<InputFloating
												className='input-filters-menu rounded shadow-inputs col p-3'
												label='C.U.I.T.'
												name='CUIT'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Moneda Requerido'}
											/>
										</div>
										<div className='col-4 p-3'>
											<InputFloating
												className='input-filters-menu rounded shadow-inputs col p-3'
												label='E-mail'
												name='moneda'
												type='text'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Moneda Requerido'}
											/>
										</div>
										<div className='col-4 p-3 d-flex align-items-center'>
											<label className='me-3'>KEY</label>
											<InputFloating
												className='input-filters-menu rounded shadow-inputs p-3'
												label=''
												name=''
												type='file'
												setFunction={setUserData}
												previousValue={userData}
												touched={touched}
												required
												errorMessage={'Moneda Requerido'}
											/>
										</div>
									</div>
								</CardBody>
							</Card> */}
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilMoneyBill className='' />
													<p className='my-0 mx-2'>COTIZACIONES</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='bg-new-gray'>
											<div className='row p-3'>
												<div className='col-4 p-3'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='Índice UVA'
														name='Índice UVA'
														type='text'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Razon Social Requerido'}
													/>
												</div>
												<div className='col-4 p-3'>
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='Índice UVA'
														name='ingresos_brutos'
														type='text'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Ingresos brutos Requerido'}
													/>
												</div>
												<div className='col-4 p-3'>
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='Índice CAC'
														name='liq_banc'
														type='text'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Gastos Requerido'}
													/>
												</div>
											</div>
											<div className='row p-3  d-flex p-3 align-items-center'>
												<div className='col-1 d-flex p-3 align-items-center'>
													<label className='me-3'>Índice ICC</label>
												</div>

												<div className='col-1 d-flex p-3 align-items-center'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col-1 p-3'
														label='Mes'
														name='mes'
														type='text'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Razon Social Requerido'}
													/>
												</div>
												<div className='col-1 d-flex p-3 align-items-center'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col-1 p-3'
														label='Año'
														name='año'
														type='text'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Razon Social Requerido'}
													/>
												</div>
												<div className='col-1 d-flex p-3 align-items-center'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col-1 p-3'
														label='valor'
														name='valor'
														type='text'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Razon Social Requerido'}
													/>
												</div>
												<div className='col-1 d-flex p-3 align-items-center'>
													{' '}
													<button className='btn btn-primary'>
														Actualizar
													</button>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilEstate className='' />
													<p className='my-0 mx-2'>
														INDICE PARA CONTRATOS DE LOCACIÓN (ICL)
													</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='bg-new-gray'>
											<div className='d-flex row ms-3'>
												<div className='d-flex align-items-center col-6'>
													<label className='col-7'>
														Actualización automática de valor de
														contratos de locación
													</label>
													<div className='ms-4 col-3'>
														<Switcher
															className='ms-2'
															name='my-switcher'
															onChange={onHandleChange}
															checkedIcon=' '
															unCheckedIcon={null}
															checked={switcherState}
															onColor='#506CF9'
														/>
													</div>
												</div>
											</div>
											<div className='d-flex row'>
												<div className='d-flex align-items-center col-6 mt-5'>
													<div className='ms-4 col-5'>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col-1 p-3'
															label='valor'
															name='valor'
															type='number'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={'Valor'}
														/>
													</div>
													<div className='ms-4 col-5'>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col-1 p-3'
															label=''
															name='fecha'
															type='date'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={'Fecha'}
														/>
													</div>
												</div>
											</div>
											<div className='d-flex row'>
												<p className='ms-4 mt-4'>
													<a href='www.bancocentral.com'>
														Ver valores del Banco central
													</a>
												</p>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilExchange className='' />
													<p className='my-0 mx-2'>VARIABLES DE COBRO</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='p-0  table-font-12'>
											<div className='d-flex w-100 bg-new-gray py-2 justify-content-center align-items-center'>
												<div className='col-4 d-flex align-items-center '>
													<div className='col-8 ms-5'>
														Cobra honorarios sobre gastos
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8 '>
														Cobra honorarios sobre gastos
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8'>
														Cobra honorarios sobre gastos
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
											</div>
											<div className='d-flex w-100 py-2 justify-content-center align-items-center'>
												<div className='col-4 d-flex align-items-center '>
													<div className='col-8 ms-5'>
														Cobra IVA sobre punitorios
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8 '>
														Cobra IVA sobre punitorios
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8'>
														Cobra IVA sobre punitorios
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
											</div>
											<div className='d-flex w-100 bg-new-gray py-2 justify-content-center align-items-center'>
												<div className='col-4 d-flex align-items-center '>
													<div className='col-8 ms-5'>
														Punitorios quedan en la administración
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8 '>
														Punitorios quedan en la administración
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8'>
														Punitorios quedan en la administración
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
											</div>
											<div className='d-flex w-100 py-2 justify-content-center align-items-center'>
												<div className='col-4 d-flex align-items-center '>
													<div className='col-8 ms-5'>
														Cobra honorarios sobre punitorios
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8 '>
														Cobra honorarios sobre punitorios
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8'>
														Cobra honorarios sobre punitorios
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
											</div>
											<div className='d-flex w-100 bg-new-gray py-2 justify-content-center align-items-center'>
												<div className='col-4 d-flex align-items-center '>
													<div className='col-8 ms-5'>
														Cobra honorarios sobre lo pagado
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8 '>
														Cobra honorarios sobre lo pagado
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8'>
														Cobra honorarios sobre lo pagado
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
											</div>
											<div className='d-flex w-100  py-2 justify-content-center align-items-center'>
												<div className='col-4 d-flex align-items-center '>
													<div className='col-8 ms-5'>
														Cobra IVA sobre punitorios
													</div>
													<Switcher
														className='ms-3'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
												<div className='col-4 d-flex align-items-center'>
													<div className='col-8 '>
														Numeración interna de recibos
													</div>
													<div className=' ms-3 col-2'>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col-1 p-3'
															label=''
															name=''
															type='number'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={'Razon Social Requerido'}
														/>
													</div>
												</div>
												<div className='col-4 d-flex flex-column align-items-start'>
													<div className='d-flex'>
														Envía aviso de pago a propietario
													</div>
													<div className='d-flex justify-content-between mt-2'>
														<div className='d-flex align-items-center'>
															<span className='fs-6 me-2'>
																Desactivado
															</span>
															<Check className='px-2' />
														</div>
														<div className='d-flex align-items-center'>
															<span className='fs-6 ms-5 me-2'>
																Manual
															</span>
															<Check className='px-2' />
														</div>
														<div className='d-flex align-items-center'>
															<span className='fs-6 ms-5 me-2'>
																Automatico
															</span>
															<Check className='px-2' />
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilMoneyBill className='' />
													<p className='my-0 mx-2'>
														DATOS DE FACTURACIÓN
													</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='bg-new-gray  table-font-12'>
											<div className='d-flex row ms-3'>
												<div className='d-flex align-items-center col-6'>
													<label className='col-7'>
														Permite editar mov. de caja de días previos
													</label>
													<div className='ms-4 col-3'>
														<Switcher
															className='ms-2'
															name='my-switcher'
															onChange={onHandleChange}
															checkedIcon=' '
															unCheckedIcon={null}
															checked={switcherState}
															onColor='#506CF9'
														/>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilFile className='' />
													<p className='my-0 mx-2'>
														DATOS DE FACTURACIÓN
													</p>
												</div>
											</CardTitle>
											<div className='d-flex me-5 '>
												<div className='float-end'>
													<button className='btn btn-primary'>
														<UilPlusCircle size={18} className='me-1' />
														Nuevo
													</button>
												</div>
											</div>
										</CardHeader>
										<CardBody className='bg-new-gray p-0'>
											<div className='d-flex'>
												<table className='table table-borderless p-0 '>
													<thead
														className=' bg-lblue p-0'
														style={{ height: '52px' }}
													>
														<th className='text-color-lblue text-center'>
															Razón social
														</th>
														<th className='text-color-lblue d-flex '>
															Posición ante el IVA
														</th>
														<th className='text-color-lblue'>
															C.U.I.T.
														</th>
														<th className='text-color-lblue'>
															Punto de venta
														</th>
														<th className=' col-2 text-color-lblue text-center'>
															CRT
														</th>
														<th className=' col-2 text-color-lblue text-center'>
															KEY
														</th>
													</thead>
													<tbody>
														<tr className='bg-new-gray'>
															<td className='col-2 text-center'>
																Sin Razon Social
															</td>
															<td className='col-1'>
																<SearchFilter
																	className='col-1'
																	style={{
																		backgroundColor:
																			'red !important',
																	}}
																	text={'Tipo Iva'}
																	name={'TipoIva'}
																	nameEng={'TipoIva'}
																	changeHandlerMultipleSelect={() =>
																		console.log('')
																	}
																	data={userData}
																	bgColor={''}
																	withSearch={false}
																	values={[
																		{
																			id: 1,
																			label: 'Monotributista',
																		},
																		{
																			id: 2,
																			label: 'Resp Inscripto',
																		},
																	]}
																/>
															</td>
															<td className='col-1'>209876435</td>
															<td className='col-1'>Arenales 1345</td>
															<td className=' text-center justify-content-center'>
																0
															</td>
															<td className=' text-center justify-content-center'>
																0
															</td>
														</tr>
														<tr className='bg-new-gray'>
															<td className='col-2 text-center'>
																Sin Razon Social
															</td>
															<td className='col-1'>
																<SearchFilter
																	className='col-1'
																	style={{
																		backgroundColor:
																			'red !important',
																	}}
																	text={'Tipo Iva'}
																	name={'TipoIva'}
																	nameEng={'TipoIva'}
																	changeHandlerMultipleSelect={() =>
																		console.log('')
																	}
																	data={userData}
																	bgColor={''}
																	withSearch={false}
																	values={[
																		{
																			id: 1,
																			label: 'Monotributista',
																		},
																		{
																			id: 2,
																			label: 'Resp Inscripto',
																		},
																	]}
																/>
															</td>
															<td className='col-1'>209876435</td>
															<td className='col-1'>Arenales 1345</td>
															<td className=' text-center justify-content-center'>
																0
															</td>
															<td className=' text-center justify-content-center'>
																0
															</td>
														</tr>
														<tr className='bg-new-gray'>
															<td className='col-2 text-center'>
																Sin Razon Social
															</td>
															<td className='col-3'>
																<SearchFilter
																	className='col-2'
																	style={{
																		backgroundColor:
																			'red !important',
																	}}
																	text={'Tipo Iva'}
																	name={'TipoIva'}
																	nameEng={'TipoIva'}
																	changeHandlerMultipleSelect={() =>
																		console.log('')
																	}
																	data={userData}
																	bgColor={''}
																	withSearch={false}
																	values={[
																		{
																			id: 1,
																			label: 'Monotributista',
																		},
																		{
																			id: 2,
																			label: 'Resp Inscripto',
																		},
																	]}
																/>
															</td>
															<td className='col-1'>209876435</td>
															<td className='col-1'>Arenales 1345</td>
															<td className=' text-center justify-content-center'>
																0
															</td>
															<td className=' text-center justify-content-center'>
																0
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							{/* Footer */}
							{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Cobranzas;
