import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import USERS from '../../common/data/userDummyData';
import Avatar from '../../components/Avatar';
import CardInmuebles from './CardInmuebles';
import { UilSearch, UilBell } from '@iconscout/react-unicons';
import Input from '../../components/bootstrap/forms/Input';
import CardBirthDay from './CardBirthDay';
import CardContract from './CardContract';
import ThemeContext from '../../contexts/themeContext';
import CardInmuebleMobile from './CardInmuebleMobile';
import CardBirthDayMobile from './CardBirthdayMobile';
import CardContractMobile from './CardContractMobile';
import { useQuery, useQueries } from '@tanstack/react-query';
import { getNotificationsType, getNotifications } from '../../services/notificationsServices';
import Check from '../../components/Check';
import CardImported from './CardImported';

const Notifications = () => {
	const [notificationsQuery] = useQueries({
		queries: [
			{
				queryKey: ['notifications'],
				queryFn: () => getNotifications(),
			},
		],
	});

	function formatDate(inputDate) {
		const date = new Date(inputDate);
		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		const formattedDate = date.toLocaleDateString('es-ES', options);
		const uppercaseDate = formattedDate.toUpperCase();
		return uppercaseDate;
	}
	const inmuebleTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 16, 15, 20]; // Array de números válidos
	const cumpleTypes = [18, 19]; // Array de números válidos
	const contractTypes = [14, 17, 24];

	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const imagenURL =
		'https://images.pexels.com/photos/2360673/pexels-photo-2360673.jpeg?cs=srgb&dl=pexels-tobias-bj%C3%B8rkli-2360673.jpg&fm=jpg';
	console.log(notificationsQuery);

	return (
		<>
			<PageWrapper title='Perfiles'>
				{!mobileDesign ? (
					<Page container='fluid'>
						<SubheaderNew
							breadCrumbs={[
								{ name: 'Notificaciones', url: '/Mantenimiento/Perfiles' },
							]}
							newButtonUrl='/Mantenimiento/Perfiles/crear'
						/>

						<div className='emulate-card rounded-1'>
							<div className='row border-bottom '>
								<div className='col d-flex align-items-center'>
									<UilBell
										size={35}
										color='#506CF9'
										className='mt-n4 text-color-lbue me-2'
									/>
									<h4 className='fs-5 text-color-lblue fw-700 mb-4'>
										NOTIFICACIONES
									</h4>
								</div>
								<div className='col-2 form-group float-end mb-4 d-flex'>
									<div className='d-flex align-items-center rounded-pill py-1 px-2 shadow-search-stadistic'>
										<label
											className='border-0  cursor-pointer me-0 ms-2 '
											htmlFor='searchInput'
										>
											{/* <Icon icon='Search' size='2x' color='blue-300' /> */}
											<UilSearch
												// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
												color='#506CF9'
												size={12}
											/>
										</label>
										<Input
											id='searchInput'
											type='search'
											className='border-0 shadow-none input-search-customers text-color-dark'
											placeholder='Buscar'
										/>
									</div>
								</div>
							</div>

							{notificationsQuery.data?.map((item) => (
								<>
									<div className='row d-flex align-items-center mt-5'>
										<div className='col-4 d-flex align-items-center'>
											<h4 className='fs-5 mt-4 fw-700 mb-4'>
												{formatDate(item.date)}
											</h4>
										</div>
										<div className='col-8 d-flex border-bottom'></div>
									</div>
									<div>
										<CardImported type={55} />{' '}
										<CardImported type={55} imported={false} />{' '}
										{/* Agregar un div contenedor para los elementos de item.data */}
										{item.data.map((element, index) => (
											<div className='row'>
												<div key={index}>
													{contractTypes.includes(element.type_id) && (
														<CardContract type={element.type_id} />
													)}
													{cumpleTypes.includes(element.type_id) && (
														<CardBirthDay
															type={element.type_id}
															notify={element}
														/>
													)}
													{inmuebleTypes.includes(element.type_id) && (
														<CardInmuebles
															type={element.type_id}
															notify={element.property}
														/>
													)}
												</div>
											</div>
										))}
									</div>
								</>
							))}
							{/* <div className='row mt-3'>
								<CardContract type={4} />
							</div>

							<div className='row mt-3'>
								<CardInmuebles type={2} />
							</div>
							<div className='row d-flex align-items-center mt-5'>
								<div className='col-2 d-flex align-items-center'>
									<h4 className='fs-5 mt-4 fw-700 mb-4'>VIERNES 25 DE MAYO</h4>
								</div>
								<div className='col-10 d-flex border-bottom'></div>
							</div>

							<div className='row mt-3'>
								<CardBirthDay type={4} />
							</div>
							<div className='row mt-3'>
								<CardInmuebles type={1} />
							</div>

							<div className='row mt-3'>
								<CardContract type={4} />
							</div> */}
						</div>
					</Page>
				) : (
					<>
						<div className='row mt-5 text-center'>
							<div className='col d-flex justify-content-center align-items-center'>
								<UilBell
									size={35}
									color='#506CF9'
									className='mt-n4 text-color-lbue me-2'
								/>
								<h4 className='fs-4 text-color-lblue fw-700 mb-4'>
									NOTIFICACIONES
								</h4>
							</div>
						</div>
						<div className='black-gradient w-100 p-2'></div>
						<div className='row d-flex align-items-center mt-5'>
							<div className='col-12 d-flex align-items-center'>
								<h4 className='fs-5 mt-4 fw-700 mb-4'>LUNES 5 DE JUNIO</h4>
							</div>
						</div>
						<div className='mt-2'>
							<CardContractMobile type={4} />
						</div>

						<div className='mt-2 '>
							<CardInmuebleMobile type={4} />
						</div>
						<div className='mt-2'>
							<CardBirthDayMobile type={2} />
						</div>
						<div className='mt-2'>
							<CardInmuebleMobile type={1} />
						</div>
					</>
				)}
			</PageWrapper>
		</>
	);
};

export default Notifications;
