import { UilLocationPoint, UilBuilding } from '@iconscout/react-unicons';
import ReactApexChart from 'react-apexcharts';
import { Tooltip } from 'react-tooltip';

export default function PostCardMobile({ estate, filters, badgeBg }) {
	const getChartOptions = (value) => {
		return {
			chart: {
				type: 'radialBar',
				// width: 25,
				// height: 25,
				sparkline: {
					enabled: true,
				},
			},
			dataLabels: {
				enabled: false,
			},
			title: {
				text: `${value}%`,
				align: 'center',
				justify: 'center',
				margin: 0,
				offsetX: 0,
				offsetY: 30,
				floating: true,
				style: {
					fontSize: '12px',
					fontWeight: 'bold',
					fontFamily: 'Montserrat',
					color: '#000',
				},
			},
			plotOptions: {
				// radar: {
				// 	size: 500,
				// },
				radialBar: {
					hollow: {
						margin: 0,
						size: '60%',
					},
					track: {
						margin: 0,
						// size: '100%'
					},
					dataLabels: {
						show: false,
					},
				},
			},

			stroke: {
				lineCap: 'round',
			},
			colors: ['#506cf9'],
		};
	};

	return (
		<div className={`d-flex my-4 position-relative`}>
			<div className='d-flex flex-column align-items-center w-100 p-4'>
				<div
					className='rounded w-100'
					style={{
						backgroundImage: 'url(https://placehold.co/200x146?text=Sin+imagen)',
						backgroundSize: 'cover',
						height: '146px',
						position: 'relative',
					}}>
					<div
						className='bg-blue-300 py-1 px-2 text-color-white'
						style={{
							position: 'absolute',
							left: 10,
							top: 10,
							borderRadius: '5px',
						}}>
						<p className='m-0'>{estate.id}</p>
					</div>
				</div>

				<div className='d-flex justify-content-between align-items-center w-100 mt-3 mb-2'>
					<div className='d-flex align-items-center'>
						<UilLocationPoint color='#506CF9' />
						<p className={`fs-6 px-1 fw-bold m-0`}>{estate.location}</p>
					</div>

					{filters.isPublished && (
						<div className='d-flex flex-column align-items-start justify-content-center'>
							<p
								className='m-0 text-color-white py-2 px-3 rounded fs-6'
								style={{
									backgroundColor: badgeBg,
								}}>
								{estate.highlighted}
							</p>
						</div>
					)}
				</div>
				<div className='d-flex align-items-center justify-content-between my-1 w-100'>
					<div className='d-flex align-items-center'>
						<UilBuilding color='#506CF9' />
						<p className={`fs-11 fw-normal ps-1 me-2} m-0`}>{estate.tipology}</p>
					</div>

					{/* <div className='d-flex flex-column align-items-start justify-content-center'>
						<p
							className='m-0 text-color-white py-2 px-3 rounded fs-6'
							style={{
								backgroundColor: badgeBg,
							}}>
							{estate.highlighted}
						</p>
					</div> */}

					{filters.isPublished ? (
						<div className='d-flex align-items-center'>
							<p className='mb-0 fw-bold px-1'>{estate.quality}%</p>
							<ReactApexChart
								series={[estate.quality]}
								options={getChartOptions(estate.quality)}
								type='radialBar'
								height={25}
								width={25}
							/>
						</div>
					) : (
						<div className='d-flex flex-column align-items-start justify-content-center'>
							<p
								className='m-0 text-color-white bg-danger py-2 px-3 rounded fs-6'
								data-tooltip-id='my-tooltip'
								data-tooltip-content={estate.errorDescription}>
								{estate.errorReason}
							</p>
							<Tooltip id='my-tooltip' />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
