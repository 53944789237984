import { useCallback } from 'react'
import { useEffect } from 'react/cjs/react.development';
import styles from './Toast.module.css'
import { XCircle, CheckCircle } from 'phosphor-react';

const Toast = ({ toastlist, position, setList }) => {

  const deleteToast = useCallback(id => {
    const toastListItem = toastlist.filter(e => e.id !== id);
    setList(toastListItem);
  }, [toastlist, setList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if(toastlist.length) {
        deleteToast(toastlist[0].id);
      }
    }, 4000);

    return () => {
      clearInterval(interval);
    }
  }, [toastlist, deleteToast]);

  return (
    <div className={`${styles.container} ${styles[position]}`}>
      {
        toastlist.map((toast, i) => (
          <div
            key={i}
            className={`${styles.notification} ${styles.toast} ${styles[position]} ${toast.backgroundClass}`}
          >
            <button onClick={() => deleteToast(toast.id)}>X</button>
            <div className='d-flex '>
              {toast.backgroundClass == 'toastDanger' && <XCircle color='#EF2206' size={24} />}
              {toast.backgroundClass == 'toastSuccess' && <CheckCircle color='#06DB41' size={24} />}
              <p className={` ${styles.title} ms-3`}>{toast.title}</p>
              
            </div>
            <p className={` ${styles.description} flex-wrap col-12 ms-5`}>{toast.description}</p>
            
          </div>
        ))
      }
    </div>
  )
}

export default Toast
