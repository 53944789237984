import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Notepad } from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import meli from '../../assets/logos/MercadoLibre_Logotipo2.png';
import zona from '../../assets/logos/zona-logo.png';
import argen from '../../assets/logos/argenprop-logo1.png';
import wsp from '../../assets/logos/wsp.png';
import doppler from '../../assets/logos/doppler.png';
import mudafy from '../../assets/logos/mudafy1.png';
import buscadorProp from '../../assets/logos/buscador-prop 1.png';

import { UilCog, UilEstate, UilGlobe } from '@iconscout/react-unicons';
import { Form, Formik } from 'formik';
import Check from '../../components/Check';

const Integraciones = ({ userData, setUserData }) => {
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);

	// onFileChange = (event) => {
	// 		// onChange={(e) => setFile(URL.createObjectURL(e.target.files[0]),checkFile())}
	// 		//setPreviewImage(URL.createObjectURL(e.target.files[0]);
	// 	// Update the state
	// 	//setFile(event.target.files[0]);

	//   }

	//   onFileUpload = () => {

	// 	// Create an object of formData
	// 	const formData = new FormData();

	// 	// Update the formData object
	// 	formData.append(
	// 	  "profile_image",
	// 	  file,
	// 	  file.name
	// 	);

	// 	// Request made to the backend api
	// 	// Send formData object
	// 	axios.post("api/uploadfile", formData);
	//   };

	function checkFile() {
		// URL.createObjectURL(file)
		// // setPreview(objectUrl)
		// console.log(window.URL.createObjectURL(dataURLToBlob(file)))
	}

	function dataURLToBlob(dataurl) {
		let arr = dataurl.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							<Card className='p-2'>
								<CardHeader>
									<CardTitle tag='h4' className='h5 text-color-lblue'>
										<div className='d-flex align-items-center'>
											<UilCog />
											<p className='my-0 mx-2'>INTEGRACIONES</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Card className='rounded-1 py-4'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right card-title h2 text-color-lblue align-items-center'>
													<UilGlobe size={48} />
													Página web
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>Integrá una página web</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Administrar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card className='rounded-1 py-4'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right'>
													<img src={meli} />
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>
														Integrá MercadoLibre para publicar , editar
														y eliminar tus inmuebles y obtener las
														consultas realizadas en el portal.
													</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Conectar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card className='rounded-1 py-4'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right'>
													<img src={meli} />
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>
														Integrá MercadoLibre para publicar , editar
														y eliminar tus inmuebles y obtener las
														consultas realizadas en el portal.
													</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Conectar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card className='rounded-1 py-4'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right'>
													<img src={zona} />
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>
														Integrá Zonaprop para publicar , editar y
														eliminar tus inmuebles y obtener las
														consultas realizadas en el portal.
													</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Conectar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card className='rounded-1 py-4'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right'>
													<img src={argen} className='me-2' />
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>
														Integrá ArgenProp para publicar , editar y
														eliminar tus inmuebles y obtener las
														consultas realizadas en el portal.
													</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Conectar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card className='rounded-1 py-1'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right'>
													<img src={doppler} />
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>
														Integrá Doppler para publicar , editar y
														eliminar tus inmuebles y obtener las
														consultas realizadas en el portal
													</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Conectar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card className='rounded-1 py-4'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right'>
													<img src={wsp} />
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>
														Integrá WhatsApp para publicar , editar y
														eliminar tus inmuebles y obtener las
														consultas realizadas en el portal.
													</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Conectar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card className='rounded-1 py-4'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right'>
													<img src={mudafy} className='me-3' />
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>
														Integrá Mudafy para publicar , editar y
														eliminar tus inmuebles y obtener las
														consultas realizadas en el portal.
													</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Conectar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card className='rounded-1 py-4'>
										<CardBody>
											<div className='d-flex'>
												<div className='d-flex col-2 border-right'>
													<img src={buscadorProp} />
												</div>
												<div className='d-flex col-4 px-5 align-items-center'>
													<p>
														Integrá Buscador Prop para publicar , editar
														y eliminar tus inmuebles y obtener las
														consultas realizadas en el portal.
													</p>
												</div>
												<div className='d-flex col-5 justify-content-end'>
													<div className='d-flex align-items-center'>
														<a className='btn btn-sm blue-gradient-buttons border-0 text-white mx-1'>
															Conectar
														</a>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</CardBody>
							</Card>

							{/* Footer */}
							{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Integraciones;
