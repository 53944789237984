import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Notepad } from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { UilCog, UilEnvelopeAlt, UilUser } from '@iconscout/react-unicons';
import { Form, Formik } from 'formik';
import Check from '../../components/Check';

const Inmuebles = ({ userData, setUserData }) => {
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);

	// onFileChange = (event) => {
	// 		// onChange={(e) => setFile(URL.createObjectURL(e.target.files[0]),checkFile())}
	// 		//setPreviewImage(URL.createObjectURL(e.target.files[0]);
	// 	// Update the state
	// 	//setFile(event.target.files[0]);

	//   }

	//   onFileUpload = () => {

	// 	// Create an object of formData
	// 	const formData = new FormData();

	// 	// Update the formData object
	// 	formData.append(
	// 	  "profile_image",
	// 	  file,
	// 	  file.name
	// 	);

	// 	// Request made to the backend api
	// 	// Send formData object
	// 	axios.post("api/uploadfile", formData);
	//   };

	function checkFile() {
		// URL.createObjectURL(file)
		// // setPreview(objectUrl)
		// console.log(window.URL.createObjectURL(dataURLToBlob(file)))
	}

	function dataURLToBlob(dataurl) {
		let arr = dataurl.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							<Card className=''>
								<CardHeader className='border-bottom'>
									<CardTitle tag='h4' className='h5 text-color-lblue ms-3'>
										<div className='d-flex align-items-center'>
											<UilCog />
											<p className='my-0 mx-2'>GENERALES</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody className='p-0  table-font-12'>
									<div className='d-flex w-100 bg-new-gray py-2'>
										<div className='col-6 d-flex'>
											<div className='col-8 ms-5'>
												Muestra nombre de edificio/inmueble en listados
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex'>
											<div className='col-8'>
												Muestra colores por fecha de actualización de precio
											</div>
											<Check />
										</div>
									</div>
									<div className='d-flex w-100 py-2'>
										<div className='col-6 d-flex'>
											<div className='col-8 ms-5'>
												Restringe destaques en Zonaprop
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex'>
											<div className='col-8'>
												Actualiza valor de inmueble según tipo de
												financiación
											</div>
											<Check />
										</div>
									</div>
									<div className='d-flex w-100 bg-new-gray py-2 rounded-bottom'>
										<div className='col-6 d-flex'>
											<div className='col-8 ms-5'>Comparto propiedades</div>
											<Check />
										</div>
										<div className='col-6 d-flex'>
											<div className='col-8'>
												Actualiza el valor de la propiedad a pesos con el
												valor del dolar
											</div>
											<Check />
										</div>
									</div>
								</CardBody>
							</Card>
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilUser className='me-2' />
													<p className='my-0 mx-2'>
														COMPARTIR CON COLEGAS
													</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='bg-new-gray'>
											<div className='d-flex row'>
												<div className='d-flex flex-column  col-4'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='Nombre'
														name='nombre'
														type='text'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Nombre Requerido'}
													/>
												</div>
												<div className='d-flex flex-column  col-4'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='E-mail'
														name='email'
														type='email'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'Email Requerido'}
													/>
												</div>
												<div className='d-flex flex-column  col-4'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='Telefono'
														name='telefono'
														type='number'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'telefono Requerido'}
													/>
												</div>
												<div className='d-flex flex-column  col-4 mt-4'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='% Alq'
														name='IVA'
														type='alq'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'% Alq Requerido'}
													/>
												</div>
												<div className='d-flex flex-column  col-4 mt-4'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='% Venta'
														name='IVA'
														type='venta'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'% Venta Requerido'}
													/>
												</div>
												<div className='d-flex flex-column  col-4 mt-4'>
													{' '}
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col p-3'
														label='% Temp'
														name='temp'
														type='number'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														required
														errorMessage={'% Temp Requerido'}
													/>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilEnvelopeAlt className='me-2' />
													<p className='my-0 mx-2'>PLANTILLAS</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='bg-new-gray px-5'>
											<div className='d-flex row px-2'>
												<h5 className='fw-600 mb-5'>
													Envío de correos electrónicos individual
												</h5>
												<div className='row mb-4'>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
												</div>
												<h5 className='fw-600 mt-4 mb-5'>
													Envío de correos electrónicos múltiples
												</h5>
												<div className='row'>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
													<div className='d-flex   col'>
														<Check />
														<div
															className='ms-3 rounded-1'
															style={{
																height: '102px',
																width: '100px',
																backgroundColor: '#D9D9D9',
															}}
														></div>
													</div>
												</div>
												<div className='d-flex flex-column  col-4'></div>
												<div className='d-flex flex-column  col-4'></div>
												<div className='d-flex flex-column  col-4 mt-4'></div>
												<div className='d-flex flex-column  col-4 mt-4'></div>
												<div className='d-flex flex-column  col-4 mt-4'></div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							{/* Footer */}
							{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Inmuebles;
