import {
	UilCheck,
	UilEllipsisH,
	UilEdit,
	UilTrash,
	UilCopy,
	UilCheckCircle,
	UilCommentsAlt,
} from '@iconscout/react-unicons';

export default function Table() {
	const thList = [
		{
			name: 'Fecha',
			nameEng: 'date',
		},
		{
			name: 'Hora',
			nameEng: 'hour',
		},
		{
			name: 'Tarea',
			nameEng: 'task',
		},
		{
			name: 'Dirección',
			nameEng: 'address',
		},
		{
			name: 'Con',
			nameEng: 'con',
		},
		{
			name: 'Forma de ingreso',
			nameEng: 'registerForm',
		},
		{
			name: 'Obs.',
			nameEng: 'obs',
		},
		{
			name: 'Operador',
			nameEng: 'employee',
		},
	];

	const mockedData = [
		{
			id: 123,
			date: '29/08/2023',
			hour: '10:00',
			task: 'Google Meet',
			taskColor: '#FFBF00',
			address: 'Av. Libertador 567',
			con: 'Andrea Nuñez prop',
			registerForm: 'Contacto web',
			operatorInitials: 'JM',
		},
		{
			id: 124,
			date: '29/08/2023',
			hour: '10:00',
			task: 'Llamado',
			taskColor: '#494294',
			address: 'Av. Libertador 567',
			con: 'Andrea Nuñez prop',
			registerForm: 'Contacto web',
			operatorInitials: 'JM',
		},
		{
			id: 125,
			date: '29/08/2023',
			hour: '10:00',
			task: 'Visita',
			taskColor: '#D94588',
			address: 'Av. Libertador 567',
			con: 'Andrea Nuñez prop',
			registerForm: 'Contacto web',
			operatorInitials: 'JM',
		},
		{
			id: 126,
			date: '29/08/2023',
			hour: '10:00',
			task: 'Consulta',
			taskColor: '#7F92CA',
			address: 'Av. Libertador 567',
			con: 'Andrea Nuñez prop',
			registerForm: 'Contacto web',
			operatorInitials: 'JM',
		},
		{
			id: 127,
			date: '29/08/2023',
			hour: '10:00',
			task: 'Capacitación',
			taskColor: '#EF7919',
			address: 'Av. Libertador 567',
			con: 'Andrea Nuñez prop',
			registerForm: 'Contacto web',
			operatorInitials: 'JM',
		},
		{
			id: 128,
			date: '29/08/2023',
			hour: '10:00',
			task: 'E-mail',
			taskColor: '#BE8ABD',
			address: 'Av. Libertador 567',
			con: 'Andrea Nuñez prop',
			registerForm: 'Contacto web',
			operatorInitials: 'JM',
		},
		{
			id: 129,
			date: '29/08/2023',
			hour: '10:00',
			task: 'Llamado',
			taskColor: '#494294',
			address: 'Av. Libertador 567',
			con: 'Andrea Nuñez prop',
			registerForm: 'Contacto web',
			operatorInitials: 'JM',
		},
	];

	return (
		<table>
			<thead className='bg-lblue'>
				<tr className='w-100'>
					<th className='col py-2 text-color-lblue fw-bold'>
						<div className='d-flex align-items-center justify-content-center w-100 h-100'>
							<label className='custom-checkbox'>
								<UilCheck size={20} color={'transparent'} />
							</label>
						</div>
					</th>
					{thList.map((th) => (
						<th className='col py-4 text-color-lblue fw-bold estateTh'>
							{
								<div
									className={`d-flex align-items-center justify-content-start w-100 h-100`}>
									{th.name}
								</div>
							}
						</th>
					))}
				</tr>
			</thead>

			{mockedData.map((item, index) => (
				<tr className={`fw-normal-bold fs-5 ${index % 2 !== 0 && 'bg-new-gray'}`}>
					<td className='py-5 px-4'>
						<div
							className='d-flex align-items-center justify-content-center h-100 position-relative'
							style={{ left: 1 }}>
							<label className='mx-4 custom-checkbox'>
								<UilCheck size={20} color='transparent' />
							</label>
							<div className='position-absolute' style={{ marginLeft: '6rem' }}>
								<UilEllipsisH
									color='#506CF9'
									size={25}
									className='cursor-pointer'
									id={`a${item.id}`}
									data-bs-toggle='dropdown'
									aria-expanded='false'
								/>

								<div
									className='bg-white-dropdown rounded dropdown-menu dropdown-menu-lg shadow text-color-black'
									aria-labelledby={`a${item.id}`}>
									<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-top'>
										<UilEdit size={26} /> <p className='m-0 ps-2'>Editar</p>
									</div>
									<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item'>
										<UilTrash size={26} /> <p className='m-0 ps-2'>Borrar</p>
									</div>
									<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item'>
										<UilCheckCircle size={26} />{' '}
										<p className='m-0 ps-2'>Realizada</p>
									</div>
									<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-bottom'>
										<UilCopy size={26} /> <p className='m-0 ps-2'>Duplicar</p>
									</div>
								</div>
							</div>
						</div>
					</td>

					<td>{item.date}</td>
					<td>{item.hour}</td>
					<td>
						<span
							className='px-3 py-1 rounded-5px text-color-white fw-normal'
							style={{ backgroundColor: item.taskColor }}>
							{item.task}
						</span>
					</td>
					<td>{item.address}</td>
					<td>{item.con}</td>
					<td>{item.registerForm}</td>
					<td className='pe-5'>
						<UilCommentsAlt size={30} color='#506CF9' />
					</td>
					<td>
						<div
							className='d-flex justify-content-center align-items-center bg-blue-icon text-color-white'
							style={{ width: '40px', height: '40px', borderRadius: '100%' }}>
							{item.operatorInitials}
						</div>
					</td>
				</tr>
			))}
		</table>
	);
}
