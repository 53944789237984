import React, { useContext } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import ThemeContext from '../../contexts/themeContext';
import MobileLayout from './MobileLayout';
import DesktopLayout from './DesktopLayout';

export default function PostsPage() {
	const { mobileDesign } = useContext(ThemeContext);

	return (
		<PageWrapper title='Publicaciones' className={`${mobileDesign && 'p-0'}`}>
			<Page container='fluid' className={`${mobileDesign && 'p-0'}`}>
				{mobileDesign ? <MobileLayout /> : <DesktopLayout />}
			</Page>
		</PageWrapper>
	);
}
