import { useState, useContext } from 'react';
import { UilFilter, UilSetting, UilExclamationCircle } from '@iconscout/react-unicons';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import FiltersDrawer from './FiltersDrawer';
import { Form, Formik } from 'formik';
import InputSelector from './InputSelector';
import ThemeContext from '../../../contexts/themeContext';
import UserContext from '../../../contexts/userContext';
import { getFilters } from '../../../services/filtersServices';
import { useQuery } from '@tanstack/react-query';

export default function FiltersMenu({ filtersApplied, setFiltersApplied, setFiltersMenu }) {
	const [filterType, setFilterType] = useState('simple');
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { token } = useContext(UserContext);
	const { mobileDesign } = useContext(ThemeContext);

	const { isLoading, data } = useQuery({
		queryKey: ['estateFilters'],
		queryFn: () => getFilters('properties', token),
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		staleTime: Infinity,
	});

	if (isLoading)
		return (
			<div className='d-flex align-items-center justify-content-center min-vh-50'>
				<h3>Cargando filtros...</h3>
			</div>
		);

	console.log(data);

	return (
		<div className='d-flex flex-column bg-white m-4 rounded min-vh-50 shadow-dark'>
			<FiltersDrawer
				offcanvasStatus={offcanvasStatus}
				setOffcanvasStatus={setOffcanvasStatus}
				menuItems={data}
			/>
			<Formik initialValues={filtersApplied}>
				{({ touched }) => (
					<Form>
						{/* Simple filter */}
						<div
							className='d-flex flex-column w-100 align-items-start p-4 border-bottom cursor-pointer'
							onClick={() => {
								// if (filterType === 'simple') return setFilterType(null);

								setFilterType('simple');
							}}>
							{/* {!mobileDesign && ( */}
							<div className='d-flex align-items-center justify-content-between w-100'>
								<div className='d-flex align-items-center w-100'>
									<UilFilter color='#506cf9' size={27} />
									<h4 className='mb-0 mx-2 text-color-lblue fw-bold'>
										FILTROS {!mobileDesign && 'SIMPLES'}
									</h4>
								</div>
								{filterType === 'simple' && !mobileDesign && (
									<div className='d-flex align-items-center justify-content-end w-100'>
										<Button
											color='primary'
											className='me-4'
											rounded={1}
											onClick={() => setOffcanvasStatus(!offcanvasStatus)}>
											<div className='d-flex align-items-center'>
												<Icon icon='CustomSettingsAdjust' />
												<p className='my-0 ms-2'>Personalizar</p>
											</div>
										</Button>
									</div>
								)}
							</div>
							{/* )} */}
							{filterType === 'simple' && (
								<>
									<div className='d-flex flex-wrap justify-content-start w-100 my-4'>
										{data
											?.filter(
												(item) =>
													item.filterCategory === 'simple' &&
													item.isActive === true,
											)
											.map((item) => (
												<div
													className={`d-flex align-items-end ${
														mobileDesign ? 'col-12' : 'col-4'
													} p-4`}>
													<InputSelector
														setFiltersApplied={setFiltersApplied}
														data={filtersApplied}
														touched={touched}
														item={item}
													/>
												</div>
											))}
									</div>

									<div className='d-flex align-items-center justify-content-end w-100'>
										<Button
											color='primary'
											className='me-4'
											rounded={1}
											onClick={() => setFiltersMenu(false)}>
											<div className='d-flex align-items-center'>
												<Icon icon='CustomFilter' />
												<p className='my-0 ms-2'>Filtrar</p>
											</div>
										</Button>
									</div>
								</>
							)}
						</div>
						{/* Simple filter */}
						{/* Advanced filters */}
						{!mobileDesign && (
							<div
								className='d-flex flex-column w-100 align-items-start p-4 border-bottom border-top cursor-pointer'
								onClick={() => {
									// if (filterType === 'advanced') return setFilterType(null);

									setFilterType('advanced');
								}}>
								<div className='d-flex align-items-center justify-content-between w-100'>
									<div className='d-flex align-items-center w-100'>
										<UilSetting color='#506cf9' size={27} />
										<h4 className='mb-0 mx-2 text-color-lblue fw-bold'>
											FILTROS AVANZADOS
										</h4>
									</div>

									{filterType === 'advanced' && (
										<div className='d-flex align-items-center justify-content-end w-100'>
											<Button
												color='primary'
												className='me-4'
												rounded={1}
												onClick={() =>
													setOffcanvasStatus(!offcanvasStatus)
												}>
												<div className='d-flex align-items-center'>
													<Icon icon='CustomSettingsAdjust' />
													<p className='my-0 ms-2'>Personalizar</p>
												</div>
											</Button>
										</div>
									)}
								</div>

								{filterType === 'advanced' && (
									<>
										<div className='d-flex flex-wrap justify-content-start w-100 my-4'>
											{data
												?.filter(
													(item) =>
														item.filterCategory === 'advanced' &&
														item.isActive === true,
												)
												.map((item) => (
													<div
														className={`d-flex align-items-center ${
															mobileDesign ? 'col-12' : 'col-4'
														} p-4`}>
														<InputSelector
															setFiltersApplied={setFiltersApplied}
															data={filtersApplied}
															touched={touched}
															item={item}
														/>
													</div>
												))}
										</div>

										<div className='d-flex align-items-center justify-content-end w-100'>
											<Button
												color='primary'
												className='me-4'
												rounded={1}
												onClick={() => setFiltersMenu(false)}>
												<div className='d-flex align-items-center'>
													<Icon icon='CustomFilter' />
													<p className='my-0 ms-2'>Filtrar</p>
												</div>
											</Button>
										</div>
									</>
								)}
							</div>
						)}
						{/* Advanced filters */}
						{/* Conditional filters */}
						{!mobileDesign && (
							<div
								className='d-flex flex-column w-100 align-items-start p-4 border-bottom border-top cursor-pointer'
								onClick={() => {
									// if (filterType === 'conditional') return setFilterType(null);

									setFilterType('conditional');
								}}>
								<div className='d-flex align-items-center justify-content-between w-100'>
									<div className='d-flex align-items-center w-100'>
										<UilExclamationCircle color='#506cf9' size={27} />
										<h4 className='mb-0 mx-2 text-color-lblue fw-bold'>
											FILTROS CONDICIONALES
										</h4>
									</div>

									{filterType === 'conditional' && (
										<div className='d-flex align-items-center justify-content-end w-100'>
											<Button
												color='primary'
												className='me-4'
												rounded={1}
												onClick={() =>
													setOffcanvasStatus(!offcanvasStatus)
												}>
												<div className='d-flex align-items-center'>
													<Icon icon='CustomSettingsAdjust' />
													<p className='my-0 ms-2'>Personalizar</p>
												</div>
											</Button>
										</div>
									)}
								</div>
								{filterType === 'conditional' && (
									<>
										<div className='d-flex flex-wrap justify-content-start w-100 my-4'>
											{data
												?.filter(
													(item) =>
														item.filterCategory === 'conditional' &&
														item.isActive === true,
												)
												.map((item) => (
													<div
														className={`d-flex align-items-center ${
															mobileDesign ? 'col-12' : 'col-4'
														} p-4`}>
														<InputSelector
															setFiltersApplied={setFiltersApplied}
															data={filtersApplied}
															touched={touched}
															item={item}
														/>
													</div>
												))}
										</div>

										<div className='d-flex align-items-center justify-content-end w-100'>
											<Button
												color='primary'
												className='me-4'
												rounded={1}
												onClick={() => setFiltersMenu(false)}>
												<div className='d-flex align-items-center'>
													<Icon icon='CustomFilter' />
													<p className='my-0 ms-2'>Filtrar</p>
												</div>
											</Button>
										</div>
									</>
								)}
							</div>
						)}

						{/* Conditional filters */}
					</Form>
				)}
			</Formik>
		</div>
	);
}
