import Swal from 'sweetalert2';

export const confirmationSwal = ( title , message , icon, confirmButtonText) => {
  return Swal.fire({
    title: title,
    text: message,
    icon: icon,
    customClass: {
      confirmButton: 'fs-12-bold',
      cancelButton: 'fs-12-bold',
    },
    showCancelButton: true,
    confirmButtonColor: '#EF2206',
    cancelButtonColor: '#506CF9',
    confirmButtonText: confirmButtonText,
    cancelButtonText: 'Cancelar',
    cancel: {
      className: 'fs-12-bold',
      closeModal: true,
    },
    confirm: {
      text: 'OK',
      value: true,
      visible: true,
      className: 'fw-bold',
      closeModal: true,
    },
  });
};