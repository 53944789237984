import React, { useState } from 'react';
import { Buildings, CaretDown, CaretUp, HouseLine } from 'phosphor-react';
import {
	UilPaintTool,
	UilLocationPoint,
	UilBookmark,
	UilBed,
	UilMoneyBill,
	UilCheck,
} from '@iconscout/react-unicons';
import Button from '../../../components/bootstrap/Button';
// import SearchFilter from '../../components/Owner/SearchFilter';
// import Switcher from 'react-switcher-rc';
import { useContext } from 'react';
import ThemeContext from '../../../contexts/themeContext';
import { Form, Formik } from 'formik';
// import InputFloating from '../../components/InputFloating';
// import CustomSelect from '../../components/CustomSelect';
// import { getFilters } from '../../services/filtersServices';
// import UserContext from '../../contexts/userContext';
import InputSelector from '../InputSelector';
import StateView from './StateView';

const SearchProfile = ({ searchProfile, setSearchProfile, searchProfileFields, inputDisabled , filteredProperties}) => {
	const { mobileDesign } = useContext(ThemeContext);
	const [showMore, setShowMore] = useState(false);
	const [selectedEstates, setSelectedEstates] = useState([]);

	// const suggested = [
	// 	{
	// 		id: 853,
	// 		img: 'https://www.bbva.com/wp-content/uploads/2021/04/casas-ecolo%CC%81gicas_apertura-hogar-sostenibilidad-certificado-.jpg',
	// 		selected: false,
	// 		location: 'Adolfo Alsina y Fidel López',
	// 		operation: 'Venta',
	// 		rooms: 5,
	// 		price: 120000,
	// 		currency: 'USD',
	// 	},
	// 	{
	// 		id: 250,
	// 		img: 'https://i.pinimg.com/550x/75/52/af/7552aff15679b363cf717dab353884a3.jpg',
	// 		selected: false,
	// 		location: 'Saavedra 3350, Marcos Paz',
	// 		operation: 'Venta',
	// 		rooms: 4,
	// 		price: 60000,
	// 		currency: 'USD',
	// 	},
	// 	{
	// 		id: 782,
	// 		img: 'https://www.arquitecturaydiseno.es/medio/2020/02/06/exterior-casa-con-piscina-en-rusia_0aa0c1a3_2000x1125.jpg',
	// 		selected: false,
	// 		location: 'Franklin Roosevelt 1935, Belgrano',
	// 		operation: 'Venta',
	// 		rooms: 3,
	// 		price: 50000,
	// 		currency: 'USD',
	// 	},
	// ];

	const changeHandler = (value, name) => {
		setSearchProfile((searchProfile) => ({ ...searchProfile, [name]: value }));
	};

	const changeHandlerMultipleSelect = (value, name) => {
		if (searchProfile[name]?.includes(value)) {
			setSearchProfile((searchProfile) => ({
				...searchProfile,
				[name]: searchProfile[name].filter((a) => a !== value),
			}));
		} else {
			setSearchProfile((searchProfile) => ({
				...searchProfile,
				[name]: [...(searchProfile[name] || []), value],
			}));
		}
	};

	return (
		<Formik initialValues={searchProfile}>
			<Form>
				<div className='d-flex flex-column w-100 p-4'>
					{/* Header desktop */}
					{!mobileDesign && (
						<div className='d-flex align-items-center justify-content-between col-12 bg-lblue'>
							<div
								className={`d-flex align-items-center ${
									mobileDesign ? 'col-12' : 'col-4'
								}`}>
								<div
									className={`d-flex align-items-center px-4 py-3 ${
										!mobileDesign && 'me-1'
									} rounded-top-lg bg-white text-color-lblue fs-6 fw-bold w-100`}>
									<Buildings size={24} color='#506cf9' />
									<p className='my-0 mx-2'>Inmuebles</p>
								</div>

								<input
									className='rounded-1 shadow-inputs input-readonly p-2 mx-3 col-6'
									type='search'
									value='Adolfo Alsina y Fidel López, Marcos...'
								/>
								<div
									rounded={1}
									className='blue-gradient-buttons mx-2 py-2 px-3 rounded-1 border-0'>
									<HouseLine size={20} color='#ffffff' />
								</div>
							</div>

							<Button
								disabled={inputDisabled}
								className=''
								color='primary'
								rounded={1}
								style={{ position: 'relative', bottom: '10px' }}
								onClick={() => setSearchProfile({})}>
								<div className='d-flex align-items-center'>
									<UilPaintTool size={20} color='#fff' />
									<p className='my-0 ms-2 fw-normal'>
										Blanquear perfil de búsqueda
									</p>
								</div>
							</Button>
						</div>
					)}
					{/* Header desktop */}

					<div
						className={`d-flex flex-column ${
							mobileDesign ? 'rounded-xl shadow' : 'rounded-right-and-bottom'
						} bg-white p-4 col-12`}>
						{/* Header mobile */}
						{mobileDesign && (
							<div className='d-flex justify-content-center align-items-end'>
								<Buildings size={41} color='#0030B8' />
								<p className='mb-1 mx-2 fs-5 fw-bold'>Inmuebles</p>
							</div>
						)}
						{/* Header mobile */}

						<div
							className={`d-flex ${
								mobileDesign
									? 'flex-column align-items-center py-4 px-2'
									: 'flex-wrap justify-content-between'
							} `}>
							{searchProfileFields ? (
								// ?.filter((item) => item.isActive === true)
								searchProfileFields.map((item, index) => {
									if (!mobileDesign || index < 2 || showMore) {
										if(item.name === "Actividad" || item.name === "Activo" || item.name === "Forma de Ingreso" || item.name === "Operador") {
											return null;
										} 
										return (
											<div
												// className={`d-flex flex-column my-4 ${
												// 	mobileDesign ? 'col-12' : 'col-4'
												// }`}
												key={index}
												className='m-2'
												style={{ width: mobileDesign ? '100%' : '32%' }}>
												<InputSelector
													data={searchProfile}
													item={item}
													touched={Formik.touched}
													inputDisabled={inputDisabled}
													changeHandler={changeHandler}
													changeHandlerMultipleSelect={
														changeHandlerMultipleSelect
													}
												/>
											</div>
										);
									}
								})
							) : (
								<h3>Cargando campos...</h3>
							)}
						</div>
						{mobileDesign && (
							<div
								className='d-flex align-items-center justify-content-end w-100'
								onClick={() => setShowMore(!showMore)}>
								{showMore ? (
									<CaretUp size={32} color='#01227d' />
								) : (
									<CaretDown size={32} color='#01227d' />
								)}
							</div>
						)}
					</div>

					{/* Header */}
					{!mobileDesign && (
						<div className='d-flex align-items-center justify-content-between pt-5 col-12'>
							<div className='d-flex align-items-center col-4'>
								<div className='d-flex align-items-center px-4 py-3 me-1 rounded-top-lg bg-white text-color-lblue fs-6 fw-bold'>
									<HouseLine size={24} color='#506cf9' />
									<p className='my-0 mx-2'>Inmuebles sugeridos</p>
								</div>
							</div>
						</div>
					)}
					{/* Header */}
					<div
						className={`d-flex flex-column  bg-white p-4 col-12 ${
							mobileDesign ? 'shadow rounded-xl mt-5' : 'rounded-right-and-bottom'
						}`}>
						{mobileDesign ? (
							<div className='d-flex w-100 justify-content-center'>
								<div
									className={`d-flex justify-content-between align-items-center w-100`}>
									<div className='d-flex align-items-center fs-5 fw-bold'>
										<HouseLine size={41} color='#0030B8' />
										<p className='my-0 mx-2'>Inmuebles sugeridos</p>
									</div>
									<div className='me-2'>
										<label
											className='custom-checkbox'
											// onClick={() => {
											// 	if (selectedEstates.length === properties.length) {
											// 		setSelectedEstates([]);
											// 	} else {
											// 		setSelectedEstates(properties);
											// 	}
											// }}>
											onClick={changeHandlerMultipleSelect
											}>
											<UilCheck
												color={
													selectedEstates.length === filteredProperties.length
														? '#506CF9'
														: '#fff'
												}
												size={20}
											/>
										</label>
									</div>
								</div>
							</div>
						) : (
							<div className='ms-4 pe-5'>
								<label
									className='custom-checkbox'
									onClick={changeHandlerMultipleSelect}>
									<UilCheck
										color={
											selectedEstates.length === filteredProperties.length
												? '#506CF9'
												: '#fff'
										}
										size={20}
									/>
								</label>
							</div>
						)}
						<hr />
						<StateView mobileDesign={mobileDesign} filteredProperties={filteredProperties } setSelectedEstates={setSelectedEstates} selectedEstates={selectedEstates} ></StateView>
					</div>
				</div>
			</Form>
		</Formik>
	);
};

export default SearchProfile;
