import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import banner from '../../assets/img/bg-header-reporte 1.png';
import areaReport from '../../assets/img/areareport.png';
import areaReportComet from '../../assets/img/areaReportComet.png';
import HolaBienvenido from '../../assets/img/HolaBienvenido.png';
import Metricas from '../../assets/img/metricas.png';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Avatar from '../../components/Avatar';
import USERS from '../../common/data/userDummyData';
import Popovers from '../../components/bootstrap/Popovers';
import SearchFilter from '../../components/Owner/SearchFilter';
import PopOverFooter from './PopOverFooter';
import PopOverTable from './PopOverTable';
import InputFloating from '../../components/InputFloating';
import { Form, Formik } from 'formik';
import Tab3 from './tab3';
import * as Unicons from '@iconscout/react-unicons';
import {
	UilArrowUp,
	UilArrowDown,
	UilSetting,
	UilBrowser,
	UilUsersAlt,
	UilBag,
	UilSearch,
	UilSearchAlt,
	UilCalender,
	UilHome,
	UilRepeat,
	UilLayerGroup,
	UilSlidersVAlt,
} from '@iconscout/react-unicons';
import Input from '../../components/bootstrap/forms/Input';
import Chart from 'react-apexcharts';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Tabselector from './Tabselector';

const Estadisticas = ({ userData, setUserData }) => {
	const [selectedTab, setSelectedTab] = useState('CONSULTAS POR CANAL');
	const [searchMenu, setSearchMenu] = useState(false);
	const [filterMenu, setFilterMenu] = useState(false);
	const [activeMenu, setActiveMenu] = useState(false);
	const [activeData, setActiveData] = useState([]);
	const [dataSelect, setDataSelect] = useState({
		valor1: '',
		password: '',
		name: '',
		surname: '',
		birthday: '',
	});
	const tabs = [
		{ name: 'CONSULTAS POR CANAL' },
		{ name: 'CONSULTAS POR OPERACION' },
		{ name: 'CONVERSIÓN POR CANAL' },
	];

	const sizes = {
		top: 0,
		left: 50,
		width: 900,
	};
	const options = {
		options: {
			stroke: {
				lineCap: 'round',
			},
			fontFamily: 'Montserrat", sans-serif',
			fill: {
				colors: ['#7c18f8', '#8676ff', '#01f1e3', '#ffba69', '#ff708b'],
			},
			labels: ['Whatsapp envío fichas', 'Visita', 'Su Pedido', 'Mail', 'Reserva de inmueble'],

			legend: {
				show: true,
				showForSingleSeries: true,
				showForNullSeries: true,
				showForZeroSeries: true,
				position: 'left',
				horizontalAlign: 'left',
				floating: true,
				fontSize: '18px',
				fontFamily: 'Montserrat", sans-serif',
				fontWeight: 500,
				color: '#383874',
				formatter: 'vertical',
				inverseOrder: false,
				width: 500,
				height: 200,
				tooltipHoverFormatter: undefined,
				customLegendItems: [],
				offsetX: 140,
				offsetY: 320,
				labels: {
					colors: '#383874',
					useSeriesColors: false,
				},
				markers: {
					width: 12,
					height: 12,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: ['#7c18f8', '#8676ff', '#01f1e3', '#ffba69', '#ff708b'],
					radius: 12,
					customHTML: undefined,
					onClick: undefined,
					offsetX: -10,
					offsetY: 0,
					fontFamily: 'Montserrat", sans-serif',
				},
				itemMargin: {
					horizontal: 5,
					vertical: 5,
				},
				onItemClick: {
					toggleDataSeries: true,
				},
				onItemHover: {
					highlightDataSeries: true,
				},
				formatter: function (label, opts) {
					return label + ' - ' + opts.w.globals.series[opts.seriesIndex];
				},
			},
			plotOptions: {
				radialBar: {
					hollow: {
						size: '45%',
					},
					startAngle: -180,
					dataLabels: {
						showOn: 'always',
						name: {
							show: true,
							color: '#383874',
							fontSize: '12px',
							fontFamily: 'Montserrat", sans-serif',
						},
						value: {
							color: '#000000',
							fontSize: '26px',
							fontWeight: 700,
							show: true,
							offsetX: 10,
							offsetY: 25,
							fontFamily: 'Montserrat", sans-serif',
						},
					},
				},
			},
		},
		series: [38, 30, 14, 42, 17],
	};

	const tableData = [
		{
			pic: 1,
			operador: {
				name: 'Pepe',
				cel: '11 1254 5678',
				email: 'mail@gmail.com',
			},
			querys: 3,
			reservas: 3,
			operaciones: 4,
			tareasRealizadas: 2,
			accion: 2,
		},
		{
			pic: 1,
			operador: {
				name: 'Pepe',
				cel: '11 1254 5678',
				email: 'mail@gmail.com',
			},
			querys: 3,
			reservas: 3,
			operaciones: 4,
			tareasRealizadas: 2,
			accion: 2,
		},
		{
			pic: 1,
			operador: {
				name: 'Pepe',
				cel: '11 1254 5678',
				email: 'mail@gmail.com',
			},
			querys: 3,
			reservas: 3,
			operaciones: 4,
			tareasRealizadas: 2,
			accion: 2,
		},
		{
			pic: 1,
			operador: {
				name: 'Pepe',
				cel: '11 1254 5678',
				email: 'mail@gmail.com',
			},
			querys: 3,
			reservas: 3,
			operaciones: 4,
			tareasRealizadas: 2,
			accion: 2,
		},
		{
			pic: 1,
			operador: {
				name: 'Pepe',
				cel: '11 1254 5678',
				email: 'mail@gmail.com',
			},
			querys: 3,
			reservas: 3,
			operaciones: 4,
			tareasRealizadas: 2,
			accion: 2,
		},
	];

	return (
		<PageWrapper title='Estadisticas'>
			<Page container='fluid'>
				<div className='row w-100 d-flex'>
					<div className='col-12 position-relative'>
						<img className='w-100' src={banner} />
						<div className='position-absolute top-15 start-10 d-flex flex-column '>
							{/* <p className='text-color-white fs-1 fw-700'>¡Hola! Bienvenid@</p> */}
							<img src={HolaBienvenido} />
							<img src={areaReport} />
							<img
								className='ms-3'
								style={{ height: 8, width: 85 }}
								src={areaReportComet}
							/>
							<img className='ms-2 mt-5' src={Metricas} />
						</div>
					</div>
				</div>
				<div className='row d-flex justify-content-center align-items-center px-5 '>
					<div className='col-4 justify-content-center '>
						<div
							onClick={() => {
								setSearchMenu(!searchMenu);
								setFilterMenu(false);
								setActiveMenu(!activeMenu);
							}}
							className='emulate-card cardBodyOnDashboard d-flex rounded-1'
							style={{ height: '100px' }}
						>
							<div className='col'>
								<UilCalender size={40} color={'#506cf9'} className='ms-4' />
							</div>
							<div className='col-6 ms-3'>
								<h3 className='fw-700'>Ver Por fecha</h3>
								<p className='ms-1'>Desde - Hasta</p>
							</div>
							<div className='col'></div>

							<div>
								<Unicons.UilAngleRightB
									className='cursor-pointer float-end'
									size={40}
									color={'#506cf9'}
								/>
							</div>
						</div>
					</div>
					<div className='col-4  justify-content-center'>
						<div
							className='emulate-card cardBodyOnDashboard d-flex rounded-1'
							onClick={() => {
								setFilterMenu(!filterMenu);
								setSearchMenu(false);
								setActiveMenu(!activeMenu);
							}}
							style={{ height: '100px' }}
						>
							<div className='col'>
								<UilSlidersVAlt
									size={40}
									color={'#506cf9'}
									style={{ transform: 'rotate(90deg)' }}
									className='ms-4'
								/>
							</div>
							<div className='col-6 ms-3'>
								<h3 className='fw-700'>Filtros</h3>
								<p className='ms-1'>
									Sucursal, operador, tipo de tareas,operaciones
								</p>
							</div>
							<div className='col'></div>

							<div>
								<Unicons.UilAngleRightB
									className='cursor-pointer float-end'
									size={40}
									color={'#506cf9'}
								/>
							</div>
						</div>
					</div>
					<div className=' col-4 justify-content-center '>
						<div
							className='emulate-card cardBodyOnDashboard d-flex rounded-1'
							style={{ height: '100px' }}
						>
							<div className='col ms-4'>
								<UilSetting size={40} color={'#506cf9'} className='' />
							</div>
							<div className='col-6 ms-3'>
								<h3 className='fw-700'>Acciones</h3>
								<p className=''>Generar reporte online</p>
							</div>
							<div className='col'></div>

							<div>
								<Unicons.UilAngleRightB
									className='cursor-pointer float-end'
									size={40}
									color={'#506cf9'}
								/>
							</div>
						</div>
					</div>
				</div>
				{activeMenu && (
					<div className='row mt-5 ms-5 mb-5'>
						{searchMenu === true && (
							<div className='d-flex mt-5 mb-5'>
								<Formik className='h-100'>
									{({ touched }) => (
										<Form className='w-100 h-100 d-flex'>
											<div className='col-2'>
												<label for='desde'>Desde</label>
												<InputFloating
													className='input-filters-menu bg-blue-100 rounded shadow-inputs col py-2 input-narrow'
													label=''
													name='desde'
													type='date'
													setFunction={setActiveData}
													previousValue={activeData}
													touched={touched}
													bgColor={'bg-white'}
													errorMessage={''}
													onChange={(e) => {
														console.log(e);
													}}
												/>
											</div>
											<div className='col-2 ms-2 justify-content-center'>
												<label for='hasta'>Hasta</label>
												<InputFloating
													className='input-filters-menu bg-blue-100 rounded shadow-inputs col py-2 input-narrow'
													label=''
													name='hasta'
													type='date'
													setFunction={setActiveData}
													previousValue={activeData}
													touched={touched}
													bgColor={'bg-white'}
													errorMessage={''}
													onChange={(e) => {
														console.log(e);
													}}
												/>
											</div>
											<div className='col-2 ms-2 d-flex justify-content-center  align-items-center mt-4'>
												<button className='btn btn-md bg-primary text-white py-3'>
													{' '}
													Buscar
												</button>
												<div
													className='ms-3 fw-600 fs-5 cursor-pointer'
													onClick={() => {
														setFilterMenu(!filterMenu);
														setActiveMenu(!activeMenu);
													}}
												>
													X Cerrar Búsqueda
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</div>
						)}

						{filterMenu === true && (
							<div className='row mt-5 mb-5'>
								<Formik className='h-100'>
									{({ touched }) => (
										<Form className='row d-flex'>
											<div className='row'>
												<div className='col-4'>
													<SearchFilter
														style={{ backgroundColor: '#ffff' }}
														text={'Sucursal'}
														name={'Operación'}
														nameEng={'Operation'}
														changeHandlerMultipleSelect={() =>
															console.log('')
														}
														data={dataSelect}
														withSearch={false}
														values={[
															{
																id: 1,
																label: 'Alquiler',
															},
															{
																id: 2,
																label: 'Alquiler temporario ',
															},
															{
																id: 3,
																label: 'Venta',
															},
														]}
													/>
												</div>
												<div className='col-4'>
													<SearchFilter
														style={{ backgroundColor: '#ffff' }}
														text={'Operador'}
														name={'Operación'}
														nameEng={'Operation'}
														changeHandlerMultipleSelect={() =>
															console.log('')
														}
														data={dataSelect}
														withSearch={false}
														values={[
															{
																id: 1,
																label: 'Alquiler',
															},
															{
																id: 2,
																label: 'Alquiler temporario ',
															},
															{
																id: 3,
																label: 'Venta',
															},
														]}
													/>
												</div>
												<div className='col-4'>
													<SearchFilter
														style={{ backgroundColor: '#ffff' }}
														text={'Tipo de Tarea'}
														name={'Operación'}
														nameEng={'Operation'}
														changeHandlerMultipleSelect={() =>
															console.log('')
														}
														data={dataSelect}
														withSearch={false}
														values={[
															{
																id: 1,
																label: 'Alquiler',
															},
															{
																id: 2,
																label: 'Alquiler temporario ',
															},
															{
																id: 3,
																label: 'Venta',
															},
														]}
													/>
												</div>
												<div className='col-4 mt-2  align-items-center '>
													<SearchFilter
														style={{ backgroundColor: '#ffff' }}
														text={'Operaciones'}
														name={'Operación'}
														nameEng={'Operation'}
														changeHandlerMultipleSelect={() =>
															console.log('')
														}
														data={dataSelect}
														withSearch={false}
														values={[
															{
																id: 1,
																label: 'Alquiler',
															},
															{
																id: 2,
																label: 'Alquiler temporario ',
															},
															{
																id: 3,
																label: 'Venta',
															},
														]}
													/>
												</div>
												<div className='col-4 d-flex mt-2'>
													<button className='btn btn-md bg-primary px-4 text-white py-3'>
														{' '}
														BUSCAR
													</button>
													<div
														className='ms-3 fw-600 fs-5 d-flex cursor-pointer align-items-center'
														onClick={() => {
															setActiveMenu(!activeMenu);
															setFilterMenu(!filterMenu);
														}}
													>
														X Cerrar Búsqueda
													</div>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</div>
						)}
					</div>
				)}

				<div
					className='row w-100 d-flex justify-content-center
				'
				>
					<div className='col-12 mt-5 mb-3 ms-3'>
						<h4 className='fw-700 ms-5'>Reportes Generales</h4>
					</div>
					<div className='row d-flex justify-content-center flex-row'>
						<div className='d-flex col-2  flex-column border-right align-items-center'>
							<p className='fs-5'>Propiedades</p>{' '}
							<p className=' fs-1 text-color-lblue fw-500'>215</p>
						</div>
						<div className='d-flex col-2  flex-column border-right align-items-center'>
							<p className='fs-5 '>Consultas</p>{' '}
							<p className='fs-1 text-color-lblue fw-500'>15</p>
						</div>
						<div className='d-flex col-2  flex-column border-right align-items-center'>
							<p className='fs-5 '>Reservas</p>{' '}
							<p className='fs-1 text-color-lblue fw-500'>5</p>
						</div>
						<div className='d-flex col-2  flex-column border-right align-items-center'>
							<p className='fs-5'>Ventas</p>{' '}
							<p className='fs-1 text-color-lblue fw-500'>5</p>
						</div>
						<div className='d-flex col-2  flex-column border-right align-items-center'>
							<p className='fs-5'>Alquileres</p>{' '}
							<p className='fs-1 text-color-lblue fw-500'>15</p>
						</div>
						<div className='d-flex col-2  flex-column align-items-center'>
							<p className='fs-5'>Retiradas</p>{' '}
							<p className='fs-1 text-color-lblue fw-500'>0</p>
						</div>
					</div>
				</div>

				<div className='row  d-flex justify-content-center mx-5 mt-5'>
					<div className='col-12 mt-5'>
						<div className='row flex-row justify-content-center '>
							<div
								className=' col-6 justify-content-center py-50p  border rounded-1 shadow-medium'
								id='chart'
							>
								<div className='row'>
									<p className='mt-5 ms-4 fs-4 fw-700'>Informe de Tareas</p>
								</div>

								<Chart
									className='mt-4 ms-3'
									options={options.options}
									series={options.series}
									type='radialBar'
									height={350}
									labels={options.options.plotOptions.labels}
								/>
							</div>
							<div className='d-flex col-6 flex-column '>
								<div className='py-50p px-3 border rounded-1 shadow-medium '>
									<div className='mt-5'>
										<span className='ms-4 fw-700'>Informe de Propiedades</span>
										<span className='float-end'>0-300</span>
									</div>
									<div className='ms-4 mt-3'>
										<label className='mt-3'>Propiedades en Cartera</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '75%',
													color: '#ffff',
													backgroundColor: '#506CF9',
												}}
											></div>
										</div>
										<label className='mt-3'>Propiedades Nuevas</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '40%',
													color: '#ffff',
													backgroundColor: '#FFA63F',
												}}
											></div>
										</div>
										<label className='mt-3'>Propiedades Reservadas</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '40%',
													color: '#ffff',
													backgroundColor: '#FF708B',
												}}
											></div>
										</div>
										<label className='mt-3'>Propiedades Vendidas</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '40%',
													color: '#ffff',
													backgroundColor: '#01F1E3',
												}}
											></div>
										</div>
										<label className='mt-3'>Propiedades Retiradas</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '40%',
													color: '#ffff',
													backgroundColor: '#7517F8',
												}}
											></div>
										</div>
									</div>
								</div>

								<div className='py-50p px-3 border rounded-1 shadow-medium mt-3'>
									<div className='mt-5'>
										<span className='ms-4 fw-700'>Informe de Tasaciones</span>
										<span className='float-end'>0-300</span>
									</div>
									<div className='ms-4 mt-3'>
										<label className='mt-3'>Propiedades en Cartera</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '75%',
													color: '#ffff',
													backgroundColor: '#506CF9',
												}}
											></div>
										</div>
										<label className='mt-3'>Propiedades Nuevas</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '40%',
													color: '#ffff',
													backgroundColor: '#FFA63F',
												}}
											></div>
										</div>
										<label className='mt-3'>Propiedades Reservadas</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '40%',
													color: '#ffff',
													backgroundColor: '#FF708B',
												}}
											></div>
										</div>
										<label className='mt-3'>Propiedades Vendidas</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '40%',
													color: '#ffff',
													backgroundColor: '#01F1E3',
												}}
											></div>
										</div>
										<label className='mt-3'>Propiedades Retiradas</label>
										<label className='mt-3 float-end me-2'>204</label>
										<div
											className='progress'
											role='progressbar'
											aria-label='Example 1px high'
											aria-valuenow='25'
											aria-valuemin='0'
											aria-valuemax='100'
											style={{ height: '8px' }}
										>
											<div
												className='progress-bar'
												style={{
													width: '40%',
													color: '#ffff',
													backgroundColor: '#7517F8',
												}}
											></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row d-flex justify-content-center mx-5 rounded-0'>
					<div className='col-12 mt-4'>
						<h4 className='fw-700'>Reportes por Canal</h4>
						<div className='d-flex fw-bold mt-4'>
							{tabs.map((tab) => (
								<div
									key={tab.name}
									className={` cursor-pointer ${
										selectedTab === tab.name
											? 'white shadow-medium border-0'
											: 'bg-lblue-065'
									}  px-4 py-3 me-1 rounded-top-lg  text-color-lblue`}
									onClick={() => setSelectedTab(tab.name)}
								>
									{tab.name == 'CONSULTAS POR CANAL' ? <UilLayerGroup /> : ''}
									{tab.name == 'CONSULTAS POR OPERACION' ? (
										<UilHome className='me-2' />
									) : (
										''
									)}
									{tab.name == 'CONVERSIÓN POR CANAL' ? (
										<UilRepeat className='me-2' />
									) : (
										''
									)}

									{tab.name}
								</div>
							))}
						</div>
						<div className='emulate-card  ms-0'>
							<Tabselector selectedTab={selectedTab} />
						</div>
					</div>
				</div>
				<div className='row d-flex justify-content-center mx-5 mt-4 rounded-0'>
					<h4 className='fw-700'>Reportes por Operador/a</h4>
					<div className='border py-5 rounded-1 shadow-sm mt-3 p-0'>
						<table className='w-100'>
							<thead className='border-bottom align-items-center'>
								<tr className='text-dark py-10'>
									<th scope='col' className='text-color-lblue text-center'>
										<UilUsersAlt />
									</th>
									<th scope='col' className='text-color-lblue'>
										Operador/a
									</th>
									<th scope='col' className='text-color-lblue text-center'>
										Consultas <br />
										Recibidas
									</th>
									<th scope='col' className='text-color-lblue text-center'>
										Reservas <br />
										Generadas
									</th>
									<th scope='col' className='text-color-lblue text-center'>
										Operaciones
										<br />
										Generadas
									</th>
									<th scope='col' className='text-color-lblue'>
										Tareas
										<br />
										Realizadas
									</th>
									<th scope='col'></th>
								</tr>
							</thead>
							<tbody>
								{tableData.map((item, index) => (
									<tr
										colSpan={1}
										className={`${index % 2 !== 0 && 'bg-table-yellow'}`}
										style={{ padding: '50px' }}
									>
										<td className='text-center'>
											<Avatar
												srcSet={USERS.CHLOE.srcSet}
												src={USERS.CHLOE.src}
												size={42}
												color={USERS.CHLOE.color}
											/>
										</td>
										<td className='' colSpan={1}>
											<div className='py-3'>
												<span
													className='text-color-violet fs-4 fw-600'
													style={{ fontSize: '18px' }}
												>
													{item.operador.name}
												</span>
												<br />
												<span
													className='fw-600  '
													style={{ fontSize: '12px' }}
												>
													Cel:{item.operador.cel}
												</span>
												<br />
												<span
													className='fw-600 '
													style={{ fontSize: '12px' }}
												>
													{item.operador.email}
												</span>
											</div>
										</td>
										<td colSpan={1} className='text-center'>
											<p className='fw-600 fs-4 text-color-violet'>
												{item.querys}
											</p>
										</td>
										<td className='text-center'>
											<p className='fw-600 fs-4 text-color-violet'>
												{item.reservas}
											</p>
										</td>
										<td className='text-center'>
											<p className='fw-600 fs-4 text-color-violet'>
												{item.operaciones}
											</p>
										</td>
										<td colSpan={4} className='text-center'>
											<div
												className='w-80 progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '6px' }}
											>
												<div
													className='progress-bar '
													style={{
														width: '55%',
														color: '#506cf9',
													}}
												></div>
											</div>
										</td>
										<td>
											<Popovers
												className='w-100'
												placement='bottom'
												desc={<PopOverTable />}
												trigger='hover'
											>
												<div className=''>
													<UilBrowser
														className=' '
														color='#383874'
														size='18'
													/>
												</div>
											</Popovers>
											{/* <UilBrowser color='#383874' size='20' /> */}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className='row d-flex justify-content-center mx-5 rounded-0 mt-4'>
					<h4 className='fw-700'>Reportes por Propiedad</h4>
					<div className='d-flex col-12 justify-content-center flex-row'>
						<div
							className='d-flex col-6 mt-3 mb-3 border py-5 rounded-1 shadow-medium justify-content-center align-items-center bg-lblue-light '
							style={{ backgroundColor: '#FAFBFF' }}
						>
							<div className='w-80'>
								<div className=''>
									<UilArrowUp className='me-1' color='#506CF9' />
									<span className='fs-5 fw-700'>
										TOP propiedades Más Consultadas
									</span>
								</div>
								<div className='ms-4 w-100 p-2 '>
									<label className='mt-3'>Calle 1234 - Depto, 2 Amb</label>
									<div
										className='progress mt-3'
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar '
											style={{ width: '55%', color: '#506cf9' }}
										></div>
									</div>
									<label className='mt-3'>Calle 1234 - Depto, 2 Amb</label>
									<div
										className='progress '
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar bg-primary'
											style={{ width: '85%', color: '#506cf9' }}
										></div>
									</div>
									<label className='mt-3'>Propiedades Reservadas</label>
									<div
										className='progress mt-3'
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar'
											style={{ width: '15%', color: '#506cf9' }}
										></div>
									</div>
									<label className='mt-3'>Propiedades Vendidas</label>
									<div
										className='progress mt-3'
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar bg-primary'
											style={{ width: '5%', color: '#506cf9' }}
										></div>
									</div>
									<label className='mt-3'>Propiedades Retiradas</label>
									<div
										className='progress mt-3'
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar '
											style={{ width: '8%', color: '#506cf9' }}
										></div>
									</div>
								</div>
							</div>
						</div>
						<div
							className='d-flex ms-3 col-6 mt-3 mb-3 border py-5 rounded-1 shadow-sm justify-content-center align-items-center bg-pink-light '
							style={{ backgroundColor: '#FFFAFB' }}
						>
							<div className='w-80'>
								<div className=''>
									<UilArrowDown className='me-1' color='#FF708B' />
									<span className='fs-5 fw-700'>
										TOP propiedades menos Consultadas
									</span>
								</div>
								<div className='ms-4 w-100 p-2 '>
									<label className='mt-3'>Calle 1234 - Depto, 2 Amb</label>
									<div
										className='progress mt-3'
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar '
											style={{
												width: '25%',
												color: '#ffff',
												backgroundColor: '#FF708B',
											}}
										></div>
									</div>
									<label className='mt-3'>Calle 1234 - Depto, 2 Amb</label>
									<div
										className='progress '
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar '
											style={{
												width: '20%',
												color: '#ffff',
												backgroundColor: '#FF708B',
											}}
										></div>
									</div>
									<label className='mt-3'>Propiedades Reservadas</label>
									<div
										className='progress mt-3'
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar'
											style={{
												width: '15%',
												color: '#ffff',
												backgroundColor: '#FF708B',
											}}
										></div>
									</div>
									<label className='mt-3'>Propiedades Vendidas</label>
									<div
										className='progress mt-3'
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar'
											style={{
												width: '10%',
												color: '#ffff',
												backgroundColor: '#FF708B',
											}}
										></div>
									</div>
									<label className='mt-3'>Propiedades Retiradas</label>
									<div
										className='progress mt-3'
										role='progressbar'
										aria-label='Example 1px high'
										aria-valuenow='25'
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ height: '8px' }}
									>
										<div
											className='progress-bar '
											style={{
												width: '8%',
												color: '#ffff',
												backgroundColor: '#FF708B',
											}}
										></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row d-flex justify-content-center mx-5 rounded-0 mt-5'>
					<div className='border py-5 rounded-1 shadow-medium justify-content-center align-items-center'>
						<div className='row mt-3 p-2 mb-5 '>
							<div className='d-flex  col col-md-8'>
								<UilSetting className='ms-4 ' color='#506CF9' />
								<h4 className='ms-3 fw-700'>Tareas por Propiedad</h4>
							</div>
							<div className='col-4 form-group float-end'>
								<div className='d-flex align-items-center rounded-pill py-1 px-2 shadow-search-stadistic'>
									<label
										className='border-0  cursor-pointer me-0 ms-2 '
										htmlFor='searchInput'
									>
										{/* <Icon icon='Search' size='2x' color='blue-300' /> */}
										<UilSearch
											// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
											color='#506CF9'
											size={12}
										/>
									</label>
									<Input
										id='searchInput'
										type='search'
										className='border-0 shadow-none input-search-customers text-color-dark'
										placeholder='Buscar propiedad'
									/>
								</div>
							</div>
						</div>
						<div className='border-top'>
							<div className='mt-5 ms-4 row'>
								<div className='col-6 col-md-5 ms-4'>
									<p>Calle 1234 - Depto, 2 Amb</p>
								</div>
								<div className='col-1 ms-5'>
									<p>Mail</p>Whatsapp<p></p>Visita<p></p>LLamado<p></p>
								</div>
								<div className='col-4 me-5 d-flex flex-column '>
									<div className='d-flex flex-row'>
										<span className='me-5'>25</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '40%',
														color: '#ffff',
														backgroundColor: '#FF708B',
													}}
												></div>
											</div>
										</div>
									</div>

									<div className='d-flex mt-3 '>
										<span className='me-5'>25</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '30%',
														color: '#ffff',
														backgroundColor: '#FFA63F',
													}}
												></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<span className='me-5'>23</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '20%',
														color: '#ffff',
														backgroundColor: '#01F1E3',
													}}
												></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<span className='me-5'>15</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '10%',
														color: '#ffff',
														backgroundColor: '#8676FF',
													}}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='row mt-4 ms-4'>
							<div className='mt-5 row'>
								<div className='col-6 col-md-5 ms-4'>
									<p>Calle 1234 - Depto, 2 Amb</p>
								</div>
								<div className='col-1 ms-5'>
									<p>Mail</p>Whatsapp<p></p>Visita<p></p>LLamado<p></p>
								</div>
								<div className='col-4 me-5 d-flex flex-column '>
									<div className='d-flex flex-row'>
										<span className='me-5'>25</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '40%',
														color: '#ffff',
														backgroundColor: '#FF708B',
													}}
												></div>
											</div>
										</div>
									</div>

									<div className='d-flex mt-3 '>
										<span className='me-5'>25</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '30%',
														color: '#ffff',
														backgroundColor: '#FFA63F',
													}}
												></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<span className='me-5'>23</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '20%',
														color: '#ffff',
														backgroundColor: '#01F1E3',
													}}
												></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<span className='me-5'>15</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '10%',
														color: '#ffff',
														backgroundColor: '#8676FF',
													}}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='row mt-4 ms-4'>
							<div className='mt-5 row'>
								<div className='col-6 col-md-5 ms-4'>
									<p>Calle 1234 - Depto, 2 Amb</p>
								</div>
								<div className='col-1 ms-5'>
									<p>Mail</p>Whatsapp<p></p>Visita<p></p>LLamado<p></p>
								</div>
								<div className='col-4 me-5 d-flex flex-column '>
									<div className='d-flex flex-row'>
										<span className='me-5'>25</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '40%',
														color: '#ffff',
														backgroundColor: '#FF708B',
													}}
												></div>
											</div>
										</div>
									</div>

									<div className='d-flex mt-3 '>
										<span className='me-5'>25</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '30%',
														color: '#ffff',
														backgroundColor: '#FFA63F',
													}}
												></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<span className='me-5'>23</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '20%',
														color: '#ffff',
														backgroundColor: '#01F1E3',
													}}
												></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<span className='me-5'>15</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '10%',
														color: '#ffff',
														backgroundColor: '#8676FF',
													}}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='row mt-4 ms-4'>
							<div className='mt-5 row'>
								<div className='col-6 col-md-5 ms-4'>
									<p>Calle 1234 - Depto, 2 Amb</p>
								</div>
								<div className='col-1 ms-5'>
									<p>Mail</p>Whatsapp<p></p>Visita<p></p>LLamado<p></p>
								</div>
								<div className='col-4 me-5 d-flex flex-column '>
									<div className='d-flex flex-row'>
										<span className='me-5'>25</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '40%',
														color: '#ffff',
														backgroundColor: '#FF708B',
													}}
												></div>
											</div>
										</div>
									</div>

									<div className='d-flex mt-3 '>
										<span className='me-5'>25</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '30%',
														color: '#ffff',
														backgroundColor: '#FFA63F',
													}}
												></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<span className='me-5'>23</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '20%',
														color: '#ffff',
														backgroundColor: '#01F1E3',
													}}
												></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<span className='me-5'>15</span>
										<div className='col-6 ms-5 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '10%',
														color: '#ffff',
														backgroundColor: '#8676FF',
													}}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row d-flex justify-content-center mx-5 rounded-0 mt-1'>
					<div className='d-flex col'>
						<div className='row mt-3 p-2'>
							<div className=' col'>
								<h4 className='fw-700'>Patrones de Búsqueda</h4>
							</div>
						</div>
					</div>
				</div>
				<div className='row d-flex justify-content-center mx-5  rounded-1 rounded-0 mt-3'>
					<div className='border py-5 rounded-1 shadow-medium justify-content-center align-items-center'>
						<div className='col'>
							<div className='row mt-2'>
								<div className='d-flex'>
									<div className='col-md-6  '>
										<span className='fs-5 fw-700 ms-5 me-3'>
											<UilSearchAlt className='me-2' color='#506CF9' />
											Categorias más buscadas
										</span>
										<span className='fs-3 text-color-violet fw-500'>
											/ En Venta
										</span>
									</div>
									<div className='col-6 mt-n2 mb-5'>
										<div className='float-end  '>
											<p className='fs-3 text-color-violet ms-5 fw-500'>
												Enero
											</p>
											<span className='me-5 fs-3 mt-5 mb-5 text-color-violet'>
												01/01 al 31/01
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='row mt-5 ms-2 me-2 d-flex'>
								<div className='col-3'>
									<div className='card rounded-1 bg-light-green'>
										<p className='mt-3 ms-4 text-color-violet fw-700'>
											Tipología
										</p>
										<p className='mt-3 ms-4 fs-3 text-color-violet fw-500'></p>
										<p className='mt-4 ms-4 fs-3 text-color-violet fw-500'>
											Casa
										</p>
									</div>
								</div>
								<div className='col-3'>
									<div className='card rounded-1 bg-light-orange'>
										<p className='mt-3 ms-4 text-color-violet fw-700 d-flex'>
											{/* <Popovers
												id='popover-positioned-right'
												title='Popover Right'
												width={500}
												position='right'
												className='w-100 rounded-1'
												trigger='hover'>
												
											</Popovers> */}
											Ambientes
											<span>
												<Popovers
													className='w-100'
													placement='top'
													desc={<PopOverFooter />}
													trigger='hover'
												>
													<div className='ms-3 position-absolute'>
														<UilBrowser
															className=' '
															color='#383874'
															size='18'
														/>
													</div>
												</Popovers>
											</span>
										</p>

										<p className='mt-3 ms-4 fs-3 text-color-violet fw-500'></p>
										<p className='mt-4 ms-4 fs-3 text-color-violet fw-500'>3</p>
									</div>
								</div>
								<div className='col-3'>
									<div className='card rounded-1 bg-light-blue'>
										<p className='mt-3 ms-4 text-color-violet fw-700'>Barrio</p>
										<p className='mt-3 ms-4 fs-3 text-color-violet fw-500'></p>
										<p className='mt-4 ms-4 fs-3 text-color-violet fw-500'>
											Palermo
										</p>
									</div>
								</div>
								<div className='col-3'>
									<div className='card rounded-1 bg-pink-medium'>
										<p className='mt-2 ms-4 text-color-violet fw-700'>
											Precio Promedio
										</p>
										<p className='mt-1 ms-4 fs-3 text-color-violet fw-500'>
											U$S
										</p>
										<p className='ms-4 fs-3 text-color-violet fw-500'>
											150.000
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Estadisticas;
