import React, { useState, useContext, useRef, useEffect } from 'react';
import { UilAngleDown, UilAngleUp, UilSearch, UilCheck } from '@iconscout/react-unicons';
import ThemeContext from '../../contexts/themeContext';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';

export const SearchFilter = ({
	text = '',
	placeholder,
	name,
	nameEng,
	size = '3',
	changeHandlerMultipleSelect,
	data,
	inputDisabled,
	bgColor = '',
	withSearch = true,
	values = [
		{
			id: 1,
			label: 'Departamento',
		},
		{
			id: 2,
			label: 'Casa',
		},
		{
			id: 3,
			label: 'Galpon',
		},
		{
			id: 4,
			label: 'Edificio',
		},
		{
			id: 5,
			label: 'Oficina',
		},
		{
			id: 6,
			label: 'Local',
		},
	],
}) => {
	const { darkModeStatus } = useContext(ThemeContext);
	const [filteredSearch, setfilteredSearch] = useState(values);
	const [toggle, setToggle] = useState(false);

	const filter = (search) => {
		let tmpArray = [];
		tmpArray = values.filter(function (item) {
			item = item.label.toLowerCase();
			return item.indexOf(search) > -1;
		});
		setfilteredSearch(tmpArray);
	};
	if(nameEng == "localities") {
		nameEng = "cities"
	}

	const checkHandler = (item) => {
		changeHandlerMultipleSelect(item.id, nameEng);
	};

	// let dropdown;

	// Nota para Pablo del futuro / quien sea que esté leyendo esto:
	// El siguiente useEffect se ve un poco feo y desactualizado, pero es
	// absolutamente necesario para switchear entre los tipos de border-radius
	// que tiene que tener el dropdown dependiendo si está desplegado o no.
	// No se puede usar onClick acá porque ese evento ya está siendo ocupado por bootstrap,
	// así que tuve que buscar otra alternativa.
	// Intenté hacerlo con un useRef en lugar de getElementById, pero por alguna razón,
	// no me permite agregarle un event listener al .current del ref.
	// Mas info sobre esto acá https://getbootstrap.com/docs/5.1/components/dropdowns/#events

	// useEffect(() => {
	// 	dropdown = document.getElementById(`${name}1`);

	// 	dropdown.addEventListener('show.bs.dropdown', function () {
	// 		setToggle(true);
	// 	});

	// 	dropdown.addEventListener('hide.bs.dropdown', function () {
	// 		setToggle(false);
	// 	});
	// }, []);

	// Esto es para que se cierre cuando detecte que hiciste click afuera del componente
	const ref = useRef(null);

	useDetectOutsideClick(ref, () => setToggle(false));

	return (
		<div className={`w-100 position-relative`} id={`${name}1`} ref={ref}>
			<div
				id={name}
				// data-bs-toggle='dropdown'
				// data-bs-display='static'
				// aria-expanded='false'
				onClick={() => setToggle(!toggle)}
				className={`dropbtn ${
					toggle ? 'rounded-top' : 'rounded-2'
				} d-flex align-items-center justify-content-between w-100 py-${size} px-${'3'}  ${
					bgColor != '' ? bgColor : ''
				}`}
				type='button'
			>
				<div className='d-flex flex-column'>
					<p className='m-0' style={{ fontSize: '11px' }}>
						{text || 'Seleccione'}
					</p>
					{(data[nameEng] || toggle) && (
						<p className='m-0' style={{ fontSize: '11px' }}>
							Seleccionados {data[nameEng]?.length || 0} de {values?.length}
						</p>
					)}
				</div>
				{toggle ? <UilAngleUp /> : <UilAngleDown />}
			</div>

			{toggle && (
				<div
					className='dropdown-content dropdown-menu-search-filter rounded-bottom w-100 bg-white shadow position-absolute'
					style={{ zIndex: 999, maxHeight: '30vh', overflowY: 'scroll' }}
					aria-labelledby={name}>
					{withSearch && (
						<div className='d-flex align-items-center py-2 px-3 col-12 searchBar'>
							<UilSearch color={darkModeStatus ? '#A9B5D7' : '#01227d'} size={25} />
							<input
								type='search'
								placeholder={placeholder || 'Buscar'}
								onChange={(e) => filter(e.target.value)}
								id='myInput'
								className='input-no-outline'
							/>
						</div>
					)}
					<div className='p-2'>
						{filteredSearch?.map((item, i) => (
							<div key={i}
							className='d-flex align-items-center justify-content-between fs-5 fw-bold text-color-lblue p-2 ms-3 cursor-pointer'
							style={{ pointerEvents: inputDisabled ? 'none' : 'auto' }}
							onClick={() => checkHandler(item)}
							>
								<label className=''>{item.label || item.name}</label>
								<div className='d-flex align-items-center justify-content-center custom-checkbox me-3'>
									<UilCheck
										color={
											data[nameEng]?.includes(item.id) ? '#506CF9' : '#fff'
										}
										size={20}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
	// return (
	// 	<div className={`dropdown w-100 ${!toggle && 'bg-success'}`} id={`${name}1`} ref={ref}>
	// 		<div
	// 			id={name}
	// 			data-bs-toggle='dropdown'
	// 			data-bs-display='static'
	// 			aria-expanded='false'
	// 			className={`dropbtn ${
	// 				toggle ? 'rounded-top' : 'rounded-2'
	// 			} d-flex align-items-center justify-content-between w-100 py-${size} px-${'3'}`}
	// 			type='button'>
	// 			<div className='d-flex flex-column'>
	// 				<p className='m-0' style={{ fontSize: '11px' }}>
	// 					{text || 'Seleccione'}
	// 				</p>
	// 				{(data[nameEng] || toggle) && (
	// 					<p className='m-0' style={{ fontSize: '11px' }}>
	// 						Seleccionados {data[nameEng]?.length || 0} de {values.length}
	// 					</p>
	// 				)}
	// 			</div>
	// 			{toggle ? <UilAngleUp /> : <UilAngleDown />}
	// 		</div>

	// 		<div
	// 			className='dropdown-content dropdown-menu dropdown-menu-search-filter rounded-bottom w-100 bg-white shadow'
	// 			aria-labelledby={name}>
	// 			<div className='d-flex align-items-center py-2 px-3 col-12 searchBar'>
	// 				<UilSearch color={darkModeStatus ? '#A9B5D7' : '#01227d'} size={25} />
	// 				<input
	// 					type='search'
	// 					placeholder={placeholder || 'Buscar'}
	// 					onChange={(e) => filter(e.target.value)}
	// 					id='myInput'
	// 					className='input-no-outline'
	// 				/>
	// 			</div>
	// 			<div className='p-2'>
	// 				{filteredSearch.map((item) => (
	// 					<div
	// 						className='d-flex align-items-center justify-content-between fs-5 fw-bold text-color-lblue p-2 ms-3 cursor-pointer'
	// 						onClick={() => checkHandler(item)}>
	// 						<label className=''>{item.label}</label>
	// 						<div className='d-flex align-items-center justify-content-center custom-checkbox me-3'>
	// 							<UilCheck
	// 								color={data[nameEng]?.includes(item.id) ? '#506CF9' : '#fff'}
	// 								size={20}
	// 							/>
	// 						</div>
	// 					</div>
	// 				))}
	// 			</div>
	// 		</div>
	// 	</div>
	// );
};

export default SearchFilter;
