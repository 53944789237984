import React, { useContext, useEffect, useRef, useState } from 'react';
import { X } from '../icon/bootstrap';
import './index.css';
import { Suggestions } from './Suggestions';
import { ThemeContext } from '@emotion/react';

export const Tags = ({ tagsSuggestion, summary,setSummary , maxTags}) => {
  const timeoutRef = useRef(null)
  const [tag, setTag] = useState('');

  const [allTags , setAllTags] = useState([]);
  const [arrayTagsUser, setArrayTagsUser] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [highlightedSuggestion, setHighlightedSuggestion] = useState(-1);
  const [editingTagIndex, setEditingTagIndex] = useState(-1);
  const [editingTagValue, setEditingTagValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const inputRefCreate = useRef(null);
  const inputRefEdit = useRef(null);
  const highlightedRef = useRef(null);

  const { mobileDesign } = useContext(ThemeContext);

  useEffect(() => {
    if (summary && summary.tags) {
      setArrayTagsUser(summary.tags);
    }
    setAllTags(tagsSuggestion)
  }, [summary]);


  const handleChange = (e) => {
    const { value } = e.target;
    sugestionFilter(value, allTags);
    if (isEdit) {
        setEditingTagValue(value);
        return;
      } 
      setTag(value);
     
  };
  
  const chargeSuggestionFilters=(tag, index) => {
    setIsEdit(true)
    setEditingTagValue(tag);
    setEditingTagIndex(index);
    sugestionFilter(tag, allTags);
  }


  const sugestionFilter = (name, allTags) => {
    const filteredSuggestions = allTags
      .filter((suggestion) =>
        suggestion.name.toLowerCase().startsWith(name.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name)); 
  
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
  };



  const handleSuggestionClick = (suggestion) => {
    if(!isEdit){
      setSummary((prevSummary) => ({
        ...prevSummary,
        tags: [...prevSummary.tags, suggestion.name]
      }));
      
      setTag('');
      setShowSuggestions(false);
      setHighlightedSuggestion(-1);
      return;
    } 
      editTag(editingTagIndex,suggestion.name )
      return;
    
  };


  const editTag = (index, newValue) => {
    if (newValue.trim() === '') {
      removeTag(index);
    } else {
      setSummary((prevSummary) => ({
        ...prevSummary,
        tags: prevSummary.tags.map((tag, i) => (i === index ? newValue : tag))
      }));
    }
    setIsEdit(false);
    setEditingTagIndex(-1);
    setEditingTagValue('');
  };

  const removeTag = (index) => {
    setSummary((prevSummary) => ({
      ...prevSummary,
      tags: prevSummary.tags.filter((_, i) => i !== index)
    }));
  };



const submitTag = (newTag) => {

  if(summary.tags && summary.tags.length >= maxTags){
    return;
  }
  
  setSummary((prevSummary) => ({
      ...prevSummary,
      tags: prevSummary.tags ? [...prevSummary.tags, newTag] : [newTag]
    }));

    setTag('');

}


  const getTypeAction = (keyCode) => {
    let newTag = tag.trim();
    const KEY_ENTER = 13; 
    const KEY_TAB = 9; 
    const KEY_COMA = 188; 
    const KEY_BACKSPACE = 8; 
    const KEY_UP = 38;
    const KEY_DOWN = 40;
    
    if(isEdit){
      newTag = editingTagValue.trim();
    }
 
    switch (keyCode) {
      case KEY_ENTER:
        case KEY_TAB:
          case KEY_COMA:
            if (newTag.length > 0 ) {
              if(!isEdit){
                submitTag(newTag)
              } else {
                editTag(editingTagIndex ,newTag )
              }
              setHighlightedSuggestion(-1)
              setShowSuggestions(false);
              }
                break;
            case KEY_BACKSPACE: 
              if (!isEdit) {
                setHighlightedSuggestion(-1);
                if (!newTag.length && summary.tags.length) {
                  const newTags = summary.tags.slice(0, -1); 
                  setSummary((prevSummary) => ({
                    ...prevSummary,
                    tags: newTags
                  }));
                  setShowSuggestions(false);
                }
              }
            break;
            case KEY_UP:
              if (highlightedSuggestion > 0 && showSuggestions) {
                if (!isEdit) {
                  setTag(filteredSuggestions[highlightedSuggestion - 1].name);
                } else {
                  setEditingTagValue(filteredSuggestions[highlightedSuggestion - 1].name);
                }
                setHighlightedSuggestion((prev) => prev - 1);
              }
              setCursorToEnd(inputRefCreate);
              setCursorToEnd(inputRefEdit);
              break;
          case KEY_DOWN:
            if(highlightedSuggestion < filteredSuggestions.length - 1 && showSuggestions){
              if(!isEdit){
                setTag(filteredSuggestions[highlightedSuggestion + 1 ].name)
              } else {
                setEditingTagValue(filteredSuggestions[highlightedSuggestion + 1 ].name)
              }
              setHighlightedSuggestion((prev) => prev + 1)
            }
            break
        default:
            break;
            
    }
  }

  const setCursorToEnd = (inputRef) => {
    if (inputRef.current) {
      setTimeout(() => {
        const input = inputRef.current;
        const length = input.value.length;
        input.selectionStart = length;
        input.selectionEnd = length;
      },10)
    }
  };


  const handleKeyDown = (e) => {
    const { keyCode } = e;

    getTypeAction(keyCode)
  };


  const handleStyleInput = (inputType) => {
    const minWidth = 30;
    const minHeight = 30;
  
    let width  = '0'

    if (inputType === 'editing' && editingTagValue !== '') {
      width = Math.max(minWidth, editingTagValue.length * 12);
    } else if (inputType === 'regular') {
      width = Math.max(minWidth, tag.length * 12);
    }
  
  
    const inputStyle = {
      minWidth: `${minWidth}px`,
      minHeight: `${minHeight}px`,
      width: `${width}px`,
    };

    return inputStyle

  }


  const handleBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false)
      setTag('')
      setEditingTagIndex(-1)
      setIsEdit(false)
      setHighlightedSuggestion(-1)
    }
      , 100);
  };
  



  const startLongPress = (tag, index) => {
    timeoutRef.current = setTimeout(() => {
      chargeSuggestionFilters(tag, index);
    }, 300);
  }

  const cancelLongPress = () => {
    clearTimeout(timeoutRef.current);
  }

  return (
    <div className='d-flex flex-wrap  '>
        {arrayTagsUser?.map((tag, index) => (
          <div className={` ${editingTagIndex === index ? 'container-input-suggestion' : 'tag-info d-flex align-items-center bg-lblue py-2 px-3 rounded-pill cursor-pointer m-2 '}`}
            key={index}
            onTouchEnd={cancelLongPress}
            onTouchStart={(e) => startLongPress( tag , index )}
            onDoubleClick={(e) => {chargeSuggestionFilters(tag, index);}}>
              {editingTagIndex === index ? (
                <div>
                  <input
                        type='text'
                        ref={inputRefEdit}
                        value={editingTagValue}
                        onChange={(e) =>handleChange(e)}
                        className='custom-letter text-color-lblue color-lblue bg-lblue cursor-pointer m-2'
                        style={handleStyleInput('editing')}
                        list='interes_list'
                        onBlur={() => {handleBlur();}}
                        onKeyDown={(e) => handleKeyDown(e)}
                        autoFocus
                    />
                  {filteredSuggestions.length > 0 && (
                    <Suggestions
                      handleKeyDown={(e) => handleKeyDown(e)}
                      highlightedSuggestion={highlightedSuggestion}
                      filteredSuggestions={filteredSuggestions}
                      handleMouseEnter={setHighlightedSuggestion}
                      handleSuggestionClick={handleSuggestionClick}
                    />
                    )}
                </div>
                ) : (
                  <>
                    <p className='custom-letter text-color-lblue m-0 pe-2 tag-content'>{tag}</p>
                    <strong>
                      <X onClick={() => removeTag(index)} color='#506cf9' />
                    </strong>
                  </>
              )}
            </div>
          ))} 
      <div className='container-input-suggestion'>
          <div className='round-input2'>
              <input 
                ref={inputRefCreate}
                type='text'
                className='custom-letter text-color-lblue color-lblue bg-lblue cursor-pointer m-2'
                id='interes'
                name='interes'
                value={tag}
                onChange={(e) =>handleChange(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                list='interes_list'
                placeholder='+'
                style={handleStyleInput('regular')}
                onBlur={() => {handleBlur();}}
              />
          </div>
          {showSuggestions && filteredSuggestions.length > 0 && tag.length > 0 && (
            <Suggestions
              handleKeyDown={(e) => handleKeyDown(e)}
              highlightedSuggestion={highlightedSuggestion}
              filteredSuggestions={filteredSuggestions}
              handleMouseEnter={setHighlightedSuggestion}
              handleSuggestionClick={handleSuggestionClick}
              highlightedRef={highlightedRef}
            />
          )}
      </div>
    </div>
  );
};
