import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import useUser from '../../../hooks/useUser';

// eslint-disable-next-line react/prop-types

const Login = () => {
	const { darkModeStatus } = useDarkMode();
	const [dni, setDNI] = useState('');
	const [password, setPassword] = useState('');
	const { isLoginLoading, hasLoginError, login, isLogged } = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (isLogged) {
			navigate('/dashboard', { replace: true });
		}
	}, [isLogged, navigate]);

	const handleSubmit = (e) => {
		e.preventDefault();
		login({ dni, password });
	};

	return (
		<PageWrapper title='Login' className={classNames('bg-primary')}>
			<Page className='p-0'>
				<div className='row vh-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} color='#212121' />
									</Link>
								</div>

								<div>
									<div className='text-center h1 fw-bold mt-5'>Bienvenido,</div>
									<div className='text-center h4 text-muted mb-5'>
										Ingresa tus credenciales para continuar
									</div>
								</div>

								<form onSubmit={handleSubmit} className='row g-4'>
									<div className='col-12'>
										<FormGroup id='dni' isFloating label='DNI del Operador'>
											<Input
												autoComplete='dni'
												type='tel'
												onChange={(e) => setDNI(e.target.value)}
												value={dni}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup
											id='login-password'
											isFloating
											label='Contraseña'>
											<Input
												type='password'
												autoComplete='password'
												onChange={(e) => setPassword(e.target.value)}
												value={password}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											type='submit'
											color='primary'
											className='w-100 py-3'>
											Ingresar
										</Button>
									</div>
								</form>
								{isLoginLoading && (
									<div className='mt-4 text-center h4 mb-2'>
										<span className='badge bg-success'>Cargando...</span>
									</div>
								)}
								{hasLoginError && (
									<div className='mt-4 text-center h4 mb-2'>
										<span className='badge bg-danger'>
											Credenciales incorrectas
										</span>
									</div>
								)}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
