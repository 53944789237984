import * as React from 'react';

const BagSVG = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg'
  viewBox='0 0 24 24'
  // style={{
  // 	enableBackground: 'new 0 0 512 512',
  // }}
  xmlSpace='preserve'
  width='24px'
  height='24px'
  className='svg-icon'
  {...props}>
	<path
		fill='#0092E4'
		d='M19,6H16V5a2,2,0,0,0-2-2H10A2,2,0,0,0,8,5V6H5A3,3,0,0,0,2,9v9a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V9A3,3,0,0,0,19,6ZM10,5h4V6H10ZM20,18a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12.39L8.68,14A1.19,1.19,0,0,0,9,14h6a1.19,1.19,0,0,0,.32-.05L20,12.39Zm0-7.72L14.84,12H9.16L4,10.28V9A1,1,0,0,1,5,8H19a1,1,0,0,1,1,1Z'
	/>
</svg>
);

export default BagSVG;

