import React from 'react';
import { componentsMenu, dashboardMenu, demoPages, layoutMenu, asideOptions } from '../menu';
import DashboardBookingHeader from '../pages/common/Headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/common/Headers/ProfilePageHeader';
// import SummaryHeader from '../pages/common/Headers/SummaryHeader';
import ProductsHeader from '../pages/common/Headers/ProductsHeader';
import ProductListHeader from '../pages/common/Headers/ProductListHeader';
import PageLayoutHeader from '../pages/common/Headers/PageLayoutHeader';
import ComponentsHeader from '../pages/common/Headers/ComponentsHeader';
import FormHeader from '../pages/common/Headers/FormHeader';
import ChartsHeader from '../pages/common/Headers/ChartsHeader';
import ContentHeader from '../pages/common/Headers/ContentHeader';
import UtilitiesHeader from '../pages/common/Headers/UtilitiesHeader';
import IconHeader from '../pages/common/Headers/IconHeader';
import ExtrasHeader from '../pages/common/Headers/ExtrasHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';
import HeaderGeneral from '../layout/Header/HeaderGeneral';
// import OperatorsHeader from '../pages/common/Headers/OperatorsHeader';

const headers = [
	{ path: 'operadores/operadores', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/interesados', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/interesados/crear', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/propietarios/crear', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/inquilinos/crear', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/garantes/crear', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/interesados/editar/:id', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/propietarios/editar/:id', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/inquilinos/editar/:id', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/garantes/editar/:id', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/propietarios', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/inquilinos', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/garantes', element: <HeaderGeneral />, exact: true },
	{ path: 'personas/operadores/crear', element: <HeaderGeneral />, exact: true },
	{ path: 'mantenimiento/perfiles', element: <HeaderGeneral />, exact: true },
	{ path: 'mantenimiento/perfiles/editar', element: <HeaderGeneral />, exact: true },
	{ path: 'mantenimiento/perfiles/crear', element: <HeaderGeneral />, exact: true },
	{ path: 'propiedades/inmuebles', element: <HeaderGeneral />, exact: true },
	{ path: 'Estadisticas', element: <HeaderGeneral />, exact: true },
	{ path: 'Notificaciones', element: <HeaderGeneral />, exact: true },
	{ path: 'Tasador', element: <HeaderGeneral />, exact: true },
	{ path: 'Tasador/Reporte', element: <HeaderGeneral />, exact: true },
	{ path: 'propiedades/emprendimientos', element: <HeaderGeneral />, exact: true },
	{ path: 'propiedades/inmuebles/crear', element: <HeaderGeneral />, exact: true },
	{ path: 'mantenimiento/sistema', element: <HeaderGeneral />, exact: true },
	{ path: 'propiedades/publicaciones', element: <HeaderGeneral />, exact: true },
	{ path: 'propiedades/inmuebles/editar/*', element: <HeaderGeneral />, exact: false },
	{ path: 'agenda', element: <HeaderGeneral />, exact: false },
	{ path: 'estados-clientes', element: <HeaderGeneral />, exact: false },
	{ path: 'oportunidades', element: <HeaderGeneral />, exact: false },
	{ path: layoutMenu.pageLayout.subMenu.onlySubheader.path, element: null, exact: true },
	{ path: layoutMenu.pageLayout.subMenu.onlyContent.path, element: null, exact: true },
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: demoPages.knowledge.subMenu.grid.path, element: null, exact: true },
	{ path: dashboardMenu.suPedido.path, element: <HeaderGeneral />, exact: true },
	{
		path: demoPages.projectManagement.subMenu.list.path,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{ path: demoPages.pricingTable.path, element: <DashboardBookingHeader />, exact: true },
	{
		path: dashboardMenu.estadoDeCliente.path,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: asideOptions.inmuebles.path,
		element: null,
		exact: true,
	},
	{
		path: asideOptions.alquileresTemporarios.path,
		element: null,
		exact: true,
	},
	{
		path: asideOptions.personas.path,
		element: null,
		exact: true,
	},
	{
		path: asideOptions.administracion.path,
		element: null,
		exact: true,
	},
	{
		path: asideOptions.cobranzas.path,
		element: null,
		exact: true,
	},
	{
		path: asideOptions.mantenimiento.path,
		element: null,
		exact: true,
	},
	{
		path: demoPages.appointment.subMenu.calendar.path,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: demoPages.appointment.subMenu.employeeList.path,
		element: <HeaderGeneral />,
		// element: <OperatorsHeader />,
		exact: true,
	},
	{
		path: demoPages.listPages.subMenu.listFluid.path,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: `${demoPages.editPages.path}/*`,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: `${demoPages.appointment.subMenu.employeeID.path}/*`,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: `${demoPages.projectManagement.subMenu.itemID.path}/*`,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: demoPages.singlePages.subMenu.fluidSingle.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.singlePages.subMenu.boxedSingle.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.sales.subMenu.transactions.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.chat.subMenu.withListChat.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.chat.subMenu.onlyListChat.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.crm.subMenu.dashboard.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.crm.subMenu.customersList.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: `${demoPages.crm.subMenu.customerID.path}/:id`,
		element: <ProfilePageHeader />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.summary.path,
	// 	element: <SummaryHeader />,
	// 	exact: true,
	// },
	{
		path: demoPages.gridPages.subMenu.gridBoxed.path,
		element: <ProductsHeader />,
		exact: true,
	},
	{
		path: demoPages.gridPages.subMenu.gridFluid.path,
		element: <ProductsHeader />,
		exact: true,
	},
	{
		path: demoPages.listPages.subMenu.listBoxed.path,
		element: <ProductListHeader />,
		exact: true,
	},
	{
		path: demoPages.sales.subMenu.salesList.path,
		element: <ProductListHeader />,
		exact: true,
	},
	{
		path: demoPages.sales.subMenu.productsGrid.path,
		element: <ProductListHeader />,
		exact: true,
	},
	{
		path: `${demoPages.sales.subMenu.productID.path}/:id`,
		element: <ProductListHeader />,
		exact: true,
	},
	{
		path: `${layoutMenu.asideTypes.path}/*`,
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: `${componentsMenu.components.path}/*`,
		element: <ComponentsHeader />,
	},
	{
		path: `${componentsMenu.forms.path}/*`,
		element: <FormHeader />,
	},
	{
		path: `${componentsMenu.charts.path}/*`,
		element: <ChartsHeader />,
	},
	{
		path: `${componentsMenu.content.path}/*`,
		element: <ContentHeader />,
	},
	{
		path: `${componentsMenu.utilities.path}/*`,
		element: <UtilitiesHeader />,
	},
	{
		path: `${componentsMenu.icons.path}/*`,
		element: <IconHeader />,
	},
	{
		path: `${componentsMenu.extra.path}/*`,
		element: <ExtrasHeader />,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
