import React from 'react';
import {
	UilRulerCombined,
	UilRuler,
	UilTape,
	UilTrees,
	UilBed,
	UilRestaurant,
	UilPlus,
	UilPen,
	UilAngleRightB,
	UilAngleLeftB,
} from '@iconscout/react-unicons';
import {
	Armchair,
	Bathtub,
	CoatHanger,
	CookingPot,
	Door,
	Drop,
	ForkKnife,
	SkipForward,
	Toilet,
} from 'phosphor-react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';

export default function Measures({ estateData, setEstateData, setSelectedStep }) {
	const inputs = [
		{
			name: 'coveredArea',
			label: 'Sup. cubierta',
			icon: <UilRulerCombined color='#506CF9' size={24} />,
		},
		{
			name: 'semiCoveredArea',
			label: 'Sup. semi cubierta',
			icon: <UilRuler color='#506CF9' size={24} />,
		},
		{
			name: 'totalBuilt',
			label: 'Total construído',
			icon: <UilTape color='#506CF9' size={24} />,
		},
		{
			name: 'front',
			label: 'Frente',
			icon: <UilRulerCombined color='#506CF9' size={24} />,
		},
		{
			name: 'garden',
			label: 'Patio',
			icon: <UilTrees color='#506CF9' size={24} />,
		},
		{
			name: 'living',
			label: 'Living',
			icon: <Armchair color='#506CF9' size={24} />,
		},
		{
			name: 'diningRoom',
			label: 'Comedor',
			icon: <ForkKnife color='#506CF9' size={24} />,
		},
		{
			name: 'bathrooms',
			label: 'Baños',
			icon: <Bathtub color='#506CF9' size={24} />,
		},
		{
			name: 'kitchen',
			label: 'Cocina',
			icon: <CookingPot color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom1',
			label: 'Dormitorio 1',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom2',
			label: 'Dormitorio 2',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom3',
			label: 'Dormitorio 3',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom4',
			label: 'Dormitorio 4',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'bedroom5',
			label: 'Dormitorio 5',
			icon: <UilBed color='#506CF9' size={24} />,
		},
		{
			name: 'diaryDiningRoom',
			label: 'Comedor diario',
			icon: <UilRestaurant color='#506CF9' size={24} />,
		},
		{
			name: 'servDep',
			label: 'Dep. de serv.',
			icon: <Door color='#506CF9' size={24} />,
		},
		{
			name: 'servToilet',
			label: 'Toilette serv.',
			icon: <Toilet color='#506CF9' size={24} />,
		},
		{
			name: 'laundry',
			label: 'Lavadero',
			icon: <Drop color='#506CF9' size={24} />,
		},
		{
			name: 'placards',
			label: 'Placards',
			icon: <CoatHanger color='#506CF9' size={24} />,
		},
		{
			name: 'toilet',
			label: 'Toilette',
			icon: <Toilet color='#506CF9' size={24} />,
		},
	];

	const [fields, setFields] = useState(inputs);

	const handleSubmit = (values) => {
		setFields((fields) => [
			...fields,
			{
				name: values.fieldName,
				label: values.fieldName,
				icon: <UilPen color='#506CF9' size={24} />,
			},
		]);
	};

	return (
		<div className='d-flex flex-column w-100'>
			<div className='d-flex flex-wrap justify-content-start w-100 p-5'>
				{fields.map((input) => (
					<div
						className='d-flex align-items-center justify-content-between col-4 p-4'
						key={input.name}>
						<div className='d-flex align-items-center'>
							{input.icon}
							<p
								className='text-color-lblue mb-0 ms-3 fw-normal-bold'
								style={{ fontSize: '1vw' }}>
								{input.label}
							</p>
						</div>

						<div className='d-flex align-items-center'>
							<input
								className='text-color-lblue bg-blue-100 fs-5 py-3 no-outline border-0 rounded'
								style={{ width: '6vw', textAlign: 'center' }}
								value={estateData.measures[input.name] || 0}
								onChange={(event) =>
									setEstateData((estateData) => ({
										...estateData,
										measures: {
											...estateData.measures,
											[input.name]: event.target.value,
										},
									}))
								}
							/>
							<div className='d-flex align-items-center justify-content-center py-3 px-4 text-color-lblue border-0 bg-blue-100 rounded fs-5 ms-3'>
								m²
							</div>
						</div>
					</div>
				))}

				{/* Agregar campo */}
				<Formik
					initialValues={{ fieldName: '' }}
					validate={(values) => {
						const errors = {};

						if (!values.fieldName) {
							errors.fieldName = 'Debe ingresar un nombre para el campo nuevo';
						}

						if (fields.some((field) => field.name === values.fieldName)) {
							errors.fieldName = 'El nombre del campo no puede estar repetido';
						}

						return errors;
					}}
					className=''
					onSubmit={handleSubmit}>
					{({ errors, touched }) => (
						<Form className='col-4 p-4'>
							<div className='d-flex align-items-center p-3 border border-2 border-blue-300 rounded col-12'>
								<UilPlus color='#506CF9' size={24} />
								<Field
									name='fieldName'
									className='no-outline text-color-lblue border-0 fs-5 add-field-input'
									placeholder='Agregar'
								/>
							</div>
							{errors.fieldName && touched.fieldName && (
								<p className='text-color-danger m-3'>{errors.fieldName}</p>
							)}
						</Form>
					)}
				</Formik>
				{/* Agregar campo */}
			</div>

			<div className='d-flex align-items-center justify-content-between w-100 p-5'>
				{/* <div
					className='d-flex align-items-center justify-content-center py-2 px-4 blue-gradient-buttons rounded cursor-pointer'
					onClick={() => setSelectedStep('multimedia')}>
					<SkipForward color='#fff' size={24} />
					<p className='mb-0 ms-2 text-color-white fs-5'>Omitir</p>
				</div> */}
				<div
					className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
					onClick={() => setSelectedStep('features')}>
					<UilAngleLeftB color='#fff' size={24} />
					<p className='mb-0 text-color-white fw-bold'>Atrás</p>
				</div>
				<div
					className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
					onClick={() => setSelectedStep('multimedia')}>
					<p className='mb-0 text-color-white fw-bold'>Siguiente</p>
					<UilAngleRightB color='#fff' size={24} />
				</div>
			</div>
		</div>
	);
}
