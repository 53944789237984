import React, { useEffect, useState } from 'react';
import {
	LineSegments,
	Envelope,
	WhatsappLogo,
    House,
    FilePlus,
    ArrowCounterClockwise,
	AddressBook
} from 'phosphor-react';
import {
	UilNotes,
	UilSearchAlt,
	UilEnvelope,
	UilMessage,
	UilLocationPoint,
	UilBookmark,
	UilBed,
	UilMoneyBill
} from '@iconscout/react-unicons';
import { useContext } from 'react';
import ThemeContext from '../../contexts/themeContext';
import ChronologyItemSelector from '../customers/createCustomer/ChronologyItemSelector';
import { Form, Formik } from 'formik';
import Button from '../../components/bootstrap/Button';
import { postEvent } from '../../services/customersServices';
import UserContext from '../../contexts/userContext';
import { showToast } from '../../components/Toast/toastUtils/toastUtils';
import Toast from '../../components/Toast/Toast';
import { getProperties } from '../../services/filtersServices';


const InfoCustomer = ({reason ,filters ,viewModalInfoCustomer , customer }) => {
	const { mobileDesign } = useContext(ThemeContext);
    const [activeItem, setActiveItem] = useState('Historial');
    const [customerInfo, setCustomerInfo] = useState(customer);
    const [contact, setContact] = useState('');
    const [task, setTask] = useState({ properties: []});
	const [view, setView] = useState(false);
	const { token } = useContext(UserContext);
	const [list, setList] = useState([])
	const [properties, setProperties] = useState([])

	const fetchProperties = async() => {
		try{
			const response = getProperties(customer.searchProfile , token).then((res) => {
				setProperties(res.data)
			})
			
		}catch(error){
			console.log(error)
		}
	}

	useEffect(() => {
		if (customer) {
			fetchProperties();
			setCustomerInfo(customer)
		}
		return() => {
			setCustomerInfo([])
			setProperties([]);

		}
	},[customer])


	const actionTypes = {
		Correo: 3,
		Whatsapp: 5
	  }

	useEffect(()=> {
			if(activeItem !== 'Actividad'){
			setContact(contactMapping[activeItem])
			}
	},[activeItem])


	const handleSubmit = (value , resetForm) => {

		let object = {}

		if(activeItem === 'Actividad'){
			if(!task.date || !task.time || !task.dueTime){
				showToast('Complete el campo Fecha' , setList , 'danger')
				return
			} else {
				let formattedTime = task.time.split(':').map(part => part.padStart(2, '0')).join(':');
				let formattedTimeDue = task.dueTime.split(':').map(part => part.padStart(2, '0')).join(':');
				let formattedDate = `${task.date.year}-${String(task.date.month).padStart(2, '0')}-${String(task.date.day).padStart(2, '0')} ${formattedTime}:00`;
				let formattedDateDue = `${task.date.year}-${String(task.date.month).padStart(2, '0')}-${String(task.date.day).padStart(2, '0')} ${formattedTimeDue}:00`;
				
				object = {
					type: task.motivo || 0,
					startTime: formattedDate || '',
					observations: task.obs || '',
					properties: task.properties || [],
					dueTime : formattedDateDue
				}
			}
		} else{ 
			if(!value.message) {
				return
			}
			let startTime = new Date();
			let formattedStartTime = startTime
			.toISOString()
			.replace('T', ' ')
			.replace(/\.\d+Z$/, '');
			object = {
				type: actionTypes[activeItem],
				startTime: formattedStartTime,
				to: value.number || null,
				observations: value.details || value.subject
			}
		}

		postEvent(customerInfo.id, object , token).then((res) => {
			if(res.status === 200){
				const { data } = res.data;
				let newEntry = {
					details: data.note || data.observations,
					type: reason && reason[data.type - 1] ? reason[data.type - 1].name : '',
					startTime: data.startTime || '',
					properties: data.properties || [],
					assignedOperator: data.assignedOperator || '-'
				};

				setCustomerInfo(prevState => ({
					...prevState,
					cronology: [newEntry, ...prevState.cronology],
				}));

				showToast(res.data.message, setList, 'success');
				setTask({})
				resetForm()
			}  else {
				console.log(res , "esto es res Error")
			}
 		})
	}
	
	const handleResetForm = (item) => {
		setTask({})
	}

	const contactMapping = {
		Whatsapp :`${customerInfo.summary?.areaCode || ''}${customerInfo.summary?.mobile ? customerInfo.summary?.mobile : ''}`,
		Correo : `${customerInfo.summary?.email || ''}`,
	}

	
	const getLastNote = () => {
			if (customerInfo && customerInfo.cronology && customerInfo.cronology.length > 0) {
				const notas = customerInfo.cronology.filter(entry => entry.type === 'Nota');
				if (notas.length > 0) {
					let maxId = -Infinity;
					let ultimaNota = null;
					
					notas.forEach(nota => {
						if (nota.id > maxId) {
							maxId = nota.id;
							ultimaNota = nota;
						}
					});
					return ultimaNota.details;
				}
			}
			
			return '';
	}

	const getMiValueInfo =(id, nameFiltro ,nameOpcion) => {
		const filtroOperacion = filters.find(filtro => filtro.name === nameFiltro);
		if (filtroOperacion && filtroOperacion.options && filtroOperacion.options[nameOpcion]) {
		  const operacion = filtroOperacion.options[nameOpcion].find(op => op.id === id);
		  return operacion ? (nameOpcion === 'currency' ? operacion.extraLabel : operacion.label)  : '-';
		} else {
		  return '-';
		}
	  }

    const chronologyItems = [
		{
			label: 'Historial',
			name: 'Historial',
			icon: <ArrowCounterClockwise size={20} color={activeItem === 'Historial' ? '#506cf9' : '#000'} />,
		},
		{
			label: 'Similares',
			name: 'Similares',
			icon: <House size={20} color={activeItem === 'Similares' ? '#506cf9' : '#000'} />
		},
		{
			label: 'Actividad',
			name: 'Generar tarea',
			icon: <FilePlus size={20} color={activeItem === 'Generar tarea' ? '#506cf9' : '#000'} />,
		},
		{
			label: 'Whatsapp',
			name: 'Enviar WhatsApp',
			icon: <WhatsappLogo size={20} color={activeItem === 'Enviar WhatsApp' ? '#506cf9' : '#000'} />,
		},
		{
			label: 'Correo',
			name: 'Enviar Correo',
			icon: <Envelope size={20} color={activeItem === 'Enviar Correo' ? '#506cf9' : '#000'} />,
		},
		
	];

	    return (
			<div>
				<Toast toastlist={list} position="top-right" setList={setList} />
				<div style={{ 'marginTop': `${!viewModalInfoCustomer?.isShow && '37px'}` ,'marginBottom': `${!viewModalInfoCustomer?.isShow && '47px'}` }} className={`${mobileDesign ? 'gap-4 px-4' : 'px-5'} w-100 d-flex flex-wrap  row ms-0 pt-4 pb-4 `}>
	{/* secccion de datos */}
						<div className={`d-flex flex-column col-xxl-4 col-xl-5 flex-shrink-0 ${mobileDesign && 'pt-5 px-0 '}`}>
							{/* Header */}
							{!mobileDesign && (
								<div className='d-flex align-items-center justify-content-between pt-1 col-12'>
									<div className='d-flex align-items-center col-4'>
										<div className='d-flex align-items-center px-4 py-3 me-1 rounded-top-lg bg-white  shadow-custom-tarjet-body text-color-lblue fs-6 fw-bold'>
											<AddressBook size={24} color='#506cf9' />
											<p className='my-0 mx-2'>Datos</p>
										</div>
									</div>
								</div>
							)}
							{/* Header */}
							<div className={`d-flex col-3 text-color-lblue ${mobileDesign ? 'rounded-xl' : 'rounded-right-and-bottom p-4'} bg-white  col-12 shadow-custom-tarjet-body style-text-custom`}>
								<tbody className={` ${mobileDesign && 'd-flex flex-wrap justify-content-around  w-100 px-3 py-2'} `}>
									{!mobileDesign && (
										<tr className='p-0'>
										{/* <td className='p-3'>{ mobileDesign ? <UilUser size={24} color='#506CF9' /> : 'Nombre:'}</td> */}
										<td className='p-3'>Nombre:</td>
										<td className={`${mobileDesign ? 'p-0' : 'p-3' } text-black fs-5 `}>{customerInfo.summary?.name || '-'}</td>
									</tr>
									)}
									<tr className={` ${mobileDesign ? 'px-0' : ''} p-0 py-2 `}>
										<td className={`${mobileDesign ? 'p-0 pe-2' : 'p-3'}`}>{ mobileDesign ? customer.summary?.mobile && <WhatsappLogo size={mobileDesign ? 20 : 24} color='#506CF9' /> : 'Telefono:'}</td>
										<td className={`${mobileDesign ? 'p-0 fs-13' : 'p-3 fs-5' } text-black`}>{customerInfo.summary?.mobile || ''}</td>
									</tr>
									<tr className={` ${mobileDesign ? 'px-0' : ''} p-0 py-2 `}>
										<td className={`${mobileDesign ? 'p-0 pe-2' : 'p-3'}`}>{ mobileDesign ? customer.summary?.email && <UilEnvelope size={mobileDesign ? 20 : 24} color='#506CF9' /> : 'Email:'}</td>
										<td className={`${mobileDesign ? 'p-0 fs-13' : 'p-3 fs-5' } text-black`}>{customerInfo.summary?.email || ''}</td>
									</tr>
								</tbody>
							</div>
						</div>
	{/* secccion de perfil */}

					<div className={`d-flex flex-column col-xxl-5 col-xl-7 style-text-custom flex-grow-1  ${mobileDesign && 'px-0'} `}>
						{/* Header */}
						{!mobileDesign && (
							<div className='d-flex align-items-center justify-content-between pt-1 col-12'>
								<div className='d-flex align-items-center col-4'>
									<div className='d-flex align-items-center px-4 py-3 me-1 rounded-top-lg bg-white text-color-lblue fs-6 fw-bold shadow-custom-tarjet-body'>
										<UilSearchAlt size={24} color='#506cf9' />
										<p className='text-nowrap my-0 mx-2'>Perfil</p>
									</div>
								</div>
							</div>
						)}
						{/* Header */}
						<div className={`d-flex justify-content-center ${mobileDesign ? 'flex-column p-3 gap-3 ' : 'flex-row'} text-color-lblue ${mobileDesign ? 'rounded-xl shadow' : 'rounded-right-and-bottom'} bg-white p-4 col-12 shadow-custom-tarjet-body`}>
							<div className={` d-flex ${mobileDesign ? 'col-12 gap-2  ' : 'col d-flex flex-nowrap   '}`}>
								{mobileDesign? (
									<div className='w-100'>
										<div className='gap-2 d-flex align-items-center pb-2 text-black'>
										{ customerInfo.searchProfile?.propertyTypes[0] && customerInfo.searchProfile?.locations[0] && customerInfo.searchProfile?.neighborhoods[0] &&
										<>
										<UilLocationPoint size={24} color={'#506CF9'}/>
										<div>{getMiValueInfo(customerInfo.searchProfile?.propertyTypes[0] , 'Tipo de inmueble' ,'propertyTypes' ) || '-'}</div>
										<div>{getMiValueInfo(customerInfo.searchProfile?.locations[0] , 'Localidades' ,'localities' )}</div>
										<div>{getMiValueInfo(customerInfo.searchProfile?.neighborhoods[0] , 'Barrio' ,'neighborhoods' )}</div>
										</>
											
										} 
										</div>
										<div className='d-flex flex-wrap gap-2 text-black justify-content-between '>
										{customerInfo.searchProfile?.operations[0] &&
											<div><UilBookmark size={24} color={'#506CF9'}/> { getMiValueInfo(customerInfo.searchProfile?.operations[0] , 'Operación' , 'operations') || ''}</div>
										 }
										{customerInfo.searchProfile?.rooms[0] &&
											<div><UilBed className='me-1' size={24} color={'#506CF9'}/> {getMiValueInfo(customerInfo.searchProfile?.rooms[0] , 'Ambientes' ,'rooms')}</div>
										 }
										{customerInfo.searchProfile?.currency &&
											<div><UilMoneyBill className='me-1' size={24} color={'#506CF9'}/>{getMiValueInfo(customerInfo.searchProfile?.currency , 'Moneda' ,'currency' ) || ''} {customerInfo.searchProfile?.valueMax}</div>
										 }
										</div>
									</div>
								)  : 
								(
									<>
									<table className='w-50'>
									<tbody className=''>
										<tr className={`${mobileDesign && 'd-flex flex-column'}`}>
											<td className={`${mobileDesign ? 'p-0' : 'p-3' }`}>Operación:</td>
											<td className={`${mobileDesign ? 'p-0' : 'px-0 py-3' } text-black fs-5 text-nowrap `}>{ getMiValueInfo(customerInfo.searchProfile?.operations[0] , 'Operación' , 'operations') || '-'} </td>
										</tr>
										<tr className={`${mobileDesign && 'd-flex flex-column'}`} >
											<td className={`${mobileDesign ? 'p-0' : 'p-3' }`}>Ambientes:</td>
											<td className={`${mobileDesign ? 'p-0' : 'px-0 py-3' } text-black fs-5 text-nowrap `}>{getMiValueInfo(customerInfo.searchProfile?.rooms[0] , 'Ambientes' ,'rooms')}</td>
										</tr>
										<tr className={`${mobileDesign && 'd-flex flex-column'}`} >
											<td className={`${mobileDesign ? 'p-0' : 'p-3' }`}>Barrio:</td>
											<td className={`${mobileDesign ? 'p-0' : 'px-0 py-3' } text-black fs-5 text-nowrap `}>{getMiValueInfo(customerInfo.searchProfile?.neighborhoods[0] , 'Barrio' ,'neighborhoods' )}</td>
										</tr>
									</tbody>
								</table>
								<table className='w-50'>
									<tbody>
									<tr className={`${mobileDesign && 'd-flex flex-column'}`}>
											<td className={`${mobileDesign ? 'p-0' : 'p-3' }`}>Tipo:</td>
											<td className={`${mobileDesign ? 'p-0' : 'p-0' } text-black fs-5 `}>{getMiValueInfo(customerInfo.searchProfile?.propertyTypes[0] , 'Tipo de inmueble' ,'propertyTypes' )}</td>
										</tr>
										<tr className={`${mobileDesign && 'd-flex flex-column'}`}>
											<td className={`${mobileDesign ? 'p-0' : 'p-3' }`}>Localidad:</td>
											<td className={`${mobileDesign ? 'p-0' : 'p-0' } text-black fs-5 `}>{getMiValueInfo(customerInfo.searchProfile?.locations[0] , 'Localidades' ,'localities' )}</td>
										</tr>
										<tr className={`${mobileDesign && 'd-flex flex-column'}`}>
											<td className={`${mobileDesign ? 'p-0' : 'p-3' }`}>Valor:</td>
											<td className={`${mobileDesign ? 'p-0' : 'p-0' } text-black fs-5 text-nowrap  `}>{getMiValueInfo(customerInfo.searchProfile?.currency , 'Moneda' ,'currency' )} {customerInfo.searchProfile?.valueMax} </td>
										</tr>
									</tbody>
								</table></>
								)}
							</div>
						</div>
					</div>
	{/* secccion de notas */}
					<div className={`d-flex flex-column col-xl-12 pt-xl-4 pt-xxl-0 col-xxl-3 ${mobileDesign && 'px-0'} `}>
						{!mobileDesign && (
							<div className='d-flex align-items-center justify-content-between pt-1 col-12'>
								<div className='d-flex align-items-center col-4'>
									<div className='d-flex align-items-center px-4 py-3 me-1 rounded-top-lg bg-white text-color-lblue fs-6 shadow-custom-tarjet-body fw-bold'>
										<UilNotes size={24} color='#506cf9' />
										<p className='my-0 mx-2'>Notas</p>
									</div>
								</div>
							</div>
						)}
						{ mobileDesign && !getLastNote().length > 0 ?
							<></> :  
								<div
								className={`${
									mobileDesign ? 'rounded-xl shadow px-5' : 'rounded-right-and-bottom'
								} bg-white p-4 h-100 shadow-custom-tarjet-body
								`}>
									{getLastNote()}
									{/* {customer.cronology} */}
								</div>
							}
					</div>
	{/* secccion de cronologia */}
			{(!mobileDesign) && (
				<div className={`d-flex flex-column w-100 ${!mobileDesign && 'pt-4'}`}>
				{/* Header */}
				{!mobileDesign && (
					<div className='d-flex align-items-center justify-content-between col-12'>
						<div className='d-flex align-items-center col-4'>
							<div className='d-flex align-items-center px-4 py-3 me-1 rounded-top-lg bg-white text-color-lblue fs-6 shadow-custom-tarjet-body fw-bold'>
								<LineSegments size={24} color='#506cf9' />
								<p className='my-0 mx-2'>Cronología</p>
							</div>
						</div>
					</div>
				)}
				{/* Header */}
				<div className='d-flex flex-column rounded-right-and-bottom bg-white col-12 shadow-custom-tarjet-body'>
					<div className={`${!mobileDesign && 'p-4'} `}>
						<div className='d-flex flex-column rounded bg-white shadow-black'>
							<div className='d-flex align-items-center border-bottom col-12'>
										{chronologyItems.map((item, index) => (
											<div
												className={`d-flex align-items-center 
												${
													!mobileDesign ? 'p-4 pb-3' : 'px-2 py-4 col flex-column'
												} fs-6 fw-normal-bold ${
													activeItem === item.label
														? 'bg-lblue text-color-lblue border-bottom  border-2 border-primary '
														: 'text-color-black border-2 border-transparent border-bottom'
												} ${index === 0 && 'rounded-tabs-chronology'} ${
													index === chronologyItems.length - 1 &&
													'rounded-top-right'
												} cursor-pointer`}
												key={index}
												onClick={()=>{
													setActiveItem(item.label)
													handleResetForm(item)}
												}>
												{item.icon}
												<strong><p className='my-0 mx-1'> 
													{item.name}</p>
												</strong>
											</div>
										))}
									</div>
									<Formik
										enableReinitialize={true}
										onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
										initialValues={{ content: '' , number: contact, message: '' , subject : '' }}
										>
										<Form>
												<div className='border-none py-2'>
													<ChronologyItemSelector view={view} reason={reason} task={task} setTask={setTask} properties={properties} activeItem={activeItem} cronology={customerInfo.cronology} />
												</div>
												<div
													className={`d-flex justify-content-end mt-4 ${
														mobileDesign && 'my-4'
													}`}>
														{(activeItem !== 'Similares' && activeItem !== 'Historial') &&(
															<Button
															rounded={1}
															className='bg-blue-300 mx-4 border-0'
															type='submit'>
																<div className='d-flex align-items-center'>
																	<UilMessage size={24} color='#ffffff' />
																	<p className='my-0 mx-2 fs-6 fw-normal text-color-white'>
																		Enviar
																	</p>
																</div>
															</Button>
														)}
												</div>
										</Form>
									</Formik>
						</div>
					</div>
				</div>
			</div>
			)}


		</div>
	</div>
	);
};

export default InfoCustomer;