import { Field } from 'formik';
import React, { useEffect, useState, useRef } from 'react';

function InputFloating({
	label = '',
	type = 'text',
	name,
	previousValue,
	touched,
	required = false,
	errorMessage = 'Requerido',
	withDebounce = false,
	changeHandler,
	className = '',
	bgColor = '',
	inputDisabled
}) {

	const [errors, setErrors] = useState(null);
	const [valueInputTemp , setValueInputTemp] = useState('')

	
	useEffect(() => {
		if (!previousValue[name] && required) {
		  setErrors(errorMessage);
		} else {
		  setValueInputTemp(previousValue[name]);
		  setErrors(null);
		}
	  }, [previousValue[name]]);
	
	  const debounceRef = useRef(null);
	
	  const onChange = (value) => {
		setValueInputTemp(value);
	
		if (withDebounce) {
		  if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		  }
	
		  debounceRef.current = setTimeout(() => {
			changeHandler(value, name, type);
		  }, 1000); 
		} else {
		  changeHandler(value, name, type);
		}
	  };

	return (
		<div class='form-floating w-100'>
			<Field
				disabled={inputDisabled}
				type={type}
				className={`form-control ${
					bgColor != '' ? bgColor : 'bg-lblue '
				}text-color-lblue ${className}`}
				name={name}
				id={name}
				// value={previousValue[name]}
				value={valueInputTemp}
				placeholder={name}
				onChange={(e) => onChange(e.target.value, withDebounce ? 1000 : 0)}
			/>
			<label
				className={`${errors && touched[name] ? 'text-color-danger' : 'text-color-lblue'}`}
				for={name}
			>
				{errors && touched[name] ? errors : label}
			</label>
		</div>
	);
}

export default InputFloating;
