import { ArrowCounterClockwise, ArrowLeft, FloppyDisk, Trash } from 'phosphor-react';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
const Tab1 = ({ userData, setUserData }) => {
	const navigate = useNavigate();
	const options_2 = {
		options: {
			stroke: {
				lineCap: 'round',
			},
			fill: {
				colors: ['#7c18f8', '#8676ff', '#01f1e3', '#ffba69', '#ff708b'],
			},
			labels: ['Xintel', 'lagranInmobiliaria', 'TuPortalonline', 'Properati', 'Página Web'],
			plotOptions: {
				radialBar: {
					hollow: {
						size: '55%',
					},
					startAngle: -180,
					dataLabels: {
						showOn: 'always',
						name: {
							show: true,
							color: '#383874',
							fontSize: '12px',
							fontFamily: 'Montserrat", sans-serif',
						},
						value: {
							color: '#000000',
							fontSize: '26px',
							fontWeight: 700,
							show: true,
							offsetX: 30,
							offsetY: 150,
							fontFamily: 'Montserrat", sans-serif',
						},
					},
				},
			},
		},
		series: [3, 9, 15, 23, 25],
	};

	return (
		<>
			<div className='d-flex w-100 h-100'>
				<div className='d-flex col-6 align-items-center justify-content-center'>
					<Chart
						className=''
						options={options_2.options}
						series={options_2.series}
						type='radialBar'
						height={350}
						labels={options_2.options.plotOptions.labels}
					/>
				</div>
				<div className='d-flex col-6'>
					<div className='d-flex col-4 '>
						<div className='d-flex'>
							<div className='d-flex flex-column align-items-end justify-content-center'>
								<p>Página Web</p>
								<p>Properati</p>
								<p>Tuportalonline</p>
								<p>laGraniNmobiliaria</p>
								<p>Xintel</p>
							</div>
						</div>
					</div>
					<div className='d-flex col-6 '>
						<div className='d-flex col-12'>
							<div className='d-flex flex-column justify-content-center col-1'>
								<p>25</p>
								<p>23</p>
								<p>15</p>
								<p>9</p>
								<p>3</p>
							</div>
							<div className='d-flex flex-column justify-content-center col-6'>
								<div className='d-flex'>
									<div className='d-flex flex-column justify-content-center col-8'>
										<p className='py-2'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '50%',
														color: '#ffff',
														backgroundColor: '#FF708B',
													}}
												></div>
											</div>
										</p>
										<p className='py-2'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '40%',
														color: '#ffff',
														backgroundColor: '#FFA63F',
													}}
												></div>
											</div>
										</p>
										<p className='py-2'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '30%',
														color: '#ffff',
														backgroundColor: '#01F1E3',
													}}
												></div>
											</div>
										</p>
										<p className='py-2'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '20%',
														color: '#ffff',
														backgroundColor: '#8676FF',
													}}
												></div>
											</div>
										</p>
										<p className='py-2'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}
											>
												<div
													className='progress-bar'
													style={{
														width: '10%',
														color: '#ffff',
														backgroundColor: '#7517F8',
													}}
												></div>
											</div>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-7 d-flex justify-content-center align-items-center'></div>

				<div className='col-5 d-flex justify-content-center mt-5 '></div>

				<div className='d-flex justify-content-center align-items-center'>
					<span
						className=' fs-4 fw-600 '
						style={{ marginTop: '-200px', marginLeft: '280px', color: '#383874' }}
					>
						TOTAL CONSULTAS: 78
					</span>
				</div>
			</div>
		</>
	);
};

export default Tab1;
