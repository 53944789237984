import React, { useState } from 'react';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';

export const CustomSwitchTabMobile = ({
	name,
	selectedTab,
	setSelectedTab,
	size = '3',
	tabs = [
		{
			id: 1,
			name: 'Valor 1',
		},
		{
			id: 2,
			name: 'Valor 2',
		},
		{
			id: 3,
			name: 'Valor 3',
		},
	],
}) => {
	const [toggle, setToggle] = useState(false);

	let dropdown;

	// Nota para Pablo del futuro / quien sea que esté leyendo esto:
	// El siguiente useEffect se ve un poco feo y desactualizado, pero es
	// absolutamente necesario para switchear entre los tipos de border-radius
	// que tiene que tener el dropdown dependiendo si está desplegado o no.
	// No se puede usar onClick acá porque ese evento ya está siendo ocupado por bootstrap,
	// así que tuve que buscar otra alternativa.
	// Intenté hacerlo con un useRef en lugar de getElementById, pero por alguna razón,
	// no me permite agregarle un event listener al .current del ref.
	// Mas info sobre esto acá https://getbootstrap.com/docs/5.1/components/dropdowns/#events

	React.useEffect(() => {
		dropdown = document.getElementById(`${name}1`);

		dropdown.addEventListener('show.bs.dropdown', function () {
			setToggle(true);
		});

		dropdown.addEventListener('hide.bs.dropdown', function () {
			setToggle(false);
		});
	}, []);

	return (
		<div className='dropdown w-100' id={`${name}1`}>
			<div
				id={name}
				data-bs-toggle='dropdown'
				data-bs-display='static'
				aria-expanded='false'
				className={`bg-blue-300 text-color-white fs-5 fw-bold ${
					toggle ? 'rounded-top' : 'rounded-2'
				} d-flex align-items-center justify-content-between w-100 py-${size} px-${'3'}`}
				type='button'>
				<div className='d-flex justify-content-center w-100'>
					<p className='m-0 ps-4'>{selectedTab}</p>
				</div>
				{toggle ? <UilAngleUp /> : <UilAngleDown />}
			</div>

			<div
				className='dropdown-content dropdown-menu dropdown-menu-search-filter rounded-bottom w-100 bg-lblue shadow'
				aria-labelledby={name}>
				<div className='p-1'>
					{tabs
						.filter((tab) => tab.name !== selectedTab)
						.map((item) => (
							<div
								onClick={() => setSelectedTab(item.name)}
								className='d-flex align-items-center justify-content-center fs-5 fw-bold text-color-lblue p-2 label-CustomSelect cursor-pointer'>
								<label className=''>{item.name}</label>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default CustomSwitchTabMobile;
