import { ArrowCounterClockwise, ArrowLeft, FloppyDisk, Trash } from 'phosphor-react';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/bootstrap/Button';
import { saveOperador } from '../../services/operatorsService';
import Swal from 'sweetalert2';
import Toast from '../../components/Toast/Toast';

const Tab2 = ({ userData, setUserData }) => {
	return (
		<>
			<div className='justify-content-between align-items-center w-100 py-50p'>
				<div className='d-flex '>
					<div className='col-md-6 col-lg-6 col-sm-12 p-5'>
						<h2 className='mt-5'>Venta</h2>
						<div className='col-md-3'>
							<div className='mt-5 d-flex'>
								<div className='col-6 me-5'>
								<p className='fw-600 text-color-violet'>Mail</p>
										<p className='fw-600 text-color-violet'>Whatsapp</p>
										<p className='fw-600 text-color-violet'>Visita</p>
										<p className='fw-600 text-color-violet'>LLamado</p>
								</div>
								<div className='col-md-12 col-lg-6 col-sm-12 ms-5 d-flex flex-column align-items-center ms-0  justify-content-center'>
									<div className='d-flex flex-row'>
									<div className='col-9'>
											<span className='ms-5 float-start'>23</span>
										</div>
										<div className='col-12 ms-4 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}>
												<div
													className='progress-bar'
													style={{
														width: '40%',
														color: '#ffff',
														backgroundColor: '#FF708B',
													}}></div>
											</div>
										</div>
									</div>

									<div className='d-flex mt-3 '>
									<div className='col-9'>
											<span className='ms-5 float-start'>15</span>
										</div>
										<div className='col-12 ms-4 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}>
												<div
													className='progress-bar'
													style={{
														width: '30%',
														color: '#ffff',
														backgroundColor: '#FFA63F',
													}}></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
										<div className='col-9'>
											<span className='ms-5 float-start'>9</span>
										</div>

										<div className='col-12 ms-4 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}>
												<div
													className='progress-bar'
													style={{
														width: '20%',
														color: '#ffff',
														backgroundColor: '#01F1E3',
													}}></div>
											</div>
										</div>
									</div>
									<div className='d-flex mt-3 '>
									<div className='col-9'>
											<span className='ms-5 float-start'>3</span>
										</div>
										<div className='col-12 ms-4 mt-1'>
											<div
												className='progress'
												role='progressbar'
												aria-label='Example 1px high'
												aria-valuenow='25'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{ height: '8px' }}>
												<div
													className='progress-bar'
													style={{
														width: '10%',
														color: '#ffff',
														backgroundColor: '#8676FF',
													}}></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<h3 className='mt-5 text-color-blue'>TOTAL CONSULTAS: 78</h3>
					</div>
					<div className='col-md-6 col-lg-6 col-sm-12  border-start p-5'>
						<div className='col-md-6 col-lg-6 col-sm-12'>
							<h2 className='mt-5'>Alquiler</h2>
							<div className='col-md-6 col-lg-6 col-sm-12'>
								<div className='mt-5 d-flex'>
									<div className='col-6 me-5'>
									<p className='fw-600 text-color-violet'>Mail</p>
										<p className='fw-600 text-color-violet'>Whatsapp</p>
										<p className='fw-600 text-color-violet'>Visita</p>
										<p className='fw-600 text-color-violet'>LLamado</p>
									</div>
									<div className='col-10 ms-5 d-flex flex-column '>
										<div className='d-flex flex-row'>
											<span className='ms-5'>23</span>
										</div>

										<div className='d-flex mt-3 '>
											<span className='ms-5'>15</span>
										</div>
										<div className='d-flex mt-3 '>
											<span className='ms-5'>9</span>
										</div>
										<div className='d-flex mt-3 '>
											<span className='ms-5'>3</span>
										</div>
									</div>
								</div>
							</div>
							<h3 className='d-flex mt-5 fw-600 text-color-violet'>TOTAL CONSULTAS: 78</h3>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Tab2;
