import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import {
	UilCog,
	UilEnvelopeAlt,
	UilUser,
	UilWhatsapp,
	UilSchedule,
} from '@iconscout/react-unicons';
import SearchFilter from '../../components/Owner/SearchFilter';
import { Form, Formik } from 'formik';
import Check from '../../components/Check';
import Contador from '../../components/Owner/Contador';
import Switcher from 'react-switcher-rc';

const TareaNovedades = ({ userData, setUserData }) => {
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);
	const [switcherState, setSwitcherState] = useState(false);
	const onHandleChange = (e) => setSwitcherState(e.target.checked);

	// onFileChange = (event) => {
	// 		// onChange={(e) => setFile(URL.createObjectURL(e.target.files[0]),checkFile())}
	// 		//setPreviewImage(URL.createObjectURL(e.target.files[0]);
	// 	// Update the state
	// 	//setFile(event.target.files[0]);

	//   }

	//   onFileUpload = () => {

	// 	// Create an object of formData
	// 	const formData = new FormData();

	// 	// Update the formData object
	// 	formData.append(
	// 	  "profile_image",
	// 	  file,
	// 	  file.name
	// 	);

	// 	// Request made to the backend api
	// 	// Send formData object
	// 	axios.post("api/uploadfile", formData);
	//   };

	function checkFile() {
		// URL.createObjectURL(file)
		// // setPreview(objectUrl)
		// console.log(window.URL.createObjectURL(dataURLToBlob(file)))
	}

	function dataURLToBlob(dataurl) {
		let arr = dataurl.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							<Card className=''>
								<CardHeader className='border-bottom'>
									<CardTitle tag='h4' className='h5 text-color-lblue'>
										<div className='d-flex align-items-center'>
											<UilCog className='ms-3' />
											<p className='my-0 mx-2'>GENERALES</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody className='p-0  table-font-12'>
									<div className='d-flex w-100 bg-new-gray py-2 justify-content-center align-items-center'>
										<div className='col-6 d-flex align-items-center '>
											<div className='col-6 ms-5'>
												Agenda correos de bienvenida/presentación
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex align-items-center '>
											<div className='col-7 ms-5'>
												Agenda correos a posibles interesados
											</div>
											<Check />
										</div>
									</div>
									<div className='d-flex w-100 py-2 justify-content-center align-items-center'>
										<div className='col-6 d-flex align-items-center '>
											<div className='col-6 ms-5'>
												Ocultar tarea SU PEDIDO en agenda diaria
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex align-items-center '>
											<div className='col-7 ms-5'>
												Ordenar agenda diaria por fecha y hora ascendente
											</div>
											<Check />
										</div>
									</div>
									<div className='d-flex w-100 py-2 bg-new-gray   align-items-center'>
										<div className='col-6 d-flex align-items-center '>
											<div className='col-4 ms-5'>
												Visualiza tareas no realizadas de
											</div>
											<Contador />
											<span className='ms-3'>días anteriores</span>
										</div>
									</div>
								</CardBody>
							</Card>
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilSchedule className='ms-3' />
													<p className='my-0 mx-2'>
														GENERACIÓN DE NOVEDADES - TAREAS
													</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='p-0 '>
											<div className='d-flex '>
												<div className='d-flex align-items-center w-100 p-0'>
													<table className='table table-borderless p-0 '>
														<thead
															className=' bg-lblue p-0'
															style={{ height: '52px' }}
														>
															<th className='text-color-lblue text-center'>
																Novedad
															</th>
															<th className='text-color-lblue'>
																Tarea
															</th>
															<th className='col-3 text-color-lblue'>
																Descripcion
															</th>
															<th className='text-color-lblue'>
																Perfiles
															</th>
															<th className=' col-2 text-color-lblue text-center'>
																Dias de anticipo
															</th>
														</thead>
														<tbody>
															<tr className='bg-new-gray'>
																<td className='d-flex justify-content-center'>
																	<Check />
																</td>
																<td className=''>
																	<Check />
																</td>
																<td className='col-2'>
																	Altas de propiedades
																</td>
																<td className='col-2'>
																	<SearchFilter
																		className='col-2'
																		style={{
																			backgroundColor:
																				'red !important',
																		}}
																		text={'Operadores'}
																		name={'Operación'}
																		nameEng={'Operation'}
																		changeHandlerMultipleSelect={() =>
																			console.log('')
																		}
																		data={userData}
																		bgColor={''}
																		withSearch={false}
																		values={[
																			{
																				id: 1,
																				label: 'Alquiler',
																			},
																			{
																				id: 2,
																				label:
																					'Alquiler temporario ',
																			},
																			{
																				id: 3,
																				label: 'Venta',
																			},
																		]}
																	/>
																</td>
																<td className=' d-flex justify-content-center'>
																	<input className='input-filters-menu rounded-1 shadow-inputs col-3 p-1' />
																</td>
															</tr>
															<tr>
																<td className='d-flex justify-content-center'>
																	<Check />
																</td>
																<td className=''>
																	<Check />
																</td>
																<td className='col-2'>
																	Altas de emprendimientos
																</td>
																<td className='col-2'>
																	<SearchFilter
																		className='col-2'
																		style={{
																			backgroundColor:
																				'red !important',
																		}}
																		text={'Operadores'}
																		name={'Operación'}
																		nameEng={'Operation'}
																		changeHandlerMultipleSelect={() =>
																			console.log('')
																		}
																		data={userData}
																		bgColor={''}
																		withSearch={false}
																		values={[
																			{
																				id: 1,
																				label: 'Alquiler',
																			},
																			{
																				id: 2,
																				label:
																					'Alquiler temporario ',
																			},
																			{
																				id: 3,
																				label: 'Venta',
																			},
																		]}
																	/>
																</td>
																<td className=' d-flex justify-content-center'>
																	<input className='input-filters-menu rounded-1 shadow-inputs col-3 p-1' />
																</td>
															</tr>
															<tr className='bg-new-gray'>
																<td className='d-flex justify-content-center'>
																	<Check />
																</td>
																<td className=''>
																	<Check />
																</td>
																<td className='col-2'>
																	Altas de propiedades
																</td>
																<td className='col-2'>
																	<SearchFilter
																		className='col-2'
																		style={{
																			backgroundColor:
																				'red !important',
																		}}
																		text={'Operadores'}
																		name={'Operación'}
																		nameEng={'Operation'}
																		changeHandlerMultipleSelect={() =>
																			console.log('')
																		}
																		data={userData}
																		bgColor={''}
																		withSearch={false}
																		values={[
																			{
																				id: 1,
																				label: 'Alquiler',
																			},
																			{
																				id: 2,
																				label:
																					'Alquiler temporario ',
																			},
																			{
																				id: 3,
																				label: 'Venta',
																			},
																		]}
																	/>
																</td>
																<td className=' d-flex justify-content-center'>
																	<input className='input-filters-menu rounded-1 shadow-inputs col-3 p-1' />
																</td>
															</tr>
															<tr>
																<td className='d-flex justify-content-center'>
																	<Check />
																</td>
																<td className=''>
																	<Check />
																</td>
																<td className='col-2'>
																	Altas de propiedades
																</td>
																<td className='col-2'>
																	<SearchFilter
																		className='col-2'
																		style={{
																			backgroundColor:
																				'red !important',
																		}}
																		text={'Operadores'}
																		name={'Operación'}
																		nameEng={'Operation'}
																		changeHandlerMultipleSelect={() =>
																			console.log('')
																		}
																		data={userData}
																		bgColor={''}
																		withSearch={false}
																		values={[
																			{
																				id: 1,
																				label: 'Alquiler',
																			},
																			{
																				id: 2,
																				label:
																					'Alquiler temporario ',
																			},
																			{
																				id: 3,
																				label: 'Venta',
																			},
																		]}
																	/>
																</td>
																<td className=' d-flex justify-content-center'>
																	<input className='input-filters-menu rounded-1 shadow-inputs col-3 p-1' />
																</td>
															</tr>
															<tr className='bg-new-gray'>
																<td className='d-flex justify-content-center'>
																	<Check />
																</td>
																<td className=''>
																	<Check />
																</td>
																<td className='col-2'>
																	Altas de propiedades
																</td>
																<td className='col-2'>
																	<SearchFilter
																		className='col-2'
																		style={{
																			backgroundColor:
																				'red !important',
																		}}
																		text={'Operadores'}
																		name={'Operación'}
																		nameEng={'Operation'}
																		changeHandlerMultipleSelect={() =>
																			console.log('')
																		}
																		data={userData}
																		bgColor={''}
																		withSearch={false}
																		values={[
																			{
																				id: 1,
																				label: 'Alquiler',
																			},
																			{
																				id: 2,
																				label:
																					'Alquiler temporario ',
																			},
																			{
																				id: 3,
																				label: 'Venta',
																			},
																		]}
																	/>
																</td>
																<td className=' d-flex justify-content-center'>
																	<input className='input-filters-menu rounded-1 shadow-inputs col-3 p-1' />
																</td>
															</tr>
															<tr>
																<td className='d-flex justify-content-center'>
																	<Check />
																</td>
																<td className=''>
																	<Check />
																</td>
																<td className='col-2'>
																	Altas de propiedades
																</td>
																<td className='col-2'>
																	<SearchFilter
																		className='col-2'
																		style={{
																			backgroundColor:
																				'red !important',
																		}}
																		text={'Operadores'}
																		name={'Operación'}
																		nameEng={'Operation'}
																		changeHandlerMultipleSelect={() =>
																			console.log('')
																		}
																		data={userData}
																		bgColor={''}
																		withSearch={false}
																		values={[
																			{
																				id: 1,
																				label: 'Alquiler',
																			},
																			{
																				id: 2,
																				label:
																					'Alquiler temporario ',
																			},
																			{
																				id: 3,
																				label: 'Venta',
																			},
																		]}
																	/>
																</td>
																<td className=' d-flex justify-content-center'>
																	<input className='input-filters-menu rounded-1 shadow-inputs col-3 p-1' />
																</td>
															</tr>
															<tr className='bg-new-gray'>
																<td className='d-flex justify-content-center'>
																	<Check />
																</td>
																<td className=''>
																	<Check />
																</td>
																<td className='col-2'>
																	Altas de propiedades
																</td>
																<td className='col-2'>
																	<SearchFilter
																		className='col-2'
																		style={{
																			backgroundColor:
																				'red !important',
																		}}
																		text={'Operadores'}
																		name={'Operación'}
																		nameEng={'Operation'}
																		changeHandlerMultipleSelect={() =>
																			console.log('')
																		}
																		data={userData}
																		bgColor={''}
																		withSearch={false}
																		values={[
																			{
																				id: 1,
																				label: 'Alquiler',
																			},
																			{
																				id: 2,
																				label:
																					'Alquiler temporario ',
																			},
																			{
																				id: 3,
																				label: 'Venta',
																			},
																		]}
																	/>
																</td>
																<td className=' d-flex justify-content-center'>
																	<input className='input-filters-menu rounded-1 shadow-inputs col-3 p-1' />
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							{/* Footer */}
							{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default TareaNovedades;
