import { useState, useRef } from 'react';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import useDetectOutsideClick from '../hooks/useDetectOutsideClick';

export default function CustomDropdown({
	label,
	classname,
	children,
	iconColor = '#506CF9',
	dropdownClassname,
	margin,
	padding,
	width,
	closeAfterClick = false,
	icon,
	arrow = true,
	isRounded = true
}) {
	const [isOpen, setIsOpen] = useState(false);

	const ref = useRef(null);

	useDetectOutsideClick(ref, () => setIsOpen(false));

	return (
		<div className={`position-relative ${margin} ${padding} ${width}`} ref={ref}>
			<div
				className={`d-flex align-items-center justify-content-between ${
					isOpen && isRounded ? 'rounded-top' : 'rounded'
				} ${classname}`}
				onClick={() => setIsOpen(!isOpen)}>
				{icon}<span>  {label}</span>
				{arrow && (
					<>
						{isOpen ? (
							<UilAngleUp color={iconColor} size={24} />
						) : (
							<UilAngleDown color={iconColor} size={24} />
						)}
					</>
				)}
			</div>
			{isOpen && (
				<div
					className={`${dropdownClassname}`}
					style={{ zIndex: 9999 }}
					onClick={() => {
						if (closeAfterClick) return setIsOpen(false);
						else return;
					}}>
					{children}
				</div>
			)}
		</div>
	);
}
