import React from 'react';

const CustomBell = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			fill='#ffffff'
			viewBox='0 0 256 256'
			className='svg-icon'>
			<rect width='256' height='256' fill='none'></rect>
			<path
				d='M56.2,104a71.9,71.9,0,0,1,72.3-72c39.6.3,71.3,33.2,71.3,72.9V112c0,35.8,7.5,56.6,14.1,68a8,8,0,0,1-6.9,12H49a8,8,0,0,1-6.9-12c6.6-11.4,14.1-32.2,14.1-68Z'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></path>
			<path
				d='M96,192v8a32,32,0,0,0,64,0v-8'
				fill='none'
				stroke='#ffffff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='16'></path>
		</svg>
	);
};

export default CustomBell;
