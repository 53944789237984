import * as React from 'react';

const CommentInfo = (props) => (
	<svg 
    xmlns='http://www.w3.org/2000/svg' 
    viewBox='0 0 24 24'
    // style={{
		// 	enableBackground: 'new 0 0 512 512',
		// }}
		xmlSpace='preserve'
		width='24px'
		height='24px'
		className='svg-icon'
		{...props}>
		<path
			fill='#506CF9'
			d='M12,11a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0V12A1,1,0,0,0,12,11Zm0-3a1,1,0,1,0,1,1A1,1,0,0,0,12,8Zm0-6A10,10,0,0,0,2,12a9.89,9.89,0,0,0,2.26,6.33l-2,2a1,1,0,0,0-.21,1.09A1,1,0,0,0,3,22h9A10,10,0,0,0,12,2Zm0,18H5.41l.93-.93a1,1,0,0,0,.3-.71,1,1,0,0,0-.3-.7A8,8,0,1,1,12,20Z'
		/>
	</svg>
);

export default CommentInfo;
