import React, { useState } from 'react';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';

export default function Description() {
	const [showMore, setShowMore] = useState(false);
	return (
		<>
			{showMore && (
				<textarea className='bg-lblue rounded no-outline border-0 w-100 innerShadowBlueSmall no-resize min-vh-25' />
			)}
			<div onClick={() => setShowMore(!showMore)} className='cursor-pointer'>
				{showMore ? (
					<UilAngleUp color='#506CF9' size={50} />
				) : (
					<UilAngleDown color='#506CF9' size={50} />
				)}
			</div>
		</>
	);
}
