import React from 'react';
import OperationType from './OperationType';
import GeneralInfo from './GeneralInfo';
import Description from './Description';
import Features from './Features';
import Measures from './Measures';
import Multimedia from './Multimedia';

export default function ChildrenSelector({
	selectedStep,
	setSelectedStep,
	estateData,
	setEstateData,
}) {
	switch (selectedStep) {
		case 'operationType':
			return (
				<OperationType
					estateData={estateData}
					setEstateData={setEstateData}
					setSelectedStep={setSelectedStep}
				/>
			);
		case 'general':
			return (
				<GeneralInfo
					estateData={estateData}
					setEstateData={setEstateData}
					setSelectedStep={setSelectedStep}
				/>
			);
		case 'description':
			return (
				<Description
					estateData={estateData}
					setEstateData={setEstateData}
					setSelectedStep={setSelectedStep}
				/>
			);
		case 'features':
			return (
				<Features
					estateData={estateData}
					setEstateData={setEstateData}
					setSelectedStep={setSelectedStep}
				/>
			);
		case 'measures':
			return (
				<Measures
					estateData={estateData}
					setEstateData={setEstateData}
					setSelectedStep={setSelectedStep}
				/>
			);
		case 'multimedia':
			return (
				<Multimedia
					estateData={estateData}
					setEstateData={setEstateData}
					setSelectedStep={setSelectedStep}
				/>
			);

		default:
			return <h1>default</h1>;
	}
}
