import React from 'react';
import InputFloating from '../../../components/InputFloating';
import SwitchThreeOptions from '../../../components/SwitchThreeOptions';
import SearchFilter from '../../../components/Owner/SearchFilter';
import InputCounter from '../../../components/InputCounter';
import CustomSelect from '../../../components/CustomSelect';

export default function InputSelector({
	data,
	touched,
	item,
	changeHandler,
	changeHandlerMultipleSelect,
}) {
	switch (item.type) {
		case 'multipleSelect':
			return (
				<SearchFilter
					text={item.label}
					name={item.label}
					nameEng={item.name}
					// values={null}
					changeHandlerMultipleSelect={() => console.log('Filtro aplicado!')}
					data={data}
				/>
			);
		case 'doubleDate':
			return (
				<div className='d-flex align-items-center w-100'>
					<InputFloating
						changeHandler={() => console.log('Filtro aplicado!')}
						label='Fecha de ingreso | Desde'
						name='registerDateFrom'
						type='date'
						previousValue={data}
						touched={touched}
						withDebounce
						className='rounded-0 rounded-start'
					/>
					<InputFloating
						changeHandler={() => console.log('Filtro aplicado!')}
						label='Fecha de ingreso | Hasta'
						name='registerDateTo'
						type='date'
						previousValue={data}
						touched={touched}
						withDebounce
						className='rounded-0 rounded-end'
					/>
				</div>
			);

		case 'text':
			return (
				<InputFloating
					changeHandler={() => console.log('Filtro aplicado!')}
					label={item.label}
					name={item.name}
					type='text'
					previousValue={data}
					touched={touched}
					withDebounce
				/>
			);
		case 'rangeWithSelect':
			return (
				<div className='d-flex align-items-center w-100'>
					<CustomSelect
						item={item}
						data={data}
						text={item.label}
						name={item.label}
						nameEng={item.name}
					/>

					<InputFloating
						changeHandler={() => console.log('Filtro aplicado!')}
						label={item.label}
						name={item.name}
						type='text'
						previousValue={data}
						touched={touched}
						withDebounce
						className='rounded-0'
					/>
					<InputFloating
						changeHandler={() => console.log('Filtro aplicado!')}
						label={item.label}
						name={item.name}
						type='text'
						previousValue={data}
						touched={touched}
						withDebounce
						className='rounded-0 rounded-end'
					/>
				</div>
			);

		case 'counter':
			return <InputCounter label={item.label} />;

		case 'conditional':
			return <SwitchThreeOptions label={item.label} />;
		default:
			return <h5 className='mb-0'>Type not supported</h5>;
	}
}
