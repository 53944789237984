import React, { useState, useContext } from 'react';
import { UilFilter, UilSetting, UilExclamationCircle } from '@iconscout/react-unicons';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import FiltersDrawer from './FiltersDrawer';
import { Form, Formik } from 'formik';
import InputSelector from './InputSelector';
import ThemeContext from '../../../contexts/themeContext';

export default function FiltersMenu({ filtersApplied, setFiltersApplied, setFiltersMenu }) {
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const menuItems = [
		{
			name: 'categories',
			label: 'Categorías',
			type: 'multipleSelect',
			isActive: true,
		},
		{
			name: 'neighborhood',
			label: 'Barrios',
			type: 'multipleSelect',
			isActive: true,
		},
		{
			name: 'type',
			label: 'Tipo',
			type: 'multipleSelect',
			isActive: true,
		},
		{
			name: 'elevators',
			label: 'Ascensores',
			type: 'multipleSelect',
			isActive: true,
		},
		{
			name: 'state',
			label: 'Estado',
			type: 'multipleSelect',
			isActive: true,
		},
		{
			name: 'salesman',
			label: 'Vendedor',
			type: 'multipleSelect',
			isActive: true,
		},
		{
			name: 'publishedIn',
			label: 'Publicada en',
			type: 'multipleSelect',
			isActive: true,
		},
		{
			name: 'branch',
			label: 'Sucursal',
			type: 'multipleSelect',
			isActive: true,
		},
		{
			name: 'onlyWithVideo',
			label: 'Sólo con video',
			type: 'conditional',
			isActive: true,
		},
		{
			name: 'onlyWithPDF',
			label: 'Sólo con PDF',
			type: 'conditional',
			isActive: true,
		},
		{
			name: 'onlyWithPhotos',
			label: 'Sólo con fotos',
			type: 'conditional',
			isActive: true,
		},
		{
			name: 'onlyFeatured',
			label: 'Sólo publicadas',
			type: 'conditional',
			isActive: true,
		},
	];

	const [fields, setFields] = useState(menuItems);

	const { mobileDesign } = useContext(ThemeContext);

	return (
		<div className='d-flex flex-column bg-white m-4 rounded min-vh-50 shadow-dark'>
			<FiltersDrawer
				offcanvasStatus={offcanvasStatus}
				setOffcanvasStatus={setOffcanvasStatus}
				menuItems={fields}
				setFields={setFields}
			/>
			<Formik initialValues={filtersApplied}>
				{({ touched }) => (
					<Form>
						<div
							className='d-flex flex-column w-100 align-items-start p-4 border-bottom cursor-pointer'
							onClick={() => setFilterType('simple')}>
							<div className='d-flex align-items-center justify-content-between w-100'>
								<div className='d-flex align-items-center w-100'>
									<UilFilter color='#506cf9' size={27} />
									<h4 className='mb-0 mx-2 text-color-lblue fw-bold'>FILTROS</h4>
								</div>

								{!mobileDesign && (
									<div className='d-flex align-items-center justify-content-end w-100'>
										<Button
											color='primary'
											className='me-4'
											rounded={1}
											onClick={() => setOffcanvasStatus(!offcanvasStatus)}>
											<div className='d-flex align-items-center'>
												<Icon icon='CustomSettingsAdjust' />
												<p className='my-0 ms-2'>Personalizar</p>
											</div>
										</Button>
									</div>
								)}
							</div>

							<>
								<div className='d-flex flex-wrap justify-content-start w-100 my-4'>
									{fields
										.filter(
											(item) =>
												item.isActive === true &&
												(mobileDesign ? item.type !== 'conditional' : item),
										)
										.map((item) => (
											<div
												className={`d-flex align-items-center ${
													mobileDesign ? 'col-12' : 'col-4'
												} p-4`}>
												<InputSelector
													data={filtersApplied}
													touched={touched}
													item={item}
												/>
											</div>
										))}
								</div>

								<div className='d-flex align-items-center justify-content-end w-100'>
									<Button
										color='primary'
										className='me-4'
										rounded={1}
										onClick={() => setFiltersMenu(false)}>
										<div className='d-flex align-items-center'>
											<Icon icon='CustomFilter' />
											<p className='my-0 ms-2'>Filtrar</p>
										</div>
									</Button>
								</div>
							</>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
