import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFileCustomer } from '../../../services/customersServices';
import UserContext from '../../../contexts/userContext';
import { UilUpload } from '@iconscout/react-unicons';
import ProrgessBar from '../../../utils/prorgessBar';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../components/Toast/toastUtils/toastUtils';
import Toast from '../../../components/Toast/Toast';
import Outline from '../../../assets/img/outline.png'

import pdf from '../../../assets/img/fileExtension/pdf.svg'
import doc from '../../../assets/img/fileExtension/doc.svg'
import zip from '../../../assets/img/fileExtension/zip.svg'
import rar from '../../../assets/img/fileExtension/rar.svg'
import bmp from '../../../assets/img/fileExtension/bmp.svg'
import csv from '../../../assets/img/fileExtension/csv.svg'
import def from '../../../assets/img/fileExtension/default.svg'

const MyDropzone = ({setFiles , mobileDesign }) => {
const [progress, setProgress] = useState([]);
const { token } = useContext(UserContext);
const [isActivDrop , setIsActivDrop] = useState(false)
const { id } = useParams();
const [list, setList] = useState([])

let message ;


const handleDragEnter = (event) => {
    event.preventDefault();
    setIsActivDrop(true);
};

const handleDragLeave = (event) => {
    event.preventDefault();
    setIsActivDrop(false);
  };


const handleDrop = async (event) => {
event.preventDefault();
setIsActivDrop(false);
}

const extensionToImage = {
   pdf,
   doc,
   zip,
   rar,
   bmp,
   csv,
   def
  };

  const onDrop = useCallback((acceptedFiles) => {

    if(acceptedFiles.length > 6){
        showToast('No se aceptan mas de 6 archivos a la vez', setList, 'danger')
        return
    }

    acceptedFiles.forEach((file) => {
        const fileName = file.name;
        const size = file.size

        if (fileName.indexOf('.') === -1) {
            showToast('No se permiten archivos sin extensión.', setList,'danger' );
            return;
        }
        if(size > 8388608) {
            showToast('La longitud del archivo no puede superar los 8 MB.', setList,'danger' );
            return;
        }
        
        if (!(file instanceof Blob)) {
            showToast('El archivo no es válido.', setList, 'danger' );
            return;
        }

        uploadFileCustomer(file, id, token, setProgress).then((result) => {
            setFiles((prevFiles) => [...prevFiles, result.data.data]);
            message = result.data.message;
            showToast(message, setList,'success');

      }).catch((error) => {
        message = error.response.data.errors.file.file[0];
        showToast(message, setList,'danger');
        });
    });
  }, []);
  
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  
  return (
    <div className='cursor-pointer'>
     <Toast toastlist={list} position="top-right" setList={setList} />
    <div className='d-flex ' {...getRootProps()}>
      <input {...getInputProps()} />
        <div
            className={`d-flex flex-column ${progress.length > 0 ? 'justify-content-center px-3 ' : 'align-items-center justify-content-center'} min-vh-20 w-100 ${
                !mobileDesign && 'border-dashed'
            } rounded`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={(handleDrop)}
            style={{borderWidth: '3px'}}>
               {progress.length > 0 ?
                <div className='w-100 h-100 d-flex flex-wrap  align-items-center'>
                    {progress.map(({ id, file, progress }) => {
                        const fileExtension = file.filename? file.filename.split('.').pop() : file.name.split('.').pop();
                        let imagePath;
                        if(fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg' ) {
                            imagePath = URL.createObjectURL(file);
                        } 
                        else{
                            imagePath = extensionToImage[fileExtension] || extensionToImage.def;
                        }
                        return(
                            <div className=' mx-1  d-flex flex-wrap flex-column ' key={id}>
                            <div style={{ width: '120px', position: 'relative' }}>
                            <img className='rounded-2' src={imagePath} alt='nada' style={{ maxHeight: '120px', maxWidth: '120px', filter: 'blur(2px)' }} />
                                <div className='h-25 ' style={{ position: 'absolute', top: '50%', left: '15%', width: '70%', transform: 'translateY(-50%)' }}>
                                     <ProrgessBar progress={progress} />
                                </div>
                            </div>
                            </div>
                            )
                        })}
                </div> : 
                    <>
                        { isActivDrop || mobileDesign ? <>
                            <img src={Outline} alt='outline' />
                        </> :
                            <>
                            <p className="fs-5 fw-normal-bold">Soltar archivos aquí o haga click para cargar</p>
                        <p className="fs-5 fw-normal-bold">(Se aceptan archivos pdf, doc, zip, png, jpg, jpeg, bmp, csv, rar)</p>
                        </>}
                    </>
                }
                        
         </div>

    </div>
    
</div>
  );
};

export default MyDropzone;
