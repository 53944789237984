import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Notepad } from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { Form, Formik } from 'formik';

const Create = ({ userData, setUserData}) => {
	
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview,setPreview] = useState(null);

	// onFileChange = (event) => {
	// 		// onChange={(e) => setFile(URL.createObjectURL(e.target.files[0]),checkFile())}
	// 		//setPreviewImage(URL.createObjectURL(e.target.files[0]);
	// 	// Update the state
	// 	//setFile(event.target.files[0]);
	   
	//   }

	//   onFileUpload = () => {
     
	// 	// Create an object of formData
	// 	const formData = new FormData();
	   
	// 	// Update the formData object
	// 	formData.append(
	// 	  "profile_image",
	// 	  file,
	// 	  file.name
	// 	);
	
	   
	// 	// Request made to the backend api
	// 	// Send formData object
	// 	axios.post("api/uploadfile", formData);
	//   };

	function checkFile(){
		// URL.createObjectURL(file)
		// // setPreview(objectUrl)
		// console.log(window.URL.createObjectURL(dataURLToBlob(file)))
	}

	function dataURLToBlob(dataurl) {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							<Card className='p-2'>
								<CardBody>
									<div className='d-flex col-12'>
										<div className='d-flex flex-column col-1'>
											<div className='d-flex col-12'>
												<div className='d-flex align-items-center py-2 col-2'>
													<div className={`${file ? 'p-0' : 'p-5'} rounded-circle shadow-img bg-blue-200`} >
														{
														file ? <img className='d-block rounded-circle'  width="95" height="95"  src={file} />
														: <span className='fs-img-name'>PN</span>
														}
														
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-column col-1 justify-content-start pt-2'>
											<label
												className='py-2 px-3 rounded-1 bg-white border-1 fw-600 cursor-pointer'
												style={{ border: '2px solid #506cf9' }} htmlFor="file"> Seleccion archivo</label>
											<div className='d-flex align-items-center justify-content-center '>
												<input
													className='py-2 px-3 rounded-1 bg-white border-1 fw-600'
													type='file'
													id="file"
													hidden	
												
													onChange={()=>console.log("falta")}
													value={''}
												/>
												{/* <p
														className='my-0 mx-2 fs-6 text-color-lblue'
														style={{ whiteSpace: 'nowrap' }}>
														Seleccionar Archivo
													</p> */}
											</div>
											{/* </button> */}
											<Button
												color='primary'
												className='mt-3 rounded-1'
												rounded={1}>
												<div className='d-flex align-items-center'>
													<Icon icon='Trash' size='50' />
													<p className='my-0 ms-2'>Eliminar Foto</p>
												</div>
											</Button>
										</div>
										<div className='d-flex flex-column justify-content-center  col-5'>
											<div className='d-flex align-items-start   mx-5 col'>
												<div className='d-flex flex-column col-12'>
													<div className='d-flex align-items-center col-12 py-2 form-floating'>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col p-3'
															label='Nombre'
															name='name'
															type='text'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={'Nombre Requerido'}
														/>
														{/* <label for="floatingInput">Nombre</label>
											<input
												className='form-control input-filters-menu shadow-inputs rounded col-12 p-3 '
												placeholder='Nombre' id ="floatingInput"
											/> */}
													</div>
													<div className='d-flex align-items-center col-12 py-2'>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col p-3'
															label='DNI'
															name='dni'
															type='number'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={'DNI Requerido'}
															onChange={(e) => {
																save(e);
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-column justify-content-center col-5'>
											<div className='d-flex col align-items-start '>
												<div className='d-flex flex-column col-12'>
													<div className='d-flex align-items-center  col-12 py-2'>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col p-3'
															label='Apellido'
															name='surname'
															type='text'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={'Apellido Requerido'}
															onChange={(e) => {
																save(e);
															}}
														/>
													</div>
													<div className='d-flex align-items-center  col-12 py-2'>
														{/* <input
															className='input-filters-menu shadow-inputs rounded col-12 p-3'
															placeholder='Fecha Nacimiento'
															id='birthday'
															type='date'
															onChange={(e) => {
																save(e);
															}}
														/> */}
														<InputFloating
															label='Fecha de nacimiento'
															name='birthday'
															type='date'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={
																'Fecha Nacimiento Requerido'
															}
															onChange={(e) => {
																save(e);
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className='p-2'>
										<CardHeader>
											<CardTitle tag='h4' className='h5'>
												<div className='d-flex align-items-center'>
													<Notepad size={40} color='#01227d' />
													<p className='my-0 mx-2'>Contacto</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody>
											<div className='d-flex col-12'>
												<div className='d-flex flex-column  col-6'>
													<div className='d-flex col-12 px-2 mx-2'>
														<div className='d-flex align-items-center  col-12 py-2'>
															{/* <input
															className='input-filters-menu rounded shadow-inputs col-12 p-3'
															placeholder='Número de teléfono'
															onChange={(e) => {
																save(e);
															}}
														/> */}
															<InputFloating
																label='Teléfono'
																name='phone'
																type='Number'
																setFunction={setUserData}
																previousValue={userData}
																touched={touched}
																required
																errorMessage={'Telefono Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
														<div className='d-flex align-items-center mx-3  col-12 py-2'>
															{/* <input
																className='input-filters-menu rounded shadow-inputs col-11 p-3'
																placeholder='Dirección E-Mail'
															/> */}
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='email'
																name='email'
																type='email'
																setFunction={setUserData}
																previousValue={userData}
																touched={touched}
																required
																errorMessage={'Email Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>
							<div className='d-flex'>
								<div className='col-12'>
									<Card className='p-2'>
										<CardHeader>
											<CardTitle tag='h4' className='h5'>
												<div className='d-flex align-items-center'>
													<Notepad size={40} color='#01227d' />
													<p className='my-0 mx-2'>Nueva Contraseña</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody>
											<div className='d-flex col-12'>
												<div className='d-flex flex-column mx-2 col-6'>
													<div className='d-flex col-12 px-2'>
														<div className='d-flex align-items-center   col-12 py-2'>
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Contraseña'
																name='password'
																type='password'
																setFunction={setUserData}
																previousValue={userData}
																touched={touched}
																required
																errorMessage={
																	'Contraseña Requerido'
																}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
														<div className='d-flex align-items-center mx-3  col-12 py-2'>
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Confirmar Contraseña'
																name='password'
																type='password'
																setFunction={setUserData}
																previousValue={userData}
																touched={touched}
																required
																errorMessage={
																	'Confirme Contraseña Requerido'
																}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							{/* Footer */}
							{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Create;
