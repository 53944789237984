import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';

const PhonePrefixSelect = ({
	text = '',
	name,
	nameEng,
	data,
	size = '3',
	changeHandler,
	inputDisabled,
	values = [
		{
			id: 1,
			// value: '+54',
			value: 54,
			label: (
				<div className='d-flex align-items-center'>
					<ReactCountryFlag
						svg
						countryCode='AR'
						style={{
							fontSize: '2em',
							lineHeight: '2em',
						}}
					/>
					<p className='m-0 mx-2'>+54</p>
				</div>
			),
		},
		{
			id: 2,
			// value: '+55',
			value: 55,
			label: (
				<div className='d-flex align-items-center'>
					<ReactCountryFlag
						svg
						countryCode='BR'
						style={{
							fontSize: '2em',
							lineHeight: '2em',
						}}
					/>
					<p className='m-0 mx-2'>+55</p>
				</div>
			),
		},
		{
			id: 3,
			// value: '+598',
			value: 598,
			label: (
				<div className='d-flex align-items-center'>
					<ReactCountryFlag
						svg
						countryCode='UY'
						style={{
							fontSize: '2em',
							lineHeight: '2em',
						}}
					/>
					<p className='m-0 mx-2'>+598</p>
				</div>
			),
		},
	],
}) => {
	const [toggle, setToggle] = useState(false);

	const clickHandler = (item) => {
		changeHandler(item.value, nameEng);
	};

	let dropdown;

	// Nota para Pablo del futuro / quien sea que esté leyendo esto:
	// El siguiente useEffect se ve un poco feo y desactualizado, pero es
	// absolutamente necesario para switchear entre los tipos de border-radius
	// que tiene que tener el dropdown dependiendo si está desplegado o no.
	// No se puede usar onClick acá porque ese evento ya está siendo ocupado por bootstrap,
	// así que tuve que buscar otra alternativa.
	// Intenté hacerlo con un useRef en lugar de getElementById, pero por alguna razón,
	// no me permite agregarle un event listener al .current del ref.
	// Mas info sobre esto acá https://getbootstrap.com/docs/5.1/components/dropdowns/#events

	useEffect(() => {
		dropdown = document.getElementById(`${name}1`);

		dropdown.addEventListener('show.bs.dropdown', function () {
			setToggle(true);
		});

		dropdown.addEventListener('hide.bs.dropdown', function () {
			setToggle(false);
		});
	}, []);

	return (
		<div className='dropdown w-100' id={`${name}1`}>
			<div
				id={name}
				data-bs-toggle='dropdown'
				data-bs-display='static'
				aria-expanded='false'
				className={`dropbtn ${
					toggle ? 'rounded-top' : 'rounded-2'
				} d-flex align-items-center justify-content-between w-100 py-${size} px-${'3'}`}
				type='button'>
				<div className='d-flex flex-column'>
					<p className='m-0' style={{ fontSize: '11px' }}>
						{text || 'Seleccione'}
					</p>
					{(data[nameEng] || toggle) && (
						<p className='m-0' style={{ fontSize: '11px' }}>
							{data[nameEng]}
						</p>
					)}
				</div>
				{toggle ? <UilAngleUp /> : <UilAngleDown />}
			</div>

			<div
				className='dropdown-content dropdown-menu dropdown-menu-search-filter rounded-bottom w-100 bg-white shadow'
				aria-labelledby={name}>
				<div className='p-2'>
					{values.map((item ,i) => (
						<div
							key={i}
							onClick={() => clickHandler(item)}
							className='d-flex align-items-center justify-content-start fs-5 fw-bold text-color-lblue p-2 label-CustomSelect cursor-pointer'
							style={{ pointerEvents: inputDisabled ? 'none' : 'auto' }}>
								
							<label className='py-2'>{item.label}</label>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PhonePrefixSelect;
