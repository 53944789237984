import React from 'react';
import Active from './active/Active';

function ChildrenSelectorEstate({ selectedTab, filtersMenu, setFiltersMenu }) {
	switch (selectedTab) {
		case 'ACTIVAS':
			return <Active filtersMenu={filtersMenu} setFiltersMenu={setFiltersMenu} />;
		case 'TASACIONES':
			return (
				<div className='d-flex justify-content-center align-items-center w-100 min-vh-50'>
					<h1>TASACIONES</h1>
				</div>
			);
		case 'HISTÓRICO':
			return (
				<div className='d-flex justify-content-center align-items-center w-100 min-vh-50'>
					<h1>HISTÓRICO</h1>
				</div>
			);
		case 'RESERVAS':
			return (
				<div className='d-flex justify-content-center align-items-center w-100 min-vh-50'>
					<h1>RESERVAS</h1>
				</div>
			);
		case 'SUSPENSIONES':
			return (
				<div className='d-flex justify-content-center align-items-center w-100 min-vh-50'>
					<h1>SUSPENSIONES</h1>
				</div>
			);
		case 'RED COMPARTIDA':
			return (
				<div className='d-flex justify-content-center align-items-center w-100 min-vh-50'>
					<h1>RED COMPARTIDA</h1>
				</div>
			);

		default:
			break;
	}
}

export default ChildrenSelectorEstate;
