import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import { UilSearch, UilBell, UilRulerCombined } from '@iconscout/react-unicons';
import InputCounter from '../../components/InputCounter';
import InputFloating from '../../components/InputFloating';
import SearchFilter from '../../components/Owner/SearchFilter';
import ThemeContext from '../../contexts/themeContext';
import banner from '../../assets/img/ilus_tasador.png';
import { Form, Formik } from 'formik';
import MapLayout from './MapLayout';
import Toggle from '../../components/Owner/Toggle';
import Contador from '../../components/Owner/Contador';

const TasadorMobile = (dataTasador, setDataTasador) => {
	const [userData, setUserData] = useState({
		valor1: '',
		password: '',
		name: '',
		surname: '',
		birthday: '',
	});
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const items = [
		{
			name: 'Seleccione',
			nameEng: 'operation',
			type: 'select',
			options: null,
		},
		{
			name: 'Barrio',
			nameEng: 'neighborhood',
			type: 'select',
			options: null,
		},
		{
			name: 'Sup. libre',
			nameEng: 'freeArea',
			type: 'text',
			options: null,
		},
		{
			name: 'Tipo de inmueble',
			nameEng: 'propertyType',
			type: 'select',
			options: null,
		},
		{
			name: 'Edificio',
			nameEng: 'building',
			type: 'select',
			options: null,
		},
		{
			name: 'Antigüedad',
			nameEng: 'antiqueness',
			type: 'text',
			options: null,
		},
		{
			name: 'Subtipo de inmueble',
			nameEng: 'propertySubType',
			type: 'select',
			options: null,
		},
		{
			name: 'Ubicación',
			nameEng: 'location',
			type: 'select',
			options: null,
		},
		{
			name: 'Cochera',
			nameEng: 'garage',
			type: 'text',
			options: null,
		},
		{
			name: 'Ambientes',
			nameEng: 'rooms',
			type: 'select',
			options: null,
		},
		{
			name: 'Sup. total',
			nameEng: 'totalArea',
			type: 'text',
			options: null,
		},
		{
			name: 'Valor',
			nameEng: 'value',
			type: 'text',
			options: null,
		},
		{
			name: 'Localidad',
			nameEng: 'city',
			type: 'select',
			options: null,
		},
		{
			name: 'Sup. cubierta',
			nameEng: 'coveredArea',
			type: 'text',
			options: null,
		},
	];

	const fetchedData = [
		{
			isMultiple: true,
			estateCount: 5,
			photo:
				'https://arquitecturayempresa.es/sites/default/files/content/arquitectura_rehabilitacion_amsterdam-235_000.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '50000',
			location: 'Av. Siempreviva 742',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 3,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [25],
			id: 995,
			position: [-34.600556, -58.395],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo:
				'https://static1.sosiva451.com/47515511/9533fecc-4397-43d2-ab54-1d97badd627d_u_small.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '60000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 4,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [50],
			id: 996,
			position: [-34.578611, -58.420861],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo: 'https://www.nocnok.com/hubfs/casa-lujo.webp',
			operation: 'Venta',
			currency: 'USD',
			value: '75000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 5,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [75],
			id: 997,
			position: [-34.60376, -58.38162],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo:
				'https://www.construyehogar.com/wp-content/uploads/2014/08/Dise%C3%B1o-de-casa-moderna-de-una-planta.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '100000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 6,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [88],
			id: 998,
			position: [-34.588056, -58.393056],
			favorite: false,
		},
	];
	return (
		<>
			<div className='row px-2 py-2 pb-2'>
				<div className='col-12 px-4 mb-2'>
					<div
						className='emulate-card p-2 rounded-1 text-left'
						style={{
							backgroundColor: '#EDEDED',
						}}
					>
						<div className='p-2 ms-3 '>
							<h1 className='fw-700 mt-2'>Tasador de </h1>
							<h1 className='fw-700'>propiedades</h1>
						</div>
						<div className='p-4 d-flex flex-column justify-content-center'>
							<div>
								{/* <label className='mb-1 ms-1 fw-600 fs-6'>Operacion</label> */}
								<SearchFilter
									text={'Operación'}
									name={'Operación'}
									nameEng={'Operation'}
									changeHandlerMultipleSelect={() => console.log('')}
									data={dataTasador}
									withSearch={false}
									bgColor={'bg-white'}
									values={[
										{
											id: 1,
											label: 'Alquiler',
										},
										{
											id: 2,
											label: 'Alquiler temporario ',
										},
										{
											id: 3,
											label: 'Venta',
										},
									]}
								/>
							</div>
							<div className='col mt-4'>
								{/* <label className='mb-1 ms-1 fw-600 fs-6'>Moneda</label> */}
								<SearchFilter
									text={'Moneda'}
									name={'Operación'}
									nameEng={'Operation'}
									bgColor={'bg-white'}
									changeHandlerMultipleSelect={() => console.log('')}
									data={dataTasador}
									withSearch={false}
									values={[
										{
											id: 1,
											label: 'Alquiler',
										},
										{
											id: 2,
											label: 'Alquiler temporario ',
										},
										{
											id: 3,
											label: 'Venta',
										},
									]}
								/>
							</div>
							<div className='mt-4'>
								{/* <label className='mb-1 ms-1 fw-600 fs-6'>
											Tipo de propiedad
										</label> */}
								<SearchFilter
									style={{ backgroundColor: 'red !important' }}
									text={'Tipo de propiedad'}
									name={'Operación'}
									nameEng={'Operation'}
									changeHandlerMultipleSelect={() => console.log('')}
									data={dataTasador}
									bgColor={'bg-white'}
									withSearch={false}
									values={[
										{
											id: 1,
											label: 'Alquiler',
										},
										{
											id: 2,
											label: 'Alquiler temporario ',
										},
										{
											id: 3,
											label: 'Venta',
										},
									]}
								/>
							</div>
							<div className='mt-4'>
								{/* <label className='mb-1 ms-1 fw-600 fs-6'>Antiguedad</label> */}
								<SearchFilter
									style={{ backgroundColor: '#ffff' }}
									text={'Antiguedad'}
									name={'Operación'}
									nameEng={'Operation'}
									bgColor={'bg-white'}
									changeHandlerMultipleSelect={() => console.log('')}
									data={dataTasador}
									withSearch={false}
									values={[
										{
											id: 1,
											label: 'Alquiler',
										},
										{
											id: 2,
											label: 'Alquiler temporario ',
										},
										{
											id: 3,
											label: 'Venta',
										},
									]}
								/>
							</div>
						</div>
						<div className='row d-flex justify-content-center align-items-center mt-2 px-4 ms-1 me-1'>
							<div className='col-4'>
								<label className='mb-1  fw-600 fs-6 text-center'>Ambientes</label>
								<div className=''>
									<Contador />
								</div>
							</div>
							<div className='col-4  d-flex flex-column'>
								<label className='mb-1 ms-1 fw-600 fs-6 '>Cochera</label>
								<div className=''>
									<Toggle label='Toggle' toggled={true} />
								</div>
							</div>

							<div className='col-4 d-flex flex-column'>
								<label className='mb-1  fw-600 fs-6'>Amenities</label>
								<Toggle label='Toggle' toggled={true} />
							</div>
						</div>
						<div className='row mt-5 px-3'>
							<Formik className='h-100'>
								{({ touched }) => (
									<Form className='w-100 h-100'>
										<div className='row'>
											<div className='col ms-2'>
												<div className=''>
													<InputFloating
														className='input-filters-menu rounded shadow-inputs col py-0 input-narrow'
														label='Sup. Cubierta'
														name='valor1'
														type='number'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														bgColor={'bg-white'}
														errorMessage={''}
														onChange={(e) => {
															console.log(e);
														}}
													/>
												</div>
											</div>
											<div className='col me-2'>
												<InputFloating
													className='input-filters-menu rounded shadow-inputs p-3 input-narrow'
													label='Sup. descubierta'
													name='valor2'
													type='number'
													setFunction={setUserData}
													previousValue={userData}
													touched={touched}
													bgColor={'bg-white'}
													errorMessage={''}
													onChange={(e) => {
														console.log(e);
													}}
												/>
											</div>
										</div>
										<div className='row mt-4'>
											<div className='col p-2 ms-3 me-3'>
												<InputFloating
													className='input-filters-menu rounded shadow-inputs p-3 input-narrow'
													label='Direccion Aproximada'
													name='valor2'
													type='number'
													bgColor='bg-white'
													setFunction={setUserData}
													previousValue={userData}
													touched={touched}
													errorMessage={''}
													onChange={(e) => {
														console.log(e);
													}}
												/>
											</div>
										</div>
										<div className='row mt-4 d-flex'>
											<div className='col-5 ms-2'>
												<div className=''>
													<InputFloating
														className='input-filters-menu rounded shadow-inputs p-3 input-narrow'
														label='Radio en Kms'
														name='valor1'
														type='number'
														bgColor={'bg-white'}
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														errorMessage={''}
														onChange={(e) => {
															console.log(e);
														}}
													/>
												</div>
											</div>
											<div className='col-6 ms-3 mt-n2 '>
												<label className=' me-4 fw-600 fs-6'>
													Consulta: Sólo activas
												</label>
												<div className='col-6'>
													<Toggle label='Toggle me' toggled={true} />
												</div>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						</div>

						<div className='d-flex justify-content-center mt-5 px-3 mb-2'>
							<button
								className='btn btn-primary rounded-7 '
								style={{ width: '100%' }}
							>
								TASAR
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='col-12 d-flex justify-content-center'>
					<div className='mt-5'>
						<h1 className=' fw-700'>322 propiedades activas</h1>
						<h2 className='text-color-lblue fw-700'>Precio estimado</h2>
						<h1 className='text-color-lblue fw-700'>ARS $300000</h1>
					</div>
				</div>
			</div>
			<div className='row position-relative'>
				<div
					className='col-8 p-0 mt-5 position-relative'
					style={{
						height: '600px',
						width: '820px',
					}}
				>
					<MapLayout fetchedData={fetchedData} />
				</div>
				<button
					className='btn btn-primary position-absolute rounded-1 bottom-5 rounded-1  start-50 translate-middle w-80 py-2 fs-5 text-center d-flex justify-content-center align-items-center'
					style={{ zIndex: 9999 }}
				>
					GENERAR REPORTE
				</button>
			</div>
		</>
	);
};

export default TasadorMobile;
