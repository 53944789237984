import axios from 'axios';
const baseURL = process.env.REACT_APP_ENDPOINT;

// if categories is not null, MUST be an array
export const searchService = async (query, token, categories = null) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const data = await axios({
		method: 'POST',
		url: `${baseURL}/app/searcher`,
		headers: header,
		data: {
			"category": categories,
			"filters": {
			  "query": query
			}
		  }
	}).then((res) => res.data);

	return data;
};
