import UserContext from '../contexts/userContext';
import { useContext } from 'react';
import { getPosts } from '../services/postsServices';
import { useQuery } from '@tanstack/react-query';

export default function usePosts(filters) {
	const { token } = useContext(UserContext);

	const posts = useQuery({
		queryKey: [filters],
		queryFn: () => getPosts(filters, token).then((response) => response),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});

	return posts;
}
