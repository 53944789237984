import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import ThemeContext from '../../contexts/themeContext';
import { useContext } from 'react';
import Page from '../../layout/Page/Page';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import {
	UilSearch,
	UilMultiply,
	UilBell,
	UilEdit,
} from '@iconscout/react-unicons';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader } from '../../components/bootstrap/Card';
import classNames from 'classnames';
import Input from '../../components/bootstrap/forms/Input';
import FiltersMenu from '../estate/active/FiltersMenu';
import { changeMultipleSellers, getCustomerChance, getStatus } from '../../services/customersServices';
import UserContext from '../../contexts/userContext';
import PaginationButtons from '../../components/PaginationButtons';
import Button from '../../components/bootstrap/Button';
import Icon from '../../components/icon/Icon';
import CommonFilterHeadStatusCustomers from '../common/Headers/CommonFilterHeadStatusCustomers';
import { getBranches } from '../../services/branchesServices';
import getOperadores from '../../services/operatorsService';
import { getEventType, getLeadIncomeTypes } from '../../services/sistemServices';
import InfoAccordionCustomer from '../clientStatus/InfoAccordionCustomer';
import useFilters from '../../hooks/useFilters';
import Check from '../../components/Check';
import CustomDropdown from '../../components/CustomDropdown';
import { showToast } from '../../components/Toast/toastUtils/toastUtils';
import Toast from '../../components/Toast/Toast';

const ChancePage = () => {
    const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const [loading, setLoading] = useState(false);
	const [activeFilters, setActiveFilters] = useState(false);
	const [customerChance, setCustomerChance] = useState([]);
	const [branches, setBranches] = useState([]);
	const [incomeTypes, setIncomeTypes] = useState([]);
    const [operatorList, setOperatorsList] = useState([]);
	const [listCustomerId, setListCustomerId] = useState([]);
	const [list, setList] = useState([]);
    const [reason, setReason] = useState([]);
    const [allStatus, setAllStatus] = useState([]);
	const filters = useFilters('customers');
	const [filtersApplied, setFiltersApplied] = useState({
        sort: {
                field: 'join_date',
                order: 'desc',
            },
			limit: 5
    });
	const debounceRef = useRef(null);
    const { token } = useContext(UserContext);
	
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getCustomerChance(filtersApplied, token);
            setCustomerChance(result);
        } catch (error) {
            console.error('Error al obtener datos:', error);
        } finally {
            setLoading(false);
        }
    };

	const customersByDate = customerChance.data?.reduce((acc, customer) => {
		const dateKey = customer.joinDate;
		if (!acc[dateKey]) {
		  acc[dateKey] = [];
		}
		acc[dateKey].push(customer);
		return acc;
	  }, {});

    useEffect(() => {
        fetchData();
    }, [filtersApplied]);


    useEffect(() => {
        Promise.all([
            getBranches(token), 
            getOperadores(), 
            getLeadIncomeTypes(token), 
            getEventType(token),
			getStatus(token)
        ])
            .then(([
                branches, 
                operators,
                incomeTypes , 
                eventTypes,
				status
            ]) => {
                setBranches(branches);
                setOperatorsList(operators);
                setIncomeTypes(incomeTypes);
			    setReason(eventTypes);
				setAllStatus(status);
            })
            .catch(error => console.error('Error al obtener datos:', error));
    }, []);


	// Funcion del buscador del header de la carta
	const onQueryChange = (value) => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}

		debounceRef.current = setTimeout(() => {
            setFiltersApplied((prevFiltersApplied) => ({
                ...prevFiltersApplied,
                    query: value,
            }));
        }, 500);
	};
	// Funcion del buscador del header de la carta HASTA ACA

	const handleCheckbox = (customerId ,allCustomerDate) => {
		const allCustomersIds = customerChance.data.map(customer => customer.id); // traigo todos los customers
		
		if(!customerId){
			listCustomerId.length === allCustomersIds.length ? 
			(setListCustomerId([]))
			: 
			(setListCustomerId(allCustomersIds))
		} else{
			const existingEntry = listCustomerId.find(entry => entry === customerId.customerId);
			if(existingEntry) {
				const newListCustomerId = listCustomerId.filter(entry => entry !== customerId.customerId);
  				setListCustomerId(newListCustomerId);
			} else {
					setListCustomerId([...listCustomerId, customerId.customerId]);
				}
		}

	}

	const isFullCustomers = () => {
        const maxCustomers = customerChance.data.length || -1
        const countCustomersList = listCustomerId.length
        if (countCustomersList == maxCustomers) {
            return true
        }
        return false
    }


	const changeSellersMan = ({customerId , operatorId}) => {

		let data = {}

        if (customerId && operatorId) {
            data = {
                seller: operatorId,
                customers: customerId
            }
        }

		changeMultipleSellers(data, token).then((result) => {
			if (result.status === 200) {
				console.log( customerChance ,"CustomerChance")
				setCustomerChance(prevCustomerChance => {
					const updateCustomers = {...prevCustomerChance};

					updateCustomers.data.forEach(customer => {
						if(customerId.includes(customer.id)){
							customer.seller = operatorId
						}
					});
					return updateCustomers

				});

				setListCustomerId( listCustomerId.filter(item => !customerId.includes(item)) )
				showToast(result.data.message, setList, 'success')

			}
		})


	}

	const formatDate = (date) => {
		let currentDate = new Date(date);

		let day = currentDate.getDay();
		let month = currentDate.getMonth();

		let daysWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
		let nameDay = daysWeek[day]

		let monthsYear = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
		let nameMonth = monthsYear[month]

		return `${nameDay} ${day} DE ${nameMonth}`
	}
	

	return (
		<PageWrapper className={`${mobileDesign && 'p-0'} `} title={'Oportunidades'}>
			<Toast toastlist={list} position="top-right" setList={setList} />
			<Page container={mobileDesign ? 'fluid p-0' : 'fluid'}>
				{/* Menu Estado de Cliente Filtros Exportar */}
				<SubheaderNew
					className={'justify-content-center gap-3 '}
					breadCrumbs={[
						{
							name: 'Oportunidades',
							url: `/oportunidades`,
						},
					]}
					filtersFunc={setActiveFilters}
					filtersStatus={activeFilters}
					withFiltersButton
					withExportButton={mobileDesign ? false : true}
					withSearchButton
				/>
				{/* Section de filtros Cuando se apreta en filtros */}
				{activeFilters && mobileDesign && (
					<div
						className='d-flex align-items-center justify-content-center w-100'
						style={{
							height: '10rem',
							borderRadius: '2rem 2rem 0rem 0rem',
							background:
								'linear-gradient(180deg, #DCE5FF 0%, rgba(220, 229, 255, 0) 100%)',
							position: 'relative',
							bottom: '1.8rem',
						}}>
						<h4 className='text-color-dark mb-4 fw-bold'>Filtro de Búsqueda</h4>
						<UilMultiply
							color='#506CF9'
							style={{ position: 'absolute', top: '10%', right: '3%' }}
							onClick={() => setActiveFilters(false)}
						/>
					</div>
				)}
				{/* Section de filtros Cuando se apreta en filtros HASTA ACA */}

				{/* Section la card */}

				<Card
					stretch
					style={{
						position: mobileDesign && activeFilters && 'relative',
						bottom: mobileDesign && activeFilters && '3rem',
					}}>
					{mobileDesign ? (
						<div className='d-flex flex-column w-100 list-mobile'>
							{activeFilters ? (
								<FiltersMenu
									filtersApplied={filtersApplied}
									setFiltersApplied={setFiltersApplied}
								/>
							) : (
								<div></div>
							)}
						</div>
					) : (
						<>
							{/* Esto es el headeer de la carta con el buscador */}
							<CardHeader
								style={{ 'border-bottom': '1px solid rgba(183, 184, 188, 0.5)' }}>
								<div className='titulo-alerta my-2'>
									<UilBell size={32} color='#506CF9' />
									<span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>
										OPORTUNIDADES
									</span>
								</div>
								{!activeFilters && (
									<CardActions>
										{/* Search input */}
										<div
											className={classNames(
												'd-flex align-items-center rounded-pill py-1 px-2',
												{ 'search-input-header-dark': darkModeStatus },
												{ 'search-input-header': !darkModeStatus },
											)}>
											<label
												className='border-0 bg-transparent cursor-pointer me-0 ms-2'
												htmlFor='searchInput'>
												<UilSearch color='#506CF9' size={12} />
											</label>
											<Input
												id='searchInput'
												type='search'
												className='border-0 shadow-none bg-transparent input-search-customers'
												placeholder='Buscar...'
												onChange={(e) =>
													onQueryChange(e.target.value, 'Query')
												}/>
										</div>
										{/* Search input */}
									</CardActions>
								)}
							</CardHeader>
							{/* Esto es el headeer de la carta con el buscador HASTA ACA */}
							</>
							)}

                            <CardBody className='table-responsive d-flex flex-column'>
									{!mobileDesign && !activeFilters && 
                                    <div className='d-flex justify-content-between mx-4 my-4 mt-5' >
										<div className='ps-3 d-flex align-items-center gap-3 '>
										{customerChance.data && <>
											 <Check checked={isFullCustomers()} clickHandler={(e) => handleCheckbox()} /> 
										{listCustomerId.length > 0 && 
										<CustomDropdown
											iconColor='#fff'
											label={'Modificar vendedor'}
											arrow={false}
											isRounded={false}
											icon={<UilEdit size={20} color={'#FFFFFF'} />}
											classname={` bg-blue-icon text-color-white px-2 py-2 w-100  fs-xxl-5 fs-xl-6 cursor-pointer fw-normal-bold gap-3
											rounded-5px
											`}
											dropdownClassname={
												'd-flex flex-column align-items-start justify-content-center text-color-lblue rounded-bottom w-100 position-absolute shadow'
											}
											closeAfterClick>
											{operatorList?.map((operator, index) => (
												<div
												className={`d-flex py-2 fs-6 justify-content-between fw-normal-bold cursor-pointer custom-dropdown-item w-100 px-3 bg-lblue text-color-lblue ${index === operatorList.length - 1 && 'rounded-bottom'}`}
												key={index}
												onClick={(e) => changeSellersMan({operatorId : operator.id , customerId: listCustomerId})}>
													<div className='text-nowrap text-truncate overflow-hidden'>
														{operator.name} {operator.surname}
													</div>
												</div>
											))}
										</CustomDropdown>}
											</>
										}
										</div>
										
                                        <CommonFilterHeadStatusCustomers filtersApplied={filtersApplied} setFiltersApplied={setFiltersApplied} branches={branches} operatorList={operatorList} incomeTypes={incomeTypes} />
                                    </div>
									}

							{ loading ? (
                                        <div className='w-100 d-flex my-5 justify-content-center'>
                                            <h2>Cargando...</h2>
                                        </div>
                                    ) : ( !activeFilters &&
                                        <div className="accordion-body  d-flex flex-column accordion-body-custom">
											{customersByDate && Object.entries(customersByDate).map(([date, customers], index) => (
												<div className={`gap-3 d-flex flex-column ${index !== 0 && 'pt-3'}`} key={date}>
													<div className="px-4 d-flex align-items-center">
													<span className='text-nowrap fw-bolder fs-5'>{formatDate(date).toUpperCase()}</span>
													{!mobileDesign && <div className="line" />}
													</div>
													{customers.map((customer, index) => (
													<InfoAccordionCustomer
														key={index}
														customer={customer}
														operatorList={operatorList}
														changeSellersMan={changeSellersMan}
														isChecked={listCustomerId.includes(customer.id)}
														filters={filters}
														reason={reason}
														handleCheckbox={handleCheckbox}
														statusCustomers={allStatus}
													/>
													))}
												</div>
												))}
                                        </div>
                                    )}
                                    
                            </CardBody>

                            {!activeFilters ? (
								<div className='pt-4'>
									<PaginationButtons
										label='items'
										currentPage={customerChance?.paginator?.page}
										filtersApplied={filtersApplied}
										setFiltersApplied={setFiltersApplied}
										totalPage={customerChance?.paginator?.total}
									/>
								</div>
							) : (
								<CardFooter>
									<CardFooterRight>
										<Button
											color='primary'
											tag='a'
											rounded={1}
											onClick={() => setActiveFilters(!activeFilters)}>
											<div className='d-flex'>
												<Icon icon='CustomSliders'/>
												<p className='my-0 ms-2'>Filtrar</p>
											</div>
										</Button>
									</CardFooterRight>
								</CardFooter>
							)}
						
				</Card>

				{/* Section la card HASTA ACA */}
			</Page>
		</PageWrapper>
	);
};

export default ChancePage;
