import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import USERS from '../../common/data/userDummyData';
import Avatar from '../../components/Avatar';
import {
	UilCheck,
	UilFileEditAlt,
	UilUsdCircle,
	UilMapMarker,
	UilBuilding,
	UilMoneyBill,
} from '@iconscout/react-unicons';
import Check from '../../components/Check';

const CardContractMobile = ({ type }) => {
	const types = ['Reserva inmueble', 'Alta de ficha', 'Suspensión de inmueble', 'Baja de ficha'];
	const [selectedEstates, setSelectedEstates] = useState([]);
	const imagenURL =
		'https://images.pexels.com/photos/2360673/pexels-photo-2360673.jpeg?cs=srgb&dl=pexels-tobias-bj%C3%B8rkli-2360673.jpg&fm=jpg';

	return (
		<>
			<div className='card rounded-1 py-2 px-4 d-flex mt-1'>
				<div className='col-11 position-absolute p-0 mt-3'>
					<div className='float-end'>
						<span className='radius-7p px-3 py-1 me-4 bg-primary text-white'>
							Contrato
						</span>
					</div>
				</div>
				<div
					id='contract'
					className='row d-flex flex-grow-2 justify-content-center align-items-center mt-4 p-0'
				>
					<div className='col-3 p-0'>
						<div className='d-flex justify-content-center align-items-center ms-2'>
							<div
								className='rounded-circle bg-blue-500 d-flex justify-content-center align-items-center'
								style={{ width: '72px', height: '72px' }}
							>
								<UilFileEditAlt
									className='text-color-white text-center'
									size={40}
								/>
							</div>
						</div>
					</div>
					<div className='col-8 p-0 ms-2 '>
						<div className='row'>
							<div className='col-12 d-flex align-items-center'>
								<UilMapMarker color='#506CF9' />
								<h4 className='fw-500 mt-2'>Yapeyú 345</h4>
							</div>
						</div>
					</div>
				</div>
				<div className='d-flex row mt-1 ms-2 flex-grow-1'>
					<div className='col-9'>
						<div className='row mt-5'>
							<div className='col-12 p-0'>
								<div className='float-start'>
									<span className='ms-1 fs-6 fw-500'>Actualizado por ICL</span>
								</div>
							</div>
							<div className='col-12 p-0'>
								<div className='float-start p-0 d-flex align-items-center'>
									<UilMoneyBill color='#506CF9' />
									<span className='ms-1 fs-6 fw-500'>NUEVO VALOR :</span>
									<span className='ms-1 fs-6 fw-600'>USD 250000</span>
								</div>
							</div>
						</div>
					</div>
					<div className='col-2 ms-3 mt-4'>
						<div className='float-end'>
							<Avatar
								className='float-end me-1'
								srcSet={USERS.CHLOE.srcSet}
								src={USERS.CHLOE.src}
								size={32}
								color={USERS.CHLOE.color}
							/>
							<p className='float-end me-2 mt-2 fw-500'>12:18</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardContractMobile;
