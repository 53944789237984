import { ArrowCounterClockwise, ArrowLeft, FloppyDisk, Trash } from 'phosphor-react';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/bootstrap/Button';
import { saveOperador } from '../../services/operatorsService';
import Swal from 'sweetalert2';
import Toast from '../../components/Toast/Toast';

const Tab3 = ({ userData, setUserData }) => {
	const navigate = useNavigate();
	const [list, setList] = useState([]);

	return (
		<>
			<div className='justify-content-between align-items-center w-100 row py-50p'>
				<div className='px-3 col-4 border-end  mt-3'>
					<div className='mt-5'>
						<span className='ms-4 fs-4 fw-700'>Consultas</span>
					</div>
					<div className='ms-4 mt-3'>
						<div className='mt-2'>
							<label className='text-color-blue fw-300'>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '75%',
										color: '#ffff',
										backgroundColor: '#506CF9',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '40%',
										color: '#ffff',
										backgroundColor: '#FFA63F',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '30%',
										color: '#ffff',
										backgroundColor: '#FF708B',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '20%',
										color: '#ffff',
										backgroundColor: '#01F1E3',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '10%',
										color: '#ffff',
										backgroundColor: '#7517F8',
									}}></div>
							</div>
						</div>
					</div>
				</div>
				<div className='px-3 col-4 border-end  mt-3'>
					<div className='mt-5'>
						<span className='ms-4 fs-4 fw-700'>Reservas</span>
					</div>
					<div className='ms-4 mt-3'>
						<div className='mt-2'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '75%',
										color: '#ffff',
										backgroundColor: '#506CF9',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '40%',
										color: '#ffff',
										backgroundColor: '#FFA63F',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '30%',
										color: '#ffff',
										backgroundColor: '#FF708B',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '20%',
										color: '#ffff',
										backgroundColor: '#01F1E3',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '10%',
										color: '#ffff',
										backgroundColor: '#7517F8',
									}}></div>
							</div>
						</div>
					</div>
				</div>
				<div className='px-3 col-4  mt-3'>
					<div className='mt-5'>
						<span className='ms-4 fs-4 fw-700'>Ventas Efectivas</span>
					</div>
					<div className='ms-4 mt-3'>
						<div className='mt-2'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '75%',
										color: '#ffff',
										backgroundColor: '#506CF9',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '40%',
										color: '#ffff',
										backgroundColor: '#FFA63F',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '30%',
										color: '#ffff',
										backgroundColor: '#FF708B',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '20%',
										color: '#ffff',
										backgroundColor: '#01F1E3',
									}}></div>
							</div>
						</div>
						<div className='mt-4'>
							<label className=''>Propiedades en Cartera</label>
							<span className='me-3 float-end'>20%</span>
							<div
								className='progress col-10'
								role='progressbar'
								aria-label='Example 1px high'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ height: '6px' }}>
								<div
									className='progress-bar '
									style={{
										width: '10%',
										color: '#ffff',
										backgroundColor: '#7517F8',
									}}></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Tab3;
