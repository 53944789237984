import { useRef } from 'react';

export default function useDebounce() {
     const debounceRef = useRef(null);

     const debouncedFn = (fn, timer = 0) => {
          if (debounceRef.current) {
               clearTimeout(debounceRef.current);
          }

          debounceRef.current = setTimeout(() => {
               fn();
          }, timer);
     };

     return debouncedFn;
}

// Usage example:
// const debouncer = useDebounce();
// const handleChange = () => {
//      debouncer(fn, 500);
// };
