import React, { useState, useContext } from 'react';
import {
	UilEdit,
	UilEllipsisH,
	UilUser,
	UilUsersAlt,
	UilCheck,
	UilSearch,
	UilTrash,
} from '@iconscout/react-unicons';
import classNames from 'classnames';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import Card, { CardBody, CardHeader, CardActions } from '../../components/bootstrap/Card';
import ThemeContext from '../../contexts/themeContext';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import useSortableData from '../../hooks/useSortableData';
import { Form, Formik } from 'formik';
import data from '../../common/data/dummyCustomerData';

const ProfileEdit = () => {
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const [userData, setUserData] = useState({});
	const { items, requestSort, getClassNamesFor } = useSortableData(data);
	const [activeFilters, setActiveFilters] = useState(false);
	return (
		<PageWrapper title='Crear interesado'>
			<Page container='fluid'>
				<SubheaderNew
					breadCrumbs={[
						{
							name: 'Perfiles',
							url: '/Mantenimiento/perfiles',
						},
						{
							name: 'Editar perfil',
							url: '/Mantenimiento/perfiles/editar',
						},
					]}
					newButtonUrl='/mantenimiento/perfiles/crear'
					withNewButton
				/>
				<div className='d-flex flex-column w-100 h-100'>
					{/* Tabs */}
					{!mobileDesign && (
						<>
							<Formik initialValues={[]}>
								{({ touched }) => (
									<Form>
										<Card
											className=''
											style={{
												position:
													mobileDesign && activeFilters && 'relative',
											}}>
											<CardHeader
												style={{
													'border-bottom':
														'1px solid rgba(183, 184, 188, 0.5)',
												}}>
												<div className='titulo-alerta my-2'>
													<UilUsersAlt
														className='text-color-lblue'
														size={30}
													/>
													<span className='m-0 px-3 fs-5 fw-bold '>
														Datos del perfil
													</span>
												</div>
											</CardHeader>
											<CardBody className='' isScrollable>
												<div className='row ms-4 mt-4'>
													<div className='col-3'>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col p-3'
															label='Nombre'
															name='name'
															type='text'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={'Nombre Requerido'}
														/>
													</div>
												</div>

												{/* <table className='table table-modern'> */}
											</CardBody>
										</Card>

										<Card
											stretch
											style={{
												position:
													mobileDesign && activeFilters && 'relative',
												bottom: mobileDesign && activeFilters && '3rem',
											}}>
											<CardHeader
												style={{
													'border-bottom':
														'1px solid rgba(183, 184, 188, 0.5)',
												}}>
												<div className='titulo-alerta my-2'>
													<UilUsersAlt
														className='text-color-lblue'
														size={30}
													/>
													<span className='m-0 px-3 fs-5 fw-bold '>
														Permisos
													</span>
												</div>
											</CardHeader>

											<CardBody>
												<table className='w-100 table table-striped table-borderless'>
													<thead
														className='border-none'
														style={{ position: 'sticky', top: '0px' }}>
														{/* <tr className='text-white blue-table-header'> */}
														<tr className='text-dark text-center'>
															<th style={{ textAlign: 'left' }}>
																Módulo
															</th>
															<th style={{ textAlign: 'left' }}>
																Sección
															</th>
															<th style={{ textAlign: 'right' }}>
																Lectura
															</th>
															<th style={{ textAlign: 'right' }}>
																Escritura
															</th>
														</tr>
													</thead>
													<tbody>
														{/* {dataPagination(items, currentPage, perPage).map(
																(item, index) => ( */}
														<tr className=' text-center'>
															<td
																style={{
																	width: '30%',
																	textAlign: 'left',
																}}>
																Adminsitracion
															</td>
															<td style={{ textAlign: 'left' }}>
																Carga Cartel
															</td>
															<td style={{ textAlign: 'right' }}>
															<label
																	className='custom-checkbox'
																	// onClick={() => {
																	// 	if (
																	// 		selectedEstates.length ===
																	// 		fetchedData.length
																	// 	) {
																	// 		setSelectedEstates([]);
																	// 	} else {
																	// 		setSelectedEstates(
																	// 			fetchedData,
																	// 		);
																	// 	}
																	// }}
																	>
																	<UilCheck
																		color={'#506CF9'}
																		size={18}
																	/>
																</label>
															</td>
															<td style={{ textAlign: 'right' }}>
															<label
																	className='custom-checkbox'
																	// onClick={() => {
																	// 	if (
																	// 		selectedEstates.length ===
																	// 		fetchedData.length
																	// 	) {
																	// 		setSelectedEstates([]);
																	// 	} else {
																	// 		setSelectedEstates(
																	// 			fetchedData,
																	// 		);
																	// 	}
																	// }}
																	>
																	<UilCheck
																		color={'#506CF9'}
																		size={18}
																	/>
																</label>
															</td>
														</tr>
														<tr className=' text-center'>
															<td
																style={{
																	width: '30%',
																	textAlign: 'left',
																}}>
																Adminsitracion
															</td>
															<td style={{ textAlign: 'left' }}>
																Carga Cartel
															</td>
															<td style={{ textAlign: 'right' }}>
															<label
																	className='custom-checkbox'
																	// onClick={() => {
																	// 	if (
																	// 		selectedEstates.length ===
																	// 		fetchedData.length
																	// 	) {
																	// 		setSelectedEstates([]);
																	// 	} else {
																	// 		setSelectedEstates(
																	// 			fetchedData,
																	// 		);
																	// 	}
																	// }}
																	>
																	<UilCheck
																		color={'#506CF9'}
																		size={18}
																	/>
																</label>
															</td>
															<td style={{ textAlign: 'right' }}>
															<label
																	className='custom-checkbox'
																	// onClick={() => {
																	// 	if (
																	// 		selectedEstates.length ===
																	// 		fetchedData.length
																	// 	) {
																	// 		setSelectedEstates([]);
																	// 	} else {
																	// 		setSelectedEstates(
																	// 			fetchedData,
																	// 		);
																	// 	}
																	// }}
																	>
																	<UilCheck
																		color={'#506CF9'}
																		size={18}
																	/>
																</label>
															</td>
														</tr>
														<tr className=' text-center'>
															<td style={{ textAlign: 'left' }}>
																Adminsitracion
															</td>
															<td style={{ textAlign: 'left' }}>
																Carga Cartel
															</td>
															<td style={{ textAlign: 'right' }}>
															<label
																	className='custom-checkbox'
																	// onClick={() => {
																	// 	if (
																	// 		selectedEstates.length ===
																	// 		fetchedData.length
																	// 	) {
																	// 		setSelectedEstates([]);
																	// 	} else {
																	// 		setSelectedEstates(
																	// 			fetchedData,
																	// 		);
																	// 	}
																	// }}
																	>
																	<UilCheck
																		color={'#506CF9'}
																		size={18}
																	/>
																</label>
															</td>
															<td style={{ textAlign: 'right' }}>
																<label
																	className='custom-checkbox'
																	// onClick={() => {
																	// 	if (
																	// 		selectedEstates.length ===
																	// 		fetchedData.length
																	// 	) {
																	// 		setSelectedEstates([]);
																	// 	} else {
																	// 		setSelectedEstates(
																	// 			fetchedData,
																	// 		);
																	// 	}
																	// }}
																	>
																	<UilCheck
																		color={'#506CF9'}
																		size={18}
																	/>
																</label>
															</td>
														</tr>
														{/* )} */}
													</tbody>
												</table>

												{/* <table className='table table-modern'> */}
											</CardBody>
										</Card>
									</Form>
								)}
							</Formik>
						</>
					)}
					{/* Tabs */}

					{mobileDesign && (
						<>
							<Formik initialValues={[]}>
								{({ touched }) => (
									<Form>
										<Card
											className=''
											style={{
												position:
													mobileDesign && activeFilters && 'relative',
											}}>
											<CardHeader
												className='card-header align-items-center'
												style={{
													'border-bottom':
														'1px solid rgba(183, 184, 188, 0.5)',
												}}>
												<div className='titulo-alerta my-2'>
													<UilUsersAlt
														className='text-color-lblue'
														size={30}
													/>
													<span className='m-0 px-3 fs-5 fw-bold '>
														Datos del perfil
													</span>
												</div>
											</CardHeader>
											<CardBody className='' isScrollable>
												<div className='row  mt-2'>
													<div className='col-12'>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col p-3'
															label='Nombre'
															name='name'
															type='text'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															required
															errorMessage={'Nombre Requerido'}
														/>
													</div>
												</div>

												{/* <table className='table table-modern'> */}
											</CardBody>
										</Card>

										<Card stretch>
											<CardHeader
												className='card-header align-items-center'
												style={{
													'border-bottom':
														'1px solid rgba(183, 184, 188, 0.5)',
												}}>
												<div className='my-2 justify-content-center align-items-center'>
													<UilUsersAlt
														className='text-color-lblue'
														size={30}
													/>
													<span className='m-0 px-3 fs-5 fw-bold align-items-center '>
														Permisos
													</span>
												</div>
											</CardHeader>

											<table className='w-100 table table-striped table-borderless'>
												<thead style={{ position: 'sticky', top: '0px' }}>
													{/* <tr className='text-white blue-table-header'> */}
													<tr className='text-dark text-center'>
														<th>Modulo</th>
														<th className='sortable'>Seccion</th>
														<th className='sortable'>Lectura</th>
														<th className='sortable'>Escritura</th>
													</tr>
												</thead>
												<tbody>
													{/* {dataPagination(items, currentPage, perPage).map(
                                                             (item, index) => ( */}
													<tr className=' text-center'>
														<td style={{ width: '30%' }}>
															Administración
														</td>
														<td>Carga Cartel</td>
														<td>
															<input type='checkbox' />
														</td>
														<td>
															<input type='checkbox' />
														</td>
													</tr>
													<tr className='text-center'>
														<td style={{ width: '30%' }}>
															Administración
														</td>
														<td>Carga Cartel</td>
														<td>
															<input type='checkbox' />
														</td>
														<td>
															<input type='checkbox' />
														</td>
													</tr>
													<tr className=' text-center'>
														<td style={{ width: '30%' }}>
															Administración
														</td>
														<td>Carga Cartel</td>
														<td>
															<input type='checkbox' />
														</td>
														<td>
															<input type='checkbox' />
														</td>
													</tr>
													{/* )} */}
												</tbody>
											</table>

											{/* <table className='table table-modern'> */}
										</Card>
									</Form>
								)}
							</Formik>
						</>
					)}
				</div>
			</Page>
		</PageWrapper>
		// <PageWrapper title='Alta Operador'>
		// 	<Page container='fluid'>
		// 		{!mobileDesign ? (
		// 			<div className='d-flex flex-column w-100 h-100'>
		// 				<SubheaderNew
		// 					breadCrumbs={[{ name: 'Perfiles', url: '/Mantenimiento/Perfiles' }]}
		// 					newButtonUrl='/Mantenimiento/Perfiles/Crear'
		// 				/>
		// 				<Card
		// 					stretch
		// 					style={{
		// 						position: mobileDesign && activeFilters && 'relative',
		// 						bottom: mobileDesign && activeFilters && '3rem',
		// 					}}
		// 				>
		// 					<CardHeader
		// 						style={{ 'border-bottom': '1px solid rgba(183, 184, 188, 0.5)' }}
		// 					>
		// 						<div className='titulo-alerta my-2'>
		// 							<UilUsersAlt className='text-color-lblue' size={30} />
		// 							<span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>
		// 								PERFILES
		// 							</span>
		// 						</div>
		// 					</CardHeader>
		// 					<CardBody className='table-responsive hidden-scroll' isScrollable>
		// 						<table className='w-100'>
		// 							<thead style={{ position: 'sticky', top: '0px' }}>
		// 								{/* <tr className='text-white blue-table-header'> */}
		// 								<tr className='text-dark'>
		// 									<th>{null}</th>
		// 									<th className='sortable'>PERFIL</th>
		// 								</tr>
		// 							</thead>
		// 							<tbody>
		// 								{/* {dataPagination(items, currentPage, perPage).map(
		// 										(item, index) => ( */}
		// 								<tr className='bg-new-gray'>
		// 									<td style={{ width: '30%' }}>
		// 										<div className='d-flex justify-content-start'>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilEdit color='#fff' size={20} />
		// 											</div>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilTrash color='#fff' size={20} />
		// 											</div>
		// 										</div>
		// 									</td>
		// 									<td>Doe</td>
		// 								</tr>
		// 								{/* )} */}
		// 							</tbody>
		// 						</table>
		// 						{/* <table className='table table-modern'> */}
		// 					</CardBody>
		// 				</Card>
		// 			</div>
		// 		) : (
		// 			<div>
		// 				{/* <SubheaderNew
		// 			breadCrumbs={[{ name: 'Perfiles', url: '/Mantenimiento/Perfiles' }]}
		// 			newButtonUrl='/Mantenimiento/Perfiles/Crear'
		// 		/> */}
		// 			{/* Search input */}
		// 			<div
		// 								className={classNames(
		// 									'd-flex align-items-center rounded-pill py-1 px-2',
		// 									{ 'search-input-header-dark': darkModeStatus },
		// 									{ 'search-input-header': !darkModeStatus },
		// 								)}>
		// 								<label
		// 									className='border-0 bg-transparent cursor-pointer me-0 ms-2'
		// 									htmlFor='searchInput'>
		// 									{/* <Icon icon='Search' size='2x' color='blue-300' /> */}
		// 									<UilSearch
		// 										// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
		// 										color='#506CF9'
		// 										size={12}
		// 									/>
		// 								</label>
		// 								<Input
		// 									id='searchInput'
		// 									type='search'
		// 									className='border-0 shadow-none bg-transparent input-search-customers'
		// 									placeholder='Buscar...'
		// 								/>
		// 							</div>
		// 							{/* Search input */}
		// 				<div className='titulo-alerta my-2 justify-content-center mt-5 mb-3'>
		// 					<UilUsersAlt className='text-color-lblue' size={30} />
		// 					<span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>PERFILES</span>

		// 				</div>
		// 				<hr className=''/>
		// 				<div className='text-center mb-5'>
		// 				<Button
		// 						size='sm'
		// 						icon='CustomUserPlus'
		// 						className={``}
		// 						color='info'
		// 						isLight
		// 						tag='a'
		// 						to={""}>
		// 						Nuevo
		// 					</Button>
		// 				</div>

		// 				<Card className="w-100 d-flex">
		// 					<CardBody className='w-100'>
		// 						<table className='w-100  table  table-striped '>
		// 							<tbody className='w-100 '>
		// 								{/* {dataPagination(items, currentPage, perPage).map(
		// 										(item, index) => ( */}
		// 								<tr className=''>
		// 									<td className='border-0' style={{width:"50%"}}>Doe</td>
		// 									<td className='border-0'>
		// 										<div className='d-flex justify-content-start'>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilEdit color='#fff' size={20} />
		// 											</div>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilTrash color='#fff' size={20} />
		// 											</div>
		// 										</div>
		// 									</td>
		// 								</tr>
		// 								<tr className=''>
		// 									<td style={{width:"50%"}}>Doe</td>
		// 									<td>
		// 										<div className='d-flex justify-content-start'>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilEdit color='#fff' size={20} />
		// 											</div>
		// 											<div className='bg-blue-300 rounded-circle p-2 mx-1'>
		// 												<UilTrash color='#fff' size={20} />
		// 											</div>
		// 										</div>
		// 									</td>
		// 								</tr>
		// 								{/* )} */}
		// 							</tbody>
		// 						</table>
		// 						{/* <table className='table table-modern'> */}
		// 					</CardBody>
		// 				</Card>
		// 			</div>
		// 		)}
		// 	</Page>
		// </PageWrapper>
	);
};

export default ProfileEdit;
