import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './mapStyles.css';
import L from 'leaflet';

import ThemeContext from '../../../contexts/themeContext';
// import EstatePopup from './EstatePopup';
import EstatePopupMultiple from './EstatePopupMultiple';
import EstatePopupNew from './EstatePopupNew';

// const markerIcon = L.icon({
// 	iconUrl: 'https://www.agroavisos.net/wp-content/uploads/2017/04/map-marker-icon.png',
// 	iconSize: [40, 40],
// 	iconAnchor: [12, 41],
// 	popupAnchor: [8, -40],
// 	shadowSize: [41, 41],
// });

const customIcon = (text) => {
	return L.divIcon({
		html: text,
		className:
			'd-flex justify-content-center align-items-center text-color-white bg-blue-300 fw-bold fs-6 rounded-pill',
		iconSize: [100, 30],
	});
};

const customIconCluster = (text) => {
	return L.divIcon({
		html: `<svg
				width='48'
				height='48'
				viewBox='0 0 48 48'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<g style='mix-blend-mode:multiply'>
					<circle cx='24' cy='24' r='20' fill='#506CF9' fill-opacity='0.5' />
				</g>
				<g style='mix-blend-mode:multiply'>
					<circle
						cx='24'
						cy='24'
						r='23'
						stroke='#506CF9'
						stroke-opacity='0.25'
						stroke-width='2'
					/>
				</g>
				<circle cx='24' cy='24' r='16' fill='#506CF9' />
				<foreignObject x='20' y='15' width='24' height='24'>
					<div
						class=''
						xmlns='http://www.w3.org/2000/svg'
						style={{ position: 'absolute', bottom: '5px' }}>
						<p class='fs-6 fw-bold text-color-white m-0'>${text}</p>
					</div>
				</foreignObject>
			</svg>`,
		className: '',
		iconSize: [24, 40],
		iconAnchor: [12, 40],
		// className:
		// 	'd-flex justify-content-center align-items-center text-color-white bg-blue-300 fw-bold fs-6 p-4 rounded-circle',
	});
};

// const center = [-32.913997, -60.674567]; Estadio Gigante de Arroyito

const center = [-34.60376, -58.38162];
const zoom = 15;

function DisplayPosition({ map }) {
	const [position, setPosition] = useState(() => map.getCenter());

	const onClick = useCallback(() => {
		map.setView(center, zoom);
	}, [map]);

	const onMove = useCallback(() => {
		setPosition(map.getCenter());
	}, [map]);

	useEffect(() => {
		map.on('move', onMove);
		return () => {
			map.off('move', onMove);
		};
	}, [map, onMove]);

	// console.log(position);
	// return (
	// 	<p>
	// 		latitude: {position.lat.toFixed(4)}, longitude: {position.lng.toFixed(4)}{' '}
	// 		<button onClick={onClick}>reset</button>
	// 	</p>
	// );
	return null;
}

export default function MapLayout({ selectedEstates, setSelectedEstates, data }) {
	const [map, setMap] = useState(null);

	const displayMap = useMemo(
		() => (
			<MapContainer center={center} zoom={zoom} scrollWheelZoom={false} ref={setMap}>
				{data.map((item) => (
					<Marker
						position={item.coordinates}
						icon={
							item.isMultiple
								? customIconCluster(item.estateCount)
								: customIcon(`${item.summaryPrices}`)
						}
						// icon={customIcon(`${item.currency} ${item.value}`)}
					>
						{item.isMultiple ? (
							<EstatePopupMultiple
								item={item}
								selectedEstates={selectedEstates}
								setSelectedEstates={setSelectedEstates}
							/>
						) : (
							<EstatePopupNew
								item={item}
								selectedEstates={selectedEstates}
								setSelectedEstates={setSelectedEstates}
							/>
						)}
					</Marker>
				))}

				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					// url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					url='https://maps.geoapify.com/v1/tile/klokantech-basic/{z}/{x}/{y}.png?apiKey=035e61bc6b9948188ef5937de57bfe77'
				/>
			</MapContainer>
		),
		[selectedEstates],
	);

	return (
		<>
			{map ? <DisplayPosition map={map} /> : null}
			{displayMap}
		</>
	);
}
