import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
	UilSearch,
	UilFileEditAlt,
	UilConstructor,
	UilEstate,
	UilUsersAlt,
} from '@iconscout/react-unicons';
import { BookBookmark } from 'phosphor-react';
import ThemeContext from '../../contexts/themeContext';
import Input from '../../components/bootstrap/forms/Input';
import useSearch from '../../hooks/useSearch';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';

const HeaderSearchBar = () => {
	const { darkModeStatus, mobileDesign } = useContext(ThemeContext);

	const [searchQuery, setSearchQuery] = useState(null);

	const [toggle, setToggle] = useState(false);

	const { dataState } = useSearch(searchQuery);

	useEffect(() => {
		if (!dataState.data || searchQuery === '') {
			setToggle(false);
		} else {
			setToggle(true);
		}
	}, [dataState, searchQuery]);

	const debounceRef = useRef(null);

	const onChange = (value, delay = 0) => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}

		debounceRef.current = setTimeout(() => {
			setSearchQuery(value);
		}, delay);
	};

	const refOutsideClick = useRef(null);

	useDetectOutsideClick(refOutsideClick, () => setToggle(false));

	return (
		<div
			className={`${mobileDesign ? 'w-100' : 'w-45 ms-xl-0 ms-xxl-5 position-relative'}`}
			ref={refOutsideClick}
			onClick={() => {
				if (!dataState.data) return;
				setToggle(true);
			}}>
			<div
				className={classNames(
					'd-flex align-items-center rounded-pill py-2 px-3 my-2 col-3 w-100',
					{ 'search-input-header-dark': darkModeStatus },
					{ 'search-input-header': !darkModeStatus },
				)}>
				<label
					className='border-0 bg-transparent cursor-pointer me-0'
					htmlFor='searchInput'>
					<UilSearch color={darkModeStatus ? '#A9B5D7' : '#01227d'} size={20} />
				</label>
				<Input
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent input-search'
					placeholder='Buscar...'
					// value={searchQuery}
					onChange={(event) => onChange(event.target.value, 500)}
				/>
			</div>

			{toggle && (
				<ul
					className={`bg-white-dropdown p-4 rounded ${
						mobileDesign && 'w-100'
					} position-absolute`}
					style={{ zIndex: 9999 }}>
					{dataState?.data?.projects.length > 0 && (
						<div className='d-flex flex-column align-items-start w-100'>
							<div className='d-flex align-items-center'>
								<UilConstructor size={25} color='#01227D' />
								<p className='ms-1 my-0 fs-6 text-color-dark'>Emprendimientos</p>
							</div>

							<div className='d-flex flex-column justify-content-center align-items-start w-100 ms-3 py-1'>
								{dataState?.data?.projects.map((item) => (
									<div className='d-flex align-items-center my-1 w-100 cursor-pointer custom-dropdown-item px-4 py-2 rounded-pill'>
										<img className='rounded-5px' src={item.image} />

										<p className='m-0 fs-5 fw-bold mx-2 text-nowrap'>
											{item.title}
										</p>
										<p className='m-0 fs-6 fw-normal text-truncate'>
											{item.resumeDescription ||
												'Contrary to popular belief · Lorem Ipsum is not simply random text.'}
										</p>
									</div>
								))}
							</div>
						</div>
					)}
					{dataState?.data?.properties.length > 0 && (
						<div className='d-flex flex-column align-items-start w-100'>
							<div className='d-flex align-items-center'>
								<UilEstate size={25} color='#01227D' />
								<p className='ms-1 my-0 fs-6 text-color-dark'>Inmuebles</p>
							</div>

							<div className='d-flex flex-column justify-content-center align-items-start w-100 ms-3 py-1'>
								{dataState?.data?.properties.map((item) => (
									<div className='d-flex align-items-center my-1 w-100 cursor-pointer custom-dropdown-item px-4 py-2 rounded-pill'>
										<img className='rounded-5px' src={item.image} />

										<p className='m-0 fs-5 fw-bold mx-2 text-nowrap'>
											{item.title}
										</p>
										<p className='m-0 fs-6 fw-normal text-truncate'>
											{item.resumeDescription ||
												'Contrary to popular belief · Lorem Ipsum is not simply random text.'}
										</p>
									</div>
								))}
							</div>
						</div>
					)}
					{dataState?.data?.customers.length > 0 && (
						<div className='d-flex flex-column align-items-start w-100'>
							<div className='d-flex align-items-center'>
								<UilUsersAlt size={25} color='#01227D' />
								<p className='ms-1 my-0 fs-6 text-color-dark'>Clientes</p>
							</div>

							<div className='d-flex flex-column justify-content-center align-items-start w-100 ms-3 py-1'>
								{dataState?.data?.customers.map((item) => (
									<div className='d-flex align-items-center my-1 w-100 cursor-pointer custom-dropdown-item px-4 py-2 rounded-pill'>
										<img className='rounded-5px' src={item.image} />

										<p className='m-0 fs-5 fw-bold mx-2 text-nowrap'>
											{item.title}
										</p>
										<p className='m-0 fs-6 fw-normal text-truncate'>
											{item.resumeDescription}
										</p>
									</div>
								))}
							</div>
						</div>
					)}
				</ul>
			)}
		</div>
	);
};

export default HeaderSearchBar;
