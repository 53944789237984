import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactCountryFlag from 'react-country-flag';
import SearchFilter from '../../components/Owner/SearchFilter';
import {
	UilAngleRightB,
	UilCheck,
	UilEdit,
	UilTimes,
	UilAngleLeftB,
} from '@iconscout/react-unicons';
import { ArrowsOut, SkipForward } from 'phosphor-react';
import CustomSelect from '../../components/CustomSelect';
import CustomChatGPT from '../../components/icon/svg-icons/CustomChatGPT';
import CustomDropdown from '../../components/CustomDropdown';

export default function Description({ estateData, setEstateData, setSelectedStep }) {
	const [lenguage, setLenguage] = useState('Español');
	const [expandedDescriptionEnabled, setExpandedDescriptionEnabled] = useState(false);

	const items = [
		{
			id: 1,
			label: 'Plantilla',
		},
		{
			id: 2,
			label: 'Contacto luego de un tiempo',
		},
		{
			id: 3,
			label: 'Nuevo ingreso',
		},
		{
			id: 4,
			label: 'Seguimiento',
		},
		{
			id: 5,
			label: 'No te olvidamos',
		},
		{
			id: 6,
			label: 'Seguimiento a clientes olvidados',
		},
		{
			id: 7,
			label: 'Contacto gerencial',
		},
		{
			id: 8,
			label: 'Plantilla de prueba',
		},
	];

	return (
		<div className='d-flex flex-column align-items-start w-100 p-5 position-relative'>
			<div className='d-flex align-items-center w-100 mb-4 px-5'>
				<div
					className={`d-flex align-items-center py-3 px-4 rounded fw-bold cursor-pointer innerShadowBlue ${
						lenguage === 'Español'
							? 'bg-blue-300 text-color-white'
							: 'bg-lblue text-color-lblue'
					}`}
					onClick={() => setLenguage('Español')}>
					<ReactCountryFlag
						svg
						countryCode='ES'
						style={{
							fontSize: '2rem',
							lineHeight: '2rem',
						}}
					/>
					<p className='mb-0 mx-2'>Español</p>
				</div>
				<div
					className={`d-flex align-items-center py-3 px-4 rounded fw-bold cursor-pointer innerShadowBlue mx-4 ${
						lenguage === 'Inglés'
							? 'bg-blue-300 text-color-white'
							: 'bg-lblue text-color-lblue'
					}`}
					onClick={() => setLenguage('Inglés')}>
					<ReactCountryFlag
						svg
						countryCode='US'
						style={{
							fontSize: '2rem',
							lineHeight: '2rem',
						}}
					/>
					<p className='mb-0 mx-2'>Inglés</p>
				</div>

				<div className='col-3'>
					<CustomDropdown
						iconColor='#fff'
						label={'Plantilla'}
						classname={`bg-blue-300 px-4 py-3 w-100 text-color-white fs-5 fw-600 cursor-pointer`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom px-4 w-100 position-absolute'
						}
						margin=''
						width='w-100'>
						{items.map((item) => (
							<div
								className='d-flex align-items-center py-3 fs-5 fw-normal-bold cursor-pointer'
								key={item.id}>
								{item.label}
							</div>
						))}
					</CustomDropdown>
				</div>

				<div
					className={`d-flex align-items-center px-4 py-3 ms-3 rounded cursor-pointer ${
						expandedDescriptionEnabled ? 'bg-blue-300' : 'bg-lblue'
					}`}
					onClick={() => setExpandedDescriptionEnabled(!expandedDescriptionEnabled)}>
					<CustomChatGPT color={expandedDescriptionEnabled ? '#fff' : '#505cf9'} />
					<p
						className={`ps-2 fs-5 fw-normal-bold mb-0 ${
							expandedDescriptionEnabled ? 'text-color-white' : 'text-color-lblue'
						}`}>
						Crear descripción ampliada
					</p>
				</div>
			</div>
			<div className='w-100 min-vh-50 px-5'>
				<CKEditor
					editor={ClassicEditor}
					className='w-100 h-100'
					data='<p>Hello from CKEditor 5!</p>'
					onReady={(editor) => {
						// You can store the "editor" and use when it is needed.
						console.log('Editor is ready to use!', editor);
					}}
					onChange={(event, editor) => {
						const data = editor.getData();
						console.log({ event, editor, data });
					}}
					onBlur={(event, editor) => {
						console.log('Blur.', editor);
					}}
					onFocus={(event, editor) => {
						console.log('Focus.', editor);
					}}
				/>
			</div>

			{expandedDescriptionEnabled && (
				<div className='d-flex align-items-center justify-content-center w-100 position-absolute bottom-15'>
					<div
						className={`d-flex align-items-center px-4 py-2 ms-3 rounded cursor-pointer bg-lblue`}>
						<UilCheck size={26} color={'#506CF9'} />
						<p className={`ps-2 fs-5 fw-normal-bold mb-0 text-color-lblue`}>Aceptar</p>
					</div>
					<div
						className={`d-flex align-items-center px-4 py-2 ms-3 rounded cursor-pointer bg-lblue`}>
						<UilEdit size={26} color={'#506CF9'} />
						<p className={`ps-2 fs-5 fw-normal-bold mb-0 text-color-lblue`}>Editar</p>
					</div>
					<div
						className={`d-flex align-items-center px-4 py-2 ms-3 rounded cursor-pointer bg-lblue`}>
						<UilTimes size={26} color={'#506CF9'} />
						<p className={`ps-2 fs-5 fw-normal-bold mb-0 text-color-lblue`}>Borrar</p>
					</div>
				</div>
			)}

			<div className='d-flex align-items-center justify-content-between w-100 mt-5'>
				{/* <div
					className='d-flex align-items-center justify-content-center py-2 px-4 blue-gradient-buttons rounded cursor-pointer'
					onClick={() => setSelectedStep('features')}>
					<SkipForward color='#fff' size={24} />
					<p className='mb-0 ms-2 text-color-white fs-5'>Omitir</p>
				</div> */}
				<div
					className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
					onClick={() => setSelectedStep('general')}>
					<UilAngleLeftB color='#fff' size={24} />
					<p className='mb-0 text-color-white fw-bold'>Atrás</p>
				</div>
				<div
					className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
					onClick={() => setSelectedStep('features')}>
					<p className='mb-0 text-color-white fw-bold'>Siguiente</p>
					<UilAngleRightB color='#fff' size={24} />
				</div>
			</div>
		</div>
	);
}
