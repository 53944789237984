import React, { useState } from 'react';
import {
	UilCheck,
	UilAngleRightB,
	UilPlus,
	UilPen,
	UilAngleLeftB,
	UilAngleDown,
	UilAngleUp,
} from '@iconscout/react-unicons';
import { SkipForward } from 'phosphor-react';
import { Field, Form, Formik } from 'formik';

export default function Features({ estateData, setEstateData, setSelectedStep }) {
	const items = [
		{
			name: 'airConditioning',
			id: 1,
			label: 'Aire acondicionado',
		},
		{
			name: 'scale',
			id: 2,
			label: 'Balanza',
		},
		{
			name: 'basketballCourt',
			id: 3,
			label: 'Cancha de básquet',
		},
		{
			name: 'hockeyCourt',
			id: 4,
			label: 'Cancha de hockey',
		},
		{
			name: 'squashCourt',
			id: 5,
			label: 'Cancha de squash',
		},
		{
			name: 'healthCare',
			id: 6,
			label: 'Centro médico',
		},
		{
			name: 'sportsSchool',
			id: 7,
			label: 'Escuela deportiva',
		},
		{
			name: 'generator',
			id: 8,
			label: 'Grupo electrógeno',
		},
		{
			name: 'forkLift',
			id: 9,
			label: 'Montacarga',
		},
		{
			name: 'ridingTrack',
			id: 10,
			label: 'Pista de equitación',
		},
		{
			name: 'reinforcedDoor',
			id: 11,
			label: 'Puerta blindada',
		},
		{
			name: 'sauna',
			id: 12,
			label: 'Sauna',
		},
		{
			name: 'fireTank',
			id: 13,
			label: 'Tanque de incendio',
		},
		{
			name: 'loft',
			id: 14,
			label: 'Altillo',
		},
		{
			name: 'diningRoom',
			id: 15,
			label: 'Comedor',
		},
		{
			name: 'desk',
			id: 16,
			label: 'Escritorio',
		},
		{
			name: 'alarm',
			id: 17,
			label: 'Alarma',
		},
		{
			name: 'waterPump',
			id: 18,
			label: 'Bombeador',
		},
		{
			name: 'bocceCourt',
			id: 19,
			label: 'Cancha de bochas',
		},
		{
			name: 'paddleCourt',
			id: 20,
			label: 'Cancha de paddle',
		},
		{
			name: 'tennisCourt',
			id: 21,
			label: 'Cancha de tenis',
		},
		{
			name: 'houseClubElders',
			id: 22,
			label: 'Club house mayores',
		},
		{
			name: 'drivingForce',
			id: 23,
			label: 'Fuerza motriz',
		},
		{
			name: 'whirlpool',
			id: 24,
			label: 'Hidromasaje',
		},
		{
			name: 'engines',
			id: 25,
			label: 'Motores',
		},
		{
			name: 'playSquare',
			id: 26,
			label: 'Plaza de juegos',
		},
		{
			name: 'quincho',
			id: 27,
			label: 'Quincho',
		},
		{
			name: 'solarium',
			id: 28,
			label: 'Solarium',
		},
		{
			name: 'hotWaterTank',
			id: 29,
			label: 'Termotanque',
		},
		{
			name: 'balcony',
			id: 30,
			label: 'Balcón',
		},
		{
			name: 'diningRoomDiary',
			id: 31,
			label: 'Comedor diario',
		},
		{
			name: 'hall',
			id: 32,
			label: 'Hall',
		},
		{
			name: 'withFurniture',
			id: 33,
			label: 'Amoblado',
		},
		{
			name: 'boiler',
			id: 34,
			label: 'Caldera',
		},
		{
			name: 'boiler',
			id: 35,
			label: 'Caldera',
		},
		{
			name: 'footballField',
			id: 36,
			label: 'Cancha de fútbol',
		},
		{
			name: 'poloField',
			id: 37,
			label: 'Cancha de polo',
		},
		{
			name: 'volleyField',
			id: 38,
			label: 'Cancha de volley',
		},
		{
			name: 'houseClubKids',
			id: 39,
			label: 'Club house niños',
		},
		{
			name: 'gym',
			id: 40,
			label: 'Gimnasio',
		},
		{
			name: 'grillInstalation',
			id: 41,
			label: 'Instalación parrilla',
		},
		{
			name: 'grill',
			id: 42,
			label: 'Parrilla',
		},
		{
			name: 'maneuveringSquare',
			id: 43,
			label: 'Plaza de maniobras',
		},
		{
			name: 'restaurant',
			id: 44,
			label: 'Restaurante',
		},
		{
			name: 'SUM',
			id: 45,
			label: 'SUM',
		},
		{
			name: 'videoclub',
			id: 46,
			label: 'Videoclub',
		},
		{
			name: 'trunk',
			id: 47,
			label: 'Baulera',
		},
		{
			name: 'serviceDependency',
			id: 48,
			label: 'Dependencia de servicio',
		},
		{
			name: 'garden',
			id: 49,
			label: 'Jardín',
		},
		{
			name: 'commercialArea',
			id: 50,
			label: 'Área comercial',
		},
		{
			name: 'heating',
			id: 51,
			label: 'Calefacción',
		},
		{
			name: 'golfField',
			id: 52,
			label: 'Campo de golf',
		},
		{
			name: 'rugbyField',
			id: 53,
			label: 'Cancha de rugby',
		},
		{
			name: 'sportsCourt',
			id: 54,
			label: 'Cancha de deportes',
		},
		{
			name: 'school',
			id: 55,
			label: 'Escuela',
		},
		{
			name: 'crane',
			id: 56,
			label: 'Grúa',
		},
		{
			name: 'laundry',
			id: 57,
			label: 'Laundry',
		},
		{
			name: 'pool',
			id: 58,
			label: 'Piscina',
		},
		{
			name: 'gate',
			id: 59,
			label: 'Portón',
		},
		{
			name: 'gameRoom',
			id: 60,
			label: 'Portón',
		},
		{
			name: 'waterTank',
			id: 61,
			label: 'Tanque de agua',
		},
		{
			name: 'surveillance',
			id: 62,
			label: 'Vigilancia',
		},
		{
			name: 'kitchen',
			id: 63,
			label: 'Cocina',
		},
		{
			name: 'suiteBedroom',
			id: 64,
			label: 'Dormitorio en suite',
		},
		{
			name: 'laundryRoom',
			id: 65,
			label: 'Lavadero',
		},
	];

	const [fields, setFields] = useState(items);

	const [showMore, setShowMore] = useState(false);

	const handleSubmit = (values) => {
		setFields((fields) => [
			...fields,
			{
				name: values.fieldName,
				label: values.fieldName,
				id: values.fieldName,
			},
		]);
	};

	return (
		<div className='d-flex flex-column w-100'>
			<div className='d-flex flex-wrap justify-content-start w-100 p-5 border-bottom'>
				{fields.map((item, index) => {
					if (index < 18) {
						return (
							<div className='d-flex align-items-center col-4 py-3' key={item.id}>
								<label
									className='mx-4 custom-checkbox'
									onClick={() => {
										if (estateData.features.some((id) => id === item.id)) {
											setEstateData((estateData) => ({
												...estateData,
												features: estateData.features.filter(
													(f) => f !== item.id,
												),
											}));
										} else {
											setEstateData((estateData) => ({
												...estateData,
												features: [...estateData.features, item.id],
											}));
										}
									}}>
									<UilCheck
										color={
											estateData.features?.some((id) => id === item.id)
												? '#506CF9'
												: '#fff'
										}
										size={20}
									/>
								</label>
								<p className='mb-0 fs-5 fw-normal-bold'>{item.label}</p>
							</div>
						);
					}
				})}
			</div>

			<h5 className='text-color-lblue fw-bold pt-5 px-5'>OTRAS CARACTERÍSTICAS</h5>
			<div className='d-flex flex-wrap justify-content-start w-100 p-5 border-bottom position-relative'>
				{fields.map((item, index) => {
					if (index < 18 || showMore) {
						return (
							<div className='d-flex align-items-center col-4 py-3' key={item.id}>
								<label
									className='mx-4 custom-checkbox'
									onClick={() => {
										if (estateData.features.some((id) => id === item.id)) {
											setEstateData((estateData) => ({
												...estateData,
												features: estateData.features.filter(
													(f) => f !== item.id,
												),
											}));
										} else {
											setEstateData((estateData) => ({
												...estateData,
												features: [...estateData.features, item.id],
											}));
										}
									}}>
									<UilCheck
										color={
											estateData.features?.some((id) => id === item.id)
												? '#506CF9'
												: '#fff'
										}
										size={20}
									/>
								</label>
								<p className='mb-0 fs-5 fw-normal-bold'>{item.label}</p>
							</div>
						);
					}
				})}
				{/* Agregar campo */}
				{showMore && (
					<Formik
						initialValues={{ fieldName: '' }}
						validate={(values) => {
							const errors = {};

							if (!values.fieldName) {
								errors.fieldName = 'Debe ingresar un nombre para el campo nuevo';
							}

							if (fields.some((field) => field.name === values.fieldName)) {
								errors.fieldName = 'El nombre del campo no puede estar repetido';
							}

							return errors;
						}}
						className=''
						onSubmit={handleSubmit}>
						{({ errors, touched }) => (
							<Form className='col-4 p-4'>
								<div className='d-flex align-items-center p-3 border border-2 border-blue-300 rounded col-12'>
									<UilPlus color='#506CF9' size={24} />
									<Field
										name='fieldName'
										className='no-outline text-color-lblue border-0 fs-5 add-field-input'
										placeholder='Agregar'
									/>
								</div>
								{errors.fieldName && touched.fieldName && (
									<p className='text-color-danger m-3'>{errors.fieldName}</p>
								)}
							</Form>
						)}
					</Formik>
				)}
				{/* Agregar campo */}

				<div
					onClick={() => setShowMore(!showMore)}
					className='position-absolute bottom-0 end-5 cursor-pointer'>
					{showMore ? (
						<UilAngleUp color='#506CF9' size={40} />
					) : (
						<UilAngleDown color='#506CF9' size={40} />
					)}
				</div>
			</div>

			<div className='d-flex align-items-center justify-content-between w-100 p-5'>
				<div
					className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
					onClick={() => setSelectedStep('description')}>
					<UilAngleLeftB color='#fff' size={24} />
					<p className='mb-0 text-color-white fw-bold'>Atrás</p>
				</div>
				<div
					className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
					onClick={() => setSelectedStep('measures')}>
					<p className='mb-0 text-color-white fw-bold'>Siguiente</p>
					<UilAngleRightB color='#fff' size={24} />
				</div>
			</div>
		</div>
	);
}
