import { useState } from 'react';
import {
	UilEllipsisH,
	UilCheck,
	UilEdit,
	UilTrash,
	UilArrowRight,
	UilTimesCircle,
	UilCheckCircle,
} from '@iconscout/react-unicons';
import ReactApexChart from 'react-apexcharts';
import Modal, { ModalHeader } from '../../components/bootstrap/Modal';
import { Star } from 'phosphor-react';
import CustomDropdown from '../../components/CustomDropdown';
// import NoPostsBanner from './NoPostsBanner';
import PaginationButtons from '../../components/PaginationButtons';

export default function PublishedTable({
	selectedEstates,
	setSelectedEstates,
	posts,
	portal,
	filters,
	setFilters,
}) {
	const thList = [
		{
			name: 'Foto',
			nameEng: 'photo',
			isSortable: false,
		},
		{
			name: 'Tipología',
			nameEng: 'type',
			isSortable: false,
		},
		{
			name: 'Dirección',
			nameEng: 'location',
			isSortable: false,
		},

		{
			name: 'Ubicación',
			nameEng: 'neighborhood',
			isSortable: false,
		},
		{
			name: 'Destaque',
			nameEng: 'highlightCategory',
			isSortable: false,
		},
		{
			name: 'Calidad',
			nameEng: 'quality',
			isSortable: false,
		},
		{
			name: 'Link',
			nameEng: 'link',
			isSortable: false,
		},
	];

	const getChartOptions = (value) => {
		return {
			chart: {
				type: 'radialBar',
				// width: 25,
				// height: 25,
				sparkline: {
					enabled: true,
				},
			},
			dataLabels: {
				enabled: false,
			},
			title: {
				text: `${value}%`,
				align: 'center',
				justify: 'center',
				margin: 0,
				offsetX: 0,
				offsetY: 30,
				floating: true,
				style: {
					fontSize: '12px',
					fontWeight: 'bold',
					fontFamily: 'Montserrat',
					color: '#000',
				},
			},
			plotOptions: {
				// radar: {
				// 	size: 500,
				// },
				radialBar: {
					hollow: {
						margin: 0,
						size: '60%',
					},
					track: {
						margin: 0,
						// size: '100%'
					},
					dataLabels: {
						show: false,
					},
				},
			},

			stroke: {
				lineCap: 'round',
			},
			colors: ['#506cf9'],
		};
	};

	const [modalStatus, setModalStatus] = useState(false);

	if (posts.isLoading || posts.isFetching)
		return (
			<div className='d-flex w-100 justify-content-center py-5'>
				<h3>Cargando publicaciones...</h3>
			</div>
		);

	const getBadgeBgColor = (highlight) => {
		const color = portal?.highlightTypes?.find((portal) => portal.name === highlight).color;

		return color;
	};

	return (
		<>
			<table>
				<thead className='bg-lblue'>
					<tr className='d-flex'>
						<th className='col py-2 text-color-lblue fw-bold'>
							<div className='d-flex align-items-center justify-content-center w-100 h-100'>
								<label
									className='custom-checkbox'
									onClick={() => {
										if (selectedEstates.length === posts.data?.data?.length) {
											setSelectedEstates([]);
										} else {
											setSelectedEstates(posts.data?.data);
										}
									}}>
									<UilCheck
										color={
											selectedEstates.length === posts.data?.data?.length &&
											posts.data?.data?.length !== 0
												? '#506CF9'
												: '#fff'
										}
										size={20}
									/>
								</label>
							</div>
						</th>
						{thList.map((th) => (
							<th className='col py-4 text-color-lblue fw-bold estateTh'>
								{
									<div
										className={`d-flex align-items-center justify-content-start w-100 h-100 ${
											th.isSortable &&
											'cursor-pointer text-decoration-underline'
										}`}
										// onClick={() =>
										// 	sortHandler(th.isSortable && th.nameEng)
										// }
									>
										{th.name}
									</div>
									// )
								}
							</th>
						))}
					</tr>
				</thead>
				{/* <tbody> */}
				{posts.data?.data?.length === 0 ? (
					<tr>
						<div className='d-flex justify-content-center w-100 fs-3 fw-bold py-5'>
							No hay inmuebles publicados
						</div>
					</tr>
				) : (
					posts.data?.data?.map((item) => (
						<tr className='d-flex py-2 align-items-center border-bottom'>
							<td className='col pe-2'>
								<div
									className='d-flex align-items-center justify-content-center w-100 h-100 position-relative'
									style={{ left: 3 }}>
									<label
										className='mx-4 custom-checkbox'
										onClick={() => {
											if (
												selectedEstates.some(
													(estate) => estate.id === item.id,
												)
											) {
												setSelectedEstates(
													selectedEstates.filter(
														(estate) => estate.id !== item.id,
													),
												);
											} else {
												// setSelectedEstates((selectedEstates) => [
												// 	...selectedEstates,
												// 	item,
												// ]);
												setSelectedEstates((selectedEstates) => [
													...selectedEstates,
													item,
												]);
											}
										}}>
										<UilCheck
											color={
												selectedEstates.some(
													(estate) => estate.id === item.id,
												)
													? '#506CF9'
													: '#fff'
											}
											size={20}
										/>
									</label>
									<div
										className='position-absolute'
										style={{ marginLeft: '6rem' }}>
										<UilEllipsisH
											color='#506CF9'
											size={25}
											className='cursor-pointer'
											id={`a${item.id}`}
											data-bs-toggle='dropdown'
											aria-expanded='false'
										/>
										{/* Dropdown table row */}
										<div
											className='bg-white-dropdown rounded dropdown-menu dropdown-menu-lg shadow text-color-black'
											aria-labelledby={`a${item.id}`}>
											<div
												className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-top'
												onClick={() => setModalStatus(!modalStatus)}>
												<UilEdit size={26} />{' '}
												<p className='m-0 ps-2'>Administrar destaques</p>
											</div>
											{/* <div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item'>
											<UilArrowCircleUp size={26} />{' '}
											<p className='m-0 ps-2'>Publicar inmueble</p>
										</div> */}
											<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-bottom'>
												<UilTrash size={26} />{' '}
												<p className='m-0 ps-2'>Quitar publicación</p>
											</div>
										</div>
										{/* Dropdown table row */}
									</div>
								</div>
							</td>
							<td className='col pe-2 fw-normal-bold fs-5'>
								<div className='d-flex flex-column align-items-start py-2'>
									<div
										className='rounded'
										style={{
											backgroundImage: `url(${
												item.photo ||
												'https://placehold.co/60x100?text=Sin+Imagen'
											})`,
											backgroundSize: 'cover',
											height: '5vw',
											width: '8vw',
											position: 'relative',
										}}>
										<div
											className='bg-blue-300 py-1 px-2 text-color-white'
											style={{
												position: 'absolute',
												right: 10,
												top: 10,
												borderRadius: '5px',
												fontSize: '10px',
											}}>
											<p className='m-0'>{item.id}</p>
										</div>
									</div>
								</div>
							</td>
							<td className='col pe-2 fw-normal-bold fs-5'>{item.tipology}</td>
							<td className='col pe-2 fw-normal-bold fs-5'>{item.address}</td>
							<td className='col pe-2 fw-normal-bold fs-5'>{item.location}</td>
							<td className='col pe-2 fw-normal-bold fs-5'>
								<div className='d-flex flex-column align-items-start justify-content-center'>
									<p
										className='m-0 text-color-white py-2 px-3 rounded fs-6'
										style={{
											backgroundColor: getBadgeBgColor(item.highlighted),
										}}>
										{item.highlighted}
									</p>
								</div>
							</td>
							<td className='col pe-2 fw-normal-bold fs-5'>
								<ReactApexChart
									series={[item.quality]}
									options={getChartOptions(item.quality)}
									type='radialBar'
									height={75}
									width={75}
									// className='me-3'
								/>
							</td>

							<td className='col pe-2 fw-normal-bold fs-5'>
								<div className='d-flex flex-column align-items-start justify-content-center'>
									<a href={item.url} target='_blank'>
										<div className='d-flex align-items-center m-0 text-color-white bg-blue-300 py-1 px-3 rounded fs-6 w-100 text-truncate'>
											Ver publicación
											<UilArrowRight color='#fff' size={24} />
										</div>
									</a>
								</div>
							</td>
						</tr>
					))
				)}
				<PaginationButtons
					label='items'
					currentPage={posts.data?.paginator.page}
					filtersApplied={filters}
					setFiltersApplied={setFilters}
					totalPage={posts.data?.paginator.total}
				/>
			</table>
			<Modal
				setIsOpen={setModalStatus}
				isOpen={modalStatus}
				titleId='new-todo-modal'
				isCentered>
				<ModalHeader
					setIsOpen={setModalStatus}
					closeButtonColor='#506CF9'
					className='px-5 py-4'>
					<div className='d-flex align-items-center'>
						<Star size={24} color='#506CF9' />
						<p className='mb-0 mt-1 fs-5 ps-2 text-color-lblue fw-bold'>
							DESTACAR PUBLICACIÓN
						</p>
					</div>
				</ModalHeader>

				<div className='d-flex flex-column px-5 py-3'>
					<div className='d-flex align-items-center'>
						<CustomDropdown
							label={'Tipo de destaque'}
							classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600 me-2`}
							dropdownClassname={
								'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom px-4 w-100 position-absolute'
							}
							margin='mb-4'
							width='w-100'>
							<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
								Gold
							</div>
							<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
								Silver
							</div>
						</CustomDropdown>

						<input
							className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 fw-600 blue-placeholder no-outline mb-4 ms-2'
							placeholder='Destaques disponibles'
						/>
					</div>

					<div className='d-flex align-items-center justify-content-end w-100 py-4'>
						<div className='d-flex align-items-center bg-lblue px-3 py-2 rounded-1 mx-2 cursor-pointer'>
							<UilTimesCircle size={24} color='#506CF9' />
							<p className='mb-0 fs-6 ps-1 fw-normal-bold text-color-lblue'>
								Cancelar
							</p>
						</div>
						<div className='d-flex align-items-center blue-gradient-buttons px-3 py-2 rounded-1 cursor-pointer'>
							<UilCheckCircle size={24} color='#fff' />
							<p className='mb-0 fs-6 ps-1  text-color-white'>Confirmar</p>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}
