import React from 'react';
import { UilCheck } from '@iconscout/react-unicons';

export default function Check({ checked = false, clickHandler = () => console.log('Checked!') }) {
	return (
		<label
			className='d-flex justify-content-center align-items-center custom-checkbox'
			onClick={() => clickHandler()}>
			<UilCheck color={checked ? '#506CF9' : 'transparent'} size={20} />
		</label>
	);
}
