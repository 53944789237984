import React from 'react';
import { UilBuilding } from '@iconscout/react-unicons';
import { CheckSquareOffset, FilmSlate, Gear, Notepad, Ruler } from 'phosphor-react';
import CustomDropdown from '../../components/CustomDropdown';
import Area from './Area';
import Prices from './Prices';
import AreaType from './AreaType';
import Features from './Features';
import Measures from './Measures';
import Multimedia from './Multimedia';
import Description from './Description';

export default function EditEstateMobile() {
	return (
		<div className='d-flex flex-column align-items-center w-100 p-4'>
			<div className='d-flex align-items-center justify-content-center w-100 py-4'>
				<UilBuilding size={44} color='#506CF9' />
				<h3 className='text-color-lblue fw-bold ps-2 m-0'>EDITAR INMUEBLE</h3>
			</div>

			{/* Card */}
			<div className='d-flex flex-column align-items-center w-100 rounded shadow'>
				{/* Generales */}
				<div
					className='d-flex flex-column align-items-center w-100 p-4'
					style={{ borderBottom: '1px solid rgba(80, 108, 249, 0.22)' }}>
					<div className='d-flex align-items-center pb-4'>
						<Gear color='#01227d' size={42} />
						<p className='mb-0 px-2 fw-bold fs-5'>Generales</p>
					</div>

					<input
						className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 fw-600 blue-placeholder no-outline mb-4'
						placeholder='Ubicación'
						name='location'
					/>

					<CustomDropdown
						label={'Superficie'}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-white shadow p-4 text-color-lblue rounded-bottom px-4 w-100'
						}
						margin='mb-4'
						width='w-100'>
						<Area />
					</CustomDropdown>

					<CustomDropdown
						label={'Precios'}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-white shadow p-4 text-color-lblue rounded-bottom px-4 w-100'
						}
						margin='mb-4'
						width='w-100'>
						<Prices />
					</CustomDropdown>

					<CustomDropdown
						label={'Otros'}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-white shadow p-4 text-color-lblue rounded-bottom px-4 w-100'
						}
						margin='mb-4'
						width='w-100'>
						<AreaType />
					</CustomDropdown>

					<CustomDropdown
						label={'Emprendimiento'}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom px-4 w-100'
						}
						margin='mb-4'
						width='w-100'>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Emprendimiento 1
						</div>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Emprendimiento 2
						</div>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Emprendimiento 3
						</div>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Emprendimiento 4
						</div>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Emprendimiento 5
						</div>
					</CustomDropdown>

					<CustomDropdown
						label={'Vendedor'}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom px-4 w-100'
						}
						margin='mb-4'
						width='w-100'>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Vendedor 1
						</div>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Vendedor 2
						</div>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Vendedor 3
						</div>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Vendedor 4
						</div>
						<div className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'>
							Vendedor 5
						</div>
					</CustomDropdown>
				</div>
				{/* Generales */}

				{/* Descripción */}
				<div
					className='d-flex flex-column align-items-center w-100 p-4'
					style={{ borderBottom: '1px solid rgba(80, 108, 249, 0.22)' }}>
					<div className='d-flex align-items-center pb-4'>
						<Notepad color='#01227d' size={42} />
						<p className='mb-0 px-2 fw-bold fs-5'>Descripción</p>
					</div>

					<Description />
				</div>
				{/* Descripción */}

				{/* Características */}
				<div
					className='d-flex flex-column align-items-center w-100 p-4'
					style={{ borderBottom: '1px solid rgba(80, 108, 249, 0.22)' }}>
					<div className='d-flex align-items-center pb-4'>
						<CheckSquareOffset color='#01227d' size={42} />
						<p className='mb-0 px-2 fw-bold fs-5'>Características</p>
					</div>

					<div className='d-flex flex-wrap justify-content-start'>
						<Features />
					</div>
				</div>
				{/* Características */}

				{/* Medidas */}
				<div
					className='d-flex flex-column align-items-center w-100 p-4'
					style={{ borderBottom: '1px solid rgba(80, 108, 249, 0.22)' }}>
					<div className='d-flex align-items-center pb-4'>
						<Ruler color='#01227d' size={42} />
						<p className='mb-0 px-2 fw-bold fs-5'>Medidas</p>
					</div>

					<Measures />
				</div>
				{/* Medidas */}

				{/* Multimedia */}
				<div className='d-flex flex-column align-items-center w-100 p-4'>
					<div className='d-flex align-items-center pb-4'>
						<FilmSlate color='#01227d' size={42} />
						<p className='mb-0 px-2 fw-bold fs-5'>Multimedia</p>
					</div>
					<Multimedia />
				</div>
				{/* Multimedia */}
			</div>
			{/* Card */}
			<div className='d-flex justify-content-end w-100 mt-5'>
				<div className='d-flex justify-content-center text-color-lblue bg-blue-100 fw-bold fs-5 rounded-1 px-5 py-3 col-4 me-3'>
					Cancelar
				</div>
				<div className='d-flex justify-content-center text-color-white bg-blue-300 fs-5 rounded-1 px-5 py-3 col-6'>
					Finalizar
				</div>
			</div>
		</div>
	);
}
