import React, { useContext } from 'react';
import InputFloating from '../../../components/InputFloating';
import SwitchThreeOptions from '../../../components/SwitchThreeOptions';
import SearchFilter from '../../../components/Owner/SearchFilter';
import InputCounter from '../../../components/InputCounter';
import CustomSelect from '../../../components/CustomSelect';
import ThemeContext from '../../../contexts/themeContext';

export default function InputSelector({
	data,
	touched,
	item,
	setFiltersApplied,
	// changeHandler,
	// changeHandlerMultipleSelect,
}) {
	const { mobileDesign } = useContext(ThemeContext);

	const changeHandlerMultipleSelect = (value, name) => {
		if (data[name]?.includes(value)) {
			setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				[name]: filtersApplied[name].filter((a) => a !== value),
				page: 1,
			}));
		} else {
			setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				[name]: [...(filtersApplied[name] || []), value],
				page: 1,
			}));
		}
	};

	const changeHandler = (value, name) => {
		setFiltersApplied((filtersApplied) => ({
			...filtersApplied,
			[name]: value,
			page: 1,
		}));
	};

	const clickHandler = (element) => {
		if (data[item.nameEng[0]] === element) {
			return setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				[item.nameEng[0]]: null,
				page: 1,
			}));
		} else {
			return setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				[item.nameEng[0]]: element,
				page: 1,
			}));
		}
	};

	switch (item.type) {
		case 'multipleSelect':
			return (
				<SearchFilter
					text={item.name}
					name={item.nameEng[0]}
					nameEng={item.nameEng[0]}
					values={item.options[item.nameEng[0]]}
					changeHandlerMultipleSelect={changeHandlerMultipleSelect}
					data={data}
				/>
			);
		case 'doubleDate':
			return (
				<div className='d-flex align-items-center w-100'>
					<InputFloating
						changeHandler={changeHandler}
						label='Fecha de ingreso | Desde'
						name='registerDateFrom'
						type='date'
						previousValue={data}
						touched={touched}
						withDebounce
						className='rounded-0 rounded-start'
					/>
					<InputFloating
						changeHandler={changeHandler}
						label='Fecha de ingreso | Hasta'
						name='registerDateTo'
						type='date'
						previousValue={data}
						touched={touched}
						withDebounce
						className='rounded-0 rounded-end'
					/>
				</div>
			);

		case 'text':
			return (
				<InputFloating
					changeHandler={changeHandler}
					label={item.label}
					name={item.name}
					type='text'
					previousValue={data}
					touched={touched}
					withDebounce
				/>
			);
		case 'rangeWithSelect':
			if (mobileDesign) {
				return null;
			} else {
				return (
					<div className='d-flex flex-column w-100'>
						<span className='py-2 fw-normal-bold fs-5'>{item.name}</span>
						<div className='d-flex align-items-center w-100'>
							<CustomSelect
								item={item}
								data={data}
								text={'Moneda'}
								name={'Moneda'}
								nameEng={item.nameEng[0]}
								values={item.options[item.nameEng[0]]}
								changeHandler={changeHandler}
							/>

							<InputFloating
								changeHandler={changeHandler}
								label={'Mín.'}
								name={item.nameEng[1]}
								type='text'
								previousValue={data}
								touched={touched}
								withDebounce
								className='rounded-0'
							/>
							<InputFloating
								changeHandler={changeHandler}
								label={'Máx.'}
								name={item.nameEng[2]}
								type='text'
								previousValue={data}
								touched={touched}
								withDebounce
								className='rounded-0 rounded-end'
							/>
						</div>
					</div>
				);
			}
		case 'rangeWithCustomField':
			if (mobileDesign) {
				return null;
			} else {
				return (
					<div className='d-flex flex-column w-100'>
						<span className='py-2 fw-normal-bold fs-5'>{item.name}</span>
						<div className='d-flex align-items-center w-100'>
							<CustomSelect
								item={item}
								data={data}
								text={item.name}
								name={item.name}
								nameEng={item.nameEng[0]}
								values={item.options[item.nameEng[0]]}
								changeHandler={changeHandler}
							/>

							<InputFloating
								changeHandler={changeHandler}
								label={'Mín.'}
								name={item.nameEng[1]}
								type='text'
								previousValue={data}
								touched={touched}
								withDebounce
								className='rounded-0'
							/>
							<InputFloating
								changeHandler={changeHandler}
								label={'Máx.'}
								name={item.nameEng[2]}
								type='text'
								previousValue={data}
								touched={touched}
								withDebounce
								className='rounded-0 rounded-end'
							/>
						</div>
					</div>
				);
			}

		case 'counter':
			return (
				<InputCounter
					label={item.name}
					value={data[item.nameEng[0]] || 0}
					handleIncrease={() =>
						setFiltersApplied((prev) => ({
							...prev,
							[item.nameEng[0]]: !prev[item.nameEng[0]]
								? 1
								: prev[item.nameEng[0]] + 1,
						}))
					}
					handleDecrease={() =>
						setFiltersApplied((prev) => ({
							...prev,
							[item.nameEng[0]]:
								prev[item.nameEng[0]] == 0 ? 0 : prev[item.nameEng[0]] - 1,
						}))
					}
				/>
			);

		case 'switch':
			return (
				<SwitchThreeOptions
					label={item.name}
					clickHandler={clickHandler}
					data={data}
					nameEng={item.nameEng[0]}
				/>
			);
		default:
			return <h5 className='mb-0'>Type not supported</h5>;
	}
}

// case 'rangeWithSelect':
// 			return (
// 				<div className='d-flex align-items-center w-100'>
// 					<CustomSelect
// 						item={item}
// 						data={data}
// 						text={item.label}
// 						name={item.label}
// 						nameEng={item.name}
// 					/>

// 					<InputFloating
// 						changeHandler={() => console.log('Filtro aplicado!')}
// 						label={item.label}
// 						name={item.name}
// 						type='text'
// 						previousValue={data}
// 						touched={touched}
// 						withDebounce
// 						className='rounded-0'
// 					/>
// 					<InputFloating
// 						changeHandler={() => console.log('Filtro aplicado!')}
// 						label={item.label}
// 						name={item.name}
// 						type='text'
// 						previousValue={data}
// 						touched={touched}
// 						withDebounce
// 						className='rounded-0 rounded-end'
// 					/>
// 				</div>
// 			);
