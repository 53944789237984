import React, { useEffect, useState } from 'react';
import { UilArrowCircleUp, UilAngleUp, UilAngleDown } from '@iconscout/react-unicons';
import usePortals from '../../hooks/usePortals';
import usePosts from '../../hooks/usePosts';
import { useQueryClient } from '@tanstack/react-query';
import PortalCard from './PortalCard';
import PostCardMobile from './PostCardMobile';
import PaginationButtons from '../../components/PaginationButtons';

export default function MobileLayout() {
	const [showMore, setShowMore] = useState(false);

	const [filters, setFilters] = useState({
		query: null,
		isPublished: true, // boolean
		highlights: null,
		portal: 2,
		sort: {
			field: null,
			order: 'ASC',
		},
		page: 1,
		limit: 5,
	});

	const portals = usePortals();

	const posts = usePosts(filters);

	const queryClient = useQueryClient();

	useEffect(() => {
		queryClient.invalidateQueries({
			queryKey: [filters],
			exact: true,
		});
	}, [filters]);

	const getBadgeBgColor = (highlight) => {
		const portal = portals.data.find((portal) => portal.id === filters.portal);

		const color = portal?.highlightTypes?.find((portal) => portal.name === highlight)?.color;

		return color;
	};

	return (
		<>
			<div className='d-flex align-items-center justify-content-center w-100 my-4'>
				<UilArrowCircleUp size={35} color='#506CF9' />
				<h3 className='mb-0 ms-2 fw-bold text-color-lblue'>PUBLICACIONES</h3>
			</div>

			<div className='d-flex flex-column w-100 bg-new-gray mb-4'>
				<div className='d-flex flex-column p-4 w-100'>
					{portals.isLoading || portals.isFetching ? (
						<div className='d-flex w-100 justify-content-center py-5'>
							<h3>Cargando portales...</h3>
						</div>
					) : (
						portals.data.map((portal, index) => {
							if (index < 4 || showMore) {
								return (
									<PortalCard
										portal={portal}
										filters={filters}
										setFilters={setFilters}
									/>
								);
							}
						})
					)}

					<div
						className='d-flex justify-content-center w-100'
						onClick={() => setShowMore(!showMore)}>
						{showMore ? (
							<UilAngleUp size={50} color='#506CF9' />
						) : (
							<UilAngleDown size={50} color='#506CF9' />
						)}
					</div>
				</div>

				<div className={`d-flex flex-column min-vh-50 my-4 mx-4 rounded-3 bg-white`}>
					<div className='d-flex align-items-center w-100 border-bottom position-relative py-4'>
						<div
							className='d-flex align-items-start justify-content-center position-absolute'
							style={{ top: 0, left: '1rem' }}>
							<div
								className={`d-flex align-items-center justify-content-center ${
									filters.isPublished ? 'bg-blue-300' : 'bg-gray-card-posts'
								} py-2 px-3 fs-11 rounded-bottom mx-2`}
								onClick={() =>
									setFilters((prev) => ({ ...prev, isPublished: true }))
								}>
								<p className='fw-bold m-0 text-color-white'>PUBLICADAS</p>
							</div>
							<div
								className={`d-flex align-items-center justify-content-center ${
									!filters.isPublished ? 'bg-blue-300' : 'bg-gray-card-posts'
								} py-2 px-3 fs-11 rounded-bottom mx-2`}
								onClick={() =>
									setFilters((prev) => ({ ...prev, isPublished: false }))
								}>
								<p className='fw-bold m-0 text-color-white'>NO PUBLICADAS</p>
							</div>
						</div>
					</div>

					{posts.isFetching || posts.isLoading ? (
						<div className='d-flex w-100 justify-content-center py-5'>
							<h3>Cargando publicaciones...</h3>
						</div>
					) : (
						posts.data?.data?.map((estate) => (
							<PostCardMobile
								estate={estate}
								filters={filters}
								key={Math.floor(Math.random() * 100000)}
								badgeBg={getBadgeBgColor(estate.highlighted)}
							/>
						))
					)}
				</div>

				{(!posts.isLoading || !posts.isFetching) && (
					<PaginationButtons
						label='items'
						currentPage={posts.data?.paginator.page}
						filtersApplied={filters}
						setFiltersApplied={setFilters}
						totalPage={posts.data?.paginator.total}
					/>
				)}
			</div>
		</>
	);
}
