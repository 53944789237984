import axios from 'axios';

export default function getOperadores() {
	const token = sessionStorage.getItem('token');
	const bearer = `Bearer ${token}`;
	const cabecera = {
		Authorization: bearer,
		'Content-Type': 'application/json',
		Accept: 'application/json',
		
	};
	const data = axios
		.get(`${process.env.REACT_APP_ENDPOINT}/users`, { headers: cabecera })
		.then((res) => {
			return res.data.data;
		});
	return data;
}



export function saveOperador(user) {
	// let testUser={
	// 	name:'Pablo',
	// 	surname:"Carro",
	// 	dni:34275892,
	// 	birthdate:"11/02/1989"
	// }
	console.log("service",user)
	const token = sessionStorage.getItem('token');
	const bearer = `Bearer ${token}`;
	const cabecera = {
		Authorization: bearer,
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};
	const data = axios
		.post(`${process.env.REACT_APP_ENDPOINT}/users/create`,{userData:user},{ headers: cabecera })
		.then((res) => {
			return res;
		});
	return data;
}

// export default function operatorService({ token }) {

// 	return fetch(`${ENDPOINT}`, {
// 		method: 'GET',
// 		withCredentials: true,

// 	})
// 		.then((res) => {
// 			return res.json();
// 		})
// 		.then((res) => {
// 			if (!res) throw new Error('Response is NOT ok');
// 			return res.data;
// 		});
// }

export { getOperadores };
