import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UilPrevious, UilAngleLeft, UilAngleRight, UilStepForward } from '@iconscout/react-unicons';
// import Icon from '../icon/Icon';
import ThemeContext from '../../contexts/themeContext';

export const PaginationItem = forwardRef(
	(
		{
			className,
			isDisabled,
			isActive,
			isPrev,
			isFirst,
			isNext,
			isLast,
			children,
			onClick,
			...props
		},
		ref,
	) => {
		const { mobileDesign } = useContext(ThemeContext);
		return (
			<li
				ref={ref}
				className={classNames(
					{
						disabled: isDisabled,
						active: isActive,
						'p-3': isActive && mobileDesign,
						// 'page-item-mobile': mobileDesign,
						'rounded-circle': mobileDesign,
						'page-item': !mobileDesign,
					},
					className,
				)}
				// className={classNames(
				// 	'page-item',
				// 	{
				// 		disabled: isDisabled,
				// 		active: isActive,
				// 	},
				// 	className,
				// )}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				<div
					role='button'
					onClick={onClick}
					onKeyDown={onClick}
					className={mobileDesign ? 'page-link-mobile' : 'page-link'}
					tabIndex={isDisabled ? '-1' : null}
					style={{
						color: mobileDesign && isActive && 'white',
						height: mobileDesign && isActive && '18px',
						width: mobileDesign && isActive && '18px',
					}}
					aria-disabled={isDisabled ? 'true' : null}
					aria-label={
						(isPrev && 'First Page') || (isNext && 'Last Page') || `${children} page`
					}>
					{isPrev && (
						<div
							className={`p-1 rounded-circle ${
								isDisabled
									? mobileDesign && 'bg-gray-500'
									: mobileDesign && 'bg-blue-300'
							}`}>
							{mobileDesign ? (
								<UilAngleLeft color={isDisabled ? '#6c757d' : '#fff'} />
							) : (
								<UilAngleLeft color={isDisabled ? '#6c757d' : '#506cf9'} />
							)}
						</div>
					)}
					{isFirst && (
						<div
							className={`p-1 rounded-circle ${
								isDisabled
									? mobileDesign && 'bg-gray-500'
									: mobileDesign && 'bg-blue-300'
							}`}>
							{mobileDesign ? (
								<UilPrevious color={isDisabled ? '#6c757d' : '#fff'} />
							) : (
								<UilPrevious color={isDisabled ? '#6c757d' : '#506cf9'} />
							)}
						</div>
					)}

					{isNext && (
						<div
							className={`p-1 rounded-circle ${
								isDisabled
									? mobileDesign && 'bg-gray-500'
									: mobileDesign && 'bg-blue-300'
							}`}>
							{mobileDesign ? (
								<UilAngleRight color={isDisabled ? '#6c757d' : '#fff'} />
							) : (
								<UilAngleRight color={isDisabled ? '#6c757d' : '#506cf9'} />
							)}
						</div>
					)}

					{isLast && (
						<div
							className={`p-1 rounded-circle ${
								isDisabled
									? mobileDesign && 'bg-gray-500'
									: mobileDesign && 'bg-blue-300'
							}`}>
							{mobileDesign ? (
								<UilStepForward color={isDisabled ? '#6c757d' : '#fff'} />
							) : (
								<UilStepForward color={isDisabled ? '#6c757d' : '#506cf9'} />
							)}
						</div>
					)}
					{children}
				</div>
			</li>
		);
	},
);
PaginationItem.propTypes = {
	className: PropTypes.string,
	isDisabled: PropTypes.bool,
	isActive: PropTypes.bool,
	isPrev: PropTypes.bool,
	isFirst: PropTypes.bool,
	isNext: PropTypes.bool,
	isLast: PropTypes.bool,
	children: PropTypes.node,
	onClick: PropTypes.func,
};
PaginationItem.defaultProps = {
	className: null,
	isDisabled: false,
	isActive: false,
	isPrev: false,
	isFirst: false,
	isNext: false,
	isLast: false,
	children: null,
	onClick: null,
};

const Pagination = forwardRef(({ ariaLabel, className, children, size, ...props }, ref) => {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<nav ref={ref} aria-label={ariaLabel} className={className} {...props}>
			<ul className={classNames('pagination', { [`pagination-${size}`]: size }, 'm-0')}>
				{children}
			</ul>
		</nav>
	);
});
Pagination.propTypes = {
	ariaLabel: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	size: PropTypes.oneOf(['sm', 'lg']),
};
Pagination.defaultProps = {
	className: null,
	size: null,
};

export default Pagination;
