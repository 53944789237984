import React from 'react';
import CustomDropdown from '../../components/CustomDropdown';
import Check from '../../components/Check';
import InputCounter from '../../components/InputCounter';
import { UilCalender, UilRulerCombined, UilSnowflake } from '@iconscout/react-unicons';
import { Car, Toilet } from 'phosphor-react';

export default function AreaType() {
	const state = [
		{
			label: 'Especificado',
			id: 30,
		},
		{
			label: 'Bueno',
			id: 31,
		},
		{
			label: 'Construcción',
			id: 32,
		},
		{
			label: 'Estrenar',
			id: 33,
		},
		{
			label: 'Excelente',
			id: 34,
		},
		{
			label: 'Muy bueno',
			id: 35,
		},
		{
			label: 'Reciclado',
			id: 36,
		},
		{
			label: 'Refaccionar',
			id: 37,
		},
		{
			label: 'Regular',
			id: 38,
		},
		{
			label: 'En pozo',
			id: 39,
		},
	];

	return (
		<>
			<div className='d-flex align-items-center w-100'>
				<input
					className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 fw-600 blue-placeholder no-outline me-2 col innerShadowBlueSmall'
					placeholder='Estilo'
				/>
				<input
					className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 fw-600 blue-placeholder no-outline ms-2 col innerShadowBlueSmall'
					placeholder='Año'
				/>
			</div>

			<CustomDropdown
				label={'Estado'}
				classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-5 fw-bold`}
				dropdownClassname={
					'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom px-4 w-100'
				}
				margin='my-4'
				width='w-100'>
				{state.map((item) => (
					<div
						className='d-flex align-items-center py-3 custom-dropdown-item fs-5 fw-bold'
						key={item.id}>
						{item.label}
					</div>
				))}
			</CustomDropdown>

			<div className='d-flex flex-wrap justify-content-start py-4'>
				<div className='d-flex align-items-center col-6'>
					<Check />
					<p className='mb-0 ms-2 fs-5 fw-bold text-color-dark'>Calefacción</p>
				</div>
				<div className='d-flex align-items-center col-6'>
					<Check />
					<p className='mb-0 ms-2 fs-5 fw-bold text-color-dark'>Agua caliente</p>
				</div>
				<div className='d-flex align-items-center col-6 py-4'>
					<Check />
					<p className='mb-0 ms-2 fs-5 fw-bold text-color-dark'>Gas</p>
				</div>
			</div>

			<div className='d-flex flex-column align-items-center w-100'>
				<div className='d-flex align-items-center w-100 mb-4'>
					<UilCalender size='30' className='me-2' />
					<InputCounter
						label='Antigüedad (años)'
						labelClassname='text-color-dark fs-5 mb-0 fw-bold'
					/>
				</div>
				<div className='d-flex align-items-center w-100 mb-4'>
					<UilRulerCombined size='30' className='me-2' />
					<InputCounter
						label='Plantas'
						labelClassname='text-color-dark fs-5 mb-0 fw-bold'
					/>
				</div>
				<div className='d-flex align-items-center w-100 mb-4'>
					<UilSnowflake size='30' className='me-2' />
					<InputCounter
						label='Aire Acon.'
						labelClassname='text-color-dark fs-5 mb-0 fw-bold'
					/>
				</div>
				<div className='d-flex align-items-center w-100 mb-4'>
					<Toilet size='30' className='me-2' />
					<InputCounter
						label='Baños'
						labelClassname='text-color-dark fs-5 mb-0 fw-bold'
					/>
				</div>
				<div className='d-flex align-items-center w-100 mb-4'>
					<Car size='30' className='me-2' />
					<InputCounter
						label='Garage'
						labelClassname='text-color-dark fs-5 mb-0 fw-bold'
					/>
				</div>
			</div>
		</>
	);
}
