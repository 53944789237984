import React, { useState, useContext } from 'react';
import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import { useNavigate } from 'react-router-dom';
import { UilSearch } from '@iconscout/react-unicons';
import { Bell } from 'phosphor-react';
import ThemeContext from '../../../contexts/themeContext';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Input from '../../../components/bootstrap/forms/Input';
import classNames from 'classnames';

const CommonHeaderRight = () => {
	const { mobileDesign, darkModeStatus, setDarkModeStatus } = useContext(ThemeContext);
	const navigate = useNavigate(); // Initialize useNavigate
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	return (
		<HeaderRight className={'col-4'}>
			<div className='d-flex align-items-center'>
				<Button
					{...styledBtn}
					className='botones-navbar'
					icon={darkModeStatus ? 'CustomMoon' : 'CustomSun'}
					onClick={() => setDarkModeStatus(!darkModeStatus)}
				/>

				{/* Notifications Button */}
				<div className='ms-2 me-5 pe-5'>
					<div className='justify-content-center align-items-center'>
						<div
							className={
								'position-absolute d-flex align-items-center justify-content-center rounded-circle  text-color-white bg-danger p-1'
							}
							style={{
								zIndex: 1,
								width: '20px',
								height: '20px',
								marginLeft: '30px',
								fontSize: '12px',
								justifyContent: 'center',
							}}
							color={'danger'}
						>
							3
						</div>
					</div>

					<Button
						{...styledBtn}
						className='botones-navbar'
						icon='CustomBell'
						onClick={() => navigate('/notificaciones')}
						aria-label='Notifications'
					></Button>
				</div>
				{/* Notifications Button */}
				<CommonHeaderChat />
			</div>

			<Modal
				id={'modal1'}
				titleId={'modal1'}
				isOpen={offcanvasStatus} // Example: state
				setIsOpen={setOffcanvasStatus} // Example: setState
				isStaticBackdrop={true}
				isScrollable={true}
				isCentered={true}
				size={'lg'} // 'sm' || 'lg' || 'xl'
				isAnimation={true}
			>
				<ModalHeader
					// className={String}
					setIsOpen={setOffcanvasStatus}
				>
					<ModalTitle className='d-flex col fw-bold'>
						<span className='text-color-lblue '>
							<Bell size={32} /> NOVEDADES
						</span>
					</ModalTitle>
					{!mobileDesign && (
						<div
							className={classNames(
								'd-flex align-items-center rounded-pill py-1 px-2 me-2',
								{ 'search-input-header-dark': darkModeStatus },
								{ 'search-input-header': !darkModeStatus },
							)}
						>
							<label
								className='border-0 bg-transparent cursor-pointer me-0 ms-2'
								htmlFor='searchInput'
							>
								{/* <Icon icon='Search' size='2x' color='blue-300' /> */}
								<UilSearch
									// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
									color='#506CF9'
									size={12}
								/>
							</label>
							<Input
								id='searchInput'
								type='search'
								className='border-0 shadow-none bg-transparent input-search-customers'
								placeholder='Buscar...'
							/>
						</div>
					)}
				</ModalHeader>
				<ModalBody>
					{!mobileDesign ? (
						<div className='row'>
							<table className='w-100 table table-striped table-borderless'>
								<thead
									className='border-none text-color-lblue bg-blue-100 px-4'
									style={{ position: 'sticky', top: '0px' }}
								>
									<th className='py-3'>Acciones</th>
									<th className='py-3'>Sucursal</th>
									<th className='py-3'>Novedad</th>
									<th className='py-3'>Fecha</th>
									<th className='py-3'>Hora</th>
								</thead>
								<tbody className='py-2 px-4'>
									<tr>
										<td className='pt-5 pb-5'>botoncito</td>
										<td className='pt-5 pb-5'>HHH</td>
										<td className='pt-5 pb-5'>
											ALGO PASO? RAJARON A UNO DE LA CASA GRAN DE HERMANO
										</td>
										<td className='pt-5 pb-5'>06/02/2023</td>
										<td className='pt-5 pb-5'>1:24 AM</td>
									</tr>
									<tr>
										<td className='pt-5 pb-5'>botoncito</td>
										<td className='pt-5 pb-5'>HHH</td>
										<td className='pt-5 pb-5'>
											ALGO PASO? RAJARON A UNO DE LA CASA GRAN DE HERMANO
										</td>
										<td className='pt-5 pb-5'>06/02/2023</td>
										<td className='pt-5 pb-5'>1:24 AM</td>
									</tr>
									<tr>
										<td className='pt-5 pb-5'>botoncito</td>
										<td className='pt-5 pb-5'>HHH</td>
										<td className='pt-5 pb-5'>
											ALGO PASO? RAJARON A UNO DE LA CASA GRAN DE HERMANO
										</td>
										<td className='pt-5 pb-5'>06/02/2023</td>
										<td className='pt-5 pb-5 '>1:24 AM</td>
									</tr>
								</tbody>
							</table>
						</div>
					) : (
						<div className='row'>
							<table className='w-100 table table-striped table-borderless'>
								<thead
									className='border-none text-color-lblue bg-blue-100 text-center'
									style={{ position: 'sticky', top: '0px' }}
								>
									<th className='py-3'>Sucursal</th>
									<th className='py-3'>Novedad</th>
								</thead>
								<tbody className='py-2 text-center'>
									<tr>
										<td className='pt-5 pb-5'>HHH</td>
										<td className='pt-5 pb-5'>
											ALGO PASO? RAJARON A UNO DE LA CASA GRAN DE HERMANO
										</td>
									</tr>
									<tr>
										<td className='pt-5 pb-5'>HHH</td>
										<td className='pt-5 pb-5'>
											ALGO PASO? RAJARON A UNO DE LA CASA GRAN DE HERMANO
										</td>
									</tr>
									<tr>
										<td className='pt-5 pb-5'>HHH</td>
										<td className='pt-5 pb-5'>
											ALGO PASO? RAJARON A UNO DE LA CASA GRAN DE HERMANO
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
				</ModalBody>
				<ModalFooter></ModalFooter>
			</Modal>
		</HeaderRight>
	);
};

export default CommonHeaderRight;
