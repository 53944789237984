import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Notepad } from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { UilCog, UilEnvelopeAlt, UilUser, UilWhatsapp } from '@iconscout/react-unicons';
import SearchFilter from '../../components/Owner/SearchFilter';
import { Form, Formik } from 'formik';
import Check from '../../components/Check';
import Switcher from 'react-switcher-rc';

const Clientes = ({ userData, setUserData }) => {
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);
	const [switcherState, setSwitcherState] = useState(false);
	const onHandleChange = (e) => setSwitcherState(e.target.checked);

	// onFileChange = (event) => {
	// 		// onChange={(e) => setFile(URL.createObjectURL(e.target.files[0]),checkFile())}
	// 		//setPreviewImage(URL.createObjectURL(e.target.files[0]);
	// 	// Update the state
	// 	//setFile(event.target.files[0]);

	//   }

	//   onFileUpload = () => {

	// 	// Create an object of formData
	// 	const formData = new FormData();

	// 	// Update the formData object
	// 	formData.append(
	// 	  "profile_image",
	// 	  file,
	// 	  file.name
	// 	);

	// 	// Request made to the backend api
	// 	// Send formData object
	// 	axios.post("api/uploadfile", formData);
	//   };

	function checkFile() {
		// URL.createObjectURL(file)
		// // setPreview(objectUrl)
		// console.log(window.URL.createObjectURL(dataURLToBlob(file)))
	}

	function dataURLToBlob(dataurl) {
		let arr = dataurl.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							<Card className=''>
								<CardHeader className='border-bottom'>
									<CardTitle tag='h4' className='h5 text-color-lblue ms-3'>
										<div className='d-flex align-items-center'>
											<UilCog className='' />
											<p className='my-0 mx-2'>GENERALES</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody className='p-0  table-font-12'>
									<div className='d-flex w-100 bg-new-gray py-2'>
										<div className='col-6 d-flex'>
											<div className='col-8 ms-5'>
												Restringe carga de personal por e-mail
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex'>
											<div className='col-9 '>
												Enviar respuesta a mercado libre automáticamente
											</div>
											<Check />
										</div>
									</div>
									<div className='d-flex w-100 py-2'>
										<div className='col-6 d-flex'>
											<div className='col-8 ms-5'>
												Restringe carga de personas por teléfono/celular
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex'>
											<div className='col-9'>
												Enviar respuesta a Gallito automáticamente
											</div>
											<Check />
										</div>
									</div>
									<div className='d-flex w-100 bg-new-gray py-2'>
										<div className='col-6 d-flex'>
											<div className='col-8 ms-5'>
												Permite modificar fecha ingreso interesado
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex'>
											<div className='col-9'>
												Enviar correo de presentación a interesados
												automáticamante
											</div>
											<Check />
										</div>
									</div>
									<div className='d-flex w-100 py-2'>
										<div className='col-6 d-flex'>
											<div className='col-8 ms-5'>
												Muestra clientes inactivos en listado
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex'>
											<div className='col-9'>
												Contactar a posibles interesados automáticamente al
												publicar inmueble
											</div>
											<Check />
										</div>
									</div>
									<div className='d-flex w-100 bg-new-gray py-2 rounded-bottom'>
										<div className='col-6 d-flex'>
											<div className='col-8 ms-5'>
												Enviar correo de bienvenida a interesados
												automáticamente
											</div>
											<Check />
										</div>
										<div className='col-6 d-flex'>
											<div className='col-9'>
												Clientes sin vendedor, visibles para todos los
												operadores
											</div>
											<Check />
										</div>
									</div>
								</CardBody>
							</Card>
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilUser className='' />
													<p className='my-0 mx-2'>OPORTUNIDADES</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='bg-new-gray  table-font-12'>
											<div className='d-flex row'>
												<div className='d-flex align-items-center col-6'>
													<label className='col-8'>
														Operador que recibe SU PEDIDO (sin
														tipología)
													</label>
													<div className='ms-4 col-3'>
														<SearchFilter
															className='col-3'
															style={{
																backgroundColor: 'red !important',
															}}
															text={'Candela Ola'}
															name={'Operación'}
															nameEng={'Operation'}
															changeHandlerMultipleSelect={() =>
																console.log('')
															}
															data={userData}
															bgColor={''}
															withSearch={false}
															values={[
																{
																	id: 1,
																	label: 'Candela Ola',
																},
																{
																	id: 2,
																	label: 'Candela Ola ',
																},
																{
																	id: 3,
																	label: 'Candela Ola',
																},
															]}
														/>
													</div>
												</div>
												<div className='d-flex align-items-center col-6'>
													<label className='col-9'>
														Operador que recibe SU PEDIDO (alquiler)
													</label>
													<div className='ms-1 col-3'>
														<SearchFilter
															className='col-3'
															style={{
																backgroundColor: 'red !important',
															}}
															text={'Candela Ola'}
															name={'Operación'}
															nameEng={'Operation'}
															changeHandlerMultipleSelect={() =>
																console.log('')
															}
															data={userData}
															bgColor={''}
															withSearch={false}
															values={[
																{
																	id: 1,
																	label: 'Candela Ola',
																},
																{
																	id: 2,
																	label: 'Candela Ola ',
																},
																{
																	id: 3,
																	label: 'Candela Ola',
																},
															]}
														/>
													</div>
												</div>
												<div className='d-flex align-items-center col-6 mt-4'>
													<label className='col-8'>
														Operador que recibe SU PEDIDO (venta)
													</label>
													<div className='ms-4 col-3'>
														<SearchFilter
															className='col-3'
															style={{
																backgroundColor: 'red !important',
															}}
															text={'Candela Ola'}
															name={'Operación'}
															nameEng={'Operation'}
															changeHandlerMultipleSelect={() =>
																console.log('')
															}
															data={userData}
															bgColor={''}
															withSearch={false}
															values={[
																{
																	id: 1,
																	label: 'Candela Ola',
																},
																{
																	id: 2,
																	label: 'Candela Ola ',
																},
																{
																	id: 3,
																	label: 'Candela Ola',
																},
															]}
														/>
													</div>
												</div>
												<div className='d-flex align-items-center col-6 mt-4'>
													<label className='col-9'>
														Operador que recibe SU PEDIDO (alquiler
														temporario)
													</label>
													<div className='ms-1 col-3'>
														<SearchFilter
															className='col-3'
															style={{
																backgroundColor: 'red !important',
															}}
															text={'Candela Ola'}
															name={'Operación'}
															nameEng={'Operation'}
															changeHandlerMultipleSelect={() =>
																console.log('')
															}
															data={userData}
															bgColor={''}
															withSearch={false}
															values={[
																{
																	id: 1,
																	label: 'Alquiler',
																},
																{
																	id: 2,
																	label: 'Alquiler temporario ',
																},
																{
																	id: 3,
																	label: 'Venta',
																},
															]}
														/>
													</div>
												</div>
											</div>
											<div className='d-flex row mt-5'>
												<div className='col-8 d-flex align-items-center'>
													<label classname='col-5'>
														SU PEDIDO DINÁMICO
													</label>
													<Switcher
														className='ms-4'
														name='my-switcher'
														onChange={onHandleChange}
														checkedIcon=' '
														unCheckedIcon={null}
														checked={switcherState}
														onColor='#506CF9'
													/>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>
							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilWhatsapp className='' />
													<p className='my-0 mx-2'>WHATSAPP</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='bg-new-gray  table-font-12'>
											<div className='d-flex row ms-3'>
												<div className='d-flex align-items-center col-5'>
													<label className='col-7'>
														Operador que recibe nuevos chats
													</label>
													<div className='ms-4 col-3'>
														<SearchFilter
															className='col-10'
															style={{
																backgroundColor: 'red !important',
															}}
															text={'Operadores'}
															name={'Operación'}
															nameEng={'Operation'}
															changeHandlerMultipleSelect={() =>
																console.log('')
															}
															data={userData}
															bgColor={''}
															withSearch={false}
															values={[
																{
																	id: 1,
																	label: 'Alquiler',
																},
																{
																	id: 2,
																	label: 'Alquiler temporario ',
																},
																{
																	id: 3,
																	label: 'Venta',
																},
															]}
														/>
													</div>
												</div>
												<div className='d-flex align-items-center col-7'>
													<label className='col-10 fs-6'>
														Enviar a portales el número de WhatsApp como
														celular de contacto
													</label>
													<div className='col-2'>
														<Switcher
															className=''
															name='my-switcher'
															onChange={onHandleChange}
															checkedIcon=' '
															unCheckedIcon={null}
															checked={switcherState}
															onColor='#506CF9'
														/>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							<div className='d-flex'>
								<div className='col-12 '>
									<Card className=''>
										<CardHeader className='border-bottom'>
											<CardTitle tag='h4' className='h5 ms-3'>
												<div className='d-flex align-items-center text-color-lblue'>
													<UilUser className='' />
													<p className='my-0 mx-2'>ESTADO DE CLIENTES</p>
												</div>
											</CardTitle>
										</CardHeader>
										<CardBody className='bg-new-gray'>
											<div className='d-flex row justify-content-center'>
												<div
													className='scroll-container d-flex justify-content-center'
													style={{ overflowX: 'auto' }}
												>
													<div className='col-2 card rounded-1 d-flex flex-column align-items-start '>
														<div
															className='progress col-11 mt-3 ms-3'
															role='progressbar'
															aria-label='Example 1px high'
															aria-valuenow='25'
															aria-valuemin='0'
															aria-valuemax='100'
															style={{ height: '8px' }}
														>
															<div
																className='progress-bar'
																style={{
																	width: '75%',
																	color: '#ffff',
																	backgroundColor: '#506CF9',
																}}
															></div>
														</div>
														<div className='mt-5 ms-3'>
															<h4 className='fw-600'>
																No contactado
															</h4>
															<p>511 interesados</p>
														</div>
													</div>
													<div className='col-2 ms-3 card rounded-1 d-flex flex-column align-items-start '>
														<div
															className='progress col-11 mt-3 ms-3'
															role='progressbar'
															aria-label='Example 1px high'
															aria-valuenow='25'
															aria-valuemin='0'
															aria-valuemax='100'
															style={{ height: '8px' }}
														>
															<div
																className='progress-bar'
																style={{
																	width: '75%',
																	color: '#ffff',
																	backgroundColor: '#BA1A1A',
																}}
															></div>
														</div>
														<div className='mt-5 ms-3'>
															<h4 className='fw-600'>Olvidado</h4>
															<p>511 interesados</p>
														</div>
													</div>
													<div className='col-2 ms-3 card rounded-1 d-flex flex-column align-items-start '>
														<div
															className='progress col-11 mt-3 ms-3'
															role='progressbar'
															aria-label='Example 1px high'
															aria-valuenow='25'
															aria-valuemin='0'
															aria-valuemax='100'
															style={{ height: '8px' }}
														>
															<div
																className='progress-bar'
																style={{
																	width: '75%',
																	color: '#ffff',
																	backgroundColor: '#FFBF00',
																}}
															></div>
														</div>
														<div className='mt-5 ms-3'>
															<h4 className='fw-600'>En proceso</h4>
															<p>511 interesados</p>
														</div>
													</div>
													<div className='col-2 ms-3 card rounded-1 d-flex flex-column align-items-start '>
														<div
															className='progress col-11 mt-3 ms-3'
															role='progressbar'
															aria-label='Example 1px high'
															aria-valuenow='25'
															aria-valuemin='0'
															aria-valuemax='100'
															style={{ height: '8px' }}
														>
															<div
																className='progress-bar'
																style={{
																	width: '75%',
																	color: '#ffff',
																	backgroundColor: '#20B21D',
																}}
															></div>
														</div>
														<div className='mt-5 ms-3'>
															<h4 className='fw-600'>Final</h4>
															<p>511 interesados</p>
														</div>
													</div>
													<div className='col-2 ms-3 card rounded-1 d-flex flex-column align-items-start '>
														<div
															className='progress col-11 mt-3 ms-3'
															role='progressbar'
															aria-label='Example 1px high'
															aria-valuenow='25'
															aria-valuemin='0'
															aria-valuemax='100'
															style={{ height: '8px' }}
														>
															<div
																className='progress-bar'
																style={{
																	width: '75%',
																	color: '#ffff',
																	backgroundColor: '#626262',
																}}
															></div>
														</div>
														<div className='mt-5 ms-3'>
															<h4 className='fw-600'>Cerrado</h4>
															<p>511 interesados</p>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							{/* Footer */}
							{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Clientes;
