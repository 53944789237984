import React, { useContext, useState } from 'react';
import ThemeContext from '../../contexts/themeContext';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import Footer from '../customers/createCustomer/Footer';
import ChildrenSelectorEstate from './ChildrenSelectorEstate';
import { UilEstate, UilGlobe, UilSearch } from '@iconscout/react-unicons';
import Button from '../../components/bootstrap/Button';
import classNames from 'classnames';
import Input from '../../components/bootstrap/forms/Input';
import CustomSwitchTabMobile from '../../components/CustomSwitchTabMobile';
import { useNavigate } from 'react-router-dom';

function EstatePage() {
	const [filtersMenu, setFiltersMenu] = useState(false);
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const [selectedTab, setSelectedTab] = useState('ACTIVAS');

	const tabs = [
		{ name: 'ACTIVAS' },
		{ name: 'TASACIONES' },
		{ name: 'HISTÓRICO' },
		{ name: 'RESERVAS' },
		{ name: 'SUSPENSIONES' },
		{ name: 'RED COMPARTIDA' },
	];

	const navigate = useNavigate();

	return (
		<PageWrapper title='Inmuebles'>
			<Page container='fluid'>
				{mobileDesign ? (
					<>
						<div className='d-flex align-items-center justify-content-center w-100 pb-4'>
							<UilEstate size={44} color='#506CF9' />
							<h3 className='text-color-lblue fw-bold ps-2 m-0'>INMUEBLES</h3>
						</div>

						<div
							className='d-flex flex-column align-items-center justify-content-center w-100 gradient-lblue rounded-top-2 p-4'
							// style={{
							// 	height: '7rem',
							// }}
						>
							{/* Buttons */}
							<div className='d-flex flex-column align-items-center justify-content-center w-100'>
								<div className='d-flex align-items-center justify-content-center botones-alerta col-12'>
									<Button
										// size='sm'
										icon='CustomFilter'
										className='me-1 col-6'
										color='primary'
										onClick={() => setFiltersMenu(!filtersMenu)}>
										Filtros
									</Button>
									<Button
										// size='sm'
										icon='CustomUserPlus'
										className='ms-1 col-6'
										color='info'
										tag='a'
										onClick={() => navigate('/propiedades/inmuebles/crear')}>
										Nuevo
									</Button>
								</div>
								<div className='d-flex align-items-center justify-content-center botones-alerta col-12 my-2'>
									{/* <div className='d-flex align-items-center justify-content-center bg-orange p-2 col-6 rounded me-1'>
										<UilGlobe size={25} color='#FFBA69' />
										<h6 className='m-0 text-color-white'>Red compartida</h6>
									</div> */}
									<div
										className={classNames(
											'd-flex align-items-center rounded py-1 px-2 col-12 bg-lblue',
											{ 'search-input-header-dark': darkModeStatus },
											{ 'search-input-header': !darkModeStatus },
										)}>
										<label
											className='border-0 cursor-pointer me-0 ms-2 bg-lblue'
											htmlFor='searchInput'>
											<UilSearch color='#506CF9' size={18} />
										</label>
										<Input
											id='searchInput'
											type='search'
											className='border-0 shadow-none input-search-customers bg-lblue'
											placeholder='Buscar inmueble...'
										/>
									</div>
								</div>
							</div>
							{/* Buttons */}

							<CustomSwitchTabMobile
								name='EstateTabs'
								selectedTab={selectedTab}
								setSelectedTab={setSelectedTab}
								tabs={tabs}
								size={2}
							/>

							{/* <select
								onChange={(e) => setSelectedTab(e.target.value)}
								style={{ 'text-align': 'center' }}
								className='bg-blue-300 text-color-white p-2 mt-1 w-100 col-8 fs-5 fw-normal rounded-1 border-0'>
								{tabs.map((tab) => (
									<option value={tab.name} key={tab.name}>
										{tab.name}
									</option>
								))}
							</select> */}
						</div>
					</>
				) : (
					<>
						<SubheaderNew
							breadCrumbs={[
								{ name: 'Propiedades', url: '/propiedades/inmuebles' },
								{ name: 'Inmuebles', url: '/propiedades/inmuebles' },
							]}
							filtersFunc={setFiltersMenu}
							filtersStatus={filtersMenu}
							newButtonUrl='/propiedades/inmuebles/crear'
							withFiltersButton
							withNewButton
							withExportButton
						/>

						<div className='d-flex fw-bold'>
							{tabs
								.filter((tab) =>
									mobileDesign ? tab : tab.name !== 'RED COMPARTIDA',
								)
								.map((tab) => (
									<div
										key={tab.name}
										className={`${
											selectedTab === tab.name ? 'bg-lblue' : 'bg-lblue-065'
										}  px-4 py-3 me-1 rounded-top-lg cursor-pointer`}
										onClick={() => setSelectedTab(tab.name)}>
										{tab.name}
									</div>
								))}
						</div>
					</>
				)}
				<div
					className={`d-flex flex-column ${
						!mobileDesign && 'bg-lblue rounded-right-and-bottom p-4'
					} w-100 mb-3`}>
					<ChildrenSelectorEstate
						selectedTab={selectedTab}
						filtersMenu={filtersMenu}
						setFiltersMenu={setFiltersMenu}
					/>
					{/* <Footer /> */}
				</div>
			</Page>
		</PageWrapper>
	);
}

export default EstatePage;
