import React from 'react';

const CustomShareBox = (props) => {
	return (
		<svg
			viewBox='0 0 24 24'
			fill='#fff'
			xmlns='http://www.w3.org/2000/svg'
			className='svg-icon'
			{...props}>
			<path
				fill='#FFFFFF'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M7.25022 15.0507C7.24195 15.3948 7.46896 15.7004 7.8008 15.7918C8.13265 15.8832 8.48412 15.737 8.65323 15.4372L9.55554 13.8377C10.6256 11.9407 12.7558 10.9015 14.9095 11.2256L15.0588 11.2481C15.0857 11.9259 15.1238 12.6035 15.1731 13.2804L15.241 14.211C15.2961 14.9678 16.14 15.3909 16.7794 14.9823C18.8771 13.6419 20.7007 11.9149 22.153 9.8931L22.6091 9.25816C22.797 8.99669 22.797 8.64451 22.6091 8.38303L22.153 7.7481C20.7007 5.72634 18.8771 3.99925 16.7794 2.65889C16.14 2.25031 15.2961 2.67338 15.241 3.43019L15.1731 4.36083C15.1333 4.90656 15.1009 5.45269 15.0757 5.99907L14.0575 5.99907C10.3928 5.99907 7.39754 8.92317 7.30946 12.5868L7.25022 15.0507ZM15.1328 9.74232C12.7135 9.3782 10.3172 10.3522 8.83294 12.2306C9.09455 9.56748 11.3393 7.49907 14.0575 7.49907L15.7962 7.49907C16.2004 7.49907 16.532 7.17871 16.5458 6.77471C16.5721 6.00591 16.6132 5.23749 16.6692 4.46989L16.6746 4.39543C18.318 5.55709 19.7583 6.98548 20.9348 8.62323L21.0765 8.8206L20.9348 9.01797C19.7583 10.6557 18.318 12.0841 16.6746 13.2458L16.6692 13.1713C16.6062 12.3074 16.562 11.4425 16.5366 10.5772C16.5259 10.2146 16.2573 9.91156 15.8985 9.85757L15.1328 9.74232Z'
			/>
			<path
				d='M19.6413 17.4113C19.7593 16.403 19.8425 15.3916 19.8911 14.379C19.8963 14.2694 19.946 14.1667 20.0283 14.0941C20.3917 13.7733 20.7438 13.44 21.0839 13.0948C21.2119 12.9649 21.4354 13.0557 21.4315 13.238C21.4003 14.6899 21.3002 16.1407 21.1312 17.5855C20.8946 19.6081 19.2703 21.1931 17.2582 21.418C13.7917 21.8054 10.2083 21.8054 6.74175 21.418C4.7297 21.1931 3.10536 19.6081 2.86881 17.5855C2.45427 14.0412 2.45427 10.4607 2.86881 6.9164C3.10536 4.89387 4.7297 3.30886 6.74175 3.08399C9.03273 2.82794 11.3747 2.7411 13.7003 2.82349C13.8961 2.83042 14.0309 3.0221 14.0064 3.2165C14.0014 3.25698 13.9973 3.29793 13.9943 3.33932L13.943 4.04314C13.9312 4.20473 13.7933 4.32767 13.6314 4.32199C11.3843 4.24315 9.12116 4.32739 6.90836 4.5747C5.57826 4.72336 4.51277 5.77298 4.35866 7.09065C3.95766 10.5192 3.95766 13.9828 4.35866 17.4113C4.51277 18.729 5.57826 19.7786 6.90836 19.9272C10.2642 20.3023 13.7358 20.3023 17.0916 19.9272C18.4217 19.7786 19.4872 18.729 19.6413 17.4113Z'
				fill='#FFFFFF'
			/>
		</svg>
	);
};

export default CustomShareBox;
