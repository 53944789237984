import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height, color }) => {
	return (
		<svg
			version='1.1'
			id='Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			x='0px'
			y='0px'
			width={height !== 854 ? height * (2155 / 854) : width}
			height={width !== 2155 ? width * (854 / 2155) : height}
			viewBox='-22.116 71.035 882.366 211.276'>
			<g>
				<g>
					<path
						fill='#4D6EF7'
						d='M16.035,101.133c-22.742,18.897-37.409,47.177-38.151,78.884c0.742,29.699,15.409,59.983,38.151,78.881
			l49.078-78.881L16.035,101.133z'
					/>
					<path
						fill='#0030B8'
						d='M124.4,84.899c-12.554-5.249-26.338-8.167-40.797-8.167c-10.666,0-20.943,1.598-30.655,4.531
			l61.445,98.754l-61.445,98.257c9.712,2.938,19.989,4.038,30.655,4.038c14.458,0,28.243-2.422,40.797-7.671l59.07-94.624
			L124.4,84.899z'
					/>
				</g>
				<g>
					<path
						fill={color}
						d='M289.126,250.617l-24.22-33.816l-19.186,33.816h-51.567L239,180.826l-46.766-64.757h52.763l24.22,33.816
			l19.431-33.816h51.567l-45.81,68.595l47.49,65.953H289.126z'
					/>
					<path fill={color} d='M401.925,116.069v134.548h-47.005V116.069H401.925z' />
					<path
						fill={color}
						d='M546.545,130.341c9.111,10.313,13.674,24.263,13.674,41.849v78.428h-47.006v-72.191
			c0-7.671-2.005-13.708-6.003-18.11c-3.992-4.396-9.351-6.595-16.063-6.595c-7.039,0-12.558,2.198-16.55,6.595
			c-3.998,4.402-5.997,10.439-5.997,18.11v72.191h-47.011V116.069h47.011v19.189c4.158-6.073,9.75-10.997,16.783-14.756
			c7.039-3.752,15.113-5.629,24.225-5.629C525.115,114.874,537.433,120.03,546.545,130.341z'
					/>
					<path
						fill={color}
						d='M658.309,210.567v40.05h-20.388c-34.381,0-51.563-17.025-51.563-51.086v-44.366h-16.549v-39.096h16.549
			V71.035h47.253v45.034h24.459v39.096h-24.459v45.086c0,3.683,0.833,6.319,2.514,7.913c1.68,1.604,4.513,2.403,8.511,2.403H658.309
			z'
					/>
					<path
						fill={color}
						d='M800.534,192.577h-89.229c0.644,7.521,2.766,13.07,6.364,16.669c3.6,3.594,8.192,5.396,13.79,5.396
			c7.824,0,13.429-3.514,16.783-10.556h50.126c-2.078,9.28-6.113,17.552-12.11,24.827c-5.996,7.278-13.551,12.993-22.662,17.146
			c-9.111,4.164-19.187,6.236-30.224,6.236c-13.274,0-25.06-2.799-35.373-8.395c-10.313-5.595-18.39-13.591-24.221-23.98
			c-5.843-10.394-8.756-22.626-8.756-36.701c0-14.066,2.882-26.259,8.634-36.572c5.751-10.311,13.79-18.267,24.104-23.865
			c10.313-5.592,22.185-8.391,35.612-8.391c13.275,0,25.023,2.719,35.258,8.152c10.233,5.436,18.229,13.235,23.987,23.383
			c5.751,10.16,8.633,22.028,8.633,35.615C801.25,185.226,801.011,188.904,800.534,192.577z M753.283,171.473
			c0-6.398-1.919-11.313-5.758-14.75c-3.832-3.434-8.634-5.157-14.392-5.157c-5.91,0-10.754,1.681-14.507,5.037
			c-3.759,3.357-6.12,8.312-7.076,14.87H753.283z'
					/>
					<path fill={color} d='M860.25,71.035v179.582l-47.012-2.101V71.035H860.25z' />
				</g>
			</g>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	color: PropTypes.string,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	color: '#fff',
};

export default Logo;
