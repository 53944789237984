import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import InputFloating from '../../components/InputFloating';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Notepad } from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import {
	UilFile,
	UilTag,
	UilPlusCircle,
	UilSearch,
	UilTrash,
	UilCompressPoint,
	UilEdit,
	UilEstate,
	UilUser,
} from '@iconscout/react-unicons';
import SearchFilter from '../../components/Owner/SearchFilter';
import { Form, Formik } from 'formik';
import Check from '../../components/Check';
import Switcher from 'react-switcher-rc';

const Etiquetas = ({ userData, setUserData }) => {
	const [file, setFile] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);
	const [switcherState, setSwitcherState] = useState(false);
	const onHandleChange = (e) => setSwitcherState(e.target.checked);

	return (
		<>
			<Formik initialValues={[]}>
				{({ touched }) => (
					<Form>
						<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
							<Card className=''>
								<CardHeader className='border-bottom'>
									<CardTitle tag='h4' className='h5 text-color-lblue ms-3'>
										<div className='d-flex align-items-center'>
											<UilTag className='' />
											<p className='my-0 mx-2'>ETIQUETAS</p>
										</div>
									</CardTitle>
								</CardHeader>
								<CardBody className='p-0'>
									<div className='row p-3 d-flex'>
										<div className='col-8 ms-4'>
											<p>
												Creá etiquetas para segmentar propiedades o usarlas
												como atributos personalizados. ¡Podrás filtrar en el
												buscador por estas etiquetas!
											</p>
										</div>
										<div className='col-2 ms-4'>
											<div className='float-end botones-alerta'>
												<button className='btn btn-sm btn-light-info'>
													<UilPlusCircle /> Nueva Etiqueta
												</button>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>

							<Card className='rounded-1 bg-new-gray'>
								<div className='d-flex'>
									<div className='d-flex col-7 p-3 px-5'>
										<Check />
										<div className='d-flex px-4'>
											<button className='d-flex align-items-center ms-3 rounded-1 bg-white border-0'>
												{' '}
												<UilTrash color={'#506CF9'} className='me-2' />
												Borrar etiquetas{' '}
											</button>
											<button className='d-flex align-items-center ms-3 rounded-1 bg-white border-0'>
												{' '}
												<UilCompressPoint
													color={'#506CF9'}
													className='me-2'
												/>
												Unificar etiquetas{' '}
											</button>
											<button className='d-flex align-items-center ms-3 rounded-1 bg-white border-0'>
												<UilEdit
													size={20}
													color={'#506CF9'}
													className='me-2'
												/>
												Editar etiquetas{' '}
											</button>
										</div>
									</div>

									<div className='d-flex col-4  align-items-center justify-content-end'>
										{/* Search input */}
										<div
											className={
												'd-flex align-items-center rounded-pill py-1 px-2 search-input-header-white'
											}
										>
											<label
												className='border-0 bg-transparent cursor-pointer me-0 ms-2'
												htmlFor='searchInput'
											>
												{/* <Icon icon='Search' size='2x' color='blue-300' /> */}
												<UilSearch
													// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
													color='#506CF9'
													size={12}
												/>
											</label>
											<Input
												id='searchInput'
												type='search'
												className='border-0 shadow-none bg-transparent input-search-customers'
												placeholder='Buscar...'
											/>
										</div>
										{/* Search input */}
									</div>
								</div>
							</Card>

							<Card className='rounded-1 bg-new-gray'>
								<div className='d-flex'>
									<div className='d-flex col-7 p-3 px-5 align-items-center'>
										<Check checked={true} />
										<div className='d-flex px-4'>
											<span>Caaguazú 6425 - Liniers</span>
										</div>
										<div className='d-flex px-4'>
											<UilEstate
												size={20}
												color={'#506CF9'}
												className='me-2'
											/>
											<span>5 propiedades</span>
										</div>
										<div className='d-flex px-4'>
											<UilUser size={20} color={'#506CF9'} className='me-2' />
											<span>30 personas</span>
										</div>
									</div>
								</div>
							</Card>
							<Card className='rounded-1 bg-new-gray'>
								<div className='d-flex'>
									<div className='d-flex col-7 p-3 px-5 align-items-center'>
										<Check checked={true} />
										<div className='d-flex px-4'>
											<span>Caaguazú 6425 - Liniers</span>
										</div>
										<div className='d-flex px-4'>
											<UilEstate
												size={20}
												color={'#506CF9'}
												className='me-2'
											/>
											<span>5 propiedades</span>
										</div>
										<div className='d-flex px-4'>
											<UilUser size={20} color={'#506CF9'} className='me-2' />
											<span>30 personas</span>
										</div>
									</div>
								</div>
							</Card>
							<Card className='rounded-1 bg-new-gray'>
								<div className='d-flex'>
									<div className='d-flex col-7 p-3 px-5 align-items-center'>
										<Check checked={true} />
										<div className='d-flex px-4'>
											<span>Caaguazú 6425 - Liniers</span>
										</div>
										<div className='d-flex px-4'>
											<UilEstate
												size={20}
												color={'#506CF9'}
												className='me-2'
											/>
											<span>5 propiedades</span>
										</div>
										<div className='d-flex px-4'>
											<UilUser size={20} color={'#506CF9'} className='me-2' />
											<span>30 personas</span>
										</div>
									</div>
								</div>
							</Card>

							{/* Footer */}
							{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Etiquetas;
