import { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './mapStyles.css';
import L from 'leaflet';

const markerIcon = L.icon({
	iconUrl: 'https://www.agroavisos.net/wp-content/uploads/2017/04/map-marker-icon.png',
	iconSize: [40, 40],
	iconAnchor: [12, 41],
	popupAnchor: [8, -40],
	shadowSize: [41, 41],
});

export default function MapLayout({ coord, estateData }) {
	function SetViewOnClick({ coords }) {
		const map = useMap();

		useEffect(() => {
			map.setView(coords, map.getZoom());
		}, [coord]);

		return null;
	}

	return (
		<MapContainer center={coord} zoom={15} scrollWheelZoom={false}>
			{estateData.operationType.coordinates && (
				<Marker position={coord} icon={markerIcon}></Marker>
			)}
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				// url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				url='https://maps.geoapify.com/v1/tile/klokantech-basic/{z}/{x}/{y}.png?apiKey=035e61bc6b9948188ef5937de57bfe77'
			/>
			<SetViewOnClick coords={coord} />
		</MapContainer>
	);
}
