import React, { useContext, useState } from 'react';
import ThemeContext from '../../contexts/themeContext';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import { UilEstate, UilSearch } from '@iconscout/react-unicons';
import Button from '../../components/bootstrap/Button';
import classNames from 'classnames';
import Input from '../../components/bootstrap/forms/Input';
import Active from './active/Active';

function VenturesPage() {
	const [filtersMenu, setFiltersMenu] = useState(false);
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);

	return (
		<PageWrapper title='Emprendimientos'>
			<Page container='fluid'>
				{mobileDesign ? (
					<>
						<div className='d-flex align-items-center justify-content-center w-100 pb-4'>
							<UilEstate size={44} color='#506CF9' />
							<h3 className='text-color-lblue fw-bold ps-2 m-0'>EMPRENDIMIENTOS</h3>
						</div>

						<div
							className='d-flex flex-column align-items-center justify-content-center w-100 gradient-lblue rounded-top-2 p-4'
							// style={{
							// 	height: '7rem',
							// }}
						>
							{/* Buttons */}
							<div className='d-flex flex-column align-items-center justify-content-center w-100'>
								<div className='d-flex align-items-center justify-content-center botones-alerta col-12'>
									<Button
										// size='sm'
										icon='CustomFilter'
										className='me-1 col-6'
										color='primary'
										onClick={() => setFiltersMenu(!filtersMenu)}>
										Filtros
									</Button>
									<Button
										// size='sm'
										icon='CustomUserPlus'
										className='ms-1 col-6'
										color='info'
										tag='a'>
										Nuevo
									</Button>
								</div>
								<div className='d-flex align-items-center justify-content-center botones-alerta col-12 my-2'>
									<div
										className={classNames(
											'd-flex align-items-center rounded py-1 px-2 col-12 bg-lblue',
											{ 'search-input-header-dark': darkModeStatus },
											{ 'search-input-header': !darkModeStatus },
										)}>
										<label
											className='border-0 cursor-pointer me-0 ms-2 bg-lblue'
											htmlFor='searchInput'>
											<UilSearch color='#506CF9' size={18} />
										</label>
										<Input
											id='searchInput'
											type='search'
											className='border-0 shadow-none input-search-customers bg-lblue'
											placeholder='Buscar inmueble...'
										/>
									</div>
								</div>
							</div>
							{/* Buttons */}
						</div>
					</>
				) : (
					<>
						<SubheaderNew
							breadCrumbs={[
								{ name: 'Propiedades', url: '/propiedades/inmuebles' },
								{ name: 'Emprendimientos', url: '/propiedades/emprendimientos' },
							]}
							filtersFunc={setFiltersMenu}
							filtersStatus={filtersMenu}
							newButtonUrl='/propiedades/emprendimientos/crear'
							withFiltersButton
							withNewButton
							withExportButton
						/>
					</>
				)}
				<div
					className={`d-flex flex-column ${
						!mobileDesign && 'bg-lblue rounded p-4'
					} w-100 mb-3`}>
					<Active filtersMenu={filtersMenu} setFiltersMenu={setFiltersMenu} />
				</div>
			</Page>
		</PageWrapper>
	);
}

export default VenturesPage;
