export const showToast = (message, setList, type) => {
    let toastProperties = null;

    switch (type) {
        case 'success':
            toastProperties = {
                id: Date.now(),
                title: 'Excelente',
                description: message,
                backgroundClass: 'toastSuccess'
            };
            break;
        case 'danger':
            toastProperties = {
                id: Date.now(),
                title: 'Error',
                description: message,
                backgroundClass: 'toastDanger'
            };
            break;
        case 'warning':
            toastProperties = {
                id: Date.now(),
                title: 'Warning',
                description: message,
                backgroundClass: 'toastWarning'
            };
            break;
        default:
            toastProperties = {};
    }

    localStorage.setItem('lastToast', JSON.stringify(toastProperties));
    setList(prevList => [...prevList, toastProperties]);
};