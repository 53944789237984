import React, { useContext } from 'react';
import ThemeContext from '../contexts/themeContext';

export default function SwitchThreeOptions({ label, clickHandler, data, nameEng , inputDisabled}) {

	const { mobileDesign } = useContext(ThemeContext);


		const treeOption = (valor, nombre) => {
			if(data[nombre] != valor) {
				clickHandler(valor , nombre)
				return;
			} 
			clickHandler(null , nombre)
		}

	return (
		<div className='d-flex align-items-center justify-content-between w-100'>
			{/* <h6 className='m-0 text-color-lblue'>{label}</h6> */}
			<p
				className={`m-0 text-color-lblue ${mobileDesign && 'fs-6'}`}
				style={{ fontSize: '1vw' }}>
				{label}
			</p>
			<div className='d-flex align-items-center'>
				<div 
					className={`d-flex align-items-center justify-content-center py-3 px-5 me-1 cursor-pointer rounded-start ${
						// data[nameEng] === 'Sí' ? 'bg-blue-300' : 'bg-lblue'
						data[nameEng] != 1 ? 'bg-lblue' : 'bg-blue-300'
					}`}
					style={{ pointerEvents: inputDisabled ? 'none' : 'auto' }}
					// onClick={() => clickHandler('Sí')}>
					onClick={() => treeOption(true , nameEng )}>
					<h4
						className={`m-0 ${
							// data[nameEng] === 'Sí' ? 'text-color-white' : 'text-color-lblue'
							data[nameEng] != 1 ? 'text-color-lblue' : 'text-color-white'
						}`}>
						Sí
					</h4>
				</div>
				<div
					className={`d-flex align-items-center justify-content-center py-3 px-5 cursor-pointer rounded-end ${
						// data[nameEng] === 'No' ? 'bg-blue-300' : ' bg-lblue'
						data[nameEng] != 0 ? 'bg-lblue' : 'bg-blue-300'
					}`}
					style={{ pointerEvents: inputDisabled ? 'none' : 'auto' }}
					// onClick={() => clickHandler('No')}>
					onClick={() => treeOption(false , nameEng)}>
					<h4
						className={`m-0 ${
							// data[nameEng] === 'No' ? 'text-color-white' : 'text-color-lblue'
							data[nameEng] != 0 ? 'text-color-lblue' : 'text-color-white'
						}`}>
						No
					</h4>
				</div>
			</div>
		</div>
	);
}
