import React, { useContext, useState } from 'react';
import { UilSearch, UilCheck } from '@iconscout/react-unicons';
import Button from '../../components/bootstrap/Button';
import Input from '../../components/bootstrap/forms/Input';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../components/bootstrap/OffCanvas';
import ThemeContext from '../../contexts/themeContext';
import Icon from '../../components/icon/Icon';
import { Form, Formik } from 'formik';
import InputSelector from './InputSelector';
import useFilters from '../../hooks/useFilters';
import { useQueryClient } from '@tanstack/react-query';

const FiltersMenu = ({ filtersApplied, setFiltersApplied }) => {
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	
	const filters = useFilters('customers');
	
	const queryClient = useQueryClient();

	const clickHandler = (item) => {
		const menu = [...filters.data];
		const index = menu.indexOf(item);
		menu[index] = { ...item, isActive: !item.isActive };
		queryClient.setQueryData(['filters', 'customers'], menu);
	};

	const changeHandler = (value, name) => {
		setFiltersApplied((filtersApplied) => ({
			...filtersApplied,
			[name]: value,
			page: 1,
		}));
	};

	const changeHandlerMultipleSelect = (value, name) => {
		if (filtersApplied[name]?.includes(value)) {
			setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				[name]: filtersApplied[name].filter((a) => a !== value),
				page: 1,
			}));
		} else {
			setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				[name]: [...(filtersApplied[name] || []), value],
				page: 1,
			}));
		}
	};

	return (
		<div className='d-flex flex-column justify-content-start p-4'>
			{/* Title and customize button */}
			{!mobileDesign && (
				<div className='d-flex justify-content-between align-items-center w-100'>
					<h5 className='text-color-dark fw-bold'>Filtro de Búsqueda</h5>
					<Button
						color='primary'
						rounded={1}
						onClick={() => setOffcanvasStatus(!offcanvasStatus)}>
						<div className='d-flex align-items-center'>
							<Icon icon='CustomSettingsAdjust' />
							<p className='my-0 ms-2'>Personalizar</p>
						</div>
					</Button>
				</div>
			)}
			{/* Title and customize button */}
			<Formik>
				<Form>
					<div className='row'>
						{filters.data ? (
							filters.data
								?.filter((item) => item.isActive === true && item.type !== "switch" && (item.nameEng[0] !== "freeSurface" && item.nameEng[0] !== "coverSurface"))
								.map((item) => (
									<div
										className={`d-flex flex-column my-4 ${
											mobileDesign ? 'col-12' : 'col-4'
										}`}>
										<InputSelector
											changeHandlerMultipleSelect={
												changeHandlerMultipleSelect
											}
											changeHandler={changeHandler}
											item={item}
											touched={Formik.touched}
											data={filtersApplied}
											setFiltersApplied={setFiltersApplied}
										/>
									</div>
								))
						) : (
							<div className='d-flex justify-content-center m-5 w-100'>
								<h3>Cargando filtros...</h3>
							</div>
						)}
					</div>
				</Form>
			</Formik>

			{/* Drawer right */}
			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus} className='bg-blue-300 p-4'>
					<div className='w-100 d-flex justify-content-center'>
						<h4 className='m-0 text-color-white fw-bold'>Mostrar / Ocultar</h4>
					</div>
				</OffCanvasHeader>
				<OffCanvasBody className='p-0'>
					{/* Search input */}
					<div className='d-flex align-items-center py-4 px-3 w-100 search-input-drawer'>
						<label
							className='border-0 bg-transparent cursor-pointer me-0'
							htmlFor='searchInput'>
							<UilSearch color={darkModeStatus ? '#A9B5D7' : '#01227d'} size={20} />
						</label>
						<Input
							id='searchInput'
							type='search'
							className='border-0 shadow-none bg-transparent input-search'
							placeholder='Buscar...'
						/>
					</div>
					{/* Search input */}

					{filters.data?.some((item) => item.isActive) && (
						<div className='d-flex align-items-center w-100 bg-blue-100 p-3'>
							<h5 className='m-0 text-color-lblue fw-bold'>Mostrar</h5>
						</div>
					)}
					<div className='d-flex flex-column w-100 p-3'>
						{filters.data
							?.filter((item) => item.isActive === true)
							.map((item) => (
								<div
									onClick={() => clickHandler(item)}
									key={item.name}
									className='d-flex align-items-center justify-content-between my-2 w-100 cursor-pointer'>
									<div className='d-flex align-items-center'>
										{/* {item.icon(true)} */}
										<p className='m-0 text-color-lblue fs-5 fw-bold px-2'>
											{item.name}
										</p>
									</div>
									<UilCheck color='#506CF9' size={35} />
								</div>
							))}
					</div>

					{filters.data?.some((item) => !item.isActive) && (
						<div className='d-flex align-items-center w-100 bg-new-gray p-3'>
							<h5 className='m-0 text-color-dark fw-bold'>Añadir</h5>
						</div>
					)}
					<div className='d-flex flex-column w-100 p-3'>
						{filters.data
							?.filter((i) => i.isActive === false)
							.map((menuItem) => (
								<div
									onClick={() => clickHandler(menuItem)}
									key={menuItem.name}
									className='d-flex align-items-center justify-content-between my-3 w-100 cursor-pointer'>
									<div className='d-flex align-items-center'>
										{/* {menuItem.icon(false)} */}
										<p className='m-0 text-color-dark fs-5 fw-bold px-2'>
											{menuItem.name}
										</p>
									</div>
								</div>
							))}
					</div>
				</OffCanvasBody>
			</OffCanvas>
			{/* Drawer right */}
		</div>
	);
};

export default FiltersMenu;
