import { useQuery } from '@tanstack/react-query';
import Swal from 'sweetalert2';
import Button from '../../components/bootstrap/Button';
import { UilUser, UilBrowser, UilPen } from '@iconscout/react-unicons';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Editor } from '@tinymce/tinymce-react';
import {
	AddressBook,
	ArrowLeft,
	CaretDown,
	FloppyDisk,
	Notepad,
	Tag,
	Trash,
	X,
} from 'phosphor-react';
import Icon from '../../components/icon/Icon';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CorreoElectronico = () => {
	return (
		<>
			<div className='d-flex flex-column bg-lblue w-100 rounded-right-and-bottom mb-3 p-4'>
				<div className='row'>
					<div className='col rounded-1 p-2'>
						<Card className='p-2'>
							<CardTitle tag='h4' className='h5'>
								<div className='d-flex align-items-center mx-3 mt-2'>
									<UilPen />
									<p className='my-0 mx-2'>Firma - CKEDITOR</p>
								</div>
							</CardTitle>
							<CardBody className='d-flex justify-content-center w-100 '>
							<Editor
									onInit={(evt, editor) => (editorRef.current = editor)}
									initialValue='<p>This is the initial content of the editor.</p>'
									init={{
										height: 500,
										menubar: false,
										branding: false,
										statusbar: false,
										resize: false,
										file_picker_callback: function (cb, value, meta) {
											var input = document.createElement('input');
											input.setAttribute('type', 'file');
											input.setAttribute('accept', 'image/*');
										
											/*
											  Note: In modern browsers input[type="file"] is functional without
											  even adding it to the DOM, but that might not be the case in some older
											  or quirky browsers like IE, so you might want to add it to the DOM
											  just in case, and visually hide it. And do not forget do remove it
											  once you do not need it anymore.
											*/
										
											input.onchange = function () {
											  var file = this.files[0];
										
											  var reader = new FileReader();
											  reader.onload = function () {
												/*
												  Note: Now we need to register the blob in TinyMCEs image blob
												  registry. In the next release this part hopefully won't be
												  necessary, as we are looking to handle it internally.
												*/
												var id = 'blobid' + (new Date()).getTime();
												var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
												var base64 = reader.result.split(',')[1];
												var blobInfo = blobCache.create(id, file, base64);
												blobCache.add(blobInfo);
										
												/* call the callback and populate the Title field with the file name */
												cb(blobInfo.blobUri(), { title: file.name });
											  };
											  reader.readAsDataURL(file);
											};
										
											input.click();
										  },
										plugins: 'image',
										toolbar: 'image',
										file_picker_types: 'image',
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'insertdatetime media table paste code help wordcount','image','code'
										],
										toolbar:
											'undo redo | formatselect | ' +
											'bold italic backcolor | alignleft aligncenter ' +
											'alignright alignjustify | bullist numlist outdent indent | ' +
											'removeformat | help | image | code',
										content_style:
											'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
									}}
								/>
							</CardBody>
						</Card>
					</div>

					<div className='col rounded-1 p-2'>
						<Card className='p-2'>
							<CardTitle tag='h4' className='h5'>
								<div className='d-flex align-items-center mx-3 mt-2'>
									<UilBrowser />
									<p className='my-0 mx-2'>Cabecera - TINY</p>
								</div>
							</CardTitle>
							<CardBody className='d-flex justify-content-center w-100 '>
								<Editor
									onInit={(evt, editor) => (editorRef.current = editor)}
									initialValue='<p>This is the initial content of the editor.</p>'
									init={{
										height: 500,
										menubar: false,
										branding: false,
										statusbar: false,
										resize: false,
										file_picker_callback: function (cb, value, meta) {
											var input = document.createElement('input');
											input.setAttribute('type', 'file');
											input.setAttribute('accept', 'image/*');
										
											/*
											  Note: In modern browsers input[type="file"] is functional without
											  even adding it to the DOM, but that might not be the case in some older
											  or quirky browsers like IE, so you might want to add it to the DOM
											  just in case, and visually hide it. And do not forget do remove it
											  once you do not need it anymore.
											*/
										
											input.onchange = function () {
											  var file = this.files[0];
										
											  var reader = new FileReader();
											  reader.onload = function () {
												/*
												  Note: Now we need to register the blob in TinyMCEs image blob
												  registry. In the next release this part hopefully won't be
												  necessary, as we are looking to handle it internally.
												*/
												var id = 'blobid' + (new Date()).getTime();
												var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
												var base64 = reader.result.split(',')[1];
												var blobInfo = blobCache.create(id, file, base64);
												blobCache.add(blobInfo);
										
												/* call the callback and populate the Title field with the file name */
												cb(blobInfo.blobUri(), { title: file.name });
											  };
											  reader.readAsDataURL(file);
											};
										
											input.click();
										  },
										plugins: 'image',
										toolbar: 'image',
										file_picker_types: 'image',
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'insertdatetime media table paste code help wordcount','image','code'
										],
										toolbar:
											'undo redo | formatselect | ' +
											'bold italic backcolor | alignleft aligncenter ' +
											'alignright alignjustify | bullist numlist outdent indent | ' +
											'removeformat | help | image | code',
										content_style:
											'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
									}}
								/>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
		</>
	);
};
export default CorreoElectronico;
