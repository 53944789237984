import React from 'react';

const CustomHeadset = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 24 24'
			width='1.5em'
			height='1.5em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M12 3.75C8.75862 3.75 6.24399 5.78002 5.81483 8.25H8C8.41421 8.25 8.75 8.58579 8.75 9V16C8.75 16.4142 8.41421 16.75 8 16.75H5C3.48122 16.75 2.25 15.5188 2.25 14V11C2.25 9.72836 3.11311 8.65832 4.28547 8.34374C4.66693 4.83959 8.0738 2.25 12 2.25C15.9262 2.25 19.3331 4.83959 19.7145 8.34375C20.8869 8.65832 21.75 9.72836 21.75 11V14C21.75 15.2752 20.8821 16.3477 19.7047 16.6589C19.3839 18.9704 17.3998 20.75 15 20.75H13.8546C13.5579 21.483 12.8393 22 12 22C10.8954 22 10 21.1046 10 20C10 18.8954 10.8954 18 12 18C12.8393 18 13.5579 18.517 13.8546 19.25H15C16.5368 19.25 17.8245 18.1834 18.1631 16.75H16C15.5858 16.75 15.25 16.4142 15.25 16V9C15.25 8.58579 15.5858 8.25 16 8.25H18.1852C17.756 5.78002 15.2414 3.75 12 3.75ZM5 9.75C4.30964 9.75 3.75 10.3096 3.75 11V14C3.75 14.6904 4.30964 15.25 5 15.25H7.25V9.75H5ZM20.25 11C20.25 10.3096 19.6904 9.75 19 9.75H16.75V15.25H19C19.6904 15.25 20.25 14.6904 20.25 14V11Z'
				fill='#fff'
			/>
		</svg>
	);
};

export default CustomHeadset;
