import React, { useContext } from 'react';
import ThemeContext from '../../contexts/themeContext';

export default function PortalCard({ portal, setFilters, filters }) {
	const { mobileDesign } = useContext(ThemeContext);

	return (
		<div
			className={`col-md-4 col-xxl-3 p-3 cursor-pointer ${mobileDesign && 'w-100'}`}
			onClick={() => {
				if (filters.portal === portal.id) return;
				setFilters((prev) => ({ ...prev, portal: portal.id, highlights: [] }));
			}}>
			<div
				// className={`d-flex align-items-center p-4 shadow-small rounded bg-white min-vh-15`}
				className={`d-flex align-items-center p-4 shadow-small rounded ${
					filters.portal === portal.id ? 'gray-card-posts-active' : 'bg-white'
				} min-vh-15`}>
				<img
					src={portal.mainLogo || 'https://placehold.co/60?text=Sin+Imagen'}
					alt={portal.name}
				/>
				<div className='d-flex flex-column align-items-start ms-4'>
					<p className='mb-2 fs-5 fw-bold'>{portal.name}</p>
					<div className='d-flex flex-column align-items-start'>
						<div className='d-flex align-items-center'>
							<div
								className={`bg-gray-card-posts fw-bold text-color-white rounded-1 ${
									mobileDesign ? 'fs-9 px-2 py-2' : 'px-3 py-2'
								}`}>
								{`${portal.count.published} publicadas`}
							</div>
							<div
								className={`bg-gray-card-posts px-3 py-2 ms-2 text-color-white rounded-1 ${
									mobileDesign ? 'fs-9 px-2 py-2' : 'px-3 py-2'
								}`}>
								{`${portal.count.unpublished} sin publicar`}
							</div>
						</div>
						<div
							className={`bg-gray-card-posts px-3 py-2 mt-2 fw-bold text-color-white rounded-1 ${
								mobileDesign ? 'fs-9 px-2 py-2' : 'px-3 py-2'
							}`}>
							{`${portal.count.highlighted} destacadas`}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
