import { useState, useContext, useEffect, useRef } from 'react';
import {
	UilEdit,
	UilEllipsisH,
	UilUser,
	UilUsersAlt,
	UilSearch,
	UilMultiply,
	UilSortAmountDown,
	UilSortAmountUp,
	UilTrash,
	UilExternalLinkAlt,
} from '@iconscout/react-unicons';
import classNames from 'classnames';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
} from '../../components/bootstrap/Card';
import PaginationButtons from '../../components/PaginationButtons';
import Icon from '../../components/icon/Icon';
import ThemeContext from '../../contexts/themeContext';
import Input from '../../components/bootstrap/forms/Input';
import FiltersMenu from './FiltersMenu';
import Button from '../../components/bootstrap/Button';
import moment from 'moment/moment';
import useCustomers from '../../hooks/useCustomers';
import { useQueryClient } from '@tanstack/react-query';
import capitalizeFirstCharacter from '../../helpers/capitalizeFirstCharacter';
import { deleteCustomer } from '../../services/customersServices'; 
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/userContext';
import Swal from 'sweetalert2';
import { confirmationSwal } from '../../utils/confirmationSwal';
import Toast from '../../components/Toast/Toast';
import { showToast } from '../../components/Toast/toastUtils/toastUtils';

const CustomersPage = ({ sectionName }) => {
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const [activeFilters, setActiveFilters] = useState(false);
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { token } = useContext(UserContext);
	const [list, setList] = useState([]);

	const getCustomerType = () => {
		switch (pathname) {
			case '/personas/interesados':
				return 1;
			case '/personas/propietarios':
				return 2;
			case '/personas/inquilinos':
				return 3;
			case '/personas/garantes':
				return 4;
			default:
				return 1;
		}
	};

	const initialValues = {
		query: '',
		propertyTypes: [],
		// operationType: [],
		operations: [],
		valueMin: null,
		valueMax: null,
		currency: [],
		// neighborhood: [],
		neighborhoods: [],
		project: [],
		location: [],
		suitableCredit: [],
		activity: [],
		antiqueness: [],
		garage: [],
		maxArea: null,
		page: null,
		customerType: getCustomerType(),
		limit: 5,
		sort: {
			field: null,
			order: null,
		},
	};

	const [filtersApplied, setFiltersApplied] = useState(initialValues);

	useEffect(() => {
		setFiltersApplied(initialValues);
		setActiveFilters(false);

	}, [pathname]);

	// useEffect(() => {
	// 	setFiltersApplied((prev) => ({ ...prev, customerType: customerType }));
	// }, [pathname]);

	const customers = useCustomers(filtersApplied, sectionName);

	const queryClient = useQueryClient();

	useEffect(() => {
		queryClient.invalidateQueries({
			queryKey: [filtersApplied, sectionName],
			exact: true,
		});
	}, [filtersApplied]);

	const sortHandler = (item) => {
		if (!item) {
			return;
		} else if (filtersApplied.sort.field !== item) {
			setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				sort: {
					field: item,
					order: 'asc',
				},
			}));
		} else if (filtersApplied.sort.field === item && filtersApplied.sort.order === 'asc') {
			setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				sort: {
					field: item,
					order: 'desc',
				},
			}));
		} else if (filtersApplied.sort.field === item && filtersApplied.sort.order === 'desc') {
			setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				sort: {
					field: null,
					order: 'null',
				},
			}));
		}
	};

	const getSortIcon = (item) => {
		if (filtersApplied.sort.field === item && filtersApplied.sort.order === 'asc') {
			return <UilSortAmountDown size={20} color='#000' />;
		} else if (filtersApplied.sort.field === item && filtersApplied.sort.order === 'desc') {
			return <UilSortAmountUp size={20} color='#000' />;
		} else {
			return null;
		}
	};

	const debounceRef = useRef(null);

	const onQueryChange = (value) => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}

		debounceRef.current = setTimeout(() => {
			setFiltersApplied((filtersApplied) => ({
				...filtersApplied,
				query: value,
			}));
		}, 500);
	};

	const StarRating = ({ rating }) => {
		const stars = [];
		for (let i = 1; i <= rating; i++) {
			stars.push(<Icon icon='CustomStar' />);
		}

		return <div className='d-flex'>{stars}</div>;
	};

    const handleEditClick = async (id) => {
		navigate(`/personas/${sectionName}/editar/${id}`);
		// navigate(`/personas/${sectionName}/ver/${id}`);
	}
    const handleViewClick = async (id) => {
		// navigate(`/personas/${sectionName}/editar/${id}`);
		navigate(`/personas/${sectionName}/ver/${id}`);
	}

	const handleDeleteClick = async (id) => {
		const singularSectionName = sectionName.slice(0, -1)

		const swalInstance = confirmationSwal("Eliminar persona" ,`¿Está seguro de que desea eliminar el ${singularSectionName} seleccionado?` ,"warning","Eliminar")
		try {
			const result = await swalInstance;
			if (result.isConfirmed) {
			  const response = await deleteCustomer( id , token);
			  showToast(response.data.message, setList,'success' );
			  queryClient.invalidateQueries([filtersApplied, sectionName], {
				exact: true,
			});
			}
		  } catch (error) {
			showToast(response.data.message, setList,'danger');
			console.error('Error al eliminar archivo', error);
		  }
	  };
 
	return (
		<PageWrapper title={capitalizeFirstCharacter(sectionName)}>
			<Toast toastlist={list} position="top-right" setList={setList} />
			<Page container='fluid'>
				<SubheaderNew
					breadCrumbs={[
						{
							name: capitalizeFirstCharacter(sectionName),
							url: `/personas/${sectionName}`,
						},
					]}
					filtersFunc={setActiveFilters}
					filtersStatus={activeFilters}
					newButtonUrl={`/personas/${sectionName}/crear`}
					withFiltersButton
					withNewButton
					withExportButton
					withSearchButton
				/>
				{activeFilters && mobileDesign && (
					<div
						className='d-flex align-items-center justify-content-center w-100'
						style={{
							height: '10rem',
							borderRadius: '2rem 2rem 0rem 0rem',
							background:
								'linear-gradient(180deg, #DCE5FF 0%, rgba(220, 229, 255, 0) 100%)',
							position: 'relative',
							bottom: '1.8rem',
						}}>
						<h4 className='text-color-dark mb-4 fw-bold'>Filtro de Búsqueda</h4>
						<UilMultiply
							color='#506CF9'
							style={{ position: 'absolute', top: '10%', right: '3%' }}
							onClick={() => setActiveFilters(false)}
						/>
					</div>
				)}
				<Card
					stretch
					style={{
						position: mobileDesign && activeFilters && 'relative',
						bottom: mobileDesign && activeFilters && '3rem',
					}}>
					{mobileDesign ? (
						<div className='d-flex flex-column w-100 list-mobile'>
							{activeFilters ? (
								<FiltersMenu
									filtersApplied={filtersApplied}
									setFiltersApplied={setFiltersApplied}
								/>
							) : customers.isLoading || customers.isFetching ? (
								<div className='w-100 my-5 d-flex justify-content-center'>
									<h2>Cargando...</h2>
								</div>
							) : (
								// customers?.data?.data?.map((person, i) => (
									<div>
										{customers?.data?.data?.map((person, i) => {
											return (
												<div
										className={`${
											i % 2 !== 0 && 'bg-new-gray'
										} d-flex align-items-center justify-content-between w-100 p-4`}>
											<div className='d-flex align-items-center'>
												<UilUser color='#000' size={30} />
												<p className='fw-normal-bold my-0 mx-3'>
													{person.name} {person.surname}
												</p>
											</div>

											<div className='d-flex justify-content-center'>
												{/* <div
													className='bg-blue-300 rounded-circle p-2 mx-2'
													onClick={() => alert('Tocaste editar')}>
													<UilEdit color='#fff' size={20} />
												</div> */}
												{/* <div
													className='bg-blue-300 rounded-circle p-2'
													onClick={() => alert('Tocaste acciones')}>
													<UilEllipsisH color='#fff' size={20} />
												</div> */}
											<div
												className='bg-blue-300 rounded-circle p-2 mx-1 cursor-pointer'
												style={{
													right: 10,
													top: 10,
												}}>
												<UilEllipsisH
													color='#fff'
													size={25}
													id={`a${person.id}`}
													data-bs-toggle='dropdown'
													aria-expanded='false'
												/>
												{/* Dropdown */}
												<div
													className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
													aria-labelledby={`a${person.id}`}>
													<div onClick={() => handleEditClick(person.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
														<UilEdit /> <p className='m-0 ps-2'>Editar</p>
													</div>
														<div  onClick={() => handleDeleteClick(person.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
												<UilTrash /> <p className='m-0 ps-2'>Borrar</p>
													</div>
													<div onClick={() => handleViewClick(person.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
														<UilExternalLinkAlt />{' '}
														<p className='m-0 ps-2'>Ver</p>
													</div>
												</div>
												{/* Dropdown */}
											</div>
												
											</div>
										</div>
											)
										})}
									</div>
									// <div
									// 	className={`${
									// 		i % 2 !== 0 && 'bg-new-gray'
									// 	} d-flex align-items-center justify-content-between w-100 p-4`}>
									// 		<div className='d-flex align-items-center'>
									// 			<UilUser color='#000' size={30} />
									// 			<p className='fw-normal-bold my-0 mx-3'>
									// 				{person.name} {person.surname}
									// 			</p>
									// 		</div>
									// 		<div className='d-flex justify-content-center'>
									// 			{/* <div
									// 				className='bg-blue-300 rounded-circle p-2 mx-2'
									// 				onClick={() => alert('Tocaste editar')}>
									// 				<UilEdit color='#fff' size={20} />
									// 			</div> */}
									// 			{/* <div
									// 				className='bg-blue-300 rounded-circle p-2'
									// 				onClick={() => alert('Tocaste acciones')}>
									// 				<UilEllipsisH color='#fff' size={20} />
									// 			</div> */}
									// 		<div
									// 			className='bg-blue-300 rounded-circle p-2 mx-1 cursor-pointer'
									// 			style={{
									// 				right: 10,
									// 				top: 10,
									// 			}}>
									// 			<UilEllipsisH
									// 				color='#fff'
									// 				size={25}
									// 				id={`a${person.id}`}
									// 				data-bs-toggle='dropdown'
									// 				aria-expanded='false'
									// 			/>
									// 			{/* Dropdown */}
									// 			<div
									// 				className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
									// 				aria-labelledby={`a${person.id}`}>
									// 				<div onClick={() => handleEditClick(person.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
									// 					<UilEdit /> <p className='m-0 ps-2'>Editar</p>
									// 				</div>
									// 				<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
									// 					<UilTrash /> <p className='m-0 ps-2'>Borrar</p>
									// 				</div>
									// 				<div onClick={() => handleViewClick(person.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
									// 					<UilExternalLinkAlt />{' '}
									// 					<p className='m-0 ps-2'>Ver</p>
									// 				</div>
									// 			</div>
									// 			{/* Dropdown */}
									// 		</div>
												
									// 		</div>
									// 	</div>
										// ))
							)	
								}
						</div>
					) : (
						<>
							<CardHeader
								style={{ 'border-bottom': '1px solid rgba(183, 184, 188, 0.5)' }}>
								<div className='titulo-alerta my-2'>
									<UilUsersAlt size={32} color='#506CF9' />
									<span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>
										{sectionName.toUpperCase()}
									</span>
								</div>
								{!activeFilters && (
									<CardActions>
										{/* Search input */}
										<div
											className={classNames(
												'd-flex align-items-center rounded-pill py-1 px-2',
												{ 'search-input-header-dark': darkModeStatus },
												{ 'search-input-header': !darkModeStatus },
											)}>
											<label
												className='border-0 bg-transparent cursor-pointer me-0 ms-2'
												htmlFor='searchInput'>
												{/* <Icon icon='Search' size='2x' color='blue-300' /> */}
												<UilSearch
													// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
													color='#506CF9'
													size={12}
												/>
											</label>
											<Input
												id='searchInput'
												type='search'
												className='border-0 shadow-none bg-transparent input-search-customers'
												placeholder='Buscar...'
												onChange={(e) => onQueryChange(e.target.value)}
											/>
										</div>
										{/* Search input */}
									</CardActions>
								)}
							</CardHeader>
							<CardBody className='table-responsive hidden-scroll' isScrollable>
								{activeFilters ? (
									<FiltersMenu
										filtersApplied={filtersApplied}
										setFiltersApplied={setFiltersApplied}
									/>
								) : customers.isLoading || customers.isFetching ? (
									<div className='w-100 m-5 d-flex justify-content-center'>
										<h2>Cargando...</h2>
									</div>
								) : (
									<table className='w-100'>
										<thead style={{ position: 'sticky', top: '0px' }}>
											{/* <tr className='text-white blue-table-header'> */}
											<tr className='text-dark'>
												<th>{null}</th>
												<th
													className='sortable'
													onClick={() => sortHandler('surname')}>
													Apellido {getSortIcon('surname')}
												</th>
												<th
													className='sortable'
													onClick={() => sortHandler('name')}>
													Nombre {getSortIcon('name')}
												</th>
												<th>Celular</th>
												<th>Mail</th>
												{sectionName === 'interesados' && (
													<th>Estrellas</th>
												)}
												<th>Tipo de propiedad</th>

												{sectionName !== 'interesados' && (
													<>
														<th>Localidad</th>
														<th>Calle</th>
													</>
												)}

												<th>Operación</th>
												<th
													className='sortable'
													onClick={() => sortHandler('joinDate')}>
													Fecha ingreso {getSortIcon('joinDate')}
												</th>
												{sectionName === 'interesados' && (
													<>
														<th>Precio desde</th>
														<th>Precio hasta</th>
													</>
												)}
											</tr>
										</thead>
										<tbody>
											{/* {dataPagination(items, currentPage, perPage).map( */}
											{customers?.data?.data?.map((item, index) => {
												return (
													<tr className={index % 2 !== 0 && 'bg-new-gray'}>
														<td>

														<div className='d-flex justify-content-start align-items-center '>
															{/* <div to={'/personas/propietarios'} onClick={() => handleEditClick(item.id)}  className='bg-blue-300 rounded-circle p-2 mx-1'>
																	<UilEdit color='#fff' size={20}/>
																</div> */}
															{/* <div className='bg-blue-300 rounded-circle p-2 mx-1'>
																<UilEllipsisH
																color='#fff'
																size={20}
																/>
															</div> */}
															 <div
																className='bg-blue-300 rounded-circle p-2 mx-1 cursor-pointer'
																style={{
																	right: 10,
																	top: 10,
																}}>
																<UilEllipsisH
																	color='#fff'
																	size={25}
																	id={`a${item.id}`}
																	data-bs-toggle='dropdown'
																	aria-expanded='false'
																	/> 
																{/* Dropdown */}
																 <div
																	className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
																	aria-labelledby={`a${item.id}`}>
																	<div onClick={() => handleEditClick(item.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
																		<UilEdit /> <p className='m-0 ps-2'>Editar</p>
																	</div>
																	<div onClick={() => handleDeleteClick(item.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
																		<UilTrash /> <p className='m-0 ps-2'>Borrar</p>
																	</div>
																	<div onClick={() => handleViewClick(item.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
																		<UilExternalLinkAlt />{' '}
																		<p className='m-0 ps-2'>Ver</p>
																	</div>
																</div>
																{/* Dropdown */}
															 </div>
															
														</div>

													</td>
													<td>{item.surname}</td>
													<td>{item.name}</td>
													<td>{item.phone}</td>
													<td>{item.email}</td>

													{sectionName === 'interesados' && (
														<td>
															<StarRating
																rating={item.qualification}
															/>
														</td>
													)}

<td>
														<div className='d-flex flex-column align-items-start w-100'>
															{item.propertyType.map((ptype , i) => (
																<p key={i} className='m-0'>{ptype.name}</p>
															))}
														</div>
													</td>

													{sectionName !== 'interesados' && (
														<>
															<td>{item.city}</td>
															<td>{item.street}</td>
														</>
													)}

													<td>
														<div className='d-flex flex-column align-items-start w-100'>
															{item.operations.map((op) => (
																<p className='m-0'>{op.name}</p>
															))}
														</div>
													</td>
													<td>
														{moment(item.createdAt).format(
															'DD-MM-YYYY',
														)}
													</td>
													{sectionName === 'interesados' && (
														<>
															<td>USD {item.valueFrom}</td>
															<td>USD {item.valueTo}</td>
														</>
													)}

												</tr>
													
												)
											})}
											
											{/* {customers?.data?.data.map((item, index) => (
												<tr className={index % 2 !== 0 && 'bg-new-gray'}>
													<td>
														<div className='d-flex justify-content-start align-items-center '> */}
															{/* <div to={'/personas/propietarios'} onClick={() => handleEditClick(item.id)}  className='bg-blue-300 rounded-circle p-2 mx-1'>
																	<UilEdit color='#fff' size={20}/>
															</div> */}
															
															{/* <div className='bg-blue-300 rounded-circle p-2 mx-1'>
																<UilEllipsisH
																	color='#fff'
																	size={20}
																/>
															</div> */}
															{/* <div
																className='bg-blue-300 rounded-circle p-2 mx-1 cursor-pointer'
																style={{
																	right: 10,
																	top: 10,
																}}>
																<UilEllipsisH
																	color='#fff'
																	size={25}
																	id={`a${item.id}`}
																	data-bs-toggle='dropdown'
																	aria-expanded='false'
																/> */}
																{/* Dropdown */}
																{/* <div
																	className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
																	aria-labelledby={`a${item.id}`}>
																	<div onClick={() => handleEditClick(item.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
																		<UilEdit /> <p className='m-0 ps-2'>Editar</p>
																	</div>
																	<div onClick={() => handleDeleteClick(item.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
																		<UilTrash /> <p className='m-0 ps-2'>Borrar</p>
																	</div>
																	<div onClick={() => handleViewClick(item.id)} className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
																		<UilExternalLinkAlt />{' '}
																		<p className='m-0 ps-2'>Ver</p>
																	</div>
																</div> */}
																{/* Dropdown */}
															{/* </div>
														</div>
													</td>
													<td>{item.surname}</td>
													<td>{item.name}</td>
													<td>{item.phone}</td>
													<td>{item.email}</td>

													{sectionName === 'interesados' && (
														<td>
															<StarRating
																rating={item.qualification}
															/>
														</td>
													)}
													<td>
														<div className='d-flex flex-column align-items-start w-100'>
															{item.propertyType.map((ptype) => (
																<p className='m-0'>{ptype.name}</p>
															))}
														</div>
													</td>

													{sectionName !== 'interesados' && (
														<>
															<td>{item.city}</td>
															<td>{item.street}</td>
														</>
													)}

													<td>
														<div className='d-flex flex-column align-items-start w-100'>
															{item.operation.map((op) => (
																<p className='m-0'>{op.name}</p>
															))}
														</div>
													</td>
													<td>
														{moment(item.createdAt).format(
															'DD-MM-YYYY',
														)}
													</td>
													{sectionName === 'interesados' && (
														<>
															<td>USD {item.valueFrom}</td>
															<td>USD {item.valueTo}</td>
														</>
													)}
												</tr> */}
											{/* ))} */}
										</tbody>
									</table>
								)}
								{/* <table className='table table-modern'> */}
							</CardBody>
							{!activeFilters ? (
								<div className='pt-4'>
									<PaginationButtons
										label='items'
										currentPage={customers.data?.paginator.page}
										filtersApplied={filtersApplied}
										setFiltersApplied={setFiltersApplied}
										totalPage={customers.data?.paginator.total}
									/>
								</div>
							) : (
								<CardFooter>
									<CardFooterRight>
										<Button
											color='primary'
											tag='a'
											rounded={1}
											onClick={() => setActiveFilters(!activeFilters)}>
											<div className='d-flex'>
												<Icon icon='CustomSliders' />
												<p className='my-0 ms-2'>Filtrar</p>
											</div>
										</Button>
									</CardFooterRight>
								</CardFooter>
							)}
						</>
					)}
				</Card>
				{mobileDesign && !activeFilters && (
					<PaginationButtons
						label='items'
						currentPage={customers.data?.paginator.page}
						filtersApplied={filtersApplied}
						setFiltersApplied={setFiltersApplied}
						totalPage={customers.data?.paginator.total}
					/>
				)}
			</Page>
		</PageWrapper>
	);
};

export default CustomersPage;
