import React, { useContext } from 'react';
import { UilUsersAlt, UilFileCheck, UilBuilding, UilUserPlus } from '@iconscout/react-unicons';
import { Wrench } from 'phosphor-react';
import ThemeContext from '../../contexts/themeContext';

const FooterMobile = () => {
	const { mobileDesign } = useContext(ThemeContext);

	return mobileDesign ? (
		<div
			className='d-flex align-items-center justify-content-around fixed-bottom bg-blue-300 w-100'
			style={{ height: '10vh' }}>
			<UilUsersAlt size='30' color='#fff' />
			<UilFileCheck size='30' color='#fff' />
			<UilBuilding size='30' color='#fff' />
			<Wrench size={30} color='#fcfcfc' />
			<UilUserPlus size='30' color='#fff' />
		</div>
	) : null;
};

export default FooterMobile;
