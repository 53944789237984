import { ArrowCounterClockwise, ArrowLeft, FloppyDisk, Trash } from 'phosphor-react';
import React, { useState, useContext } from 'react';
import bgBackground from '../../assets/img/bg-header-reporte-propietarios.png';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { useNavigate } from 'react-router-dom';
import MapLayout from './MapLayout';
import { UilSave, UilPrint, UilEstate } from '@iconscout/react-unicons';
import Chart from 'react-apexcharts';
const TasadorReport = ({ userData, setUserData }) => {
	const navigate = useNavigate();
	const fetchedData = [
		{
			isMultiple: true,
			estateCount: 5,
			photo:
				'https://arquitecturayempresa.es/sites/default/files/content/arquitectura_rehabilitacion_amsterdam-235_000.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '50000',
			location: 'Av. Siempreviva 742',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 3,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [25],
			id: 995,
			position: [-34.600556, -58.395],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo:
				'https://static1.sosiva451.com/47515511/9533fecc-4397-43d2-ab54-1d97badd627d_u_small.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '60000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 4,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [50],
			id: 996,
			position: [-34.578611, -58.420861],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo: 'https://www.nocnok.com/hubfs/casa-lujo.webp',
			operation: 'Venta',
			currency: 'USD',
			value: '75000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 5,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [75],
			id: 997,
			position: [-34.60376, -58.38162],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo:
				'https://www.construyehogar.com/wp-content/uploads/2014/08/Dise%C3%B1o-de-casa-moderna-de-una-planta.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '100000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 6,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [88],
			id: 998,
			position: [-34.588056, -58.393056],
			favorite: false,
		},
	];
	const options_2 = {
		options: {
			stroke: {
				lineCap: 'round',
			},
			fill: {
				colors: ['#7c18f8', '#8676ff', '#01f1e3', '#ffba69', '#ff708b'],
			},
			labels: ['Xintel', 'lagranInmobiliaria', 'TuPortalonline', 'Properati', 'Página Web'],
			plotOptions: {
				radialBar: {
					hollow: {
						size: '55%',
					},
					startAngle: -180,
					dataLabels: {
						showOn: 'always',
						name: {
							show: true,
							color: '#383874',
							fontSize: '12px',
							fontFamily: 'Montserrat", sans-serif',
						},
						value: {
							color: '#000000',
							fontSize: '26px',
							fontWeight: 700,
							show: true,
							offsetX: 10,
							offsetY: 25,
							fontFamily: 'Montserrat", sans-serif',
						},
					},
				},
			},
		},
		series: [3, 9, 15, 23, 25],
	};

	return (
		<>
			<PageWrapper title='Estadisticas'>
				<Page container='fluid'>
					<div className='row d-flex'>
						<div className='col-12 flex-row'>
							<div className='float-end d-flex align-items-center me-3'>
								<span className='me-4 fw-600 fs-4'> REPORTE: FICHA MKP28</span>
								<button className='btn bg-grey-button me-2 rounded-1'>
									<span className='text-color-lblue'>
										{' '}
										<UilSave className='me-1' />
										Guardar
									</span>
								</button>
								<button className='btn bg-grey-button  rounded-1'>
									<span className='text-color-lblue'>
										{' '}
										<UilPrint /> Imprimir
									</span>
								</button>
							</div>
						</div>
					</div>
					<div
						className='row position-relative mt-4'
						style={{
							backgroundImage: `url(${bgBackground})`,
							backgroundSize: 'cover',
							height: '457px',
							width: '100%',
							position: 'relative',
						}}
					>
						<span className='position-relative start-10 col-7  top-20 '>
							<h1 className='fw-700  '>Tasación de su propiedad</h1>
							<span className='fs-3'>Chacras 1098, Palermo, CABA</span>
						</span>

						<div className='position-relative col-7 bottom-0 d-flex start-10 align-items-end'>
							<div
								className='bg-grey-button '
								style={{ width: '130px', height: '130px' }}
							>
								Logo aqui
							</div>
							<div className='d-flex flex-column  align-items-start'>
								<div className='mt-auto py-0 ms-2 mt-4'>
									<p className='fs-4 px-5 mt-3 py-0 mb-0'>
										Seguimos trabajando para concretar <br />
										el mejor negocio para vos
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='row mt-5'>
						<div className=' text-center justify-content-center col-12 mt-5'>
							<p>
								<h2 className='fw-700'>Precio estimado</h2>
							</p>
							<h1 className='text-color-lblue fs-1'>Ars $300.000</h1>
						</div>
					</div>
					<div className='d-flex justify-content-center  w-100 px-5 min-vh-75'>
						{/* <div
							className='col-12 p-0 mt-5'
							style={{
								height: '584px',
								width: '1142px',
							}}
						> */}
						<MapLayout fetchedData={fetchedData} />
						{/* </div> */}
					</div>
					<div className=' d-flex px-5  flex-column mt-5 align-items-start justify-content-center'>
						<h3 className='py-3 fw-600 '>Propiedades de referencia</h3>

						<table className='w-100 emulate-card rounded-1'>
							<thead className='border-bottom'>
								<tr className='text-dark py-10'>
									<th scope='col' className='text-color-lblue text-center py-4'>
										<UilEstate className='me-2' />
										<span className='me-5'>Direccion</span>
									</th>
									<th scope='col' className='text-color-lblue text-center '>
										Ambientes
									</th>
									<th scope='col' className='text-color-lblue text-center'>
										Superficie total (m2)
									</th>
									<th scope='col' className='text-color-lblue text-center'>
										Antiguedad
									</th>
									<th scope='col' className='text-color-lblue text-center'>
										Precio
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='text-center'>Uriarte 2097</td>
									<td className='text-center'>
										<div className='py-3 '>
											<span className='text-color-violet  fs-4 fw-500'>
												3
											</span>
										</div>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>50</p>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>40 años</p>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>
											USD $1400000
										</p>
									</td>
								</tr>
								<tr>
									<td className='text-center'>Uriarte 2097</td>
									<td className='text-center '>
										<div className='py-3'>
											<span className='text-color-violet fs-4 fw-500'>3</span>
										</div>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>50</p>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>40 años</p>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>
											USD $1400000
										</p>
									</td>
								</tr>
								<tr>
									<td className='text-center'>Uriarte 2097</td>
									<td className='text-center '>
										<div className='py-3'>
											<span className='text-color-violet fs-4 fw-500'>3</span>
										</div>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>50</p>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>40 años</p>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>
											USD $1400000
										</p>
									</td>
								</tr>
								<tr>
									<td className='text-center'>Uriarte 2097</td>
									<td className='text-center '>
										<div className='py-3'>
											<span className='text-color-violet fs-4 fw-500'>3</span>
										</div>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>50</p>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>40 años</p>
									</td>
									<td className='text-center'>
										<p className='fw-600 fs-4 text-color-violet'>
											USD $1400000
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Page>
			</PageWrapper>
		</>
	);
};

export default TasadorReport;
