import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CardFooter, CardFooterRight } from './bootstrap/Card';
import Pagination, { PaginationItem } from './bootstrap/Pagination';
import Select from './bootstrap/forms/Select';
import Option from './bootstrap/Option';
import ThemeContext from '../contexts/themeContext';

export const PER_COUNT = {
	3: 3,
	5: 5,
	10: 10,
	25: 25,
	50: 50,
};

export const dataPagination = (data, currentPage, perPage) =>
	data.filter(
		(i, index) => index + 1 > (currentPage - 1) * perPage && index + 1 <= currentPage * perPage,
	);

const PaginationButtons = ({
	setCurrentPage,
	currentPage,
	// perPage,
	// setPerPage,
	// data,
	label,
	filtersApplied,
	setFiltersApplied,
	totalPage,
}) => {
	// const totalItems = data.length;
	// const totalPage = Math.ceil(totalItems / perPage);
	// const totalPage = data?.paginator?.lastPage;

	// console.log(totalPage)

	// console.log(data)
	const { mobileDesign } = useContext(ThemeContext);

	const pagination = () => {
		let items = [];

		let i = currentPage - 1;
		while (i >= currentPage - 1 && i > 0) {
			items.push(
				<PaginationItem
					key={i}
					// onClick={() => setCurrentPage(currentPage - 1)}
					onClick={() => {
						setFiltersApplied((filtersApplied) => ({
							...filtersApplied,
							page: currentPage - 1,
						}));
					}}>
					{i}
				</PaginationItem>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<PaginationItem
				className='bg-blue-300'
				key={currentPage}
				isActive
				// onClick={() => setCurrentPage(currentPage)}
				onClick={() => {
					setFiltersApplied((filtersApplied) => ({
						...filtersApplied,
						page: currentPage,
					}));
				}}>
				{currentPage}
				{/* {mobileDesign ? (
					currentPage
				) : (
					<input
						className='input-pagination'
						placeholder={currentPage}
						onChange={(e) => handleInputChange(e.target.value)}
					/>
				)} */}
			</PaginationItem>,
		);

		i = currentPage + 1;
		while (i <= currentPage + 1 && i <= totalPage) {
			items.push(
				<PaginationItem
					key={i}
					onClick={() => {
						setFiltersApplied((filtersApplied) => ({
							...filtersApplied,
							page: currentPage + 1,
						}));
					}}
					// onClick={() => setCurrentPage(currentPage + 1)}
				>
					{i}
				</PaginationItem>,
			);

			i += 1;
		}

		return items;
	};

	// const getInfo = () => {
	// 	const start = perPage * (currentPage - 1) + 1;

	// 	const end = perPage * currentPage;

	// 	return (
	// 		<span className='pagination__desc'>
	// 			{/* Showing {start} to {end > totalItems ? totalItems : end} of {totalItems} {label} */}
	// 			Mostrando del {start} al {end > totalItems ? totalItems : end} de {totalItems}{' '}
	// 			{label}
	// 		</span>
	// 	);
	// };

	// const handleInputChange = (page) => {
	// 	const pageNumber = parseInt(page, 10);

	// 	if (!pageNumber) {
	// 		setCurrentPage(1);
	// 		// setFiltersApplied((prev) => ({ ...prev, page: 1 }));
	// 		return;
	// 	}

	// 	setCurrentPage(pageNumber);

	// 	// setFiltersApplied((prev) => ({ ...prev, page: pageNumber }));
	// };

	return (
		<CardFooter>
			{/* <CardFooterLeft>
				<span className='text-muted'>{getInfo()}</span>
			</CardFooterLeft> */}

			{mobileDesign ? (
				<div className='w-100'>
					<Pagination ariaLabel={label}>
						<div className='d-flex w-100 justify-content-between align-items-center'>
							<PaginationItem
								isFirst
								isDisabled={!(currentPage - 1 > 0)}
								// onClick={() => setCurrentPage(1)}
								onClick={() => {
									setFiltersApplied((filtersApplied) => ({
										...filtersApplied,
										page: 1,
									}));
								}}
							/>
							<PaginationItem
								isPrev
								isDisabled={!(currentPage - 1 > 0)}
								// onClick={() => setCurrentPage(currentPage - 1)}
								onClick={() => {
									setFiltersApplied((filtersApplied) => ({
										...filtersApplied,
										page: currentPage - 1,
									}));
								}}
							/>
							{/* {currentPage - 1 > 1 && (
								<PaginationItem onClick={() => setCurrentPage(currentPage - 2)}>
									...
								</PaginationItem>
							)} */}
							{pagination()}
							{/* {currentPage + 1 < totalPage && (
								<PaginationItem onClick={() => setCurrentPage(currentPage + 2)}>
									...
								</PaginationItem>
							)} */}
							<PaginationItem
								isNext
								isDisabled={!(currentPage + 1 <= totalPage)}
								// onClick={() => setCurrentPage(currentPage + 1)}
								onClick={() =>
									setFiltersApplied((filtersApplied) => ({
										...filtersApplied,
										page: currentPage + 1,
									}))
								}
							/>
							<PaginationItem
								isLast
								isDisabled={!(currentPage + 1 <= totalPage)}
								// onClick={() => setCurrentPage(totalPage)}
								onClick={() => {
									setFiltersApplied((filtersApplied) => ({
										...filtersApplied,
										page: totalPage,
									}));
								}}
							/>
						</div>
					</Pagination>
				</div>
			) : (
				<CardFooterRight className='d-flex'>
					{totalPage > 1 && (
						<Pagination ariaLabel={label}>
							<PaginationItem
								isFirst
								isDisabled={!(currentPage - 1 > 0)}
								// onClick={() => setCurrentPage(1)}
								onClick={() => {
									setFiltersApplied((filtersApplied) => ({
										...filtersApplied,
										page: 1,
									}));
								}}
							/>
							<PaginationItem
								isPrev
								isDisabled={!(currentPage - 1 > 0)}
								// onClick={() => setCurrentPage(currentPage - 1)}
								onClick={() => {
									setFiltersApplied((filtersApplied) => ({
										...filtersApplied,
										page: currentPage - 1,
									}));
								}}
							/>
							{currentPage - 1 > 1 && (
								<PaginationItem
									// onClick={() => setCurrentPage(currentPage - 2)}
									onClick={() => {
										setFiltersApplied((filtersApplied) => ({
											...filtersApplied,
											page: currentPage - 2,
										}));
									}}>
									...
								</PaginationItem>
							)}
							{pagination()}
							{currentPage + 1 < totalPage && (
								<PaginationItem
									//  onClick={() => setCurrentPage(currentPage + 2)}
									onClick={() => {
										setFiltersApplied((filtersApplied) => ({
											...filtersApplied,
											page: currentPage + 2,
										}));
									}}>
									...
								</PaginationItem>
							)}
							<PaginationItem
								isNext
								isDisabled={!(currentPage + 1 <= totalPage)}
								// onClick={() => setCurrentPage(currentPage + 1)
								onClick={() => {
									setFiltersApplied((filtersApplied) => ({
										...filtersApplied,
										page: currentPage + 1,
									}));
								}}
							/>
							<PaginationItem
								isLast
								isDisabled={!(currentPage + 1 <= totalPage)}
								// onClick={() => setCurrentPage(totalPage)}
								onClick={() => {
									setFiltersApplied((filtersApplied) => ({
										...filtersApplied,
										page: totalPage,
									}));
								}}
							/>
						</Pagination>
					)}

					{!mobileDesign && (
						<div className='d-flex justify-content-around align-items-center w-100'>
							<p className='fw-normal-bold me-2 my-0'>Mostrar registros</p>
							<div>
								<Select
									// size='sm'
									ariaLabel='Per'
									onChange={(e) => {
										// setPerPage(parseInt(e.target.value, 10));
										// setCurrentPage(1);
										setFiltersApplied((filtersApplied) => ({
											...filtersApplied,
											limit: parseInt(e.target.value, 10),
										}));
									}}
									className='text-white bg-blue-300'
									value={filtersApplied?.limit}>
									{Object.keys(PER_COUNT).map((i) => (
										<Option key={i} value={i}>
											{i}
										</Option>
									))}
								</Select>
							</div>
						</div>
					)}
				</CardFooterRight>
			)}
		</CardFooter>
	);
};
PaginationButtons.propTypes = {
	// setCurrentPage: PropTypes.func.isRequired,
	currentPage: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	setPerPage: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.array.isRequired,
	label: PropTypes.string,
};
PaginationButtons.defaultProps = {
	label: 'items',
};

export default PaginationButtons;
