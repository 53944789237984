import axios from 'axios';
const baseURL = process.env.REACT_APP_ENDPOINT;

export const getEstatesFiltered = async (filters = {}, token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const body = {
		filters: filters,
	};

	const data = await axios({
		method: 'POST',
		url: `${baseURL}/properties`,
		headers: header,
		data: body,
	}).then((res) => res.data);

	return data;
};

export const createEstate = async (estateData, token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const body = {
		operationType: estateData,
	};

	const data = await axios({
		method: 'POST',
		url: `${baseURL}/properties/create`,
		headers: header,
		data: body,
	}).then((res) => res.data);

	return data;
};

export const getSingleEstate = async (id, token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const data = await axios({
		method: 'GET',
		url: `${baseURL}/properties/${id}`,
		headers: header,
	}).then((res) => res.data);

	return data;
};
