import React, { useContext, useState } from 'react';
import {
	AddressBook,
	CaretUp,
	CaretDown,
	Notepad,
	Tag,
	X,
	SlidersHorizontal,
} from 'phosphor-react';
import {
	UilWhatsapp,
	UilClipboardNotes,
	UilEnvelopeUploadAlt,
	UilEnvelope,
} from '@iconscout/react-unicons';
import Button from '../../../components/bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import InputFloating from '../../../components/InputFloating';
import { Form, Formik } from 'formik';
import ModalDatePicker from '../../../components/ModalDatePicker';
import CustomSelect from '../../../components/CustomSelect';
import PhonePrefixSelect from '../../../components/PhonePrefixSelect';
import SearchFilter from '../../../components/Owner/SearchFilter';
import { Tags } from '../../../components/Tags/Tags';
import { useParams } from 'react-router-dom';




const Summary = ({ chronology, setChronology, properties, reason , allTags ,summary, setSummary,inputDisabled ,incomeTypes ,operators}) => {
	const { id } = useParams();
	const { mobileDesign } = useContext(ThemeContext);
	const [showFeatures, setShowFeatures] = useState(false);
	const [showTags, setShowTags] = useState(false);
	const [modalStatus, setModalStatus] = useState(false);
	const MAX_TAGS = 5

	const changeHandler = (value, name) => {
		setSummary((summary) => ({ ...summary, [name]: value }));
	};

	const changeHandlerMultipleSelect = (value, name) => {
		if (summary[name]?.includes(value)) {
			setSummary((summary) => ({
				...summary,
				[name]: summary[name].filter((a) => a !== value),
			}));
		} else {
			setSummary((summary) => ({
				...summary,
				[name]: [...(summary[name] || []), value],
			}));
		}
	};

	return (
		<>
			<Card className='p-2'>
				<div
					className={`d-flex align-items-center position-relative ${
						mobileDesign ? 'justify-content-center' : 'justify-content-between'
					} m-4`}>
					<div className='d-flex align-items-center'>
						<AddressBook size={40} color='#01227d' />
						<p className='my-0 mx-2 h5 fw-bold'>Generales</p>
					</div>
					{id && 
					<div>
					
					{!mobileDesign ? (
						<Button
							className=''
							color='primary'
							rounded={1}
							data-bs-toggle='dropdown'
							aria-expanded='false'>
							<div className='d-flex align-items-center'>
								<Icon icon='CustomSettingsAdjust' />
								<p className='my-0 ms-2'>Acciones</p>
							</div>
						</Button>
					) : (
						<div
						className='bg-blue-300 px-2 py-3 rounded-bottom position-absolute'
						data-bs-toggle='dropdown'
						aria-expanded='false'
						style={{ right: 0, top: '-2rem' }}>
							<SlidersHorizontal size={32} color='#ffffff' />
						</div>
					)}
					<div
						className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
						style={{ width: '15rem' }}>
						{/* <div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
									<UilPhone /> <p className='m-0 ps-2'>Volver a llamar</p>
								</div> */}
						<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
							<UilWhatsapp /> <p className='m-0 ps-2'>Enviar un Whatsapp</p>
						</div>
						<div
							onClick={() => setModalStatus(!modalStatus)}
							className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
							<UilClipboardNotes /> <p className='m-0 ps-2'>Generar tarea</p>
						</div>
						<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
							<UilEnvelopeUploadAlt /> <p className='m-0 ps-2'>Enviar un Email</p>
						</div>
						<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
							<UilEnvelope /> <p className='m-0 ps-2'>Mail de bienvenida</p>
						</div>
					</div>
					
					</div>	
					}
					{modalStatus &&
						<ModalDatePicker chronology={chronology} setChronology={setChronology} properties={properties} reason={reason} summary={summary} modalStatus={modalStatus} setModalStatus={setModalStatus} customerId={id}/>
					}
				</div>
				<CardBody>
					<Formik
						initialValues={summary}
						validate={(values) => {
							const errors = {};
							if (!values.surname) {
								errors.surname = 'Apellido obligatorio';
							}
							return errors;
						}}>
						{({ errors, isValid, touched }) => (
							<Form>
								<div
									className={`d-flex ${
										mobileDesign && 'flex-column'
									} justify-content-between`}>
									{/* First column */}
									<div
										className={`d-flex flex-column align-items-start ${
											mobileDesign ? 'col-12' : 'col-4'
										} `}>
										<div className='d-flex align-items-center col-12 py-2'>
											<InputFloating
												changeHandler={changeHandler}
												label='Apellido/Razón'
												name='surname'
												type='text'
												setFunction={setSummary}
												previousValue={summary}
												inputDisabled={inputDisabled}
												// errors={errors}
												touched={touched}
												errorMessage='Apellido obligatorio'
												required
											/>
										</div>
										<div className='d-flex align-items-center col-12 py-2'>
											<InputFloating
												changeHandler={changeHandler}
												label='Nombre'
												name='name'
												type='text'
												setFunction={setSummary}
												previousValue={summary}
												inputDisabled={inputDisabled}
												errorMessage='Nombre obligatorio'
												required
												touched={touched}
											/>
										</div>
										<div className='d-flex align-items-center col-12 py-2'>
											<InputFloating
												changeHandler={changeHandler}
												inputDisabled={inputDisabled}
												label='Email'
												name='email'
												type='email'
												setFunction={setSummary}
												previousValue={summary}
											/>
										</div>
									</div>
									{/* First column */}
									{/* Second column */}
									<div
										className={`d-flex flex-column align-items-start ${
											mobileDesign ? 'col-12' : 'col-4 px-4'
										} `}>
										<div className='d-flex align-items-center col-12 py-2'>
											<div
												className={`d-flex justify-content-between col-12`}>
												<div className='col-4 pe-2'>
													<PhonePrefixSelect
														text='Código'
														name='Código'
														nameEng='areaCode'
														data={summary}
														changeHandler={changeHandler}
														inputDisabled={inputDisabled}
													/>
												</div>

												<InputFloating
													changeHandler={changeHandler}
													label='Celular'
													name='mobile'
													type='text'
													setFunction={setSummary}
													previousValue={summary}
													inputDisabled={inputDisabled}
													touched={touched}
													// errorMessage='Apellido obligatorio'
												/>
											</div>
										</div>
										<div className='d-flex align-items-center col-12 py-2'>
											<InputFloating
												changeHandler={changeHandler}
												label='Web'
												name='web'
												type='text'
												setFunction={setSummary}
												previousValue={summary}
												inputDisabled={inputDisabled}
												touched={touched}
												// errorMessage='Apellido obligatorio'
											/>
										</div>
										<div className='d-flex align-items-center col-12 py-2'>
											<InputFloating
												changeHandler={changeHandler}
												label='Cargo'
												name='position'
												type='text'
												setFunction={setSummary}
												inputDisabled={inputDisabled}
												previousValue={summary}
												touched={touched}
											/>
										</div>
									</div>
									{/* Second column */}
									{/* Third column */}
									<div
										className={`d-flex flex-column align-items-start ${
											mobileDesign ? 'col-12' : 'col-4'
										}`}>
										<div className='d-flex align-items-center col-12 py-2'>
											{/* {!mobileDesign && (
												<label
													htmlFor='type'
													className='fs-5 fw-bold col-xl-5 col-xxl-3'>
													Sexo
												</label>
											)} */}
											<div className='col-6 pe-2'>
											<CustomSelect
												data={summary}
												text={'Pais'}
												name={'Pais'}
												nameEng={'country'}
												changeHandler={changeHandler}
												inputDisabled={inputDisabled}
												values={[
													{
														id: 1,
														label: 'Argentina',
													},
													{
														id: 2,
														label: 'Chile',
													},
													{
														id: 3,
														label: 'Estados Unidos',
													},
													{
														id: 4,
														label: 'Paraguay',
													},
													{
														id: 5,
														label: 'Peru',
													},
													{
														id: 6,
														label: 'Uruguay',
													},
												]}
											/>
											</div>


											<CustomSelect
												data={summary}
												text={'Sexo'}
												name={'Sexo'}
												nameEng={'genre'}
												changeHandler={changeHandler}
												inputDisabled={inputDisabled}
												values={[
													{
														id: 1,
														label: 'Masculino',
													},
													{
														id: 2,
														label: 'Femenino',
													},
													{
														id: 3,
														label: 'Otro',
													},
												]}
											/>
										</div>
										<div className='d-flex align-items-center col-12 py-2'>
											<InputFloating
												changeHandler={changeHandler}
												label='Fecha de nacimiento'
												name='bday'
												type='date'
												setFunction={setSummary}
												previousValue={summary}
												inputDisabled={inputDisabled}
												touched={touched}
											/>
										</div>
										<div className='d-flex align-items-center col-12 py-2'>
											<div className='d-flex justify-content-between col-12'>
												<div className='col-4 pe-2'>
													<CustomSelect
														data={summary}
														text={'Tipo'}
														name={'Tipo'}
														nameEng={'idType'}
														changeHandler={changeHandler}
														inputDisabled={inputDisabled}
														values={[
															{
																id: 1,
																label: 'DNI',
															},
															{
																id: 2,
																label: 'LC',
															},
															{
																id: 3,
																label: 'LE',
															},
														]}
													/>
												</div>

												<InputFloating
													changeHandler={changeHandler}
													label='Documento'
													name='id'
													type='text'
													setFunction={setSummary}
													previousValue={summary}
													inputDisabled={inputDisabled}
													touched={touched}
												/>
											</div>
										</div>
									</div>
									{/* Third column */}
								</div>
							</Form>
						)}
					</Formik>
				</CardBody>
			</Card>
			<div className={`d-flex ${mobileDesign && 'flex-column'}`}>
				<div className={`${mobileDesign ? 'col-12' : 'col-8 pe-4'}`}>
					<Card className='p-2'>
						<CardHeader>
							<div
								className={`d-flex ${
									mobileDesign && 'justify-content-center'
								} align-items-center w-100`}>
								<Notepad size={40} color='#01227d' />
								<p className='my-0 mx-2 h5 fw-bold'>Características</p>
							</div>
							{!mobileDesign && (
								<CardActions
									className='cursor-pointer'
									onClick={() => setShowFeatures(!showFeatures)}>
									{showFeatures ? (
										<CaretUp size={32} color='#01227d' />
									) : (
										<CaretDown size={32} color='#01227d' />
									)}
								</CardActions>
							)}
						</CardHeader>
						{(showFeatures || mobileDesign) && (
							<CardBody>
								<Formik initialValues={summary}>
								{/* {({ errors, isValid, touched }) => ( */}
									<Form>
										<div
											className={`d-flex ${
												mobileDesign && 'flex-column'
											} col-12`}>
											<div
												className={`d-flex flex-column ${
													mobileDesign ? 'col-12' : 'col-6'
												} `}>
												<div className='d-flex flex-column align-items-start col-12'>
													<div className='d-flex align-items-center justify-content-between col-12 py-2'>
														<CustomSelect
															data={summary}
															text={'Forma de ingreso'}
															name={'Forma de ingreso'}
															nameEng={'incomeType'}
															changeHandler={changeHandler}
															inputDisabled={inputDisabled}
															values={incomeTypes}
														/>
													</div>
													<div className='d-flex align-items-center justify-content-between col-12 py-2'>
														<CustomSelect
															data={summary}
															text={'Vendedor'}
															name={'Vendedor'}
															nameEng={'salesman'}
															changeHandler={changeHandler}
															inputDisabled={inputDisabled}
															values={operators}
														/>
													</div>
												</div>
											</div>
											<div
												className={`d-flex flex-column ${
													mobileDesign ? 'col-12' : 'col-6 ps-4'
												}`}>
												<div className='d-flex flex-column align-items-start col-12'>
													<div className='d-flex align-items-center justify-content-between col-12 py-2'>
														<InputFloating
															changeHandler={changeHandler}
															label='Fecha de ingreso'
															name='joinDate'
															type='date'
															setFunction={setSummary}
															previousValue={summary}
															inputDisabled={inputDisabled}
															touched={Formik.touched}
														/>
													</div>
													<div className='d-flex align-items-center justify-content-between col-12 py-2'>
														<SearchFilter
															text={'Tipo de cliente'}
															name={'Tipo de cliente'}
															nameEng={'customerType'}
															inputDisabled={inputDisabled}
															values={[
																{
																	id: 1,
																	label: 'Interesado',
																},
																{
																	id: 2,
																	label: 'Propietario',
																},
																{
																	id: 3,
																	label: 'Inquilino',
																},
																{
																	id: 4,
																	label: 'Garante',
																},
															]}
															changeHandlerMultipleSelect={
																changeHandlerMultipleSelect
															}
															data={summary}
														/>
													</div>
												</div>
											</div>
										</div>
									</Form>
								{/* // )} */}
								</Formik>
							</CardBody>
						)}
					</Card>
				</div>
				<div className={`${mobileDesign ? 'col-12' : 'col-4'}`}>
					<Card className='p-2'>
						<CardHeader>
							<div
								className={`d-flex ${
									mobileDesign && 'justify-content-center'
								} align-items-center w-100`}>
								<Tag size={40} color='#01227d' />
								<p className='my-0 mx-2 h5 fw-bold'>Etiquetas</p>
							</div>
							{!mobileDesign && (
								<CardActions
									className='cursor-pointer'
									onClick={() => setShowTags(!showTags)}>
									{showTags ? (
										<CaretUp size={32} color='#01227d' />
									) : (
										<CaretDown size={32} color='#01227d' />
									)}
								</CardActions>
							)}
						</CardHeader>
						{(showTags || mobileDesign) && (
							<CardBody>
								<div className=''>
									<Tags 
										tagsSuggestion={allTags} 
										summary={summary} 
										setSummary={setSummary} 
										maxTags={MAX_TAGS}
									 />
								
								</div>
							</CardBody>
						)}
					</Card>
				</div>
			</div>
		</>
	);
};

export default Summary;
