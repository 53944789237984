import React from 'react';
import Logo from '../../../components/Logo';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import SubHeader, { SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import Icon from '../../../components/icon/Icon';
import Input from '../../../components/bootstrap/forms/Input';

const DashboardBookingHeader = () => {
	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center contenedor-header'>
					<div className='row g-8 contenedor-logo-and-title'>
						<div className='col-md-auto'>
							<Logo width={145} />
						</div>
						<div className='contenedor-titulo-navbar'>
							<span className='titulo-navbar'>
								SOFTWARE DE ADMINISTRACION <br /> INTEGRAL INMOBILIARIO
							</span>
						</div>
					</div>
					<div className='row g-4 barra-busqueda'>
						<SubHeader>
							<SubHeaderLeft>
								<label
									className='border-0 bg-transparent cursor-pointer me-0'
									htmlFor='searchInput'>
									<Icon icon='Search' size='2x' color='primary' />
								</label>
								<Input
									id='searchInput'
									type='search'
									className='border-0 shadow-none bg-transparent'
									placeholder='Buscar...'
								/>
							</SubHeaderLeft>
						</SubHeader>
					</div>
				</div>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DashboardBookingHeader;
