import React from 'react';
import CustomSelect from '../../components/CustomSelect';

export default function Prices() {
	return (
		<>
			<div className='d-flex align-items-center w-100 mb-3'>
				<div className='col-3'>
					<CustomSelect
						data={{}}
						text={'D'}
						name={'D'}
						nameEng={'sellCurrency'}
						className='rounded-start innerShadowBlueSmall'
					/>
				</div>
				<div className='col-9 ps-2'>
					<input
						className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 blue-placeholder no-outline innerShadowBlueSmall'
						placeholder='Valor venta'
					/>
				</div>
			</div>
			<div className='d-flex align-items-center w-100 mb-3'>
				<div className='col-3'>
					<CustomSelect
						data={{}}
						text={'M'}
						name={'M'}
						nameEng={'sellCurrency'}
						className='rounded-start innerShadowBlueSmall'
					/>
				</div>
				<div className='col-3 ps-2'>
					<CustomSelect
						data={{}}
						text={'P'}
						name={'P'}
						nameEng={'sellCurrency'}
						className='innerShadowBlueSmall'
					/>
				</div>
				<div className='col-6 ps-2'>
					<input
						className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 blue-placeholder no-outline innerShadowBlueSmall'
						placeholder='Valor alquiler'
					/>
				</div>
			</div>
			<div className='d-flex align-items-center w-100 mb-3'>
				<div className='col-3'>
					<CustomSelect
						data={{}}
						text={'P'}
						name={'P'}
						nameEng={'sellCurrency'}
						className='rounded-start innerShadowBlueSmall'
					/>
				</div>
				<div className='col-9 ps-2'>
					<input
						className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 blue-placeholder no-outline innerShadowBlueSmall'
						placeholder='Valor impuestos'
					/>
				</div>
			</div>
			<div className='d-flex align-items-center w-100 mb-3'>
				<div className='col-3'>
					<CustomSelect
						data={{}}
						text={'P'}
						name={'P'}
						nameEng={'sellCurrency'}
						className='rounded-start innerShadowBlueSmall'
					/>
				</div>
				<div className='col-9 ps-2'>
					<input
						className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 blue-placeholder no-outline innerShadowBlueSmall'
						placeholder='Valor expensas'
					/>
				</div>
			</div>
		</>
	);
}
