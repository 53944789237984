import UserContext from '../contexts/userContext';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getEstatesFiltered } from '../services/estateServices';

export default function useEstates(filters) {
	const { token } = useContext(UserContext);

	const estates = useQuery({
		queryKey: [filters],
		queryFn: () => getEstatesFiltered(filters, token).then((response) => response),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});

	return estates;
}
