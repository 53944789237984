import { useState, useEffect, useCallback, useContext } from 'react';
import { searchService } from '../services/searchService';
import UserContext from '../contexts/userContext';

export default function useSearch(searchTerm) {
	const { token } = useContext(UserContext);

	const [dataState, setDataState] = useState({
		data: null,
		errors: null,
		loading: false,
	});

	const searchHandler = useCallback(async () => {
		if (!searchTerm || searchTerm === '') {
			return setDataState((prev) => ({ ...prev, data: null }));
		}

		setDataState((prev) => ({ ...prev, loading: true }));

		await searchService(searchTerm, token)
			.then((res) =>
				setDataState((prev) => ({ ...prev, data: res.data, loading: false, errors: null })),
			)
			.catch((error) =>
				setDataState((prev) => ({ ...prev, data: null, errors: error, loading: false })),
			);
	}, [searchTerm]);

	useEffect(() => {
		const abortController = new AbortController();

		searchHandler();

		return () => abortController.abort();
	}, [searchTerm]);

	return { dataState };
}
