import React, { useState, useContext, useEffect } from 'react';
import ThemeContext from '../../../contexts/themeContext';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../../layout/SubHeader/SubHeaderNew';
import ChildrenSelector from './ChildrenSelector';
import Footer from './Footer';
import { UilUserCircle } from '@iconscout/react-unicons';
import CustomSwitchTabMobile from '../../../components/CustomSwitchTabMobile';
import UserContext from '../../../contexts/userContext';
import { getFilters } from '../../../services/filtersServices';
import { getDataCustomerId } from '../../../services/customersServices';
import { getProperties } from '../../../services/filtersServices'
import { useNavigate, useParams } from 'react-router-dom';
import capitalizeFirstCharacter from '../../../helpers/capitalizeFirstCharacter';
import { getAllTags, getEventType, getLeadIncomeTypes } from '../../../services/sistemServices';
import getOperadores from '../../../services/operatorsService';


	const ManageCustomer = ({ sectionName, action }) => {
		const { id } = useParams();
		const [selectedTab, setSelectedTab] = useState('RESUMEN');
		const [summary, setSummary] = useState({
			status: 1
		});
		const [customerTagsUser, setCustomerTagsUser] = useState([]);
		const [personaInteresada, setPersonaInteresada] = useState(false);
		const [inputDisabled, seInputDisabled] = useState(false);
		const [searchProfile, setSearchProfile] = useState({});
		// const [chronology, setChronology] = useState({ customerStatus: 'No contactado' }); 
		const [chronology, setChronology] = useState([]); 
		const [files, setFiles] = useState([]);
		const [searchProfileFields, setSearchProfileFields] = useState(null);
		const [incomeTypes, setIncomeTypes] = useState([]);
		const [properties, setProperties] = useState([]);
		const [filteredProperties, setFilteredProperties] = useState([]);
		const [reason , setReason] = useState([])
		const [operators , SetOperators] = useState([])
		const [allTags, setAlltags] = useState([])
		const { mobileDesign } = useContext(ThemeContext);
		const { token } = useContext(UserContext);
		const navigate = useNavigate()
		
		useEffect(() => {
			setPersonaInteresada(isPersonaInteresado(summary));
		}, [summary.customerType]);


		useEffect(() => {
		  initializeData(id, sectionName, action, token);
		}, [id, sectionName, action, token]);
	  
		useEffect(() => {
				 getProperties(searchProfile, token).then((res) => {
					setFilteredProperties(res.data);
				 });
				}, [searchProfile]);
		useEffect(() => {
					getProperties({}, token).then((res) => {
					   setProperties(res.data);
					});
					getOperadores().then((res) => {
						SetOperators(res);
					});
					getEventType(token).then((res) => {
						setReason(res)
					})
					getLeadIncomeTypes(token).then((res) => {
						setIncomeTypes(res)
					})
					getAllTags(token).then((res) => {
						setAlltags(res.data);
					});
		},[token])
				
	
		
	  
		const tabs = [
		  { name: 'RESUMEN', isActive: true },
		  { name: 'PERFIL DE BÚSQUEDA', isActive: personaInteresada },
		  { name: 'CRONOLOGÍA', isActive: action === 'editar' },
		  { name: 'ARCHIVO', isActive: action === 'editar' },
		];
	  
		function initializeData(id, sectionName, action, token) {
		  if (!id) {
			handlePerson(sectionName);
		  } else {
			handleEdit(id, token);
		  }
	  
		  if (action === 'ver') {
			seInputDisabled(true);
		  }
	  
		  getFilters('customers', token).then((res) => setSearchProfileFields(res));
		}

		function isPersonaInteresado(summary){
			for( let valor in summary.customerType){
				if(summary.customerType[valor] === 1){
					if(id){
						getDataCustomerId(id, token).then((res)=> {
							if(res.searchProfile){
								setSearchProfile((prevSearchProfile) => {
									return { ...prevSearchProfile, ...res.searchProfile };
								});
							}
						})
						
					}
					setSearchProfile((prevSearchProfile) => {
						return { ...prevSearchProfile, isActive : true };
					});

					return true
				} else {
					setSearchProfile({})
				}
			}
	
			return false
		}
    
	  
		function handlePerson(sectionName) {
			switch (sectionName) {
				case 'interesados':
				  setSummary(prevSummary => ({ ...prevSummary, customerType: [1] }));
				  break;
				case 'propietarios':
				  setSummary(prevSummary => ({ ...prevSummary, customerType: [2] }));
				  break;
				case 'inquilinos':
				  setSummary(prevSummary => ({ ...prevSummary, customerType: [3] }));
				  break;
				case 'garantes':
				  setSummary(prevSummary => ({ ...prevSummary, customerType: [4] }));
				  break;
				default:
				  break;
			  }
		}
	  
		async function handleEdit(id, token) {
			try {
			  const res = await getDataCustomerId(id, token);
			  if (res.files) setFiles(res.files);
			  if (res.summary) setSummary(res.summary);
			  if (res.summary.tags) setCustomerTagsUser(res.summary.tags);
			  if (res.cronology) setChronology(res.cronology);
			} catch (error) {
			  navigate('/error-page');
			}
		  }
	
	return (
		<PageWrapper title={`${action} ${sectionName}`}>
			<Page container='fluid'>
				{!mobileDesign && (
					<SubheaderNew
						breadCrumbs={[
							// { name: 'Interesados', url: '/personas/interesados' },
							// { name: 'Crear', url: '/personas/interesados/crear' },
							{ name: capitalizeFirstCharacter(sectionName), url: `/personas/${sectionName}` },
							// { name: customerEdit? "Editar" : "Crear", url: customerEdit? `/personas/${persona}/editar/${id}` : `/personas/${persona}/crear` },
							{ name: capitalizeFirstCharacter(action), url: id? `/personas/${sectionName}/${action}/${id}?` : `/personas/${sectionName}/${action}` },
						]}
						hideButtons
						/>
				)}
				<div className='d-flex flex-column w-100 h-100'>
					{/* Tabs */}
					{!mobileDesign && (
						<div className='d-flex fw-bold'>
							{tabs
							.filter((item) => item.isActive === true)
							.map((tab) => (
								<div
								key={tab.name}
								className={`${
									selectedTab === tab.name ? 'bg-lblue' : 'bg-lblue-065'
								}  px-4 py-3 me-1 rounded-top-lg cursor-pointer`}
								onClick={() => setSelectedTab(tab.name)}>
									{tab.name}
								</div>
							))}
						</div>
					)}
					{/* Tabs */}

					{mobileDesign && (
						<>
							<div className='d-flex align-items-center justify-content-center w-100 pb-4'>
								<UilUserCircle size={44} color='#506CF9' />
								<h3 className='text-color-lblue fw-bold ps-2 m-0'>
									{/* CREAR INTERESADO */}
									{action.toUpperCase()} {sectionName.toUpperCase()}
								</h3>
							</div>
							<div
								className='d-flex align-items-center justify-content-center w-100 gradient-lblue rounded-top-2'
								style={{
									height: '7rem',
								}}>
								<div className='col-9'>
									<CustomSwitchTabMobile
										name='CustomerTabs'
										selectedTab={selectedTab}
										setSelectedTab={setSelectedTab}
										tabs={tabs}
										size={2}
									/>
								</div>
							</div>
						</>
					)}

					<div
						className={`d-flex flex-column ${
							!mobileDesign && 'bg-lblue rounded-right-and-bottom p-4'
						} w-100 mb-3`}>
						<ChildrenSelector
							selectedTab={selectedTab}
							summary={summary}
							setSummary={setSummary}
							searchProfile={searchProfile}
							setSearchProfile={setSearchProfile}
							searchProfileFields={searchProfileFields}
							inputDisabled={inputDisabled}
							chronology={chronology}
							setChronology={setChronology}
							filteredProperties={filteredProperties}
							properties={properties}
							setFiles={setFiles}
							files={files}
							reason={reason}
							allTags={allTags}
							incomeTypes={incomeTypes}
							operators={operators}
							

						/>
						<Footer summary={summary} customerTagsUser={customerTagsUser} searchProfile={searchProfile} action={action} sectionName={sectionName} chronology={chronology} />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ManageCustomer;
