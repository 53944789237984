import React, { useRef, useContext, useEffect } from 'react';
import {
	UilKeySkeleton,
	UilHome,
	UilTrees,
	UilStore,
	UilCar,
	UilBuilding,
	UilLocationArrow,
	UilAngleRightB,
} from '@iconscout/react-unicons';
import { Factory, Handbag, Buildings, Stack, HouseLine } from 'phosphor-react';
import MapLayout from './MapLayout';
import CustomDropdown from '../../components/CustomDropdown';
import Check from '../../components/Check';
import UserContext from '../../contexts/userContext';
import { createEstate, getSingleEstate } from '../../services/estateServices';
import useBranches from '../../hooks/useBranches';

export default function OperationType({ estateData, setEstateData, setSelectedStep }) {
	const tipologyItems = [
		{
			label: 'Departamento',
			id: 1,
			icon: (
				<UilKeySkeleton
					color={estateData.operationType.tipology === 1 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Casa',
			id: 2,
			icon: (
				<UilHome
					color={estateData.operationType.tipology === 2 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Campo',
			id: 3,
			icon: (
				<UilTrees
					color={estateData.operationType.tipology === 3 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Galpón',
			id: 4,
			icon: (
				<Factory
					color={estateData.operationType.tipology === 4 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Local',
			id: 5,
			icon: (
				<UilStore
					color={estateData.operationType.tipology === 5 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Negocio',
			id: 6,
			icon: (
				<Handbag
					color={estateData.operationType.tipology === 6 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Cochera',
			id: 7,
			icon: (
				<UilCar
					color={estateData.operationType.tipology === 7 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Oficina',
			id: 8,
			icon: (
				<Buildings
					color={estateData.operationType.tipology === 8 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Lote',
			id: 9,
			icon: (
				<Stack
					color={estateData.operationType.tipology === 9 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Edificio',
			id: 10,
			icon: (
				<UilBuilding
					color={estateData.operationType.tipology === 10 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
		{
			label: 'Quinta',
			id: 11,
			icon: (
				<HouseLine
					color={estateData.operationType.tipology === 11 ? '#fff' : '#506CF9'}
					size={24}
				/>
			),
		},
	];

	const operationTypeItems = [
		{
			label: 'Venta',
			id: 1,
		},
		{
			label: 'Alquiler',
			id: 2,
		},
		{
			label: 'Alquiler temporario',
			id: 3,
		},
	];

	const incomeTypeItems = [
		{
			id: 1,
			label: 'Tasación',
		},
		{
			id: 2,
			label: 'Autorizado',
		},
		{
			id: 3,
			label: 'Exclusivo',
		},
	];

	const inputRef = useRef(null);

	useEffect(() => {
		const autocompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
			componentRestrictions: { country: ['AR', 'UY'] },
		});

		autocompleteInstance.setTypes(['address']);

		autocompleteInstance.addListener('place_changed', () => {
			const selectedPlace = autocompleteInstance.getPlace();
			if (!selectedPlace.geometry) {
				console.log('No geometry for selected place');
				return;
			}

			const { lat, lng } = selectedPlace.geometry.location;

			console.log(selectedPlace);

			console.log(
				selectedPlace['address_components'].find((result) =>
					result.types.includes('route'),
				)['short_name'],
			);
			console.log(
				selectedPlace['address_components'].find((result) =>
					result.types.includes('street_number'),
				)['short_name'],
			);
			console.log(
				selectedPlace['address_components'].find((result) =>
					result.types.includes('country'),
				)['long_name'],
			);

			setEstateData((prev) => ({
				...prev,
				operationType: {
					...prev.operationType,
					coordinates: [lat(), lng()],
					streetName: selectedPlace['address_components'].find((result) =>
						result.types.includes('route'),
					)['short_name'],
					streetNumber: selectedPlace['address_components'].find((result) =>
						result.types.includes('street_number'),
					)['short_name'],
					country: selectedPlace['address_components'].find((result) =>
						result.types.includes('country'),
					)['long_name'],
					longitude: lng().toString(),
					latitude: lat().toString(),
					neighborhood: selectedPlace['address_components'].find((result) =>
						result.types.includes('neighborhood'),
					)['short_name'],
					city: selectedPlace['address_components'].find((result) =>
						result.types.includes('locality'),
					)['short_name'],
				},
			}));
		});
	}, []);

	const { token } = useContext(UserContext);

	const branches = useBranches();


	getSingleEstate(106, token).then(response => console.log(response))


	return (
		<div className='d-flex flex-column align-items-center justify-content-center w-100 h-100 mh-75'>
			<div className='containerOperationType col-11 h-80'>
				{/* tipology */}
				<p className='mb-0 fw-bold fs-5 mb-2 tipologyTitle'>Tipología</p>
				<div className='d-flex flex-column align-items-start justify-content-between rounded bg-lblue p-3 blueBox innerShadowBlue'>
					{tipologyItems.map((item) => (
						<div
							onClick={() =>
								setEstateData((estateData) => ({
									...estateData,
									operationType: {
										...estateData.operationType,
										tipology: item.id,
									},
								}))
							}
							className={`d-flex align-items-center rounded-pill py-2 ps-2 pe-4 fs-5 cursor-pointer w-100 ${
								estateData.operationType.tipology === item.id
									? 'bg-blue-300 text-color-white'
									: 'text-color-lblue'
							}`}>
							{item.icon}
							<p className='px-2 mb-0'>{item.label}</p>
						</div>
					))}
				</div>
				{/* tipology */}

				{/* search bar and map  */}

				<div className='d-flex align-items-center justify-content-between p-3 bg-lblue rounded searchBar mb-4'>
					<input
						placeholder='Ubicación'
						className='w-100 bg-lblue border-0 text-color-lblue fs-5 fw-bold input-filters-menu'
						// value={estateData.operationType.address}
						ref={inputRef}
						// onChange={(event) => onChange(event.target.value, 500)}
					/>
					<UilLocationArrow color='#506CF9' size={24} />
				</div>
				<div className='map'>
					<MapLayout
						coord={estateData.operationType.coordinates || [-34.60376, -58.38162]}
						estateData={estateData}
					/>
				</div>

				{/* search bar and map  */}
				<div className='d-flex flex-column align-items-start inputOperationType1 w-100'>
					<p className='fs-5 fw-bold'>Operación</p>
					<CustomDropdown
						label={'Tipo de operación'}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-6 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center rounded-bottom position-absolute w-100 shadow'
						}
						margin='mb-4'
						width='w-100'>
						{operationTypeItems.map((item, index) => (
							<div
								className={`d-flex justify-content-between align-items-center bg-lblue text-color-lblue py-3 cursor-pointer fs-6 fw-bold w-100 px-4
										${index === operationTypeItems.length - 1 && 'rounded-bottom'}
										`}
								key={item.id}
								onClick={() => {
									if (estateData.operationType.operations.includes(item.id)) {
										setEstateData((estateData) => ({
											...estateData,
											operationType: {
												...estateData.operationType,
												operations:
													estateData.operationType.operations.filter(
														(type) => type !== item.id,
													),
											},
										}));
									} else {
										setEstateData((estateData) => ({
											...estateData,
											operationType: {
												...estateData.operationType,
												operations: [
													...estateData.operationType.operations,
													item.id,
												],
											},
										}));
									}
								}}>
								{item.label}
								<Check
									checked={estateData.operationType.operations.includes(item.id)}
								/>
							</div>
						))}
					</CustomDropdown>
				</div>
				<div className='d-flex flex-column align-items-start inputOperationType2 w-100'>
					<p className='fs-5 fw-bold'>Sucursal</p>
					<CustomDropdown
						label={
							branches?.data?.data?.find(
								(branch) => branch.id === estateData.operationType.branch,
							)?.social_name || 'Seleccione'
						}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-6 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom position-absolute shadow w-100'
						}
						margin='mb-4'
						width='w-100'
						closeAfterClick>
						{branches?.data?.data?.map((item, index) => (
							<div
								className={`d-flex align-items-center py-3 cursor-pointer fs-6 fw-bold custom-dropdown-item w-100 px-4 ${
									index === branches?.data?.data?.length - 1 && 'rounded-bottom'
								}`}
								key={item.id}
								onClick={() =>
									setEstateData((estateData) => ({
										...estateData,
										operationType: {
											...estateData.operationType,
											branch: item.id,
										},
									}))
								}>
								{item.social_name}
							</div>
						))}
					</CustomDropdown>
				</div>
				<div className='d-flex flex-column align-items-start inputOperationType3 w-100'>
					<p className='fs-5 fw-bold'>Forma de ingreso</p>
					<CustomDropdown
						label={
							incomeTypeItems.find(
								(item) => item.id === estateData.operationType.registrationType,
							)?.label || 'Seleccione'
						}
						classname={`bg-lblue px-4 py-3 w-100 text-color-lblue fs-6 fw-600`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom position-absolute shadow w-100'
						}
						margin='mb-4'
						width='w-100'
						closeAfterClick>
						{incomeTypeItems.map((item, index) => (
							<div
								// className='d-flex align-items-center py-3 cursor-pointer fs-6 fw-bold'
								className={`d-flex align-items-center py-3 cursor-pointer fs-6 fw-bold custom-dropdown-item w-100 px-4 ${
									index === incomeTypeItems.length - 1 && 'rounded-bottom'
								}`}
								key={item.id}
								onClick={() =>
									setEstateData((estateData) => ({
										...estateData,
										operationType: {
											...estateData.operationType,
											registrationType: item.id,
										},
									}))
								}>
								{item.label}
							</div>
						))}
					</CustomDropdown>
				</div>
			</div>
			<div className='d-flex align-items-center justify-content-end col-11 pt-5'>
				<div
					className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
					onClick={() => {
						console.log(estateData);

						createEstate(estateData.operationType, token).then((res) =>
							console.log(res),
						);

						// setSelectedStep('general');
					}}>
					<p className='mb-0 text-color-white fw-bold'>Siguiente</p>
					<UilAngleRightB color='#fff' size={24} />
				</div>
			</div>
		</div>
	);
}
