import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import { UilSearch, UilBell, UilRulerCombined } from '@iconscout/react-unicons';
import InputCounter from '../../components/InputCounter';
import InputFloating from '../../components/InputFloating';
import SearchFilter from '../../components/Owner/SearchFilter';
import ThemeContext from '../../contexts/themeContext';
import banner from '../../assets/img/ilus_tasador.png';
import { Form, Formik } from 'formik';
import MapLayout from './MapLayout';
import { useQuery } from '@tanstack/react-query';
import Toggle from '../../components/Owner/Toggle';
import Contador from '../../components/Owner/Contador';
import Spinner from '../../components/bootstrap/Spinner';
import TasadorMobile from './TasadorMobile';
import { getTasacion, getFilters } from '../../services/tasadorService';
import useCustomers from '../../hooks/useCustomers';

export default function Tasador(searchProfile, setSearchProfile) {
	const { isLoading, data: filters = [] } = useQuery(['filters'], () => getFilters());
	const [tasacionData, setTasacionData] = useState();
	const [estateData, setEstateData] = useState({});
	const [loadingTas, setLoadingTas] = useState(false);
	const [operacion, setOperacion] = useState([]);
	const [moneda, setMoneda] = useState([]);
	const [tipoPropiedad, setTipoPropiedad] = useState([]);
	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState({
		valor1: '',
		password: '',
		name: '',
		surname: '',
		birthday: '',
	});

	// const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	// const inputRef = useRef(null);

	useEffect(() => {
		const autocompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
			componentRestrictions: { country: ['AR', 'UY'] },
		});
		if (!isLoading && filters.length > 0) {
			setLoading(true);
			filters.map((item) => {
				if (item.name === 'Operación') {
					setOperacion([]);
					item.options.operation.map((el) => {
						operacion.push(el);
					});
					// setOperacion(item.options.operation);
				}
				if (item.name === 'Moneda') {
					setMoneda([]);
					item.options.currency.map((curr) => {
						moneda.push(curr);
					});
					//setMoneda(item.options.currency);
				}

				if (item.name === 'Tipo de propiedad') {
					setTipoPropiedad([]);
					item.options.propertyType.map((tp) => {
						tipoPropiedad.push(tp);
					});
					//setMoneda(item.options.currency);
				}

				setLoading(false);
			});
		}
		autocompleteInstance.setTypes(['address']);

		autocompleteInstance.addListener('place_changed', () => {
			const selectedPlace = autocompleteInstance.getPlace();
			if (!selectedPlace.geometry) {
				console.log('No geometry for selected place');
				return;
			}

			const { lat, lng } = selectedPlace.geometry.location;

			console.log(selectedPlace);

			setEstateData((prev) => ({
				...prev,
				operationType: {
					...prev.operationType,
					coordinates: [lat(), lng()],
					lng: lng().toString(),
				},
			}));
		});
	}, [isLoading, filters]);

	console.log(filters);
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const inputRef = useRef(null);

	useEffect(() => {
		const autocompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
			componentRestrictions: { country: ['AR', 'UY'] },
		});

		autocompleteInstance.setTypes(['address']);

		autocompleteInstance.addListener('place_changed', () => {
			const selectedPlace = autocompleteInstance.getPlace();
			if (!selectedPlace.geometry) {
				console.log('No geometry for selected place');
				return;
			}

			const { lat, lng } = selectedPlace.geometry.location;

			console.log(selectedPlace);

			setEstateData((prev) => ({
				...prev,
				operationType: {
					...prev.operationType,
					coordinates: [lat(), lng()],
					lng: lng().toString(),
				},
			}));
		});
	}, []);

	console.log(estateData);
	const handleTasarButtonClick = async () => {
		try {
			setLoadingTas(true);
			const token = 'yourAccessToken'; // Replace with your actual token
			const data = await getTasacion('properties', token); // Call the getTasacion function
			console.log('Tasacion data:', data.data); // Do something with the response data
			setTasacionData(data.data);
			console.log(tasacionData);
			setLoadingTas(false);
		} catch (error) {
			console.error('Error fetching tasacion:', error);
		}
	};
	const items = [
		{
			name: 'Seleccione',
			nameEng: 'operation',
			type: 'select',
			options: null,
		},
		{
			name: 'Barrio',
			nameEng: 'neighborhood',
			type: 'select',
			options: null,
		},
		{
			name: 'Sup. libre',
			nameEng: 'freeArea',
			type: 'text',
			options: null,
		},
		{
			name: 'Tipo de inmueble',
			nameEng: 'propertyType',
			type: 'select',
			options: null,
		},
		{
			name: 'Edificio',
			nameEng: 'building',
			type: 'select',
			options: null,
		},
		{
			name: 'Antigüedad',
			nameEng: 'antiqueness',
			type: 'text',
			options: null,
		},
		{
			name: 'Subtipo de inmueble',
			nameEng: 'propertySubType',
			type: 'select',
			options: null,
		},
		{
			name: 'Ubicación',
			nameEng: 'location',
			type: 'select',
			options: null,
		},
		{
			name: 'Cochera',
			nameEng: 'garage',
			type: 'text',
			options: null,
		},
		{
			name: 'Ambientes',
			nameEng: 'rooms',
			type: 'select',
			options: null,
		},
		{
			name: 'Sup. total',
			nameEng: 'totalArea',
			type: 'text',
			options: null,
		},
		{
			name: 'Valor',
			nameEng: 'value',
			type: 'text',
			options: null,
		},
		{
			name: 'Localidad',
			nameEng: 'city',
			type: 'select',
			options: null,
		},
		{
			name: 'Sup. cubierta',
			nameEng: 'coveredArea',
			type: 'text',
			options: null,
		},
	];

	const fetchedData = [
		{
			isMultiple: true,
			estateCount: 5,
			photo:
				'https://arquitecturayempresa.es/sites/default/files/content/arquitectura_rehabilitacion_amsterdam-235_000.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '50000',
			location: 'Av. Siempreviva 742',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 3,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [25],
			id: 995,
			position: [-34.600556, -58.395],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo:
				'https://static1.sosiva451.com/47515511/9533fecc-4397-43d2-ab54-1d97badd627d_u_small.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '60000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 4,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [50],
			id: 996,
			position: [-34.578611, -58.420861],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo: 'https://www.nocnok.com/hubfs/casa-lujo.webp',
			operation: 'Venta',
			currency: 'USD',
			value: '75000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 5,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [75],
			id: 997,
			position: [-34.60376, -58.38162],
			favorite: false,
		},
		{
			isMultiple: false,
			estateCount: 1,
			photo:
				'https://www.construyehogar.com/wp-content/uploads/2014/08/Dise%C3%B1o-de-casa-moderna-de-una-planta.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '100000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 6,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [88],
			id: 998,
			position: [-34.588056, -58.393056],
			favorite: false,
		},
	];
	console.log('Userdata', { userData });

	return (
		<PageWrapper title='Tasador' className='p-0'>
			<Page container='xxl'>
				<div className='d-flex w-100 h-100 pb-5'>
					{/* herramienta Tasador gris */}
					<div className='d-flex flex-column col-md-6 col-xxl-4 col-sm-6 align-items-center px-5'>
						<div
							className='emulate-card p-0 rounded-1 text-left p-4'
							style={{
								marginTop: '5vh',

								backgroundColor: '#EDEDED',
							}}
						>
							<div className='p-2 ms-3 '>
								<h1 className='fw-700 mt-2'>Tasador de </h1>
								<h1 className='fw-700'>propiedades</h1>
							</div>
							<div className='p-4 d-flex flex-column justify-content-center'>
								<div>
									{/* <label className='mb-1 ms-1 fw-600 fs-6'>Operacion</label> */}
									{!isLoading && !loading ? (
										<SearchFilter
											text={'Operación'}
											name={'Operación'}
											nameEng={'operation'}
											changeHandlerMultipleSelect={() => console.log('')}
											data={searchProfile}
											withSearch={false}
											bgColor={'bg-white'}
											values={operacion} // Asegúrate de pasar operacion como valores
										/>
									) : (
										<Spinner />
									)}
								</div>
								<div className='col mt-4'>
									{/* <label className='mb-1 ms-1 fw-600 fs-6'>Moneda</label> */}
									{!loading && (
										<SearchFilter
											text={'Moneda'}
											name={'Moneda'}
											nameEng={'Currency'}
											bgColor={'bg-white'}
											changeHandlerMultipleSelect={() => console.log('')}
											data={searchProfile}
											withSearch={false}
											values={moneda}
										/>
									)}
								</div>
								<div className='mt-4'>
									{/* <label className='mb-1 ms-1 fw-600 fs-6'>
											Tipo de propiedad
										</label> */}
									<SearchFilter
										text={'Tipo Propiedad'}
										name={'Operación'}
										nameEng={'Operation'}
										bgColor={'bg-white'}
										changeHandlerMultipleSelect={() => console.log('')}
										data={searchProfile}
										withSearch={false}
										values={tipoPropiedad}
									/>
								</div>
								<div className='mt-4'>
									{/* <label className='mb-1 ms-1 fw-600 fs-6'>Antiguedad</label> */}
									<SearchFilter
										text={'Antiguedad'}
										name={'Operación'}
										nameEng={'Operation'}
										bgColor={'bg-white'}
										changeHandlerMultipleSelect={() => console.log('')}
										data={searchProfile}
										withSearch={false}
										values={[
											{
												id: 1,
												label: 'Alquiler',
											},
											{
												id: 2,
												label: 'Alquiler temporario ',
											},
											{
												id: 3,
												label: 'Venta',
											},
										]}
									/>
								</div>
							</div>
							<div className='row d-flex justify-content-center align-items-center mt-2 px-4 ms-1 me-1'>
								<div className='col-4'>
									<label className='mb-1  fw-600 fs-6 text-center'>
										Ambientes
									</label>
									<div className=''>
										<Contador />
									</div>
								</div>
								<div className='col-4  d-flex flex-column'>
									<label className='mb-1 ms-1 fw-600 fs-6 '>Cochera</label>
									<div className=''>
										<Toggle label='Toggle' toggled={true} />
									</div>
								</div>

								<div className='col-4 d-flex flex-column'>
									<label className='mb-1  fw-600 fs-6'>Amenities</label>
									<Toggle label='Toggle' toggled={true} />
								</div>
							</div>
							<div className='row mt-5 px-3'>
								<Formik className='h-100'>
									{({ touched }) => (
										<Form className='w-100 h-100'>
											<div className='row'>
												<div className='col ms-2'>
													<div className=''>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs col py-0 input-narrow'
															label='Superficie Cubierta'
															name='valor1'
															type='number'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															bgColor={'bg-white'}
															errorMessage={''}
															onChange={(e) => {
																console.log(e);
															}}
														/>
													</div>
												</div>
												<div className='col me-2'>
													<InputFloating
														className='input-filters-menu rounded shadow-inputs p-3 input-narrow'
														label='Superficie descubierta'
														name='valor2'
														type='number'
														setFunction={setUserData}
														previousValue={userData}
														touched={touched}
														bgColor={'bg-white'}
														errorMessage={''}
														onChange={(e) => {
															console.log(e);
														}}
													/>
												</div>
											</div>
											<div className='row mt-4'>
												<div className='col p-2 ms-3 me-3'>
													{/* <InputFloating
															className='input-filters-menu rounded shadow-inputs p-3 input-narrow'
															label='Direccion Aproximada'
															name='valor2'
															ref={inputRef}
															type='text'
															bgColor='bg-white'
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															errorMessage={''}
															onChange={(e) => {
																console.log(e);
															}}
														/> */}
													<input
														type='text'
														className='form-control rounded-1'
														ref={inputRef}
													/>
												</div>
											</div>
											<div className='row mt-4 d-flex'>
												<div className='col-5 ms-2'>
													<div className=''>
														<InputFloating
															className='input-filters-menu rounded shadow-inputs p-3 input-narrow'
															label='Radio en Kms'
															name='valor1'
															type='number'
															bgColor={'bg-white'}
															setFunction={setUserData}
															previousValue={userData}
															touched={touched}
															errorMessage={''}
															onChange={(e) => {
																console.log(e);
															}}
														/>
													</div>
												</div>
												<div className='col-6 ms-3 mt-n2 '>
													<label className=' me-4 fw-600 fs-6'>
														Consulta: Sólo activas
													</label>
													<div className='col-6'>
														<Toggle label='Toggle me' toggled={true} />
													</div>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</div>

							<div className='d-flex justify-content-center mt-5 px-3'>
								<button
									className='btn btn-primary rounded-7 '
									style={{ width: '100%' }}
									onClick={handleTasarButtonClick}
								>
									TASAR
								</button>
							</div>
						</div>
					</div>
					{/* FIN herramienta Tasador gris */}
					{/* container mapa y titulo */}
					<div className='d-flex flex-column col-8'>
						{/* Titulos y boton  */}
						<div className='d-flex flex-column w-100 '>
							<div className='d-flex w-100 align-items-center justify-content-between'>
								<div className='d-flex flex-column'>
									<h1 className=' fw-700'>322 propiedades activas</h1>
									<h2 className='text-color-lblue fw-700 pt-4'>
										Precio estimado
									</h2>
									<h1 className='text-color-lblue fs-1 fw-700'>ARS $300000</h1>
								</div>
								<img
									className=''
									style={{ height: '227px', width: '375px' }}
									src={banner}
									alt='Banner'
								/>
							</div>
							<button className='btn btn-primary rounded-1 mt-5'>
								GENERAR REPORTE
							</button>
						</div>
						{/* Titulos y boton  */}
						<div className='w-100 h-75 pt-5 mt-5'>
							{loadingTas ? (
								<div className='d-flex justify-content-center'>
									<Spinner />
								</div>
							) : (
								<MapLayout fetchedData={tasacionData} />
							)}
						</div>
					</div>
					{/* container mapa y titulo */}
				</div>
			</Page>
		</PageWrapper>
	);
}
