import Drawer from '../../components/Drawer';
import { useQueryClient } from '@tanstack/react-query';
import Switcher from 'react-switcher-rc';

export default function PortalSidebar({ offcanvasStatus, setOffcanvasStatus, portals }) {
	const queryClient = useQueryClient();

	const handleClick = (portal) => {
		const portalsCopy = [...portals.data];

		const index = portalsCopy.indexOf(portal);

		portalsCopy[index] = { ...portalsCopy[index], isDefault: !portalsCopy[index].isDefault };

		queryClient.setQueryData(['portals'], portalsCopy);
	};

	return (
		<Drawer offcanvasStatus={offcanvasStatus} setOffcanvasStatus={setOffcanvasStatus}>
			{portals.data?.map((portal) => (
				<div
					className='d-flex justify-content-between align-items-center p-4 shadow rounded m-3 cursor-pointer'
					onClick={() => handleClick(portal)}>
					<p className={`fs-5 ps-2 mb-0`}>{portal.name}</p>
					<Switcher
						name='my-switcher'
						checkedIcon=' '
						unCheckedIcon={null}
						checked={portal.isDefault}
						onColor='#506cf9'
					/>
				</div>
			))}
		</Drawer>
	);
}
