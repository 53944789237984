import React, { useContext } from 'react';
import { UilSearch } from '@iconscout/react-unicons';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from './bootstrap/OffCanvas';
import ThemeContext from '../contexts/themeContext';
import Input from './bootstrap/forms/Input';

export default function Drawer({ offcanvasStatus, setOffcanvasStatus, children }) {
	const { darkModeStatus } = useContext(ThemeContext);

	return (
		<OffCanvas
			id='notificationCanvas'
			titleId='offcanvasExampleLabel'
			placement='end'
			isOpen={offcanvasStatus}
			setOpen={setOffcanvasStatus}>
			<OffCanvasHeader setOpen={setOffcanvasStatus} className='bg-blue-300 p-4'>
				<div className='w-100 d-flex justify-content-center'>
					<h4 className='m-0 text-color-white fw-bold'>Mostrar / Ocultar</h4>
				</div>
			</OffCanvasHeader>
			<OffCanvasBody className='p-0'>
				<div className='d-flex align-items-center py-4 px-3 w-100 search-input-drawer'>
					<label
						className='border-0 bg-transparent cursor-pointer me-0'
						htmlFor='searchInput'>
						<UilSearch color={darkModeStatus ? '#A9B5D7' : '#01227d'} size={20} />
					</label>
					<Input
						id='searchInput'
						type='search'
						className='border-0 shadow-none bg-transparent input-search'
						placeholder='Buscar...'
					/>
				</div>
				{children}
			</OffCanvasBody>
		</OffCanvas>
	);
}
