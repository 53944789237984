import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	UilArrowUp,
	UilArrowDown,
	UilSetting,
	UilBrowser,
	UilUsersAlt,
    UilRulerCombined,
	UilBag,
} from '@iconscout/react-unicons';
import Avatar from '../../components/Avatar';
import Button from '../../components/bootstrap/Button';
import USERS from '../../common/data/userDummyData';
import { saveOperador } from '../../services/operatorsService';
import Swal from 'sweetalert2';
import Toast from '../../components/Toast/Toast';

const PopOverFooter = () => {
	return (
		<>
			<div className='row border-bottom p-4  justify-content-center align-items-center w-100 d-flex'>
				<div className='col-1 me-4'>
					<UilRulerCombined className='text-color-lblue' size={30} />
				</div>

				<div className='col-6 mt-2'>
					<span className='text-align-left fw-6 fw-500'>
						Búsqueda General:<p class>Ambientes</p>
					</span>
				</div>
				<div className='col-4 d-flex'>
					<div className='float-end'>
						<span className='text-color-violet fw-600 fs-5'>Consultas</span>
					</div>
				</div>
			</div>
			<div className='row justify-content-center align-items-center'>
				<div className='col-12 '>
					<div className='col-6 p-2'>
						<div className='col-6 d-flex '>
							<div className='mt-5 d-flex'>
								<div className='col-6 me-5'>
									<p className='fw-600 text-color-blue'>Mail</p>
									<p className='fw-600 text-color-blue'>Whatsapp</p>
									<p className='fw-600 text-color-blue'>Visita</p>
									<p className='text-color-blue'>LLamado</p>
								</div>
								<div className='col-10 ms-5 d-flex flex-column '>
									<div className='d-flex flex-row'>
										<span className='ms-5'>23</span>
									</div>

									<div className='d-flex mt-3 '>
										<span className='ms-5'>15</span>
									</div>
									<div className='d-flex mt-3 '>
										<span className='ms-5'>9</span>
									</div>
									<div className='d-flex mt-3 '>
										<span className='ms-5'>3</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PopOverFooter;
