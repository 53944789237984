import React, { useState } from 'react';
import './ToggleButton.css'; // Archivo CSS para estilos personalizados

const Toggle = () => {
	const [isChecked, setIsChecked] = useState(false);

	const handleToggle = () => {
		setIsChecked(!isChecked);
	};

	return (
		<div className={`toggle-button ${isChecked ? 'active' : ''}`}>
			<div
				className={`toggle-option rounded-1 px-2 justify-content-center text-center	 ${
					isChecked ? 'active px-2 ' : ''
				}`}
				onClick={handleToggle}
			>
				<span className='float-end'>SI</span>
			</div>
			<div
				className={`toggle-option rounded-1 px-2 justify-content-center 	 ${
					!isChecked ? 'active px-2' : ''
				}`}
				onClick={handleToggle}
			>
				<span className='text-center'>NO</span>
			</div>
		</div>
	);
};

export default Toggle;
