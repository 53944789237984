import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import USERS from '../../common/data/userDummyData';
import Avatar from '../../components/Avatar';
import { UilUsdCircle, UilCheck, UilFileEditAlt } from '@iconscout/react-unicons';
import Check from '../../components/Check';

const CardContract = ({ type }) => {
	const types = ['Reserva inmueble', 'Alta de ficha', 'Suspensión de inmueble', 'Baja de ficha'];
	const [selectedEstates, setSelectedEstates] = useState([]);
	const imagenURL =
		'https://images.pexels.com/photos/2360673/pexels-photo-2360673.jpeg?cs=srgb&dl=pexels-tobias-bj%C3%B8rkli-2360673.jpg&fm=jpg';
	return (
		<>
			<div className='col-12 mt-3'>
				<div className='emulate-card py-2 rounded-1 d-flex align-items-center justify-content-center -p-4'>
					<div className='col-1  '>
						{/* <input type='checkbox' className='mx-5 text-color-lblue' size={40} />
						<label
							className='custom-checkbox ms-5'
							onClick={() => {
								if (selectedEstates.length) {
									setSelectedEstates([]);
								} else {
									setSelectedEstates(1);
								}
							}}
						>
							<UilCheck
								color={selectedEstates.length === 0 ? '#506CF9' : '#fff'}
								size={16}
							/>
						</label> */}
						<div className='mx-5'>
							<Check />
						</div>
					</div>
					<div className='col-2 me-2'>
						<div class='d-flex justify-content-center align-items-center'>
							<div
								class='rounded-circle bg-blue-500 d-flex justify-content-center align-items-center'
								style={{ width: '72px', height: '72px' }}
							>
								<UilFileEditAlt
									className='text-color-white text-center '
									size={40}
								/>
							</div>
						</div>
					</div>
					<div className='col-6 me-4 '>
						<div className='row'>
							<div className='col-7 border-end mt-4'>
								<h4 className='fw-600 d-flex align-items-center'>Yapeyú 345</h4>
								<p className='fs-6 d-flex align-items-center'>
									Actualizó valor por ICL
								</p>
							</div>

							<div className='col-4 ms-5  justify-content-center align-items-center d-flex'>
								<UilUsdCircle
									className='float-start d-flex r  ms-5'
									color='#506CF9'
								/>
								<div className='ms-2 mt-3 justify-content-center align-items-center '>
									<h4 className='fw-500'>VENTA</h4>
									<p className='fs-4 fw-700'>USD 250.000</p>
								</div>
							</div>
						</div>
					</div>

					<div className='col-3'>
						<div className='row'>
							<div className=''>
								<span className='radius-7p px-3 py-1 bg-primary text-white float-end '>
									Contrato
								</span>
							</div>
						</div>
						<div className='row'>
							<div className='float-end mt-5'>
								<Avatar
									className='mt-3 float-end me-2'
									srcSet={USERS.CHLOE.srcSet}
									src={USERS.CHLOE.src}
									size={40}
									color={USERS.CHLOE.color}
								/>
							</div>
						</div>
						<p className='float-end me-2 fs-5 mt-3 fw-500'>12:18</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardContract;
