import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../contexts/userContext';

export default function loginService({ dni, password }) {
	const formData = new FormData();
	formData.append('dni', dni);
	formData.append('password', password);

	return fetch(`${process.env.REACT_APP_ENDPOINT}/login`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
		},
		body: formData,
	})
		.then((res) => {
			return res.json();
		})
		.then((res) => {
			if (!res.token) throw new Error('Response is NOT ok');
			return res;
		});
}

export  function logout() {
	console.log("llega?");
	const { token, setToken, userData, setUserData } = useContext(UserContext);
	window.sessionStorage.removeItem('token');
		window.sessionStorage.removeItem('userData');
		setToken(null);
		setUserData(null);
		navigate('/', { replace: true });
	

}
