import { useContext, useEffect, useState } from 'react';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import { UilArrowCircleUp } from '@iconscout/react-unicons';
import { Sliders } from 'phosphor-react';
import ThemeContext from '../../contexts/themeContext';
import Button from '../../components/bootstrap/Button';
import PublishedTable from './PublishedTable';
import NotPublishedTable from './NotPublishedTable';
import PortalCard from './PortalCard';
import usePortals from '../../hooks/usePortals';
import usePosts from '../../hooks/usePosts';
import TableAssets from './TableAssets';
import NoPostsBanner from './NoPostsBanner';
import PortalSidebar from './PortalSidebar';
import { useQueryClient } from '@tanstack/react-query';

export default function DesktopLayout() {
	const { mobileDesign } = useContext(ThemeContext);
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const [selectedEstates, setSelectedEstates] = useState([]);

	const [filters, setFilters] = useState({
		query: null,
		isPublished: true, // boolean
		highlights: [],
		portal: 2,
		sort: {
			field: null,
			order: 'ASC',
		},
		page: 1,
		limit: 5,
	});

	const portals = usePortals();

	const posts = usePosts(filters);

	const queryClient = useQueryClient();

	useEffect(() => {
		queryClient.invalidateQueries({
			queryKey: [filters],
			exact: true,
		});
	}, [filters]);

	return (
		<>
			<SubheaderNew
				breadCrumbs={[{ name: 'Publicaciones', url: '/propiedades/publicaciones' }]}
			/>

			<div
				className={`d-flex flex-column w-100 bg-new-gray ${
					!mobileDesign && 'shadow-subheader rounded-xl'
				} mb-4 min-vh-75`}>
				<div className='d-flex align-items-center justify-content-between p-4 border-bottom bg-white'>
					<div className='d-flex align-items-center'>
						<UilArrowCircleUp size={28} color='#506CF9' />
						<p className='mb-0 ms-2 fw-bold fs-5 text-color-lblue'>PUBLICACIONES</p>
					</div>
					<Button
						className=''
						color='primary'
						rounded={1}
						data-bs-toggle='dropdown'
						onClick={() => setOffcanvasStatus(!offcanvasStatus)}
						aria-expanded='false'>
						<div className='d-flex align-items-center'>
							<Sliders color='#fff' size={20} />
							<p className='my-0 ms-2'>Personalizar</p>
						</div>
					</Button>
					<PortalSidebar
						offcanvasStatus={offcanvasStatus}
						setOffcanvasStatus={setOffcanvasStatus}
						portals={portals}
					/>
				</div>

				{/* Portals */}
				<div className='d-flex justify-content-start flex-wrap p-4 w-100'>
					{portals.isLoading ? (
						<div className='d-flex w-100 justify-content-center py-5'>
							<h3>Cargando portales...</h3>
						</div>
					) : (
						portals.data
							?.filter((portal) => portal.isDefault === true)
							.map((portal, index) => (
								<PortalCard
									portal={portal}
									setFilters={setFilters}
									filters={filters}
									key={`${portal.name}${index}`}
								/>
							))
					)}
				</div>
				{/* Portals */}

				<div
					className={`d-flex flex-column min-vh-50 my-4 mx-4 rounded-3 shadow-dark bg-white`}
					// className={`d-flex flex-column min-vh-50 my-4 mx-4 rounded-3 ${
					// 	posts.data?.data?.length === 0 ? 'bg-transparent' : 'shadow-dark bg-white'
					// }`}
				>
					{/* {!posts.isLoading && posts.data?.data?.length !== 0 && ( */}
					<TableAssets
						selectedEstates={selectedEstates}
						filters={filters}
						setFilters={setFilters}
						highlightTypes={
							portals.data?.find((portal) => portal.id === filters.portal)
								.highlightTypes
						}
					/>
					{/* )} */}
					{filters.isPublished ? (
						<PublishedTable
							selectedEstates={selectedEstates}
							setSelectedEstates={setSelectedEstates}
							posts={posts}
							portal={portals.data?.find((portal) => portal.id === filters.portal)}

							filters={filters}
							setFilters={setFilters}
						/>
					) : (
						<NotPublishedTable
							selectedEstates={selectedEstates}
							setSelectedEstates={setSelectedEstates}
							posts={posts}
							portalName={
								portals.data?.find((portal) => portal.id === filters.portal).name
							}
							filters={filters}
							setFilters={setFilters}

						/>
					)}
				</div>
			</div>
		</>
	);
}
