import React from 'react';
import Chronology from './Chronology';
import Files from './Files';
import SearchProfile from './SearchProfile';
import Summary from './Summary';

const ChildrenSelector = ({
	selectedTab,
	summary,
	setSummary,
	searchProfile,
	setSearchProfile,
	searchProfileFields,
	inputDisabled,
	chronology,
	setChronology,
	filteredProperties,
	setFiles,
	files,
	properties,
	reason,
	allTags,
	incomeTypes,
	operators
}) => {
	switch (selectedTab) {
		case 'RESUMEN':
			return <Summary chronology={chronology} setChronology={setChronology} properties={properties} reason={reason} allTags={allTags} summary={summary} setSummary={setSummary} inputDisabled={inputDisabled} incomeTypes={incomeTypes} operators={operators} />;
		case 'PERFIL DE BÚSQUEDA':
			return (
				<SearchProfile
					searchProfile={searchProfile}
					setSearchProfile={setSearchProfile}
					searchProfileFields={searchProfileFields}
					inputDisabled={inputDisabled}
					filteredProperties={filteredProperties}
				/>
			);

		case 'CRONOLOGÍA':
			return <Chronology  reason={reason} properties={properties} summary={summary} setSummary={setSummary} chronology={chronology} setChronology={setChronology} />;

		case 'ARCHIVO':
			return <Files setFiles={setFiles} files={files} summary={summary} />;

		default:
			break;
	}
};

export default ChildrenSelector;
