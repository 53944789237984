import React, { useState, useContext } from 'react';
import {
	UilEdit,
	UilEllipsisH,
	UilUser,
	UilUsersAlt,
	UilSearch,
	UilMultiply,
	UilKeySkeletonAlt,
	UilInvoice,
	UilCoins,
	UilLocationPoint,
	// UilCheck,
	UilFileAlt,
	UilRulerCombined,
	UilUniversity,
	UilShop,
	UilSchedule,
	UilCarSideview,
	UilTape,
} from '@iconscout/react-unicons';
import classNames from 'classnames';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
} from '../../components/bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import data from '../../common/data/dummyCustomerData';
import useSortableData from '../../hooks/useSortableData';
import Icon from '../../components/icon/Icon';
import ThemeContext from '../../contexts/themeContext';
// import { getCustomers } from '../../services/customersService';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import FiltersMenu from '../customers/FiltersMenu';

const GuarantorsPage = () => {
	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const { items, requestSort, getClassNamesFor } = useSortableData(data);
	const [activeFilters, setActiveFilters] = useState(false);

	// getCustomers();

	const iconColorActive = '#506CF9';
	const iconColorInactive = '#000';

	const [menuItems, setMenuItems] = useState([
		{
			name: 'Tipo de inmueble',
			// icon: <UilKeySkeletonAlt color={iconColorActive} />,
			icon: (status) => (
				<UilKeySkeletonAlt color={status ? iconColorActive : iconColorInactive} />
			),
			isActive: true,
		},
		{
			name: 'Operación',
			icon: (status) => <UilFileAlt color={status ? iconColorActive : iconColorInactive} />,
			isActive: true,
		},
		{
			name: 'Valor',
			icon: (status) => <UilInvoice color={status ? iconColorActive : iconColorInactive} />,
			isActive: true,
		},
		{
			name: 'Moneda',
			icon: (status) => <UilCoins color={status ? iconColorActive : iconColorInactive} />,
			isActive: true,
		},
		{
			name: 'Barrio',
			icon: (status) => (
				<UilLocationPoint color={status ? iconColorActive : iconColorInactive} />
			),
			isActive: true,
		},
		{
			name: 'Proyecto',
			icon: (status) => (
				<UilRulerCombined color={status ? iconColorActive : iconColorInactive} />
			),
			isActive: false,
		},
		{
			name: 'Ubicación',
			icon: (status) => (
				<UilLocationPoint color={status ? iconColorActive : iconColorInactive} />
			),
			isActive: false,
		},
		{
			name: 'Apto crédito',
			icon: (status) => (
				<UilUniversity color={status ? iconColorActive : iconColorInactive} />
			),
			isActive: false,
		},
		{
			name: 'Actividad',
			icon: (status) => <UilShop color={status ? iconColorActive : iconColorInactive} />,
			isActive: false,
		},
		{
			name: 'Antigüedad',
			icon: (status) => <UilSchedule color={status ? iconColorActive : iconColorInactive} />,
			isActive: false,
		},
		{
			name: 'Cochera',
			icon: (status) => (
				<UilCarSideview color={status ? iconColorActive : iconColorInactive} />
			),
			isActive: false,
		},
		{
			name: 'Superficie máxima',
			icon: (status) => <UilTape color={status ? iconColorActive : iconColorInactive} />,
			isActive: false,
		},
	]);

	return (
		<PageWrapper title='Interesados'>
			<Page container='fluid'>
				<SubheaderNew
					breadCrumbs={['Garantes']}
					filtersFunc={setActiveFilters}
					filtersStatus={activeFilters}
				/>
				{/* <CommonUpcomingEvents isFluid /> */}

				{activeFilters && mobileDesign && (
					<div
						className='d-flex align-items-center justify-content-center w-100'
						style={{
							height: '10rem',
							borderRadius: '2rem 2rem 0rem 0rem',
							background:
								'linear-gradient(180deg, #DCE5FF 0%, rgba(220, 229, 255, 0) 100%)',
							position: 'relative',
							bottom: '1.8rem',
						}}>
						<h4 className='text-color-dark mb-4 fw-bold'>Filtro de Búsqueda</h4>
						<UilMultiply
							color='#506CF9'
							style={{ position: 'absolute', top: '10%', right: '3%' }}
							onClick={() => setActiveFilters(false)}
						/>
					</div>
				)}
				<Card
					stretch
					style={{
						position: mobileDesign && activeFilters && 'relative',
						bottom: mobileDesign && activeFilters && '3rem',
					}}>
					{mobileDesign ? (
						<div className='d-flex flex-column w-100 list-mobile'>
							{activeFilters ? (
								<FiltersMenu menuItems={menuItems} setMenuItems={setMenuItems} />
							) : (
								dataPagination(items, currentPage, perPage).map((person, i) => (
									<div
										className={`${
											i % 2 === 0 && 'bg-new-gray'
										} d-flex align-items-center justify-content-between w-100 p-4`}>
										<div className='d-flex align-items-center'>
											<UilUser color='#000' size={30} />
											<p className='fw-normal-bold my-0 mx-3'>
												{person.name}
											</p>
										</div>
										<div className='d-flex justify-content-center'>
											<div
												className='bg-blue-300 rounded-circle p-2 mx-2'
												onClick={() => alert('Tocaste editar')}>
												<UilEdit color='#fff' size={20} />
											</div>
											<div
												className='bg-blue-300 rounded-circle p-2'
												onClick={() => alert('Tocaste acciones')}>
												<UilEllipsisH color='#fff' size={20} />
											</div>
										</div>
									</div>
								))
							)}
						</div>
					) : (
						<>
							<CardHeader
								style={{ 'border-bottom': '1px solid rgba(183, 184, 188, 0.5)' }}>
								<div className='titulo-alerta my-2'>
									<UilUsersAlt size={32} color='#506CF9' />
									<span className='m-0 px-3 fs-5 fw-bold text-color-lblue'>
										GARANTES
									</span>
								</div>
								<CardActions>
									{/* Search input */}
									<div
										className={classNames(
											'd-flex align-items-center rounded-pill py-1 px-2',
											{ 'search-input-header-dark': darkModeStatus },
											{ 'search-input-header': !darkModeStatus },
										)}>
										<label
											className='border-0 bg-transparent cursor-pointer me-0 ms-2'
											htmlFor='searchInput'>
											{/* <Icon icon='Search' size='2x' color='blue-300' /> */}
											<UilSearch
												// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
												color='#506CF9'
												size={12}
											/>
										</label>
										<Input
											id='searchInput'
											type='search'
											className='border-0 shadow-none bg-transparent input-search-customers'
											placeholder='Buscar...'
										/>
									</div>
									{/* Search input */}
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive hidden-scroll' isScrollable>
								{activeFilters ? (
									<FiltersMenu
										menuItems={menuItems}
										setMenuItems={setMenuItems}
									/>
								) : (
									<table className='w-100'>
										<thead style={{ position: 'sticky', top: '0px' }}>
											{/* <tr className='text-white blue-table-header'> */}
											<tr className='text-dark'>
												<th>{null}</th>
												<th className='sortable'>Apellido</th>
												<th
													className='sortable'
													onClick={() => requestSort('name')}>
													Nombre
													<Icon
														size='lg'
														className={getClassNamesFor('name')}
														icon='FilterList'
													/>
												</th>
												<th>Celular</th>
												<th>Mail</th>
												{/* <th>Estrellas</th> */}
												<th>Tipo de propiedad</th>
												<th>Localidad</th>
												<th>Calle</th>
												<th>Operación</th>
												<th className='sortable'>Fecha ingreso</th>
												{/* <th>Precio desde</th> */}
												{/* <th>Precio hasta</th> */}
											</tr>
										</thead>
										<tbody>
											{dataPagination(items, currentPage, perPage).map(
												(item, index) => (
													<tr
														className={
															index % 2 !== 0 && 'bg-new-gray'
														}>
														<td>
															<div className='d-flex justify-content-start'>
																<div className='bg-blue-300 rounded-circle p-2 mx-1'>
																	<UilEdit
																		color='#fff'
																		size={20}
																	/>
																</div>
																<div className='bg-blue-300 rounded-circle p-2 mx-1'>
																	<UilEllipsisH
																		color='#fff'
																		size={20}
																	/>
																</div>
															</div>
														</td>
														<td>Doe</td>
														<td>{item.name}</td>
														<td>{item.phone}</td>
														<td>{item.email}</td>

														<td>Casa</td>
														<td>CABA</td>

														<td>Calle falsa 123</td>
														<td>Venta</td>
														<td>20/8/22</td>
													</tr>
												),
											)}
										</tbody>
									</table>
								)}
								{/* <table className='table table-modern'> */}
							</CardBody>
							{!activeFilters ? (
								<div className='pt-4'>
									<PaginationButtons
										data={items}
										label='items'
										setCurrentPage={setCurrentPage}
										currentPage={currentPage}
										perPage={perPage}
										setPerPage={setPerPage}
									/>
								</div>
							) : (
								<CardFooter>
									<CardFooterRight>
										<Button
											color='primary'
											icon='CustomSliders'
											rounded={1}
											onClick={() => setActiveFilters(!activeFilters)}>
											Filtrar
										</Button>
									</CardFooterRight>
								</CardFooter>
							)}
						</>
					)}
				</Card>
				{mobileDesign && !activeFilters && (
					<PaginationButtons
						data={items}
						label='items'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
					/>
				)}
			</Page>
		</PageWrapper>
	);
};

export default GuarantorsPage;
