import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import USERS from '../../common/data/userDummyData';
import Avatar from '../../components/Avatar';
import { UilUsdCircle, UilCheck, UilCamera } from '@iconscout/react-unicons';
import { Cake } from 'phosphor-react';
import Check from '../../components/Check';

const CardBirthDay = ({ notificationw, notify }) => {
	const types = ['Reserva inmueble', 'Alta de ficha', 'Suspensión de inmueble', 'Baja de ficha'];
	const [selectedEstates, setSelectedEstates] = useState([]);
	const imagenURL =
		'https://images.pexels.com/photos/2360673/pexels-photo-2360673.jpeg?cs=srgb&dl=pexels-tobias-bj%C3%B8rkli-2360673.jpg&fm=jpg';
	console.log(('notficiation', notify.customer));
	return (
		<>
			<div className='col-12 mt-3'>
				<div className='emulate-card py-4 rounded-1 d-flex align-items-center justify-content-center bg-new-gray'>
					<div className='col-1  '>
						{/* <input type='checkbox' className='mx-5 text-color-lblue' size={40} />
						<label
							className='custom-checkbox ms-5'
							onClick={() => {
								if (selectedEstates.length) {
									setSelectedEstates([]);
								} else {
									setSelectedEstates(1);
								}
							}}
						>
							<UilCheck
								color={selectedEstates.length === 0 ? '#506CF9' : '#fff'}
								size={16}
							/>
						</label> */}
						<div className='mx-5'>
							<Check />
						</div>
					</div>
					<div className='col-2 text-center'>
						<div class='avatar-full'>
							<Avatar
								className='border-50'
								srcSet={USERS.CHLOE.srcSet}
								src={USERS.CHLOE.src}
								size={75}
								color={USERS.CHLOE.color}
							/>
							<div
								class='round bg-blue-500'
								style={{ width: '40px', height: '40px' }}
							>
								<Cake size={28} className='mt-1' color='#FFFFFF' />
							</div>
						</div>
					</div>
					<div className='col-8 me-5 '>
						<div className='row'>
							<div className='col'>
								<h4 className='fw-600'>
									{`Hoy es el cumpleaños de ${
										notify.customer ? notify.customer.name : ''
									}`}
								</h4>
								<p className='fs-5'>¡Felicítala!</p>
							</div>
						</div>
					</div>

					<div className='col-1'>
						<p className='float-end me-2 mt-3 fs-5 fw-500'>12:18</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardBirthDay;
