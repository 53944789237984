import React, { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import { UilWhatsapp, UilEnvelope, UilBag, UilMapMarker } from '@iconscout/react-unicons';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import { demoPages } from '../../menu';
import { getOperadores } from '../../services/operatorsService';
import Icon from '../../components/icon/Icon';
import ThemeContext from '../../contexts/themeContext';
import SearchFilter from '../../components/Owner/SearchFilter';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import { confirmationSwal } from '../../utils/confirmationSwal';
import { showToast } from '../../components/Toast/toastUtils/toastUtils';
import Toast from '../../components/Toast/Toast';

const OperatorsPage = () => {
	const [filterX, setOnchange] = useState([]);
	const { mobileDesign } = useContext(ThemeContext);
	const { isLoading, data: operators = [] } = useQuery(['operators'], () => getOperadores());
	const [list , setList] = useState([])
	

	const handleDelete = async (evt, id)  => {

		const swalInstance = confirmationSwal("¿Eliminar Usuario?","¿Está seguro de que desea eliminar el usuario seleccionado?" , "warning", "Eliminar" )

		try{
			const result = await swalInstance;
			if(result.isConfirmed){
				showToast("Usuario eliminado", setList , "success")
			} else {
				console.log("confirmacion negativa")
			}
		} catch (error){
			showToast("Error al eliminar un usuario", setList , "danger")
			console.log("entro en error")
		}
		evt.preventDefault();
		console.log(id);
		// npodeleteOperator(id);
	}

	return (
		<PageWrapper className={`${mobileDesign && 'p-0'} `} title='Operadores'>
			<Toast toastlist={list} position="top-right" setList={setList} />
			<Page container={mobileDesign ? 'fluid p-0' : 'fluid'}>
			<SubheaderNew
					className={'justify-content-center gap-2 '}
					breadCrumbs={[{ name: 'Operadores', url: '/personas/Operadores' }]}
					newButtonUrl='/personas/operadores/crear'
					withNewButton
					withFiltersButton
                    withExportButton
				/>
				<div className={`${mobileDesign ?  'row px-4 m-0' : 'd-flex flex-wrap gap-4'}`}>
					{!isLoading &&
						operators.map((user) => (
							<div className={`${mobileDesign ? 'col-12 my-2 ' : 'px-4'} ${
								 		user.id === 1 && 'gradient-admin'} blue-shadow rounded justify-content-between shadow-sm position-relative`}>
									<div>
										<div className='d-flex'>
											<div className='py-4'><img
													className='rounded-circle'
													src='/static/media/wanna7.5db20cd148c48dbaeb17.png'
													alt={user.name}
													width={!mobileDesign ?  74 : 58}/>
											</div>
											<div className='d-flex flex-column justify-content-between w-100 '>
												<div className=' d-flex flex-grow-1 justify-content-between ms-3'>	
													<div className='justify-content-center  d-flex flex-column'>
														<h4 className='fw-bold'>
															{user.name} {user.surname}
														</h4>
														<p className='m-0'>{user.dni}</p>
														<p className='m-0'>{user.email}Emailejemplo@gmail.com</p>
													</div>
														<div className='d-flex flex-nowrap justify-content-center  align-items-start pt-3'>
															<div className={`d-flex ${mobileDesign ? ' flex-column  align-items-end' : 'align-items-center'}`}>
															<p className='fw-bold fs-6 text-color-blue  my-0 mx-2'>
																{user.id === 1 ? 'Admin' : 'Operador'}
															</p>
															<Dropdown>
																<DropdownToggle hasIcon={false}>
																	<Icon
																		icon='CommentInfo'
																		size='lg'
																		color='primary'
																		isLight
																	/>
																</DropdownToggle>
																<DropdownMenu
																	isAlignmentEnd
																	size='lg'
																	isCloseAfterLeave={false}>
																	<Button
																		className='dropdown-item'
																		tag='a'
																		to={`../${demoPages.appointment.subMenu.employeeID.path}/${user.id}`}
																		data-tour={user.name}>
																		Editar
																	</Button>
																	<Button
																		tag='a'
																		className='dropdown-item'
																		onClick={(e) => handleDelete(e, user.id)}>
																		Eliminar
																	</Button>
																</DropdownMenu>
															</Dropdown>
															</div>
														</div>
													</div>
												{!mobileDesign && 
													<div className='d-flex flex-nowrap h-auto gap-2'>
													<div className='col d-flex align-items-center px-7px py-2 rounded-top bg-wsp  cursor-pointer'>
														<UilWhatsapp color='#06DB41' size='15' />
														<span className='fs-9 ms-1 text-success'>
															Whatsapp
														</span>
													</div>
													<div
														className={`col d-flex align-items-center correo px-7px rounded-top cursor-pointer ${
															user.id === 1 ? 'correoAdmin' : 'correo'
														}`}>
														<UilEnvelope size='15' />
														<span className='fs-9 ms-1 flex-nowrap text-nowrap '>
															Correo SMTP
														</span>
													</div>
													<div className='col d-flex align-items-center py-2 px-7px rounded-top bg-blue-300 text-color-white cursor-pointer'>
														<UilBag size='15' />
														<span className='fs-9 ms-1'>Vendedor</span>
													</div>
													<div className='col d-flex align-items-center py-2 px-7px rounded-top bg-blue-300 text-color-white cursor-pointer'>
														<UilMapMarker size='15' />
														<span className='fs-9 ms-1'>Sucursal</span>
													</div>
												</div>
												}
											</div>
										</div>
										{mobileDesign && 
											<div className='d-flex  h-auto gap-2 '>
											<div className='col d-flex align-items-center px-2 py-2 rounded-top bg-wsp  cursor-pointer justify-content-center '>
												<UilWhatsapp color='#06DB41' size='15' />
												<span className='fs-8 ms-1 text-success'>
													Whatsapp
												</span>
											</div>
											<div
												className={`col d-flex align-items-center correo px-2 rounded-top cursor-pointer justify-content-center ${
													user.id === 1 ? 'correoAdmin' : 'correo'
												}`}>
												<UilEnvelope size='15' />
												<span className='fs-8 ms-1 text-nowrap'>
													Correo SMTP
												</span>
											</div>
											<div className='col d-flex align-items-center py-2 px-2 rounded-top  bg-blue-300 text-color-white cursor-pointer justify-content-center'>
												<UilBag size='15' />
												<span className='fs-8 ms-1'>Vendedor</span>
											</div>
											<div className='col d-flex align-items-center py-2 px-2 rounded-top bg-blue-300 text-color-white cursor-pointer justify-content-center'>
												<UilMapMarker size='15' />
												<span className='fs-8 ms-1'>Sucursal</span>
											</div>
										</div>
										}
									</div>
							</div>
						))}
				</div>
			</Page>
		</PageWrapper>
	);
};

export default OperatorsPage;
