import { useContext, useRef } from 'react';
import { UilArrowCircleUp, UilSearch, UilEdit, UilTrash } from '@iconscout/react-unicons';
import { Gear } from 'phosphor-react';
import ThemeContext from '../../contexts/themeContext';
import classNames from 'classnames';
import Button from '../../components/bootstrap/Button';
import CustomDropdown from '../../components/CustomDropdown';
import useDebounce from '../../hooks/useDebounce';
import Check from '../../components/Check';

export default function TableAssets({ selectedEstates, filters, setFilters, highlightTypes }) {
	const { darkModeStatus } = useContext(ThemeContext);

	const inputRef = useRef(null);

	const debouncer = useDebounce();

	const handleChange = (value) => {
		debouncer(() => setFilters((prev) => ({ ...prev, query: value })), 500);
	};

	return (
		<div className='d-flex align-items-center justify-content-end position-relative mx-4 my-3'>
			{selectedEstates.length > 0 && (
				<>
					<Button
						className='position-absolute start-0'
						color='primary'
						rounded='1'
						data-bs-toggle='dropdown'
						aria-expanded='false'>
						<div className='d-flex align-items-center'>
							<Gear color='#fff' size={20} />
							<p className='my-0 ms-2'>Acciones</p>
						</div>
					</Button>
					<div className='bg-white-dropdown rounded dropdown-menu dropdown-menu-lg shadow text-color-black'>
						<div
							className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-top'
							// onClick={() => setModalStatus(!modalStatus)}
						>
							<UilEdit size={26} />
							<p className='m-0 ps-2'>Administrar destaques</p>
						</div>

						{filters.isPublished === 'published' ? (
							<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-bottom'>
								<UilTrash size={26} />{' '}
								<p className='m-0 ps-2'>Quitar publicación</p>
							</div>
						) : (
							<div className='d-flex align-items-center p-3 fs-5 cursor-pointer custom-dropdown-item rounded-bottom'>
								<UilArrowCircleUp size={26} />{' '}
								<p className='m-0 ps-2'>Publicar inmueble</p>
							</div>
						)}
					</div>
				</>
			)}

			<div
				className='d-flex align-items-start justify-content-start position-relative w-55 ps-5'
				style={{ top: '-1rem', left: 100 }}>
				<div
					className={`d-flex align-items-center justify-content-center ${
						filters.isPublished ? 'bg-blue-300' : 'bg-gray-card-posts'
					} py-3 px-4 rounded-bottom mx-2 cursor-pointer`}
					onClick={() => {
						if (filters.isPublished) return;

						setFilters((prev) => ({ ...prev, isPublished: true, page: 1 }));
					}}>
					<h6 className='fw-bold m-0 text-color-white fs-xxl-5 fs-xl-6'>PUBLICADAS</h6>
				</div>
				<div
					className={`d-flex align-items-center justify-content-center ${
						!filters.isPublished ? 'bg-blue-300' : 'bg-gray-card-posts'
					} py-3 px-4 rounded-bottom mx-2 cursor-pointer`}
					onClick={() => {
						if (!filters.isPublished) return;

						setFilters((prev) => ({ ...prev, isPublished: false, page: 1 }));
					}}>
					<h6 className='fw-bold m-0 text-color-white fs-xxl-5 fs-xl-6'>NO PUBLICADAS</h6>
				</div>
			</div>

			<div className='col-xxl-2 col-xl-3 px-5'>
				{highlightTypes?.length !== 0 && filters.isPublished && (
					<CustomDropdown
						iconColor='#fff'
						label={'Tipo de destaque'}
						classname={`bg-blue-300 px-4 py-2 w-100 text-color-white fs-xxl-5 fs-xl-6 cursor-pointer innerShadowBlueSmall fw-normal-bold`}
						dropdownClassname={
							'd-flex flex-column align-items-start justify-content-center bg-lblue text-color-lblue rounded-bottom w-100 position-absolute shadow'
						}
						margin=''
						width='w-100'
						closeAfterClick>
						{/* <div
							className={`d-flex align-items-center py-3 fs-5 fw-normal-bold cursor-pointer custom-dropdown-item w-100 px-4 
								${filters.highlight === null && 'bg-blue-300 text-color-white'}`}
							onClick={() =>
								setFilters((prev) => ({ ...prev, highlight: null, page: 1 }))
							}>
							Ninguno
						</div> */}

						{highlightTypes?.map((type, index) => (
							<div
								className={`d-flex align-items-center justify-content-between py-3 fs-5 fw-normal-bold cursor-pointer custom-dropdown-item w-100 px-4 bg-lblue text-color-lblue ${
									index === highlightTypes.length - 1 && 'rounded-bottom'
								}`}
								key={type.name}
								onClick={() => {
									if (filters.highlights.includes(type.id)) {
										setFilters((prev) => ({
											...prev,
											highlights: filters.highlights.filter(
												(h) => h !== type.id,
											),
										}));
									} else {
										setFilters((prev) => ({
											...prev,
											highlights: [...filters.highlights, type.id],
											page: 1,
										}));
									}
								}}>
								{type.name}
								<Check checked={filters.highlights.includes(type.id)} />
							</div>
						))}
					</CustomDropdown>
				)}
			</div>

			<div
				className={classNames(
					'd-flex align-items-center rounded-pill py-1 px-2',
					{ 'search-input-header-dark': darkModeStatus },
					{ 'search-input-header': !darkModeStatus },
				)}>
				<label
					className='border-0 bg-transparent cursor-pointer me-0 ms-2'
					htmlFor='searchInput'>
					<UilSearch
						// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
						color='#506CF9'
						size={12}
					/>
				</label>
				<input
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent input-search-customers py-2 no-outline'
					placeholder='Buscar...'
					ref={inputRef}
					onChange={() => handleChange(inputRef.current.value)}
				/>
			</div>
		</div>
	);
}
