import React, { useContext } from 'react';
import classNames from 'classnames';
import { UilBars } from '@iconscout/react-unicons';

import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import CommonHeaderRight from '../../pages/common/Headers/CommonHeaderRight';
import ThemeContext from '../../contexts/themeContext';
import Avatar from '../../components/Avatar';
import USERS from '../../common/data/userDummyData';
import HeaderSearchBar from './HeaderSearchBar';

const HeaderGeneral = () => {
	const { mobileDesign, asideStatus, setAsideStatus, darkModeStatus } = useContext(ThemeContext);

	const fetchedData = [
		{
			type: 'Contrato',
			img: 'https://via.placeholder.com/25',
			title: 'Titulo del contrato 1',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Contrato',
			img: 'https://via.placeholder.com/25',
			title: 'Titulo del contrato 2',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Emprendimiento',
			img: 'https://via.placeholder.com/25',
			title: 'Emprendimiento 1',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Emprendimiento',
			img: 'https://via.placeholder.com/25',
			title: 'Emprendimiento 2',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Emprendimiento',
			img: 'https://via.placeholder.com/25',
			title: 'Emprendimiento 3',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Inmueble',
			img: 'https://via.placeholder.com/25',
			title: 'Dirección del inmueble 1',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Inmueble',
			img: 'https://via.placeholder.com/25',
			title: 'Dirección del inmueble 2',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Inmueble',
			img: 'https://via.placeholder.com/25',
			title: 'Dirección del inmueble 3',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Agenda',
			img: 'https://via.placeholder.com/25',
			title: 'Item agenda',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Cliente',
			img: 'https://via.placeholder.com/25',
			title: 'Nombre del cliente 1',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
		{
			type: 'Cliente',
			img: 'https://via.placeholder.com/25',
			title: 'Nombre del cliente 2',
			content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
		},
	];

	const types = fetchedData.reduce((prev, curr) => {
		if (!prev.includes(curr.type)) {
			return [...prev, curr.type];
		}
		return prev;
	}, []);

	return !mobileDesign ? (
		<div
			className={`d-flex align-items-center justify-content-between ${
				!darkModeStatus && 'shadow-subheader'
			} rounded my-2 mx-4 px-4 py-2`}>
			{/* Logo, title and search*/}
			<div className='d-flex align-items-center col-xxl-7 col-xl-8'>
				<Link to='/'>
					<Logo width={145} color={darkModeStatus ? '#fff' : '#212121'} />
				</Link>
				<span
					className={classNames('title-navbar px-4', {
						'text-color-white': darkModeStatus,
					})}
				>
					SOFTWARE DE ADMINISTRACION <br /> INTEGRAL INMOBILIARIO
				</span>

				<HeaderSearchBar />
			</div>
			{/* Logo, title and search*/}

			<CommonHeaderRight />
		</div>
	) : (
		<div className='d-flex flex-column align-items-center mx-4 my-2'>
			<div className='d-flex align-items-center justify-content-between w-100 mb-4'>
				<Avatar
					srcSet={USERS.CHLOE.srcSet}
					src={USERS.CHLOE.src}
					size={40}
					color={USERS.CHLOE.color}
				/>
				<Link to='/'>
					<Logo color={darkModeStatus ? '#fff' : '#212121'} width={145} />
				</Link>
				<UilBars
					color={darkModeStatus ? '#fff' : '#000'}
					size={40}
					onClick={() => setAsideStatus(!asideStatus)}
				/>
			</div>

			{/* Search input */}
			<HeaderSearchBar fetchedData={fetchedData} />
			{/* Search input */}
		</div>
	);
};

export default HeaderGeneral;
