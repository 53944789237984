import axios from 'axios';

export function getNotifications() {
	const token = sessionStorage.getItem('token');
	const bearer = `Bearer ${token}`;
	const cabecera = {
		Authorization: bearer,
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};
	const data = axios
		.get(`${process.env.REACT_APP_ENDPOINT}/notifications`, { headers: cabecera })
		.then((res) => {
			console.log(res);
			return res.data;
		});
	return data;
}

export function getNotificationsType() {
	const token = sessionStorage.getItem('token');
	const bearer = `Bearer ${token}`;
	const cabecera = {
		Authorization: bearer,
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};
	const data = axios
		.get(`${process.env.REACT_APP_ENDPOINT}/system/getNotificationsTypes`, {
			headers: cabecera,
		})
		.then((res) => {
			return res.data.data;
		});
	return data;
}
