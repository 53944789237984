import React from 'react';
import InputCounter from '../../components/InputCounter';
import { UilRulerCombined } from '@iconscout/react-unicons';

export default function Area() {
	return (
		<>
			<div className='d-flex align-items-center w-100'>
				<UilRulerCombined size='30' className='me-2' />
				<InputCounter label='Ambientes' labelClassname='text-color-dark fs-5 mb-0' />
			</div>
			<input
				className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 blue-placeholder no-outline innerShadowBlueSmall my-4'
				placeholder='Superficie libre (m²)'
			/>
			<input
				className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 blue-placeholder no-outline innerShadowBlueSmall mb-4'
				placeholder='Superficie cubierta (m²)'
			/>
			<input
				className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 blue-placeholder no-outline innerShadowBlueSmall mb-4'
				placeholder='Superficie semicubierta (m²)'
			/>
			<input
				className='w-100 bg-lblue text-color-lblue py-3 px-4 border-0 rounded fs-5 blue-placeholder no-outline innerShadowBlueSmall mb-4'
				placeholder='Superficie total (m²)'
			/>
		</>
	);
}
