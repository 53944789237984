import { FloppyDisk, Trash } from 'phosphor-react';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import ThemeContext from '../../../contexts/themeContext';
import UserContext from '../../../contexts/userContext';
import Toast from '../../../components/Toast/Toast';
import { createCustomer, updateCustormer } from '../../../services/customersServices';
import { showToast } from '../../../components/Toast/toastUtils/toastUtils';

const Footer = ({ summary, searchProfile , sectionName, action, chronology} ) => {

	const { token } = useContext(UserContext);
	const navigate = useNavigate();
	const { mobileDesign } = useContext(ThemeContext);

	const [list, setList] = useState([]);
	
	const submitHandler = () => {
		
		if(action === "editar"){
			const {customerId} = summary
			updateCustormer({ summary , searchProfile , chronology } , customerId , token )
			.then((res) => {
				showToast(res, setList,'success')
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			})
			.catch((error) => {
				let	dataErrors = error.response.data.errors

				let msg;
				for (const property in dataErrors) {
					for ( const nameError in  dataErrors[property]){
						msg = dataErrors[property][nameError]
						showToast(msg, setList,'danger');
					}
				}
				showToast(msg, setList,'danger');
			})

		}else{ 
			// usamos la logica para crear Customer
			createCustomer({ summary, searchProfile }, token)
			.then((res) => {
				showToast(res, setList,'success')
				setTimeout(() => {
					navigate(`/personas/${sectionName}`);
				}, 1000);
			})
			.catch((error) => {
			let	dataErrors = error.response.data.errors
				let msg;
				for (const property in dataErrors) {
						for ( const nameError in  dataErrors[property]){
							msg = dataErrors[property][nameError]

						}
						}
						showToast(msg, setList,'danger');
				
			});
		}
	};

	return (
		<>
			<div className='d-flex justify-content-between align-items-center w-100 mt-5 px-1'>
				<Toast toastlist={list} position="top-right" setList={setList} />
				<div className='d-flex align-items-center'>
					{/* <ArrowLeft
						size={32}
						color='#506cf9'
						className='cursor-pointer'
						onClick={() => navigate('/personas/interesados')}
					/> */}
					{action !== "ver"? 
					<Button
						disabled={Object.entries(summary).length === 0}
						rounded={1}
						className='blue-gradient-buttons border-0'
						onClick={submitHandler}>
						<div className='d-flex align-items-center'>
							<FloppyDisk size={24} color='#ffffff' />
							<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
						</div>
					</Button>
					 :  
					 <></>
					 }
					
				</div>
				{/* <Button
					rounded={1}
					className={`${mobileDesign ? 'bg-salmon' : 'bg-white'} border-0`}>
					<div className='d-flex align-items-center'>
						<Trash size={24} color='#ef2206' />
						<p className='my-0 mx-2 fs-6 text-color-danger'>Eliminar</p>
					</div>
				</Button> */}
			</div>
		</>
	);
};

export default Footer;
