import React, { useContext } from 'react';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import {
	UilSearch,
	UilCheck,
	UilFilter,
	UilSetting,
	UilExclamationCircle,
} from '@iconscout/react-unicons';
import Input from '../../../components/bootstrap/forms/Input';
import ThemeContext from '../../../contexts/themeContext';
import { useQueryClient } from '@tanstack/react-query';

export default function FiltersDrawer({ offcanvasStatus, setOffcanvasStatus, menuItems }) {
	const { darkModeStatus } = useContext(ThemeContext);
	const queryClient = useQueryClient();

	const clickHandler = (item) => {
		const menu = [...menuItems];
		const index = menu.indexOf(item);
		menu[index] = { ...item, isActive: !item.isActive };
		queryClient.setQueryData(['estateFilters'], menu);
	};

	return (
		<OffCanvas
			id='notificationCanvas'
			titleId='offcanvasExampleLabel'
			placement='end'
			isOpen={offcanvasStatus}
			setOpen={setOffcanvasStatus}>
			<OffCanvasHeader setOpen={setOffcanvasStatus} className='bg-blue-300 p-4'>
				<div className='w-100 d-flex justify-content-center'>
					<h4 className='m-0 text-color-white fw-bold'>Mostrar / Ocultar</h4>
				</div>
			</OffCanvasHeader>
			<OffCanvasBody className='p-0'>
				{/* Search input */}
				<div className='d-flex align-items-center py-4 px-3 w-100 search-input-drawer'>
					<label
						className='border-0 bg-transparent cursor-pointer me-0'
						htmlFor='searchInput'>
						<UilSearch color={darkModeStatus ? '#A9B5D7' : '#01227d'} size={20} />
					</label>
					<Input
						id='searchInput'
						type='search'
						className='border-0 shadow-none bg-transparent input-search'
						placeholder='Buscar...'
					/>
				</div>
				{/* Search input */}
				{/* Simple filter */}
				<div className='d-flex flex-column'>
					<div className='d-flex align-items-center bg-blue-100 p-3'>
						<UilFilter color='#506cf9' size={27} />
						<h5 className='mb-0 mx-2 text-color-lblue fw-bold'>Filtros simples</h5>
					</div>
					<div className='my-3'>
						{menuItems
							?.filter((item) => item.filterCategory === 'simple')
							.map((item) => (
								<div
									onClick={() => clickHandler(item)}
									key={item.label}
									className='d-flex align-items-center justify-content-between my-2 w-100 cursor-pointer px-5'>
									<div className='d-flex align-items-center'>
										<p className='my-0 ms-1 fs-5 fw-bold px-2'>{item.name} 22</p>
									</div>
									<UilCheck
										color={item.isActive ? '#506CF9' : '#fff'}
										size={35}
									/>
								</div>
							))}
					</div>
				</div>
				{/* Simple filter */}

				{/* Advanced filter */}
				<div className='d-flex flex-column'>
					<div className='d-flex align-items-center bg-blue-100 p-3'>
						<UilSetting color='#506cf9' size={27} />
						<h5 className='mb-0 mx-2 text-color-lblue fw-bold'>Filtros avanzados</h5>
					</div>
					<div className='my-3'>
						{menuItems
							?.filter((item) => item.filterCategory === 'advanced')
							.map((item) => (
								<div
									onClick={() => clickHandler(item)}
									key={item.label}
									className='d-flex align-items-center justify-content-between my-2 w-100 cursor-pointer px-5'>
									<div className='d-flex align-items-center'>
										<p className='my-0 ms-1 fs-5 fw-bold px-2'>{item.name}</p>
									</div>
									<UilCheck
										color={item.isActive ? '#506CF9' : '#fff'}
										size={35}
									/>
								</div>
							))}
					</div>
				</div>
				{/* Advanced filter */}

				{/* Conditional filter */}
				<div className='d-flex flex-column'>
					<div className='d-flex align-items-center bg-blue-100 p-3'>
						<UilExclamationCircle color='#506cf9' size={27} />
						<h5 className='mb-0 mx-2 text-color-lblue fw-bold'>
							Filtros condicionales
						</h5>
					</div>
					<div className='my-3'>
						{menuItems
							?.filter((item) => item.filterCategory === 'conditional')
							.map((item) => (
								<div
									onClick={() => clickHandler(item)}
									key={item.label}
									className='d-flex align-items-center justify-content-between my-2 w-100 cursor-pointer px-5'>
									<div className='d-flex align-items-center'>
										<p className='my-0 ms-1 fs-5 fw-bold px-2'>{item.name}</p>
									</div>
									<UilCheck
										color={item.isActive ? '#506CF9' : '#fff'}
										size={35}
									/>
								</div>
							))}
					</div>
				</div>
				{/* Conditional filter */}
			</OffCanvasBody>
		</OffCanvas>
	);
}
