import React from 'react';

const CustomPropertySearch = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 24 24'
			width='2em'
			height='2em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path
				d='M8.68689 10.5928L17.853 3.72021L27.28 10.5928V22.0647C27.2782 22.462 27.1139 22.8423 26.8231 23.1226C26.5323 23.4029 26.1387 23.5602 25.7283 23.5602H10.2317'
				stroke='white'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M7.67996 21.0801C9.73446 21.0801 11.4 19.4146 11.4 17.3601C11.4 15.3056 9.73446 13.6401 7.67996 13.6401C5.62546 13.6401 3.95996 15.3056 3.95996 17.3601C3.95996 19.4146 5.62546 21.0801 7.67996 21.0801Z'
				stroke='white'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M2 23.0918L4.48 20.6118'
				stroke='white'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M21.08 13.6401H14.88V23.5601H21.08V13.6401Z'
				stroke='white'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

export default CustomPropertySearch;
