import { useQuery } from '@tanstack/react-query';
import React, { useState, useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card';
import { Headset, Notepad, Trash, Pencil, AddressBook, LockKey } from 'phosphor-react';
import ConfigurationMobile from './ConfigurationMobile';
import { UilSetting, UilCameraPlus } from '@iconscout/react-unicons';
import { Form, Formik } from 'formik';
import InputFloating from '../../components/InputFloating';

const Create = () => {
	const [configuration, setConfiguracion] = useState(false);
	const [userFirst, setDataFirst] = useState({
		dni: 0,
		password: '',
		name: '',
		surname: '',
		birthday: '',
	});

	return (
		<>
			<Formik initialValues={userFirst}>
				{({ touched }) => (
					<Form>
						{!configuration ? (
							<div className='d-flex flex-column w-100 rounded-right-and-bottom mb-3 p-4'>
								<div className='col d-flex justify-content-center align-items-center mt-2 mb-2 '>
									<Headset size={44} className='text-color-lblue me-3' />
									<h1 className='text-color-lblue fw-bold'>CREAR OPERADOR</h1>
								</div>
								<div className='rounded-top-2 box-gradient p-4'>
									<button
										className='py-2 px-2 rounded-1 bg-white border-1 fw-600 float-end'
										style={{ border: 'none' }}
										onClick={() => setConfiguracion(!configuration)}>
										<div className='d-flex align-items-center justify-content-center'>
											<UilSetting className='text-color-lblue' />
											<p
												className='my-0 mx-2 fs-6 text-color-lblue'
												style={{ whiteSpace: 'nowrap' }}>
												Configuración
											</p>
										</div>
									</button>
								</div>
								<div className='row'>
									<div className='d-flex flex-column col'>
										<div className='d-flex col-12 px-4'>
											<div className='d-flex align-items-center py-2 col-2'>
												<div className='rounded-circle shadow-img  p-5'>
													<UilCameraPlus
														size='34'
														className='icon-blue-light'
													/>
													<span className='fs-img-name'></span>
												</div>
											</div>
										</div>
									</div>
									<div className='d-flex flex-column col justify-content-center pt-2'>
										{/* <button
									className='py-2 px-3 rounded-1 bg-white border-1 fw-600'
									style={{ border: '2px solid #506cf9' }}>
									<div className='d-flex align-items-center justify-content-center'>
										<p
											className='my-0 mx-2 fs-6 text-color-lblue'
											style={{ whiteSpace: 'nowrap' }}>
											Seleccionar Archivo
										</p>
									</div>
								</button> */}
										<Button
											color='primary'
											className='mt-3 rounded-1 flex-nowrap  me-1'
											rounded={1}>
											<div className='d-flex align-items-center  '>
												<Trash size={20} />
												<p className='my-0  p-2'>Eliminar Foto</p>
											</div>
										</Button>
									</div>
								</div>

								<Card className='mt-3 p-2'>
									<CardHeader className=' align-items-center'>
										<CardTitle tag='h4' className='h5'>
											<div className='d-flex align-items-center'>
												<Pencil size={41} color='#01227d' />
												<p className='my-0 mx-2 fw-500'>
													Información Personal
												</p>
											</div>
										</CardTitle>
									</CardHeader>

									<CardBody className=''>
										<div className='d-flex flex-column col-12'>
											<div className='d-flex  justify-content-center  col'>
												<div className='d-flex align-items-start   mx-1 col'>
													<div className='d-flex flex-column col-12'>
														<div className='d-flex align-items-center col-12 py-2'>
															{/* <input
																className='input-filters-menu shadow-inputs rounded col p-3'
																placeholder='Nombre'
															/> */}
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Nombre'
																name='name'
																type='text'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={'Nombre Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
														<div className='d-flex align-items-center col-12 py-2'>
															{/* <input
																className='input-filters-menu shadow-inputs rounded col p-3'
																placeholder='DNI'
															/> */}
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='DNI'
																name='dni'
																type='number'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={'DNI Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
													</div>
												</div>
											</div>

											<div className='d-flex  justify-content-center  col'>
												<div className='d-flex align-items-start   mx-1 col'>
													<div className='d-flex flex-column col-12'>
														<div className='d-flex align-items-center  col-12 py-2'>
															{/* <input
																className='input-filters-menu shadow-inputs rounded col p-3'
																placeholder='Apellido'
															/> */}
															<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Apellido'
																name='surname'
																type='text'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={'Apellido Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
														<div className='d-flex align-items-center  col-12 py-2'>
															{/* <input
																className='input-filters-menu shadow-inputs rounded col p-3'
																placeholder='Fecha Nacimiento'
																type='date'
															/> */}
															<InputFloating
																label='Fecha de nacimiento'
																name='birthday'
																type='date'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={
																	'Fecha Nacimiento Requerido'
																}
																onChange={(e) => {
																	save(e);
																}}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
								<div className='d-flex'>
									<div className='col-12 '>
										<Card className='p-2'>
											<CardHeader className='align-items-center'>
												<CardTitle tag='h4' className='h5'>
													<div className='d-flex align-items-center'>
														<AddressBook size={41} color='#01227d' />

														<p className='my-0 mx-2  fw-500'>
															Contacto
														</p>
													</div>
												</CardTitle>
											</CardHeader>
											<CardBody>
												<div className='d-flex flex-column col-12'>
													<div className='d-flex  justify-content-center  col'>
														<div className='d-flex align-items-start   mx-1 col'>
															<div className='d-flex flex-column col-12'>
																<div className='d-flex align-items-center col-12 py-2'>
																	{/* <input
																		className='input-filters-menu shadow-inputs rounded col p-3'
																		placeholder='Número de teléfono'
																	/> */}
																	<InputFloating
																		label='Teléfono'
																		name='phone'
																		type='Number'
																		setFunction={setDataFirst}
																		previousValue={userFirst}
																		touched={touched}
																		required
																		errorMessage={
																			'Telefono Requerido'
																		}
																		onChange={(e) => {
																			save(e);
																		}}
																	/>
																</div>
																<div className='d-flex align-items-center col-12 py-2'>
																	{/* <input
																		className='input-filters-menu shadow-inputs rounded col p-3'
																		placeholder='Dirección E-Mail'
																	/> */}
																	<InputFloating
																		className='input-filters-menu rounded shadow-inputs col p-3'
																		label='email'
																		name='email'
																		type='email'
																		setFunction={setDataFirst}
																		previousValue={userFirst}
																		touched={touched}
																		required
																		errorMessage={
																			'Email Requerido'
																		}
																		onChange={(e) => {
																			save(e);
																		}}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</div>
								<div className='d-flex'>
									<div className='col-12'>
										<Card className='p-2'>
											<CardHeader className='align-items-center'>
												<CardTitle tag='h4' className='h5 '>
													<div className='d-flex align-items-center justify-content-center'>
														<LockKey size={41} color='#01227d' />
														<p className='my-0 mx-2 fw-500'>
															Nueva Contraseña
														</p>
													</div>
												</CardTitle>
											</CardHeader>
											<CardBody>
												<div className='d-flex flex-column col-12'>
													<div className='d-flex  justify-content-center  col'>
														<div className='d-flex align-items-start   mx-1 col'>
															<div className='d-flex flex-column col-12'>
																<div className='d-flex align-items-center col-12 py-2'>
																	{/* <input
																		className='input-filters-menu shadow-inputs rounded col p-3'
																		placeholder='Contraseña'
																	/> */}
																		<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Contraseña'
																name='password'
																type='password'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={'Contraseña Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
																</div>
																<div className='d-flex align-items-center col-12 py-2'>
																	{/* <input
																		className='input-filters-menu shadow-inputs rounded col p-3'
																		placeholder='Confirmar Contraseña'
																	/> */}
																		<InputFloating
																className='input-filters-menu rounded shadow-inputs col p-3'
																label='Contraseña'
																name='password'
																type='password'
																setFunction={setDataFirst}
																previousValue={userFirst}
																touched={touched}
																required
																errorMessage={'Contraseña Requerido'}
																onChange={(e) => {
																	save(e);
																}}
															/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</div>

								{/* Footer */}
								{/* <div className='d-flex justify-content-between align-items-center w-100'>
					<div className='d-flex align-items-center'>
						<Button rounded={1} className='blue-gradient-buttons  border-0'>
							<div className='d-flex align-items-center'>
								<FloppyDisk size={24} color='#ffffff' />
								<p className='my-0 mx-2 fs-6 fw-light text-color-white'>Guardar</p>
							</div>
						</Button>
					</div>
				</div> */}
							</div>
						) : (
							<ConfigurationMobile />
						)}
					</Form>
				)}
			</Formik>
		</>
	);
};
export default Create;
