import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import USERS from '../../common/data/userDummyData';
import Avatar from '../../components/Avatar';
import { UilUsdCircle, UilFileImport, UilImport } from '@iconscout/react-unicons';
import Check from '../../components/Check';

const CardImported = ({ type, imported = true }) => {
	const types = ['Reserva inmueble', 'Alta de ficha', 'Suspensión de inmueble', 'Baja de ficha'];
	const [selectedEstates, setSelectedEstates] = useState([]);
	const imagenURL =
		'https://images.pexels.com/photos/2360673/pexels-photo-2360673.jpeg?cs=srgb&dl=pexels-tobias-bj%C3%B8rkli-2360673.jpg&fm=jpg';
	return (
		<>
			<div className='col-12 mt-3'>
				<div className='emulate-card py-2 rounded-1 d-flex align-items-center justify-content-center -p-4'>
					<div className='col-1  '>
						{/* <input type='checkbox' className='mx-5 text-color-lblue' size={40} />
						<label
							className='custom-checkbox ms-5'
							onClick={() => {
								if (selectedEstates.length) {
									setSelectedEstates([]);
								} else {
									setSelectedEstates(1);
								}
							}}
						>
							<UilCheck
								color={selectedEstates.length === 0 ? '#506CF9' : '#fff'}
								size={16}
							/>
						</label> */}
						<div className='mx-5'>
							<Check />
						</div>
					</div>
					<div className='col-2 me-2'>
						<div class='d-flex justify-content-center align-items-center'>
							<div
								class='rounded-circle bg-blue-500 d-flex justify-content-center align-items-center'
								style={{ width: '72px', height: '72px' }}
							>
								{imported ? (
									<UilImport
										className='text-color-white text-center '
										size={40}
									/>
								) : (
									<UilFileImport
										className='text-color-white text-center '
										size={40}
									/>
								)}
							</div>
						</div>
					</div>
					<div className='col-8 me-4 '>
						<div className='row'>
							<div className='col-12 mt-4'>
								{imported ? (
									<p className='fs-5 d-flex align-items-center'>
										Sus contactos han sido importados a la lista{' '}
										<span className='fw-700 ms-2'>
											[44] PA - Invitados Bercic
										</span>
									</p>
								) : (
									<p className='fs-5 d-flex align-items-center'>
										Se están importando sus contactos a la lista
										<span className='fw-700 ms-2'>
											[44] PA - Invitados Bercic
										</span>
									</p>
								)}
								{imported && (
									<p className='fs-6 d-flex align-items-center'>
										Puede consultar el informe detallado <a href=''>aquí</a>
									</p>
								)}
							</div>
						</div>
					</div>

					<div className='col-1'>
						{/* <div className='row'>
							<div className=''>
								<span className='radius-7p px-3 py-1 bg-primary text-white float-end '>
									Contrato
								</span>
							</div>
						</div> */}
						<div className='row'>
							<div className='float-end mt-5'>
								<Avatar
									className='mt-3 float-end me-1'
									srcSet={USERS.CHLOE.srcSet}
									src={USERS.CHLOE.src}
									size={40}
									color={USERS.CHLOE.color}
								/>
							</div>
						</div>
						<p className='float-end me-1 fs-5 mt-3 fw-500'>12:18</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardImported;
