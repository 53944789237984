import { useContext } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import SubheaderNew from '../../layout/SubHeader/SubHeaderNew';
import { UilSchedule } from '@iconscout/react-unicons';
import ThemeContext from '../../contexts/themeContext';
import SectionAssets from './SectionAssets';
import TableAssets from './TableAssets';
import Table from './Table';

export default function SchedulePage() {
	const { mobileDesign } = useContext(ThemeContext);

	return (
		<PageWrapper title='Agenda'>
			<Page container='fluid'>
				<SubheaderNew breadCrumbs={[{ name: 'Agenda', url: '/agenda' }]} />
				<div
					className={`d-flex flex-column w-100 bg-blue-100 ${
						!mobileDesign && 'shadow-subheader rounded-xl'
					} mb-4 min-vh-75`}>
					{/* card header */}
					<div className='d-flex align-items-center justify-content-between p-4 border-bottom bg-white'>
						<div className='d-flex align-items-center'>
							<UilSchedule size={28} color='#506CF9' />
							<p className='mb-0 ms-2 fw-bold fs-5 text-color-lblue'>
								AGENDA DIARIA Y ENTREVISTA
							</p>
						</div>
					</div>
					{/* card header */}

					<SectionAssets />

					{/* white rounded card */}
					<div
						className={`d-flex flex-column min-vh-50 my-4 mx-4 rounded-3 shadow bg-white`}>
						<TableAssets />
						<Table />
					</div>
					{/* white rounded card */}
				</div>
			</Page>
		</PageWrapper>
	);
}
