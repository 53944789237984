import axios from 'axios';
const baseURL = process.env.REACT_APP_ENDPOINT;

export const getBranches = async (token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const branches = await axios({
		method: 'GET',
		url: `${baseURL}/system/getBranches`,
		headers: header,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));

	return branches;
};
