import { Field } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import ThemeContext from '../../../contexts/themeContext';
import { UilClock } from '@iconscout/react-unicons';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import CustomSelect from '../../../components/CustomSelect';
import InputFloating from '../../../components/InputFloating';
import StateView from './StateView';

const ChronologyItemSelector = ({view , reason , properties , activeItem , setTask , task ,cronology}) => {

	const { mobileDesign } = useContext(ThemeContext);

	const [filteredSuggestions, setFilteredSuggestions] = useState([])

	useEffect(() => {
		const fetchData = async () => {
			setFilteredSuggestions(properties)
		};
		fetchData();
	  
	  }, [activeItem]);

	const historialTh = [
		'Fecha',
		'Hora',
		'Tarea',
		'Dirección',
		'Observación',
		'Operador',
		'Cargado por',
	]

	const hours = [
		'8:00',
		'8:30',
		'9:00',
		'9:30',
		'10:00',
		'10:30',
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30',
		'17:00',
		'17:30',
		'18:00',
		'18:30',
		'19:00',
		'19:30',
		'20:00',
	];

const handleInputChange = (e) => {
    sugestionFilter(e, properties || []);
     
  };


  const sugestionFilter = (name, properties) => {
    const filteredSuggestions = properties
      .filter((suggestion) =>
        suggestion.location.toLowerCase().includes(name.toLowerCase())
      )
		setFilteredSuggestions(filteredSuggestions);
  };
	const renderCustomInput = ({ ref }) => (
		<div className='d-flex align-items-end' style={{ borderBottom: '2px solid #506cf9' }}>
			<UilClock className='m-1' size={24} color='#506cf9' />
			<input
				// readOnly
				className='p-2 text-color-lblue fw-normal-bold fw-normal-bold'
				ref={ref} // necessary
				placeholder='Ingrese la fecha'
				value={`${task.date?.day || 'dd'}/${task.date?.month || 'mm'}/${
					task.date?.year || 'aaaa'
				}`}
				style={{
					border: 'none',
					outline: 'none',
				}}
			/>
		</div>
			)

	switch (activeItem) {
		case 'Correo':
			return (
				<div className='d-flex flex-column m-3 mx-4'>
					<label className='fs-6 fw-bold m-2'>Para</label>
					<Field
						className={`input-filters-menu p-2 inner-shadow-blue rounded ${
							mobileDesign ? 'col-12' : 'col-3'
						}`}
						name='number'
					/>
					{/* {activeItem === 'Correo' && ( */}
					<>
						<label className='fs-6 fw-bold m-2'>Asunto</label>
						<Field
							className={`input-filters-menu p-2 inner-shadow-blue rounded ${
								mobileDesign ? 'col-12' : 'col-3'
							}`}
							name='subject'
						/>
					</>
					{/* )} */}
					<label className='fs-6 fw-bold mx-2 mt-4 mb-2'>Mensaje</label>
					<Field
						className={`input-filters-menu px-3 pt-3 inner-shadow-blue rounded col-12 textarea-chronology min-vh-10`}
						name='message'
						as='textarea'
					/>
				</div>
			);
		case 'Whatsapp':
			return (
				<div className='d-flex flex-column m-3 mx-4'>
					<label className='fs-6 fw-bold m-2'>Para</label>
					<Field
						className={`input-filters-menu p-2 inner-shadow-blue rounded ${
							mobileDesign ? 'col-12' : 'col-3'
						}`}
						name='number'
					/>
					<label className='fs-6 fw-bold mx-2 mt-4 mb-2'>Mensaje</label>
					<Field
						className={`input-filters-menu px-3 pt-3  inner-shadow-blue rounded col-12 textarea-chronology min-vh-10`}
						name='message'
						as='textarea'
					/>
				</div>
			);

		case 'Notas':
			return (
				<Field
					autoComplete='off'
					disabled={view}
					className='
					text-area-chronology-notas
					bg-body-tertiary
					w-100
					p-4'
					placeholder='Haz clic aquí para tomar notas...'
					name='content'
				/>
			);
		case 'Actividad':
			return (
				<div
					className={`d-flex flex-column ${
						mobileDesign ? 'align-items-center' : 'align-items-start'
					} col-12`}>
					<div className={`${mobileDesign ? 'col-9' : 'col-4'}  mt-4 mx-4`}>
					<CustomSelect
							inputDisabled={view}
							text={task.motivoName}
							name={'Motivo'}
							nameEng={'motivo'}
							data={reason}
							filtersApplied={task}
							values={reason}
							setFiltersApplied={setTask}
							// changeHandler={setTask}
							// changeHandler={(e) => setTask((prevValues) => ({ ...prevValues, motivo: e , motivoName: e.name }))}
							changeHandler={(e) => {
								const selectedReason = reason[e - 1];
								if (selectedReason && selectedReason.name) {
									setTask((prevValues) => ({ ...prevValues, motivo: e , motivoName: selectedReason.name }));
								}
							}}
							size='2'
							withSearch={true}
							/>


							{/* <SearchFilter
										text={task.name}
										name={'Motivo'}
										nameEng={'motivo'}
										values={motivos}
										filtersApplied={task}
										setFiltersApplied={setTask}
										size='2'
										data={motivos}
										changeHandler={(e) => setTask((prevValues) => ({ ...prevValues, motivo: e , motivoName: motivos[e -1].name }))}
										
									/> */}
							
						{/* <SearchFilter
							text={'Motivo'}
							name={'Motivo'}
							nameEng={'reason'}
							filtersApplied={task}
							setFiltersApplied={setTask}
							size='2'
						/> */}
					</div>
					<div className={`${mobileDesign ? 'col-9' : 'col-4'} my-4 mx-4`}>
						{/* <CustomSelect
							text={'Propiedad'}
							name={'Propiedad'}
							nameEng={'estate'}
							data={properties}
							values={properties}
							filtersApplied={task}
							// changeHandler={setTask}
							changeHandler={(e) => setTask((prevValues) => ({ ...prevValues, propiedad: e }))}
							setFiltersApplied={setTask}
							size='2'
							withSearch={false}

						/> */}
						
						<div className={'dropdown w-100'}>
										<div
											className='w-100'
											id='dropdownMenuButton1'
											data-bs-toggle='dropdown'
											aria-expanded='false'>
											<InputFloating
												inputDisabled={view}
												label='Inmueble'
												name='propertiesName'
												type='text'
												setFunction={setTask}
												previousValue={task}
												errorMessage='Ingresa una dirección'
												required={false}
												changeHandler={handleInputChange}
											/>
										</div>

										{/* {	filteredProperties.length > 0 &&  */}
										 <ul
											className={'dropdown-menu bg-white-dropdown p-4 w-100'}
											aria-labelledby='dropdownMenuButton1'>
											<li>
												<div className='d-flex flex-column align-items-start w-100'>
													{/* <div className='d-flex align-items-center'>
														<UilEstate size={25} color='#01227D' />
														<p className='ms-1 my-0 fs-6 text-color-dark'>
															Inmuebles
														</p>
													</div> */}
													{filteredSuggestions?.length > 0 ? (
													
														<div className='d-flex flex-column justify-content-center align-items-start w-100 py-1'>
															{filteredSuggestions?.map((item , i) => (
																	<div key={i}
																		onClick={() =>
																			setTask((task) => ({
																				...task,
																				properties: [item.id],
																				propertiesName : item.location
																			}))
																		}
																		className='d-flex align-items-center my-1 w-100 cursor-pointer custom-dropdown-item px-4 py-2 rounded-pill'>
																		<img
																			style={{width: '30px'  , height: '30px'}}
																			src={item.photo}
																			className='rounded-5px'
																		/>
																		<p className='m-0 fs-5 fw-bold mx-2 text-nowrap'>
																			{item.location}
																		</p>
																		<p className='m-0 fs-6 fw-normal text-truncate'>
																			{item.estateType}
																		</p>
																	</div>
																))}
														</div>
														) : (
															<div className="text-center my-2">No hay más datos</div>
														)}
												</div>
											</li>
										</ul>
									
									</div>


						{/* solucion esto! */}
						{/* <SearchFilter
							text={'Propiedad'}
							name={'Propiedad'}
							nameEng={'properties'}
							// changeHandlerMultipleSelect={(e) => setTask((prevValues) => ({ ...prevValues, properties:[...prevValues.properties, properties[e - 1].id] }))}

							changeHandlerMultipleSelect={(e) => {
								setTask((prevValues) => {
								  // Verifica si el ID ya está en el array
								  const isAlreadySelected = prevValues.properties.includes(properties[e - 1].id);
							
								  // Si ya está seleccionado, elimínalo del array, de lo contrario, agrégalo
								  const updatedProperties = isAlreadySelected
									? prevValues.properties.filter((id) => id !== properties[e - 1].id)
									: [...prevValues.properties, properties[e - 1].id];
							
								  return {
									...prevValues,
									properties: updatedProperties,
								  };
								});
							  }}
							data={task}
							filtersApplied={task}
							setFiltersApplied={setTask}
							size='2'
							values={properties}
						/> */}


						{/* <CustomSelect
							// inputDisabled={inputDisabled}
							text={'Propiedad'}
							name={'Propiedad'}
							nameEng={'estate'}
							data={properties}
							filtersApplied={task}
							setFiltersApplied={setTask}
							// changeHandler={changeHandler}
							size='2'
							/> */}
					</div>
					<div 
					className='d-flex align-items-center justify-content-start w-100 px-4'>
						<DatePicker
							onChange={(e) => setTask((prevValues) => ({ ...prevValues, date: e }))}
							value={task.date}
							colorPrimary='#506cf9'
							date={new Date()}
							minDate={new Date()}
							color={process.env.REACT_APP_PRIMARY_COLOR}
							renderInput={renderCustomInput}
							shouldHighlightWeekends
						/>

						<div
							className='dropdown p-2 mx-4'
							style={{
								borderBottom: '2px solid #506cf9',
							}}>
							<div
								className='text-color-lblue fw-normal-bold'
								data-bs-toggle='dropdown'
								aria-expanded='false'>
								Desde: {task.time || 'hh:mm'}
							</div>
							<div className='dropdown-menu'>
								<div
									className='d-flex flex-column'
									style={{ height: '25rem', overflowY: 'scroll' }}>
									{hours.map((hour) =>{
										let dueTimeInt = parseInt(task.dueTime?.replace(":", ""));
										return(
											<span
											onClick={() =>{
												let hourInt = parseInt(hour.replace(":", ""))
												if(dueTimeInt < hourInt ){
													setTask((task) => ({ ...task, time: hour , dueTime : hour}))
												} else {
													setTask((task) => ({ ...task, time: hour  }))
												}
											}}
											className='p-4 fw-normal-bold cursor-pointer custom-dropdown-item'
											key={hour}>
											{hour}
										</span>
										)} 
									)}
								</div>
							</div>
						</div>
						<div
							className='dropdown p-2 mx-4'
							style={{
								borderBottom: '2px solid #506cf9',
							}}>
							<div
								className='text-color-lblue fw-normal-bold'
								data-bs-toggle='dropdown'
								aria-expanded='false'>
								Hasta: {task.dueTime || 'hh:mm'}
							</div>
							<div className='dropdown-menu'>
								<div
									className='d-flex flex-column'
									style={{ height: '25rem', overflowY: 'scroll' }}>
									{hours.map((hour) => {
										let timeInt = parseInt(task.time?.replace(":", ""));
										return(
											<span
											onClick={(e) =>{
												if(timeInt < parseInt(hour.replace(":", ""))){
													setTask((task) => ({ ...task, dueTime: hour }))
												} else {
													e.stopPropagation()
												}
											}}
											className={`${parseInt(hour.replace(":", "")) < timeInt && 'fw-light'}  p-4 fw-normal-bold cursor-pointer custom-dropdown-item`}
											key={hour}>
											{hour}
										</span>
										)
									})}
								</div>
							</div>
						</div>
					</div>

					<div className='d-flex flex-column w-100 mt-4 mb-2'>
						<label className='fs-5 fw-normal-bold mx-4'>Observaciones</label>
						<textarea
							disabled={view}
							type='textarea'
							name='message'
							className='mx-4 px-3 pt-3 my-2 min-vh-10 input-filters-menu inner-shadow-blue rounded-1 textarea-chronology input-no-outline'
							value={task.obs ?? ''}
							onChange={(e) => setTask((task) => ({ ...task, obs: e.target.value }))}
						/>
					</div>
				</div>
			);

			case 'Historial':
			return (
				<table className='w-100 shadow-custom-tarjet-body'>
					<thead className='py-2'>
						<tr className='text-color-test text-primary '>
						{historialTh.map((title , i) => {
							return(
								<th key={i} className={`${i === 0 && 'ps-4'} py-2`}>{title}</th>
							)
						})}
						</tr>
					</thead>

					<tbody>
						{cronology?.map((item , i) => {
							return(
								<tr key={i} className={`${i % 2 == 0 && 'bg-new-gray'}`}>
									<td className='ps-4 '>{item.startTime? item.startTime.split(' ')[0] : ''}</td>{/* fecha */}
									<td>{item.startTime? item.startTime.split(' ')[1] : ''}</td>{/* hora */}
									<td>{item.type}</td> {/* tarea */}
									<td>
										{item.properties ? properties?.filter((property) => property.id === item.properties[0]).length > 0
										? properties?.filter((property) => property.id === item.properties[0])[0].location
										: "" : ''}
										</td>
									<td>{item.details || '-'}</td> {/* observacion */}
									<td>{item.assignedOperator}</td> {/* operador */}
									<td>{item.creator}</td>{/* cargado por */}
								</tr>
							)
						})}
					</tbody>
				</table>
			);

			case 'Similares':
			return(
				<div>
					<StateView mobileDesign={mobileDesign} filteredProperties={properties} isCheckbox={false}  />
				</div>
			)

		default:
			break;
	}
};

export default ChronologyItemSelector;
