import axios from 'axios';
const baseURL = process.env.REACT_APP_ENDPOINT;

export const getPortals = async (token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const portals = await axios({
		method: 'GET',
		url: `${baseURL}/system/portals/properties`,
		headers: header,
	}).then((response) => response.data);

	return portals;
};

export const getPosts = async (filters, token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const posts = await axios({
		method: 'POST',
		url: `${baseURL}/publications`,
		headers: header,
		data: {
			section: 'properties',
			filters,
		},
	}).then((response) => response.data);

	return posts;
};

// Si revienta todo usar este body
// {
// 	section: 'properties',
// 	filters: {
// 		query: null,
// 		isPublished: 1, // boolean
// 		highlight: null,
// 		portal: 2,
// 		sort: {
// 			field: null,
// 			order: 'ASC',
// 		},
// 		page: 1,
// 		limit: 10,
// 	},
// }
