import React from 'react';

const CustomUserPlus = (props) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='svg-icon'
			{...props}>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M6.75 7.5C6.75 5.15279 8.65279 3.25 11 3.25C13.3472 3.25 15.25 5.15279 15.25 7.5C15.25 9.84721 13.3472 11.75 11 11.75C8.65279 11.75 6.75 9.84721 6.75 7.5ZM11 4.75C9.48122 4.75 8.25 5.98122 8.25 7.5C8.25 9.01878 9.48122 10.25 11 10.25C12.5188 10.25 13.75 9.01878 13.75 7.5C13.75 5.98122 12.5188 4.75 11 4.75Z'
				fill='#fff'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M3.25 17C3.25 14.9289 4.92893 13.25 7 13.25H7.34087C7.52536 13.25 7.70869 13.2792 7.88407 13.3364L8.74959 13.6191C10.2119 14.0965 11.7881 14.0965 13.2504 13.6191L14.1159 13.3364C14.2913 13.2792 14.4746 13.25 14.6591 13.25H15C17.0711 13.25 18.75 14.9289 18.75 17V18.1883C18.75 18.9415 18.2041 19.5837 17.4607 19.7051C13.1819 20.4037 8.8181 20.4037 4.53927 19.7051C3.79588 19.5837 3.25 18.9415 3.25 18.1883V17ZM7 14.75C5.75736 14.75 4.75 15.7574 4.75 17V18.1883C4.75 18.2064 4.76311 18.2218 4.78097 18.2247C8.89972 18.8972 13.1003 18.8972 17.219 18.2247C17.2369 18.2218 17.25 18.2064 17.25 18.1883V17C17.25 15.7574 16.2426 14.75 15 14.75H14.6591C14.6328 14.75 14.6066 14.7542 14.5815 14.7623L13.716 15.045C11.9512 15.6212 10.0488 15.6212 8.28398 15.045L7.41847 14.7623C7.39342 14.7542 7.36722 14.75 7.34087 14.75H7Z'
				fill='#fff'
			/>
			<path
				d='M19.5 6.25C19.9142 6.25 20.25 6.58579 20.25 7V8.75H22C22.4142 8.75 22.75 9.08579 22.75 9.5C22.75 9.91421 22.4142 10.25 22 10.25H20.25V12C20.25 12.4142 19.9142 12.75 19.5 12.75C19.0858 12.75 18.75 12.4142 18.75 12V10.25H17C16.5858 10.25 16.25 9.91421 16.25 9.5C16.25 9.08579 16.5858 8.75 17 8.75H18.75V7C18.75 6.58579 19.0858 6.25 19.5 6.25Z'
				fill='#fff'
			/>
		</svg>
	);
};

export default CustomUserPlus;
