import React, { useState, useContext } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import { UilBuilding } from '@iconscout/react-unicons';
import ChildrenSelector from './ChildrenSelector';
import ThemeContext from '../../contexts/themeContext';
import NewEstateMobile from './NewEstateMobile';

export default function CreateEstatePage() {
	const [selectedStep, setSelectedStep] = useState('operationType');

	const { mobileDesign } = useContext(ThemeContext);

	const steps = [
		{
			name: 'operationType',
			label: '1. Tipo de operación',
		},
		{
			name: 'general',
			label: '2. Generales',
		},
		{
			name: 'description',
			label: '3. Descripción',
		},
		{
			name: 'features',
			label: '4. Características',
		},
		{
			name: 'measures',
			label: '5. Medidas',
		},
		{
			name: 'multimedia',
			label: '6. Multimedia',
		},
	];

	const [estateData, setEstateData] = useState({
		operationType: {
			tipology: 1,
			operations: [],
			branch: null,
		},
		general: {},
		features: [],
		measures: {},
		multimedia: {
			files: [],
		},
	});

	console.log(estateData);

	return (
		<PageWrapper title='Crear inmueble' className='mt-4'>
			<Page container='fluid'>
				{mobileDesign ? (
					<NewEstateMobile estateData={estateData} setEstateData={setEstateData} />
				) : (
					<div className='d-flex flex-column align-items-start bg-lblue rounded-xl w-100 pb-4'>
						{/* step selector  */}
						<div className='d-flex align-items-center col-12 p-5'>
							{steps.map((item, index, array) => (
								<div
									className={`d-flex justify-content-center col p-2 fs-5 fw-normal-bold 
              ${
					selectedStep === item.name
						? 'bg-blue-300 text-color-white'
						: 'bg-white text-color-dark cursor-pointer'
				} ${index === 0 && 'rounded-start'} ${index === array.length - 1 && 'rounded-end'}`}
									style={{ margin: '0px 1px' }}
									onClick={() => setSelectedStep(item.name)}
									key={item.name}>
									{item.label}
								</div>
							))}
						</div>
						{/* step selector  */}
						{/* tab */}
						<div className='d-flex align-items-center bg-white rounded-top py-2 px-4 ms-5'>
							<UilBuilding color='#506cf9' size={27} />
							<p className='text-color-lblue fs-5 px-2 mb-0 fw-bold'>
								NUEVO INMUEBLE
							</p>
						</div>
						{/* tab */}
						<div className='d-flex flex-column pb-5 px-5 w-100'>
							{/* white background container  */}
							<div className='bg-white rounded-bottom rounded-end w-100 h-100'>
								<ChildrenSelector
									selectedStep={selectedStep}
									setSelectedStep={setSelectedStep}
									estateData={estateData}
									setEstateData={setEstateData}
								/>
							</div>
							{/* white background container  */}
						</div>
					</div>
				)}
			</Page>
		</PageWrapper>
	);
}
