import { useQuery } from '@tanstack/react-query';
import { getPortals } from '../services/postsServices';
import UserContext from '../contexts/userContext';
import { useContext } from 'react';

export default function usePortals() {
	const { token } = useContext(UserContext);

	const portals = useQuery({
		queryKey: ['portals'],
		queryFn: () => getPortals(token).then((response) => response.data),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});

	return portals;
}
