import { useContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../contexts/userContext';
import loginService from '../services/loginService';

export default function useUser() {
	const { token, setToken, userData, setUserData } = useContext(UserContext);
	const [state, setState] = useState({ loading: false, error: false });
	const navigate = useNavigate();

	const login = useCallback(
		({ dni, password }) => {
			setState({ loading: true, error: false });
			loginService({ dni, password })
				.then((data) => {
					window.sessionStorage.setItem('token', data.token);
					window.sessionStorage.setItem('userData', JSON.stringify(data.data));
					setState({ loading: false, error: false });
					setToken(data.token);
					setUserData(data.data);
				})
				.catch((err) => {
					window.sessionStorage.removeItem('token');
					window.sessionStorage.removeItem('userData');
					setState({ loading: false, error: true });
					console.error(err);
				});
		},
		[setToken, setUserData],
	);

	const logout = useCallback(() => {
		window.sessionStorage.removeItem('token');
		window.sessionStorage.removeItem('userData');
		setToken(null);
		setUserData(null);
		navigate('/', { replace: true });
	}, [setToken, setUserData, navigate]);

	return {
		isLogged: Boolean(token),
		userData,
		isLoginLoading: state.loading,
		hasLoginError: state.error,
		login,
		logout,
	};
}
