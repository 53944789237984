import React, { useRef, useEffect } from 'react';

export const Suggestions = ({
  handleKeyDown,
  filteredSuggestions,
  handleMouseEnter,
  highlightedSuggestion,
  handleSuggestionClick,
}) => {
  const containerRef = useRef(null);
  const highlightedItemRef = useRef(null);

  useEffect(() => {
    if (highlightedItemRef.current) {
      highlightedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [highlightedSuggestion]);

  return (
    <div className='dataListCustom' ref={containerRef}>
      <ul className='suggestion custom-letter' onKeyDown={(e) => handleKeyDown(e)} id='interes_list'>
        {filteredSuggestions?.map((suggestion, index) => (
          <li
            onMouseEnter={() => handleMouseEnter(index)}
            ref={index === highlightedSuggestion ? highlightedItemRef : null}
            className={`suggestion-item cursor-pointer ${index === highlightedSuggestion ? 'highlighted' : ''}`}
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
          >
            {suggestion.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
