import React from 'react'
import {
	UilLocationPoint,
	UilBookmark,
	UilBed,
	UilMoneyBill,
	UilCheck,
} from '@iconscout/react-unicons';

const StateView = ({
    filteredProperties,
    mobileDesign,
    selectedEstates, 
    setSelectedEstates, 
    isCheckbox = true
}) => {

  return (
    <div
        className={`d-flex ${
            mobileDesign ? 'flex-column' : 'flex-wrap'
        } col-12`}>
        {filteredProperties?.map((estate , i) => (
            <div
                className={`d-flex my-4 position-relative ${
                    !mobileDesign
                        ? 'mx-4'
                        : 'justify-content-center flex-row-reverse'
                }`}
                key={i}>
                <div className={`${!mobileDesign ? 'pe-4' : 'ps-2'}`}>
                    {isCheckbox && (

                    <label
                        className='custom-checkbox'
                        onClick={() => {
                            if (
                                selectedEstates?.some(
                                    (es) => es.id === estate.id,
                                )
                            ) {
                                setSelectedEstates(
                                    selectedEstates.filter(
                                        (es) => es.id !== estate.id,
                                    ),
                                );
                            } else {
                                setSelectedEstates((selectedEstates) => [
                                    ...selectedEstates,
                                    estate,
                                ]);
                            }
                        }}>
                        <UilCheck
                            color={
                                selectedEstates.some(
                                    (es) => es.id === estate.id,
                                )
                                    ? '#506CF9'
                                    : '#fff'
                            }
                            size={20}
                        />
                    </label>
                )}

                </div>
                <div className='d-flex flex-column align-items-center'>
                    <div
                        className='rounded'
                        style={{
                            backgroundImage: `url(${estate.photo})`,
                            backgroundSize: 'cover',
                            height: mobileDesign ? '146px' : '184px',
                            width: '242px',
                            position: 'relative',
                        }}>
                        <div
                            className='bg-blue-300 py-1 px-2 text-color-white'
                            style={{
                                position: 'absolute',
                                right: 10,
                                top: 10,
                                borderRadius: '5px',
                            }}>
                            <p className='m-0'>{estate.id}</p>
                        </div>
                    </div>
                    <div
                        className='d-flex justify-content-start align-items-center my-2'
                        style={{
                            width: '242px',
                        }}>
                        <UilLocationPoint color='#506CF9' />
                        <p className={`fs-5 px-2 fw-normal m-0 text-truncate`}>
                            {estate.location}
                        </p>
                    </div>
                    <div
                        className='d-flex align-items-center my-1'
                        style={{
                            width: '242px',
                        }}>
                        <div className='d-flex align-items-center'>
                            <UilBookmark color='#506CF9' />
                            <p
                                className={`fs-5 px-2 ${
                                    mobileDesign ? 'me-2' : 'me-3'
                                } fw-normal m-0`}>
                                {estate.operation}
                            </p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <UilBed color='#506CF9' />
                            <p
                                className={`fs-5 px-2 ${
                                    mobileDesign ? 'me-2' : 'me-3'
                                } fw-normal m-0`}>
                                {estate.rooms}
                            </p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <UilMoneyBill color='#506CF9' />
                            <p
                                className={`${
                                    mobileDesign ? 'fs-6' : 'fs-6 me-3'
                                } px-2 fw-normal m-0 text-truncate`}>
                                {estate.currency} {estate.prices}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>
  )
}

export default StateView