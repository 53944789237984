import axios from 'axios';
const baseURL = process.env.REACT_APP_ENDPOINT;

export const getTasacion = async () => {
	const token = sessionStorage.getItem('token');
	const header = {
		Authorization: `Bearer ${token}`,
	};
	const tasacion = {
		filters: {
			propertyType: 1,
			operationType: 1,
			currency: 2,
			antiquity: '+10',
			garage: true,
			amenities: true,
			rooms: 1,
			coverSurface: 0,
			freeSurface: 0,
			address: 'string',
			coordinates: [-34.582483, -58.456564],
			radius: 5,
			onlyActives: true,
		},
	};
	const data = await axios({
		method: 'POST',
		url: `${baseURL}/properties/appraiser`,
		headers: header,
		data: tasacion,
	}).then((res) => res.data);

	return data;
};

export const getFilters = async () => {
	const token = sessionStorage.getItem('token');
	const header = {
		Authorization: `Bearer ${token}`,
	};
	const data = await axios({
		method: 'GET',
		url: `${baseURL}/app/filters/appraiser`,
		headers: header,
	}).then((res) => res.data);

	return data;
};
