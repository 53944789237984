import axios from "axios";
const baseURL = process.env.REACT_APP_ENDPOINT;




export const getAllTags = async(token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	try {
		const response = await axios ({
			method:'GET',
			url:`${baseURL}/system/getCustomerTags`,
			headers: header,
		});

		const data = response.data
		return data;
	} catch(error) {
		console.log(error);

	}

}
export const getEventType = async(token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	try {
		const response = await axios ({
			method:'GET',
			url:`${baseURL}/system/getEventTypes`,
			headers: header,
		});


		const data = response.data.data
		return data;
	} catch(error) {
		console.log(error);

	}
}

export const getLeadIncomeTypes = async(token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	try {
		const response = await axios ({
			method:'GET',
			url:`${baseURL}/system/getLeadIncomeTypes`,
			headers: header,
		});


		const data = response.data.data
		return data;
	} catch(error) {
		console.log(error);

	}
}