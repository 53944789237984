import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './mapStyles.css';
import L from 'leaflet';
import ThemeContext from '../../contexts/themeContext';
import EstatePopupMultiple from './EstatePopupMultiple';
import EstatePopupNew from './EstatePopupNew';

const customIcon = (text) => {
	return L.divIcon({
		html: text,
		className:
			'd-flex justify-content-center align-items-center text-color-white bg-blue-300 fw-bold customIcon fs-6 rounded-pill',
	});
};

const customIconCluster = (text) => {
	console.log(text);
	return L.divIcon({
		html: `${text} <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_6345_6762)">
				<g filter="url(#filter0_d_6345_6762)">
				<path d="M16.5 2.75C13.5826 2.75 10.7847 3.90893 8.72183 5.97183C6.65893 8.03473 5.5 10.8326 5.5 13.75C5.5 21.175 15.1938 29.5625 15.6063 29.92C15.8553 30.133 16.1723 30.2501 16.5 30.2501C16.8277 30.2501 17.1447 30.133 17.3937 29.92C17.875 29.5625 27.5 21.175 27.5 13.75C27.5 10.8326 26.3411 8.03473 24.2782 5.97183C22.2153 3.90893 19.4174 2.75 16.5 2.75ZM16.5 16.5C15.9561 16.5 15.4244 16.3387 14.9722 16.0365C14.5199 15.7344 14.1675 15.3049 13.9593 14.8024C13.7512 14.2999 13.6967 13.7469 13.8028 13.2135C13.9089 12.6801 14.1709 12.1901 14.5555 11.8055C14.9401 11.4209 15.4301 11.1589 15.9635 11.0528C16.4969 10.9467 17.0499 11.0012 17.5524 11.2093C18.0549 11.4175 18.4844 11.7699 18.7865 12.2222C19.0887 12.6744 19.25 13.2061 19.25 13.75C19.25 14.4793 18.9603 15.1788 18.4445 15.6945C17.9288 16.2103 17.2293 16.5 16.5 16.5Z" fill="#506CF9"/>
				<path d="M17.0956 29.5186L17.0818 29.5289L17.0687 29.54C16.9103 29.6756 16.7086 29.7501 16.5 29.7501C16.2914 29.7501 16.0897 29.6756 15.9313 29.54L15.6063 29.92L15.9337 29.5422L15.9314 29.5402C15.7169 29.3542 13.2259 27.1955 10.7877 24.1715C8.32032 21.1113 6 17.2766 6 13.75C6 10.9652 7.10625 8.29451 9.07538 6.32538C11.0445 4.35625 13.7152 3.25 16.5 3.25C19.2848 3.25 21.9555 4.35625 23.9246 6.32538C25.8938 8.29451 27 10.9652 27 13.75C27 17.2777 24.6957 21.1128 22.2376 24.1721C19.7935 27.214 17.2969 29.3691 17.0956 29.5186ZM14.6944 16.4523C15.2289 16.8094 15.8572 17 16.5 17C17.362 17 18.1886 16.6576 18.7981 16.0481C19.4076 15.4386 19.75 14.612 19.75 13.75C19.75 13.1072 19.5594 12.4789 19.2023 11.9444C18.8452 11.4099 18.3376 10.9934 17.7437 10.7474C17.1499 10.5014 16.4964 10.437 15.866 10.5624C15.2355 10.6878 14.6564 10.9974 14.2019 11.4519C13.7474 11.9064 13.4378 12.4855 13.3124 13.116C13.187 13.7464 13.2514 14.3999 13.4974 14.9937C13.7434 15.5876 14.1599 16.0952 14.6944 16.4523Z" stroke="white"/>
				</g>
				</g>
				<defs>
				<filter id="filter0_d_6345_6762" x="3.5" y="2.75" width="26" height="31.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix"/>
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
				<feOffset dy="2"/>
				<feGaussianBlur stdDeviation="1"/>
				<feComposite in2="hardAlpha" operator="out"/>
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6345_6762"/>
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6345_6762" result="shape"/>
				</filter>
				<clipPath id="clip0_6345_6762">
				<rect width="33" height="33" fill="white"/>
				</clipPath>
				</defs>
				</svg>
						`,
		className: 'text-color-orange',
		iconSize: [24, 40],
		iconAnchor: [12, 40],
		// className:
		// 	'd-flex justify-content-center align-items-center text-color-white bg-blue-300 fw-bold fs-6 p-4 rounded-circle',
	});
};

const customIconClusterOrange = (text) => {
	console.log(text);
	return L.divIcon({
		html: `${text} <svg xmlns="http://www.w3.org/2000/svg" width="47" height="58" viewBox="0 0 47 58" fill="none">
				<g filter="url(#filter0_d_2746_3089)">
				<path d="M23.3469 0.632812C17.7879 0.632813 12.4566 2.84943 8.5258 6.79503C4.59501 10.7406 2.38672 16.092 2.38672 21.6719C2.38672 35.8734 20.8578 51.9157 21.6438 52.5995C22.1184 53.0069 22.7224 53.2308 23.3469 53.2308C23.9713 53.2308 24.5753 53.0069 25.0499 52.5995C25.9669 51.9157 44.307 35.8734 44.307 21.6719C44.307 16.092 42.0987 10.7406 38.1679 6.79503C34.2371 2.84943 28.9058 0.632813 23.3469 0.632812ZM23.3469 26.9317C22.3105 26.9317 21.2974 26.6232 20.4356 26.0453C19.5739 25.4673 18.9023 24.6459 18.5057 23.6848C18.1091 22.7237 18.0053 21.6661 18.2075 20.6458C18.4097 19.6255 18.9088 18.6883 19.6416 17.9527C20.3744 17.2171 21.3081 16.7162 22.3246 16.5132C23.341 16.3103 24.3946 16.4144 25.3521 16.8125C26.3096 17.2106 27.128 17.8848 27.7038 18.7498C28.2796 19.6147 28.5869 20.6317 28.5869 21.6719C28.5869 23.0669 28.0348 24.4048 27.0521 25.3912C26.0694 26.3776 24.7366 26.9317 23.3469 26.9317Z" fill="#EFAE06"/>
				<path d="M24.751 52.1986L24.7372 52.2089L24.7242 52.2201C24.3401 52.5498 23.8516 52.7308 23.3469 52.7308C22.8421 52.7308 22.3536 52.5498 21.9696 52.2201L21.9677 52.2185C21.5524 51.8573 16.7818 47.7075 12.1103 41.8917C7.40208 36.0301 2.88672 28.5874 2.88672 21.6719C2.88672 16.224 5.04279 10.9996 8.88002 7.14792C12.7172 3.29629 17.9211 1.13281 23.3469 1.13281C28.7726 1.13281 33.9765 3.29629 37.8137 7.14792C41.6509 10.9996 43.807 16.224 43.807 21.6719C43.807 28.5885 39.3232 36.0317 34.632 41.8924C29.9545 47.7358 25.1702 51.886 24.751 52.1986ZM20.1571 26.4605C21.1011 27.0937 22.2112 27.4317 23.3469 27.4317C24.8698 27.4317 26.33 26.8244 27.4063 25.7441C28.4826 24.6637 29.0869 23.1989 29.0869 21.6719C29.0869 20.5332 28.7505 19.4198 28.12 18.4727C27.4895 17.5255 26.5932 16.787 25.5441 16.3509C24.495 15.9147 23.3405 15.8005 22.2267 16.0229C21.1129 16.2453 20.09 16.7942 19.2874 17.5998C18.4848 18.4055 17.9384 19.4317 17.717 20.5486C17.4957 21.6655 17.6093 22.8233 18.0435 23.8755C18.4777 24.9277 19.2132 25.8274 20.1571 26.4605Z" stroke="white"/>
				</g>
				<defs>
				<filter id="filter0_d_2746_3089" x="0.386719" y="0.632812" width="45.9203" height="56.5977" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix"/>
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
				<feOffset dy="2"/>
				<feGaussianBlur stdDeviation="1"/>
				<feComposite in2="hardAlpha" operator="out"/>
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2746_3089"/>
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2746_3089" result="shape"/>
				</filter>
				</defs>
				</svg> `,
		className: 'text-color-orange',
		iconSize: [24, 40],
		iconAnchor: [12, 40],
		// className:
		// 	'd-flex justify-content-center align-items-center text-color-white bg-blue-300 fw-bold fs-6 p-4 rounded-circle',
	});
};

const center = [-34.60376, -58.38162];
const zoom = 15;

function DisplayPosition({ map }) {
	const [position, setPosition] = useState(() => map.getCenter());

	const onClick = useCallback(() => {
		map.setView(center, zoom);
	}, [map]);

	const onMove = useCallback(() => {
		setPosition(map.getCenter());
	}, [map]);

	useEffect(() => {
		map.on('move', onMove);
		return () => {
			map.off('move', onMove);
		};
	}, [map, onMove]);

	return null;
}

export default function MapLayout({ selectedEstates, setSelectedEstates, fetchedData }) {
	const [map, setMap] = useState(null);
	const { mobileDesign } = React.useContext(ThemeContext);
	const [cordinate, setCordinate] = useState(center);
	const [loading, setLoading] = useState(true); // Inicialmente, se establece como cargando
	const [data, setData] = useState(fetchedData); // Inicialmente, se establece como cargando

	const handleFavoriteClick = (item) => {
		console.log(item);
		// Clonar el array de datos para evitar mutaciones directas
		const updatedData = [...data];

		// Encontrar el índice del elemento en el array
		const index = updatedData.findIndex((dataItem) => dataItem.id === item.id);

		// Actualizar la propiedad 'favorite' del elemento
		updatedData[index].favorite = !updatedData[index].favorite;

		// Actualizar el estado con los datos actualizados
		setData(updatedData);
	};

	useEffect(() => {
		// Verificar si fetchedData tiene al menos un elemento y si ese elemento tiene la propiedad coordinates
		if (data && data.length > 0 && data[0].coordinates) {
			setCordinate(data[0].coordinates);
		} else {
			setCordinate(center); // Si no hay coordenadas, utiliza center
		}

		// Una vez que se actualiza la coordenada, establece loading como falso
		setLoading(false);
	}, [data]);

	const displayMap = useMemo(
		() => (
			<MapContainer center={cordinate} zoom={zoom} scrollWheelZoom={false} ref={setMap}>
				{data ? (
					data.map((item) => (
						<Marker
							position={item.coordinates}
							icon={
								!item.favorite ? customIconCluster('') : customIconClusterOrange('')
							}
							key={item.id} // Asegurarse de proporcionar una clave única para cada marcador
						>
							<Popup>
								{' '}
								<div className='d-flex flex-column align-items-center w-80 h-100 p-3'>
									<div className='d-flex justify-content-start align-items-center w-100  text-color-dark pt-3 pb-2  sticky'>
										<h4 className='mb-0 fs-5 fw-700 px-2'>
											PROPIEDAD EN {item.address.toUpperCase()}
										</h4>
									</div>
									<div className='d-flex flex-column align-items-center w-100'>
										{/* estate map */}
										<div className='d-flex align-items-center w-100 justify-content-between  fw-600'>
											<div className='d-flex flex-column align-items-start justify-content-start '>
												<div className='d-flex align-items-center'>
													<p className={`fs-6 px-2 m-0`}>
														PRECIO: USD {item.price}
													</p>
												</div>
												<div className='d-flex align-items-center my-1'>
													<p className={`fs-6 px-2  m-0`}>
														AMBIENTES: {item.rooms}{' '}
													</p>
												</div>
												<div className='d-flex justify-content-start align-items-center '>
													<p className={`fs-6 px-2 m-0`}>
														SUPERFICIE TOTAL : {item.totalSurface} m²
													</p>
												</div>

												<div className='d-flex align-items-center mt-1'>
													<p className={`fs-6 px-2  m-0`}>
														ANTIGUEDAD: {item.antiquity} años
													</p>
												</div>
											</div>
										</div>
										<div className='d-flex align-items-center w-100   pt-4  fw-600'>
											<div className='d-flex  '>
												<div className='d-flex align-items-center'>
													<p
														className={`fs-5 px-2 m-0 text-success cursor-pointer`}
														onClick={() => {
															handleFavoriteClick(item);
														}}
													>
														Agregar a favoritos
													</p>
												</div>
												<div className='d-flex align-items-center'>
													<p
														className={`fs-5 fw-600 px-2 m-0 ms-4 text-danger cursor-pointer`}
													>
														Eliminar
													</p>
												</div>
											</div>
										</div>
										{/* estate map */}
									</div>
								</div>
							</Popup>
						</Marker>
					))
				) : (
					<p>Loading data...</p>
				)}

				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url='https://maps.geoapify.com/v1/tile/klokantech-basic/{z}/{x}/{y}.png?apiKey=035e61bc6b9948188ef5937de57bfe77'
				/>
			</MapContainer>
		),
		[selectedEstates, cordinate, fetchedData], // Agregar fetchedData como dependencia
	);

	return (
		<>
			{map ? <DisplayPosition map={map} /> : null}
			{loading ? <p>Loading data...</p> : displayMap}
		</>
	);
}
