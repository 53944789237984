import React from 'react';
import {
	UilFileMedical,
	UilTrashAlt,
	UilLink,
	UilPlus,
	UilRotate360,
	UilAngleLeftB,
} from '@iconscout/react-unicons';
import { FilePdf, FlagCheckered, YoutubeLogo } from 'phosphor-react';

export default function Multimedia({ estateData, setEstateData, setSelectedStep }) {
	const changeHandler = (event) => {
		const image = event.target.files[0];

		const imageURL = URL.createObjectURL(image);

		setEstateData((estateData) => ({
			...estateData,
			multimedia: {
				...estateData.multimedia,
				files: [...estateData.multimedia.files, imageURL],
			},
		}));
	};

	const deleteHandler = (image) => {
		setEstateData((estateData) => ({
			...estateData,
			multimedia: {
				...estateData.multimedia,
				files: estateData.multimedia.files.filter((img) => img !== image),
			},
		}));
	};

	return (
		<div className='d-flex flex-column align-items-center w-100 p-5'>
			<label
				htmlFor='file'
				className={`d-flex align-items-center justify-content-center min-vh-20 w-95 border-dashed rounded cursor-pointer`}>
				<UilFileMedical color='rgb(80, 108, 249, 0.4)' size={83} />
				<div className='d-flex flex-column'>
					<p className='fs-5 fw-normal-bold mb-0 text-color-lblue'>
						Soltar archivos aquí o haga click para cargar
					</p>
					<p className='fs-5 fw-normal-bold mb-0 text-color-lblue'>
						(Se aceptan archivos pdf, doc, zip, png, jpg, jpeg, bmp, csv, rar)
					</p>
				</div>
			</label>

			<input
				type='file'
				className='d-none'
				id='file'
				onChange={(event) => changeHandler(event)}
			/>

			<div className='d-flex flex-wrap justify-content-start w-100 my-4'>
				{estateData.multimedia.files?.map((image) => (
					<div
						style={{
							backgroundImage: `url(${image})`,
							backgroundSize: 'cover',
							height: '197px',
							width: '180px',
							position: 'relative',
						}}
						className='rounded m-4'>
						<div className='d-flex align-items-center justify-content-center bg-white text-color-lblue fw-bold px-4 py-2 rounded-5px position-absolute top-5 end-5 col-1'>
							{estateData.multimedia.files.indexOf(image) + 1}
						</div>

						<div
							className='d-flex align-items-center justify-content-center bg-white text-color-lblue fw-bold p-1 rounded-5px position-absolute top-5 start-5 cursor-pointer'
							onClick={() => deleteHandler(image)}>
							<UilTrashAlt color='#EF2206' size={24} />
						</div>
					</div>
				))}
			</div>

			<div className='d-flex align-items-center w-100'>
				<div className='d-flex align-items-center col px-3'>
					<div className='d-flex align-items-center p-3 bg-lblue rounded-start innerShadowBlue w-100'>
						<UilLink color='#506CF9' size={24} />
						<input
							className='no-outline text-color-lblue border-0 fs-5 add-field-input bg-transparent'
							placeholder='URL'
						/>
					</div>
					<div className='bg-blue-300 p-3 rounded-end ms-2'>
						<UilPlus color='#fff' size={24} />
					</div>
				</div>
				<div className='d-flex align-items-center col px-3'>
					<div className='d-flex align-items-center p-3 bg-lblue rounded-start innerShadowBlue w-100'>
						<UilRotate360 color='#506CF9' size={24} />
						<input
							className='no-outline text-color-lblue border-0 fs-5 add-field-input bg-transparent'
							placeholder='Tour 360º'
						/>
					</div>
					<div className='bg-blue-300 p-3 rounded-end ms-2'>
						<UilPlus color='#fff' size={24} />
					</div>
				</div>
				<div className='d-flex align-items-center col px-3'>
					<div className='d-flex align-items-center p-3 bg-lblue rounded-start innerShadowBlue w-100'>
						<FilePdf color='#506CF9' size={24} />
						<input
							className='no-outline text-color-lblue border-0 fs-5 add-field-input bg-transparent'
							placeholder='Seleccionar archivo'
						/>
					</div>
					<div className='bg-blue-300 p-3 rounded-end ms-2'>
						<UilPlus color='#fff' size={24} />
					</div>
				</div>
				<div className='d-flex align-items-center col px-3'>
					<div className='d-flex align-items-center p-3 bg-lblue rounded-start innerShadowBlue w-100'>
						<YoutubeLogo color='#506CF9' size={24} />
						<input
							className='no-outline text-color-lblue border-0 fs-5 add-field-input bg-transparent'
							placeholder='Enlace de Youtube'
						/>
					</div>
					<div className='bg-blue-300 p-3 rounded-end ms-2'>
						<UilPlus color='#fff' size={24} />
					</div>
				</div>
			</div>

			<div className='d-flex align-items-center justify-content-between w-100 my-5 py-4'>
				<div
					className='d-flex align-items-center bg-blue-300 px-3 py-2 rounded cursor-pointer'
					onClick={() => setSelectedStep('measures')}>
					<UilAngleLeftB color='#fff' size={24} />
					<p className='mb-0 text-color-white fw-bold'>Atrás</p>
				</div>
				<div className='d-flex align-items-center justify-content-center py-2 px-4 blue-gradient-buttons rounded cursor-pointer'>
					<FlagCheckered color='#fff' size={24} />
					<p className='mb-0 ms-2 text-color-white fs-5 fw-bold'>Finalizar</p>
				</div>
			</div>
		</div>
	);
}
