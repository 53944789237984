import React from 'react';
import {
	UilSchedule,
	UilAngleLeft,
	UilAngleRight,
	UilUser,
	UilEstate,
	UilClipboardAlt,
} from '@iconscout/react-unicons';

export default function SectionAssets() {
	return (
		<div className='d-flex align-items-center justify-content-between w-100 p-4'>
			{/* calendario */}
			<div className='d-flex'>
				<div className='d-flex align-items-center bg-blue-300 text-color-white rounded-5px py-1 px-3 cursor-pointer'>
					<UilSchedule size={24} color='#fff' className='me-2' />
					Calendario
				</div>
				{/* calendario */}

				{/* mostrar por */}
				<div className='d-flex align-items-center px-5'>
					<p className='fw-normal-bold mb-0 fs-5 me-4'>Mostrar por:</p>
					<div className='d-flex align-items-center justify-content-between bg-blue-icon text-color-white rounded-5px py-1 cursor-pointer vw-5'>
						<UilAngleLeft color='#fff' />
						Mes
						<UilAngleRight color='#fff' />
					</div>
					<p className='mb-0 px-4'>Semana</p>
					<div className='d-flex align-items-center justify-content-between text-color-dark rounded-5px py-1 cursor-pointer vw-5'>
						<UilAngleLeft color='#506CF9' />
						Hoy
						<UilAngleRight color='#506CF9' />
					</div>
				</div>
				{/* mostrar por */}
			</div>
			{/* filtrar por */}
			<div className='d-flex align-items-center'>
				<p className='fw-normal-bold mb-0 fs-5 me-4'>Filtrar por:</p>
				<div className='d-flex align-items-center justify-content-center bg-white rounded-5px py-2 cursor-pointer vw-5 me-3'>
					<UilUser color='#0030B8' size={20} className='me-2' />
					Operador
				</div>
				<div className='d-flex align-items-center justify-content-center bg-blue-icon text-color-white rounded-5px py-2 cursor-pointer vw-5 me-3'>
					<UilEstate color='#fff' size={20} className='me-2' />
					Inmueble
				</div>
				<div className='d-flex align-items-center justify-content-center bg-blue-icon text-color-white rounded-5px py-2 cursor-pointer vw-5 me-3'>
					<UilUser color='#fff' size={20} className='me-2' />
					Persona
				</div>
				<div className='d-flex align-items-center justify-content-center bg-white rounded-5px py-2 cursor-pointer vw-5 me-3'>
					<UilClipboardAlt color='#0030B8' size={20} className='me-2' />
					Tarea
				</div>
			</div>
			{/* filtrar por */}
		</div>
	);
}
