import React, { useRef } from 'react'
import CustomDropdown from '../../../components/CustomDropdown'
import {
    UilUser,
    UilEstate,
    UilEditAlt,
    UilAngleDown,

} from '@iconscout/react-unicons';
import Check from '../../../components/Check';

const CommonFilterHeadStatusCustomers = ({filtersApplied ,setFiltersApplied, branches ,operatorList , incomeTypes , sort}) => {

    const debounceRef = useRef(null);

    const appliFilter = (fieldValue, id) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }
    
        debounceRef.current = setTimeout(() => {
            setFiltersApplied((prevFiltersApplied) => {
                if (prevFiltersApplied[fieldValue]) {
                    if (prevFiltersApplied[fieldValue].includes(id)) {
                        return {
                            ...prevFiltersApplied,
                            [fieldValue]: prevFiltersApplied[fieldValue].filter(existingId => existingId !== id),
                        };
                    } else {
                        return {
                            ...prevFiltersApplied,
                            [fieldValue]: [...prevFiltersApplied[fieldValue], id],
                        };
                    }
                } else {
                    return {
                        ...prevFiltersApplied,
                        [fieldValue]: [id],
                    };
                }
            });
        }, 500);
    };

    const appliFilterSort = (fieldValue) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            setFiltersApplied((prevFiltersApplied) => ({
                ...prevFiltersApplied,
                    sort: {
                        field: fieldValue,
                        order: 'desc'
                    }
            }));
        }, 500);
    }


  return (
            <span className='d-flex gap-2 justify-content-center align-items-center'><strong>Filtrar por: </strong>

            {/* filtro sucursales */}
            {branches && (
                <CustomDropdown
                iconColor='#fff'
                label={'Sucursales'}
                arrow={false}
                isRounded={false}
                icon={<UilEstate size={20} color={` ${filtersApplied['branch_id']?.length > 0 ? '#ffffff' : '#0030b8'}`} />}
                classname={` ${filtersApplied['branch_id']?.length > 0 ? 'bg-blue-icon text-color-white' : 'bg-custom-newGray'}  px-4 py-2 w-100  fs-xxl-5 fs-xl-6 cursor-pointer fw-normal-bold gap-3
                rounded-5px
                `}
                dropdownClassname={
                    'd-flex flex-column align-items-start justify-content-center text-color-lblue rounded-bottom w-100 position-absolute shadow'
                }
                closeAfterClick>
                {branches.data?.map((branch, index) => (
                    <div
                        className={`d-flex py-2 fs-6   justify-content-between  fw-normal-bold cursor-pointer custom-dropdown-item w-100 px-3 bg-lblue text-color-lblue   ${index === branches.data?.data?.length - 1 && 'rounded-bottom'
                    }`}
                    key={index}
                    onClick={(e) => appliFilter('branch_id', branch.id)}
                    >
                        <div className='text-nowrap text-truncate overflow-hidden'>
                            {branch.social_name}
                        </div>
                        <div>
                            <Check
                                checked={filtersApplied['branch_id']?.includes(branch.id)}
                                />
                        </div>
                    </div>
                ))}
            </CustomDropdown>
            )}
            {/* filtro sucursales hasta aca*/}

            {/* filtro operadores*/}
            {operatorList && (

            <CustomDropdown
                iconColor='#fff'
                label={'Operadores'}
                arrow={false}
                isRounded={false}
                icon={<UilUser size={20} color={` ${filtersApplied['seller']?.length > 0 ? '#ffffff' : '#0030b8'}`} />}
                classname={` ${filtersApplied['seller']?.length > 0 ? 'bg-blue-icon text-color-white' : 'bg-custom-newGray'}  px-4 py-2 w-100  fs-xxl-5 fs-xl-6 cursor-pointer fw-normal-bold gap-3
                                rounded-5px
                                `}
                dropdownClassname={
                    'd-flex flex-column align-items-start justify-content-center text-color-lblue rounded-bottom w-100 position-absolute shadow'
                }
                closeAfterClick>
                {operatorList?.map((operator, index) => (
                    <div
                        className={`d-flex py-2 fs-6 justify-content-between fw-normal-bold cursor-pointer custom-dropdown-item w-100 px-3 bg-lblue bg-danger text-color-lblue ${index === operatorList.length - 1 && 'rounded-bottom'}`}
                        key={index}
                        onClick={(e) => appliFilter('seller', operator.id)}>
                        <div className='text-nowrap text-truncate overflow-hidden'>
                            {operator.name} {operator.surname}
                        </div>
                        <div>
                            <Check
                                checked={filtersApplied['seller']?.includes(operator.id)}
                            />
                        </div>
                    </div>
                ))}
            </CustomDropdown>
            )}

            {/* filtro Operadores hasta aca  */}

            {/* filtro Forma de ingreso */}
            { incomeTypes && (

            <CustomDropdown
                iconColor='#fff'
                label={'Forma de ingreso'}
                arrow={false}
                isRounded={false}
                icon={<UilEditAlt size={20} color={` ${filtersApplied['incomeType']?.length > 0 ? '#ffffff' : '#0030b8'}`}/>}
                classname={`${filtersApplied['incomeType']?.length > 0 ? 'bg-blue-icon text-color-white' : 'bg-custom-newGray'}  px-4 py-2 w-100  fs-xxl-5 fs-xl-6 cursor-pointer fw-normal-bold gap-3 rounded-5px`}
                dropdownClassname={'d-flex flex-column align-items-start justify-content-center text-color-lblue rounded-bottom w-100 position-absolute shadow'}
                closeAfterClick>
                {incomeTypes?.map((incomeType, index) => (
                    <div
                        className={`d-flex py-2 fs-6   justify-content-between  fw-normal-bold cursor-pointer custom-dropdown-item w-100 px-3 bg-lblue bg-danger text-color-lblue   ${index === incomeTypes?.length - 1 && 'rounded-bottom'}`}
                        key={index}
                        onClick={(e) => appliFilter('incomeType', incomeType.id)}>
                        <div className='text-nowrap text-truncate overflow-hidden'>{incomeType.name}</div>
                        <div><Check checked={filtersApplied['incomeType']?.includes(incomeType.id)}/></div>
                    </div>
                ))}
            </CustomDropdown>
        )}
            {/* filtro Forma de ingreso hasta aca  */}
            {sort && (
                <>
                    <div
                        className='h-100 d-flex align-items-center'
                        rounded={1}
                        data-bs-toggle='dropdown'
                        aria-expanded='false'>
                        <div className='d-flex align-items-center cursor-pointer'><p className=' user-select-none my-0 ms-2 fw-bold'>Ordenar por <UilAngleDown size={24} color='#506cf9'/></p></div>
                    </div>
                    
                    <div
                        className='bg-lblue rounded dropdown-menu  shadow text-color-black-lighter'
                        style={{ width: '15rem' }}>
                        <div className='d-flex align-items-center p-2 fs-6 cursor-pointer custom-dropdown-item'
                            onClick={(e) => appliFilterSort('join_date')}>
                            <p className='m-0 ps-2'>Fecha de ingreso</p>
                        </div>
                        <div className='d-flex align-items-center p-2 fs-6 cursor-pointer custom-dropdown-item'
                            onClick={(e) => appliFilterSort('activities')}>
                            <p className='m-0 ps-2'>Última tarea</p>
                        </div>
                        <div className='d-flex align-items-center p-2 fs-6 cursor-pointer custom-dropdown-item'
                            onClick={(e) => appliFilterSort('properties')}>
                            <p className='m-0 ps-2'>Propiedad</p>
                        </div>
                    </div>
                </>

            )}

        </span>
  )
}

export default CommonFilterHeadStatusCustomers