import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import UserContext from '../contexts/userContext';
import { getCustomersFiltered } from '../services/customersServices';

export default function useCustomers(filters, section) {
	const { token } = useContext(UserContext);

	const customers = useQuery({
		queryKey: [filters, section],
		queryFn: () => getCustomersFiltered(filters, token),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});

	return customers;
}
