import classNames from 'classnames';
import { useContext } from 'react';
import ThemeContext from '../../contexts/themeContext';
import { UilSearchAlt, UilSearch } from '@iconscout/react-unicons';

export default function TableAssets() {
	const { darkModeStatus } = useContext(ThemeContext);

	return (
		<div className='d-flex align-items-start justify-content-between w-100 p-4'>
			<div
				style={{ position: 'relative', top: '-1.5rem', left: '11rem' }}
				className={`d-flex align-items-center justify-content-center bg-blue-300 py-2 px-3 rounded-bottom mx-2 cursor-pointer`}>
				<UilSearchAlt color='#95B1FE' />
				<h5 className='fw-bold mb-0 mx-2 text-color-white fs-xxl-5 fs-xl-6'>
					9 RESULTADOS
				</h5>
			</div>

			<div
				className={classNames(
					'd-flex align-items-center rounded-pill py-1 px-2',
					{ 'search-input-header-dark': darkModeStatus },
					{ 'search-input-header': !darkModeStatus },
				)}>
				<label
					className='border-0 bg-transparent cursor-pointer me-0 ms-2'
					htmlFor='searchInput'>
					<UilSearch
						// color={darkModeStatus ? '#A9B5D7' : '#01227d'}
						color='#506CF9'
						size={12}
					/>
				</label>
				<input
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent input-search-customers py-2 no-outline'
					placeholder='Buscar...'
				/>
			</div>
		</div>
	);
}
