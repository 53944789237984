import { Gear, Key, ListBullets, MapTrifold, SlidersHorizontal } from 'phosphor-react';
import React, { useContext, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import ThemeContext from '../../../contexts/themeContext';
import {
	UilSearch,
	UilSearchAlt,
	UilGlobe,
	UilEllipsisH,
	UilCheck,
	UilLocationPoint,
	UilBookmark,
	UilBed,
	UilMoneyBill,
	UilEnvelopeUploadAlt,
	UilWhatsapp,
	UilDesktop,
	UilImport,
	UilClipboardNotes,
	UilUserSquare,
	UilWindow,
	UilEdit,
	UilTrash,
	UilExternalLinkAlt,
	UilSortAmountDown,
	UilSortAmountUp,
	UilTrafficBarrier,
	UilConstructor,
} from '@iconscout/react-unicons';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import MapLayout from './MapLayout';
import FiltersMenu from './FiltersMenu';

function Active({ filtersMenu, setFiltersMenu }) {
	const menuItems = [
		{
			name: 'Foto',
			nameEng: 'photo',
			isActive: true,
			isSortable: false,
		},
		{
			name: 'Nombre',
			nameEng: 'name',
			isActive: true,
			isSortable: true,
		},
		{
			name: 'Tipo',
			nameEng: 'type',
			isActive: true,
			isSortable: false,
		},
		{
			name: 'Estado',
			nameEng: 'state',
			isActive: true,
			isSortable: false,
		},
		{
			name: 'Categoría',
			nameEng: 'category',
			isActive: true,
			isSortable: false,
		},
		{
			name: 'Entrega',
			nameEng: 'delivery',
			isActive: true,
			isSortable: false,
		},
		{
			name: 'Unidades',
			nameEng: 'units',
			isActive: true,
			isSortable: false,
		},
		{
			name: 'Localidad',
			nameEng: 'city',
			isActive: true,
			isSortable: false,
		},
	];

	const fetchedData = [
		{
			isMultiple: true,
			name: 'Altos de la sierra',
			city: 'Capital Federal',
			delivery: '24/04/2023',
			units: '3 disponibles',
			state: 'En pozo',
			type: 'Edificio',
			category: 'Excelente',
			estateCount: 5,
			photo: 'https://arquitecturayempresa.es/sites/default/files/content/arquitectura_rehabilitacion_amsterdam-235_000.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '50000',
			location: 'Av. Siempreviva 742',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 3,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [25],
			id: 995,
			position: [-34.600556, -58.395],
		},
		{
			isMultiple: false,
			name: 'Arribeños Loft',
			city: 'Capital Federal',
			delivery: '24/04/2023',
			units: '3 disponibles',
			state: 'Terminado',
			type: 'Condominio',
			category: 'Muy buena',
			estateCount: 1,
			photo: 'https://www.construyehogar.com/wp-content/uploads/2014/08/Dise%C3%B1o-de-casa-moderna-de-una-planta.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '60000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 4,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [50],
			id: 996,
			position: [-34.578611, -58.420861],
		},
		{
			isMultiple: false,
			name: 'Miramar Boutique',
			city: 'Capital Federal',
			delivery: '24/04/2023',
			units: '2 disponibles',
			state: 'En producción',
			type: 'Complejo',
			category: 'Buena',
			estateCount: 1,
			photo: 'https://www.nocnok.com/hubfs/casa-lujo.webp',
			operation: 'Venta',
			currency: 'USD',
			value: '75000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 5,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [75],
			id: 997,
			position: [-34.60376, -58.38162],
		},
		{
			isMultiple: false,
			name: 'Upgrade II',
			city: 'Capital Federal',
			delivery: '24/04/2023',
			units: '1 disponible',
			state: 'En pozo',
			type: 'Edificio',
			category: 'Excelente',
			estateCount: 1,
			photo: 'https://www.construyehogar.com/wp-content/uploads/2014/08/Dise%C3%B1o-de-casa-moderna-de-una-planta.jpg',
			operation: 'Venta',
			currency: 'USD',
			value: '100000',
			location: 'Calle falsa 123',
			neighborhood: 'Palermo',
			bedrooms: 3,
			bathrooms: 2,
			area: '200m2',
			rooms: 6,
			registeredAt: '10/1/2023',
			estateType: 'Casa',
			roofArea: '150m2',
			quality: [88],
			id: 998,
			position: [-34.588056, -58.393056],
		},
	];

	const { mobileDesign, darkModeStatus } = useContext(ThemeContext);

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const [selectedEstates, setSelectedEstates] = useState([]);

	const [showMap, setShowMap] = useState(false);

	const [thList, setThList] = useState(menuItems);

	const clickHandler = (item) => {
		const menu = [...thList];
		const index = menu.indexOf(item);
		menu[index] = { ...item, isActive: !item.isActive };
		setThList(menu);
	};

	const [sortType, setSortType] = useState({
		field: null,
		order: null,
	});

	const sortHandler = (item) => {
		if (!item) {
			return;
		} else if (sortType.field !== item) {
			setSortType({ field: item, order: 'asc' });
		} else if (sortType.field === item && sortType.order === 'asc') {
			setSortType({ field: item, order: 'des' });
		} else if (sortType.field === item && sortType.order === 'des') {
			setSortType({ field: null, order: null });
		}
	};

	const getSortIcon = (item) => {
		if (sortType.field === item && sortType.order === 'asc') {
			return <UilSortAmountDown size={20} color='#506cf9' />;
		} else if (sortType.field === item && sortType.order === 'des') {
			return <UilSortAmountUp size={20} color='#506cf9' />;
		} else {
			return null;
		}
	};

	const [filtersApplied, setFiltersApplied] = useState({});

	return filtersMenu ? (
		<FiltersMenu
			filtersApplied={filtersApplied}
			setFiltersApplied={setFiltersApplied}
			setFiltersMenu={setFiltersMenu}
		/>
	) : (
		<div className='d-flex flex-column'>
			{/* Buttons */}
			{!mobileDesign && (
				<div className='d-flex align-items-center justify-content-between w-100'>
					<div className='d-flex align-items-center ms-4'>
						{selectedEstates.length > 0 && (
							<>
								<Button
									className=''
									color='primary'
									rounded='1'
									data-bs-toggle='dropdown'
									aria-expanded='false'>
									<div className='d-flex align-items-center'>
										<Gear color='#fff' size={20} />
										<p className='my-0 ms-2'>Acciones</p>
									</div>
								</Button>

								<div
									className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
									style={{ width: '20rem' }}>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilEnvelopeUploadAlt />{' '}
										<p className='m-0 ps-2'>Enviar mail</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilWhatsapp /> <p className='m-0 ps-2'>Enviar Whatsapp</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilDesktop />{' '}
										<p className='m-0 ps-2'>Generar cartelera dinámica</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilImport />{' '}
										<p className='m-0 ps-2'>Descargar / exportar</p>
									</div>
									<div
										onClick={() => setModalStatus(!modalStatus)}
										className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilClipboardNotes />{' '}
										<p className='m-0 ps-2'>Generar tarea</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilUserSquare />{' '}
										<p className='m-0 ps-2'>Ver interesados</p>
									</div>
									<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
										<UilWindow />{' '}
										<p className='m-0 ps-2'>Publicar en portales</p>
									</div>
								</div>
							</>
						)}
						{/* Search input */}
						<div
							className={`d-flex align-items-center rounded-pill py-1 px-2 ${
								selectedEstates.length > 0 && 'ms-4'
							} bg-white`}>
							<label
								className='border-0 bg-white cursor-pointer me-0 ms-2'
								htmlFor='searchInput'>
								<UilSearch color='#506CF9' size={18} />
							</label>
							<Input
								id='searchInput'
								type='search'
								className='border-0 shadow-none input-search-customers bg-white'
								placeholder='Buscar...'
							/>
						</div>
						{/* Search input */}
					</div>
					<div className='d-flex align-items-center me-4'>
						{!showMap && (
							<Button
								className=''
								color='primary'
								rounded={1}
								data-bs-toggle='dropdown'
								onClick={() => setOffcanvasStatus(!offcanvasStatus)}
								aria-expanded='false'>
								<div className='d-flex align-items-center'>
									<SlidersHorizontal color='#fff' size={20} />
									<p className='my-0 ms-2'>Personalizar</p>
								</div>
							</Button>
						)}
						{showMap ? (
							<Button
								className='ms-2'
								color='primary'
								rounded={1}
								data-bs-toggle='dropdown'
								aria-expanded='false'
								onClick={() => setShowMap(false)}>
								<div className='d-flex align-items-center'>
									<ListBullets color='#fff' size={20} />
									<p className='my-0 ms-2'>Listado</p>
								</div>
							</Button>
						) : (
							<Button
								className='ms-2'
								color='primary'
								rounded={1}
								data-bs-toggle='dropdown'
								aria-expanded='false'
								onClick={() => setShowMap(true)}>
								<div className='d-flex align-items-center'>
									<MapTrifold color='#fff' size={20} />
									<p className='my-0 ms-2'>Mapa</p>
								</div>
							</Button>
						)}
					</div>
					{/* Drawer right */}
					<OffCanvas
						id='notificationCanvas'
						titleId='offcanvasExampleLabel'
						placement='end'
						isOpen={offcanvasStatus}
						setOpen={setOffcanvasStatus}>
						<OffCanvasHeader setOpen={setOffcanvasStatus} className='bg-blue-300 p-4'>
							<div className='w-100 d-flex justify-content-center'>
								<h4 className='m-0 text-color-white fw-bold'>Mostrar / Ocultar</h4>
							</div>
						</OffCanvasHeader>
						<OffCanvasBody className='p-0'>
							{/* Search input */}
							<div className='d-flex align-items-center py-4 px-3 w-100 search-input-drawer'>
								<label
									className='border-0 bg-transparent cursor-pointer me-0'
									htmlFor='searchInput'>
									<UilSearch
										color={darkModeStatus ? '#A9B5D7' : '#01227d'}
										size={20}
									/>
								</label>
								<Input
									id='searchInput'
									type='search'
									className='border-0 shadow-none bg-transparent input-search'
									placeholder='Buscar...'
								/>
							</div>
							{/* Search input */}
							<div className='d-flex align-items-center w-100 bg-blue-100 p-3'>
								<h5 className='m-0 text-color-lblue fw-bold'>Mostrar</h5>
							</div>
							<div className='d-flex flex-column w-100 p-3'>
								{thList
									.filter((item) => item.isActive === true)
									.map((item) => (
										<div
											onClick={() => clickHandler(item)}
											key={item.name}
											className='d-flex align-items-center justify-content-between my-2 w-100 cursor-pointer'>
											<div className='d-flex align-items-center'>
												{/* {item.icon(true)} */}
												<p className='m-0 text-color-lblue fs-5 fw-bold px-2'>
													{typeof item.name === 'object' ? (
														<p>
															{item.name[0]} / {item.name[1]}
														</p>
													) : (
														item.name
													)}
													{/* {item.name} */}
												</p>
											</div>
											<UilCheck color='#506CF9' size={35} />
										</div>
									))}
							</div>

							<div className='d-flex align-items-center w-100 bg-new-gray p-3'>
								<h5 className='m-0 text-color-dark fw-bold'>Añadir</h5>
							</div>
							<div className='d-flex flex-column w-100 p-3'>
								{thList
									.filter((i) => i.isActive === false)
									.map((menuItem) => (
										<div
											onClick={() => clickHandler(menuItem)}
											key={menuItem.name}
											className='d-flex align-items-center justify-content-between my-3 w-100 cursor-pointer'>
											<div className='d-flex align-items-center'>
												{/* {menuItem.icon(false)} */}
												<p className='m-0 text-color-dark fs-5 fw-bold px-2'>
													{menuItem.name}
												</p>
											</div>
										</div>
									))}
							</div>
						</OffCanvasBody>
					</OffCanvas>
					{/* Drawer right */}
				</div>
			)}
			{/* Buttons */}

			<div className='d-flex flex-column min-vh-50 bg-white mt-4 mx-4 rounded-3 shadow'>
				{/* Inmuebles, resultados, red compartida */}
				{!mobileDesign && !showMap && (
					<div className='d-flex align-items-center justify-content-between position-relative mx-4 my-3 col-xl-6 col-xxl-5'>
						<div className='d-flex align-items-center ms-4'>
							<UilConstructor color='#506cf9' size={30} />
							<h5 className='fw-bold m-0 text-color-lblue'>EMPRENDIMIENTOS</h5>
						</div>

						<div
							className='d-flex align-items-start position-relative w-50'
							style={{ top: '-1rem' }}>
							<div className='d-flex align-items-center justify-content-center bg-blue-300 py-2 px-3 rounded-bottom mx-2 shadow-blue'>
								<UilSearchAlt size={25} color='#95B1FE' />
								<h6 className='fw-bold m-0 text-color-white'>500 RESULTADOS</h6>
							</div>
						</div>
					</div>
				)}
				{/* Inmuebles, resultados, red compartida */}
				{/* Tabla */}

				{showMap ? (
					<div className='w-100 h-100'>
						<MapLayout
							selectedEstates={selectedEstates}
							setSelectedEstates={setSelectedEstates}
							fetchedData={fetchedData}
						/>
					</div>
				) : mobileDesign ? (
					fetchedData.map((estate) => (
						<div className={`d-flex my-4 position-relative`} key={estate.location}>
							<div className='d-flex flex-column align-items-center w-100'>
								<div className='d-flex align-items-start justify-content-center px-4 w-100'>
									<div
										className='rounded'
										style={{
											backgroundImage: `url(${estate.photo})`,
											backgroundSize: 'cover',
											// height: mobileDesign ? '146px' : '184px',
											height: '184px',
											// width: '242px',
											width: '100%',
											position: 'relative',
										}}>
										<div
											className='bg-blue-300 py-1 px-2 text-color-white'
											style={{
												position: 'absolute',
												left: 10,
												top: 10,
												borderRadius: '5px',
											}}>
											<p className='m-0'>{estate.id}</p>
										</div>

										<div
											className='d-flex justify-content-center position-absolute bg-blue-300 rounded-5px px-1'
											style={{
												right: 10,
												top: 10,
											}}>
											<UilEllipsisH
												color='#fff'
												size={25}
												id={`a${estate.id}`}
												data-bs-toggle='dropdown'
												aria-expanded='false'
											/>
											{/* Dropdown */}
											<div
												className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
												aria-labelledby={`a${estate.id}`}>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilEdit /> <p className='m-0 ps-2'>Editar</p>
												</div>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilTrash /> <p className='m-0 ps-2'>Borrar</p>
												</div>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilExternalLinkAlt />{' '}
													<p className='m-0 ps-2'>Ver mas</p>
												</div>
											</div>
											{/* Dropdown */}
										</div>
									</div>
								</div>
								<div className='d-flex justify-content-start align-items-center w-100 mt-3 mb-2 ps-4'>
									{/* <UilLocationPoint color='#506CF9' /> */}
									<p className={`fs-5 px-2 fw-bold m-0`}>{estate.name}</p>
								</div>
								<div className='d-flex align-items-center justify-content-start ps-4 ms-2 my-1 w-100'>
									<div className='d-flex align-items-center'>
										<UilTrafficBarrier color='#506CF9' />
										<p
											className={`fs-11 fw-normal ps-2 ${
												mobileDesign ? 'me-2' : 'me-3'
											} m-0`}>
											{estate.state}
										</p>
									</div>
									<div className='d-flex align-items-center'>
										<Key size={20} color='#506CF9' />
										<p
											className={`fs-11 fw-normal ps-2 ${
												mobileDesign ? 'me-2' : 'me-3'
											} m-0`}>
											{estate.units}
										</p>
									</div>
									<div className='d-flex align-items-center'>
										<UilLocationPoint color='#506CF9' />
										<p
											className={`${
												mobileDesign ? '' : ' me-3'
											}fs-11 fw-normal ps-2 m-0`}>
											{estate.city}
										</p>
									</div>
								</div>
							</div>
						</div>
					))
				) : (
					<table>
						<thead className='bg-lblue'>
							<tr className='d-flex'>
								<th
									className='col py-2 text-color-lblue fw-bold'
									// style={{ width: '15rem' }}
								>
									<div className='d-flex align-items-center justify-content-center w-100 h-100'>
										{/* <Checks /> */}
										<label
											className='custom-checkbox'
											onClick={() => {
												if (selectedEstates.length === fetchedData.length) {
													setSelectedEstates([]);
												} else {
													setSelectedEstates(fetchedData);
												}
											}}>
											<UilCheck
												color={
													selectedEstates.length === fetchedData.length
														? '#506CF9'
														: '#fff'
												}
												size={20}
											/>
										</label>
									</div>
								</th>
								{thList
									.filter((th) => th.isActive === true)
									.map((th) => (
										<th className='col py-4 text-color-lblue fw-bold estateTh'>
											{typeof th.name === 'object' ? (
												<div className='d-flex flex-column align-items-start justify-content-center'>
													<p
														className={`m-0 ${
															th.isSortable[0] &&
															'cursor-pointer text-decoration-underline'
														}`}
														onClick={() =>
															sortHandler(
																th.isSortable[0] && th.nameEng[0],
															)
														}>
														{th.name[0]}{' '}
														{getSortIcon(
															th.isSortable[0] && th.nameEng[0],
														)}
													</p>
													<p
														className={`m-0 ${
															th.isSortable[1] &&
															'cursor-pointer text-decoration-underline'
														}`}
														onClick={() =>
															sortHandler(
																th.isSortable[1] && th.nameEng[1],
															)
														}>
														{th.name[1]}{' '}
														{getSortIcon(
															th.isSortable[1] && th.nameEng[1],
														)}
													</p>
												</div>
											) : (
												<div
													className={`d-flex align-items-center justify-content-start w-100 h-100 ${
														th.isSortable &&
														'cursor-pointer text-decoration-underline'
													}`}
													onClick={() =>
														sortHandler(th.isSortable && th.nameEng)
													}>
													{th.name}{' '}
													{getSortIcon(th.isSortable && th.nameEng)}
												</div>
											)}
										</th>
									))}
							</tr>
						</thead>
						{/* <tbody> */}
						{fetchedData.map((item) => (
							<tr className='d-flex py-2 align-items-center border-bottom'>
								<td className='col pe-2'>
									<div className='d-flex align-items-center justify-content-center w-100 h-100 position-relative'>
										<label
											className='mx-4 custom-checkbox'
											onClick={() => {
												if (
													selectedEstates.some(
														(estate) => estate.id === item.id,
													)
												) {
													setSelectedEstates(
														selectedEstates.filter(
															(estate) => estate.id !== item.id,
														),
													);
												} else {
													setSelectedEstates((selectedEstates) => [
														...selectedEstates,
														item,
													]);
												}
											}}>
											<UilCheck
												color={
													selectedEstates.some(
														(estate) => estate.id === item.id,
													)
														? '#506CF9'
														: '#fff'
												}
												size={20}
											/>
										</label>

										<div
											className='position-absolute'
											style={{ marginLeft: '6rem' }}>
											<UilEllipsisH
												color='#506CF9'
												size={25}
												className='cursor-pointer'
												id={`a${item.id}`}
												data-bs-toggle='dropdown'
												aria-expanded='false'
											/>
											{/* Dropdown table row */}
											<div
												className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter'
												// style={{ minWidth: '10rem !important' }}
												aria-labelledby={`a${item.id}`}>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilEdit /> <p className='m-0 ps-2'>Editar</p>
												</div>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilTrash /> <p className='m-0 ps-2'>Borrar</p>
												</div>
												<div className='d-flex align-items-center p-2 fs-6 rounded-pill cursor-pointer custom-dropdown-item'>
													<UilExternalLinkAlt />{' '}
													<p className='m-0 ps-2'>Ver mas</p>
												</div>
											</div>
											{/* Dropdown table row */}
										</div>
									</div>
								</td>
								{thList
									.filter((th) => th.isActive === true)
									.map((th) => (
										<td className='col pe-2 fw-normal-bold fs-5'>
											{th.nameEng === 'state' ? (
												<div className='d-flex flex-column align-items-start justify-content-center'>
													<p className='m-0 text-color-white bg-yellow py-2 px-3 rounded fs-6'>
														{item[th.nameEng]}
													</p>
												</div>
											) : th.nameEng === 'photo' ? (
												<div className='d-flex flex-column align-items-start py-2'>
													<div
														className='rounded'
														style={{
															backgroundImage: `url(${item.photo})`,
															backgroundSize: 'cover',
															height: '5vw',
															width: '8vw',
															position: 'relative',
														}}>
														<div
															className='bg-blue-300 py-1 px-2 text-color-white'
															style={{
																position: 'absolute',
																right: 10,
																top: 10,
																borderRadius: '5px',
																fontSize: '10px',
															}}>
															<p className='m-0'>{item.id}</p>
														</div>
													</div>
												</div>
											) : th.nameEng === 'units' ? (
												<div className='d-flex flex-column align-items-start justify-content-center'>
													<p className='m-0 text-color-white bg-purple py-2 px-3 rounded fs-6'>
														{item[th.nameEng]}
													</p>
												</div>
											) : (
												item[th.nameEng]
											)}
										</td>
									))}
							</tr>
						))}
						{/* </tbody> */}
					</table>
				)}

				{/* Tabla */}
			</div>
			<div className='d-flex justify-content-center w-100'>
				{showMap && (
					<>
						<div className='d-flex justify-content-center align-items-center mx-2'>
							<p className='m-0 text-color-lblue fs-5 bg-white px-5 py-2 rounded-bottom shadow'>
								Mostrando 86 propiedades
							</p>
						</div>
					</>
				)}

				{selectedEstates.length > 0 && (
					<div className='d-flex justify-content-center align-items-center mx-2'>
						<p className='m-0 text-color-white fs-5 bg-blue-300 px-5 py-2 rounded-bottom shadow'>
							{selectedEstates.length} propiedades seleccionadas
						</p>
					</div>
				)}
			</div>
		</div>
	);
}

export default Active;
