import React from 'react';

const CustomFilter = (props) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='svg-icon'
			{...props}>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M17.9858 5.42367C14.0077 4.97906 9.99234 4.97906 6.01418 5.42367C5.74501 5.45376 5.61538 5.76941 5.78573 5.97998L9.30275 10.3275C10.5626 11.8849 11.25 13.8275 11.25 15.8307V18.7199L12.75 19.8199V15.8307C12.75 13.8275 13.4374 11.8849 14.6973 10.3275L18.2143 5.97998C18.3846 5.76941 18.255 5.45376 17.9858 5.42367ZM5.84757 3.93296C9.93646 3.47596 14.0635 3.47596 18.1524 3.93296C19.5994 4.09467 20.2962 5.79146 19.3805 6.92339L15.8634 11.2709C14.8195 12.5614 14.25 14.1709 14.25 15.8307V21.3C14.25 21.5826 14.0911 21.8413 13.839 21.969C13.5869 22.0968 13.2844 22.0719 13.0565 21.9048L10.0565 19.7048C9.86382 19.5635 9.75 19.3389 9.75 19.1V15.8307C9.75 14.1709 9.18048 12.5614 8.13656 11.2709L4.61954 6.92339C3.70385 5.79146 4.40064 4.09467 5.84757 3.93296Z'
				fill='#fff'
			/>
		</svg>
	);
};

export default CustomFilter;
