import React, { useContext } from 'react';
import classNames from 'classnames';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import ThemeContext from '../../../contexts/themeContext';
import useUser from '../../../hooks/useUser';

const CommonHeaderChat = () => {
	const { logout } = useUser();
	const { darkModeStatus } = useContext(ThemeContext);

	const user = JSON.parse(sessionStorage.getItem('userData'));

	return (
		<>
			<div className='col d-flex align-items-center cursor-pointer'>
				<div className='me-3'>
					<div className='text-end'>
						<div
							className={classNames('username mb-0', {
								'text-color-white': darkModeStatus,
							})}>
							{`${user?.name} ${user?.surname}`}
						</div>
						<div
							className={classNames('username mb-0', {
								'text-color-white': darkModeStatus,
							})}>
							Company: {user?.data?.company.code}
						</div>
					</div>
				</div>
				<div className='position-relative ms-3'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Avatar
								srcSet={USERS.CHLOE.srcSet}
								src={USERS.CHLOE.src}
								size={48}
								color={USERS.CHLOE.color}
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd className='my-1' isCloseAfterLeave={false}>
							<Button
								tag='a'
								className='dropdown-item'
								to={`/`}
								onClick={() => logout()}>
								Logout
							</Button>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
		</>
	);
};

export default CommonHeaderChat;
