import { FolderOpen } from 'phosphor-react';
import { UilUpload, UilCloudDownload, UilTrashAlt } from '@iconscout/react-unicons';
import React, { useContext, useState } from 'react';
import ThemeContext from '../../../contexts/themeContext';
import { deleteFileCustomer , downloadFileCustomer} from '../../../services/customersServices';
import UserContext from '../../../contexts/userContext';
import Toast from '../../../components/Toast/Toast';
import { confirmationSwal } from '../../../utils/confirmationSwal';
import { showToast } from '../../../components/Toast/toastUtils/toastUtils';
// import ProrgessBar from '../../../utils/prorgessBar';
import MyDropzone from './MyDropzone';
import pdf from '../../../assets/img/fileExtension/pdf.svg'
import doc from '../../../assets/img/fileExtension/doc.svg'
import zip from '../../../assets/img/fileExtension/zip.svg'
import rar from '../../../assets/img/fileExtension/rar.svg'
import bmp from '../../../assets/img/fileExtension/bmp.svg'
import csv from '../../../assets/img/fileExtension/csv.svg'
import def from '../../../assets/img/fileExtension/default.svg'



const Files = ({summary , setFiles , files}) => {
	const { mobileDesign } = useContext(ThemeContext);
	const { token } = useContext(UserContext);
	const [list, setList] = useState([])
	const {customerId} = summary
	const [deletingFiles, setDeletingFiles] = useState(new Set())



	// const files = [
	// 	{
	// 		url: 'https://static1.sosiva451.com/89404121/b63c6cba-1b83-403a-9a43-31f73dd18413_u_small.jpg',
	// 	},
	// 	{
	// 		url: 'https://images.adsttc.com/media/images/5f90/e509/63c0/1779/0100/010e/newsletter/3.jpg?1603331288',
	// 	},
	// ];

	const extensionToImage = {
		pdf,
		doc,
		zip,
		rar,
		bmp,
		csv,
		def
	  };

	  const handleFileDelete =  async (index , fileID) => {

		const swalInstance = confirmationSwal("Eliminar archivo" ,'¿Está seguro que desea eliminar el archivo seleccionado?', 'warning', 'Eliminar');

		try {
		  const result = await swalInstance;
		  if (result.isConfirmed) {
			setDeletingFiles((prevDeletingFiles) => new Set([...prevDeletingFiles, index]));
			const response = await deleteFileCustomer( customerId , fileID, token);
			const nuevosFiles = files.filter((archivo) => archivo.id !== fileID);
			setFiles(nuevosFiles);
			showToast(response.data.message, setList,'success' );
		  }
		} catch (error) {
		  showToast(list, setList,'danger');
		}
		finally {
			setDeletingFiles((prevDeletingFiles) => {
			  const newDeletingFiles = new Set(prevDeletingFiles);
			  newDeletingFiles.delete(index);
			  return newDeletingFiles;
			});
		  }

	};

	  const handleFileDownload = async (fileID) => {
		const swalInstance = confirmationSwal('Descargar archivo' ,'¿Está seguro que desea descargar el archivo seleccionado?', 'info', 'Descargar');
		try {
		  const result = await swalInstance;
		  if (result.isConfirmed) {
			const response = await downloadFileCustomer( customerId , fileID, token);

			const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = 'Archivo';
			link.style.display = 'none';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			showToast('Archivo descargado exitosamente', setList,'success',);
		  }
		} catch (error) {
		  showToast(list, setList,'danger', "Error al descargar archivo");
		}

	};
	
	const convertFileSize = (size) => {
		let sizeFile;
  		let formato;

		  if (size < 1024) {
			[sizeFile, formato] = [size, 'bytes'];
		  } else if (size < 1048576) {
			[sizeFile, formato] = [(size / 1024).toFixed(2), 'KB'];
		  } else {
			[sizeFile, formato] = [(size / (1024 * 1024)).toFixed(2), 'MB'];
		  }

		return `${sizeFile} ${formato}`

	}

	return (
		<div className={`d-flex flex-column w-100 ${!mobileDesign && 'p-4'}`}>
			<Toast toastlist={list} position="top-right" setList={setList} />
			{/* Header */}
			{!mobileDesign && (
				<div className='d-flex align-items-center justify-content-betwee col-12'>
					<div className='d-flex align-items-center col-4'>
						<div className='d-flex align-items-center px-4 py-3 me-1 rounded-top-lg bg-white text-color-lblue fs-6 fw-bold'>
							<FolderOpen size={24} color='#506cf9' />
							<p className='my-0 mx-2'>Subir archivos</p>
						</div>
					</div>
				</div>
			)}
			{/* Header */}					 
						<div className={`d-flex flex-column bg-white  ${mobileDesign ? 'p-4 shadow rounded-xl' : 'p-5 rounded-right-and-bottom'} col-12 mb-4`}>
							<MyDropzone setFiles={setFiles} mobileDesign={mobileDesign}/>		
						</div>
			{/* Header */}
			{!mobileDesign && (
				<div className='d-flex align-items-center justify-content-betwee col-12'>
					<div className='d-flex align-items-center col-4'>
						<div className='d-flex align-items-center px-4 py-3 me-1 rounded-top-lg bg-white text-color-lblue fs-6 fw-bold'>
							<FolderOpen size={24} color='#506cf9' />
							<p className='my-0 mx-2'>Archivos</p>
						</div>
					</div>
				</div>
			)}
			{/* Header */}
			<div
				className={`d-flex flex-column bg-white ${
					mobileDesign ? 'p-4 shadow rounded-xl' : 'p-5 rounded-right-and-bottom'
				} col-12 mb-4`}
				>
				<i className="bi bi-filetype-doc"></i>
				<div
					className={`d-flex gap-3 flex-wrap  ${
						mobileDesign ? ' justify-content-center  align-items-center' : ''
					} min-vh-15 rounded`}>
					{/* {renderArchivos} */}
					{files?.map((file , index) => {
						const isDeleting = deletingFiles.has(index);
						const sizeFile = convertFileSize(file.size);
						
						const fileExtension = file.filename? file.filename.split('.').pop() : file.name.split('.').pop();
						let imagePath;
						if(fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg' ) {
							imagePath = file.url;
						} 
						else{
							imagePath = extensionToImage[fileExtension] || extensionToImage.def;
						}
						return(
							<div className=" bg-lblue card-file rounded shadow-sm position-relative overflow-hidden  text-white"
								style={{ bottom: '0%', left: '0%', height: '175px', width: '170px' }}>

									<div className='h-75 d-flex justify-content-center align-items-center '>
										<div>
											{fileExtension !== 'pdf'?  
													<img
													src={imagePath}
													alt={file.filename}
													style={{ height: '100px', width: '100px' }}
													/>
												: 
												<iframe
													style={{overflow: 'auto'}}
													title="PDF Viewer"
													src={`${file.url}#page=1`}
													width="100px"
													height="100px"
													frame-border="0"
												>
													El navegador no admite la visualización de PDF. Puedes descargarlo{' '}
													<a href={file.url} target="_blank" rel="noreferrer">
														aquí
													</a>
													.
												</iframe>
												}
										</div>
									</div>
								<div className='hover-card-file align-items-center text-center  d-flex flex-column  py-1 h-100 position-absolute w-100'>
										<span><strong>{sizeFile}</strong></span>
										<span className='w-100 px-2 text-break custom-name-archivo'>{file.filename}</span>
										<div className="iconFile position-absolute gap-2 d-flex " style={{'margin-top' : '37%'}}>
													{!isDeleting && (
														<>
														<UilCloudDownload
															size={25}
															className="me-2 icon-action-file  cursor-pointer "
															onClick={() => handleFileDownload(file.id)}
															title="Descargar archivo"
															/>
														<UilTrashAlt className="me-2 icon-action-file cursor-pointer"  size={25} onClick={() => handleFileDelete(index, file.id)} title="Eliminar archivo" />
														</>
													)}
													{isDeleting && (
														<div className="spinner-border" role="status">
														<span className="visually-hidden">Loading...</span>
														</div>
													)}
											</div>
										</div>	
											</div>
											);
										})}
									</div>
								</div>
							</div>
						);
					};

export default Files;