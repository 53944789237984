import React, { useState, useContext, useEffect } from 'react';
// Advertencia: NO TOCAR LA SIGUIENTE IMPORTACIÓN DE CSS
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import DatePicker from 'react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import Modal, { ModalBody, ModalHeader } from './bootstrap/Modal';
import SearchFilter from './Owner/SearchFilter';
import Button from './bootstrap/Button';
import { FloppyDisk } from 'phosphor-react';
import { UilClock, UilEstate } from '@iconscout/react-unicons';
import ThemeContext from '../contexts/themeContext';
import InputFloating from './InputFloating';
import { Form, Formik } from 'formik';
import CustomSelect from './CustomSelect';
import { postEvent } from '../services/customersServices';
import UserContext from '../contexts/userContext';
import Toast from '../components/Toast/Toast'
import { showToast } from './Toast/toastUtils/toastUtils';

const ModalDatePicker = ({ setChronology  ,properties, customerId , reason , setModalStatus, modalStatus }) => {
	const { mobileDesign } = useContext(ThemeContext);
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const { token } = useContext(UserContext);
	const [list, setList] = useState([])
	const [task, setTask] = useState({
		obs: null,
		date: null,
		startTime: null,
		endTime: null,
		properties: [],
	});

	const fetchData = () => {
		setFilteredSuggestions(properties)
	};

	useEffect(() => {
		fetchData();
		return() => {
			setTask({})
		}
	  },[]);

	  const handleInputChange = (e) => {
		if (!e) {
			setFilteredSuggestions(properties)
			setTask({ ...task, properties: [] });
		  } else {
			sugestionFilter(e, properties);
			setTask({
				...task, propertiesName: e
			})
		  }
	  };

	  const handleSubmit = async(value) => {
		let object = {};
	
			let formattedTime = value.startTime.split(':').map(part => part.padStart(2, '0')).join(':');
			let formattedDate = `${value.date.year}-${String(value.date.month).padStart(2, '0')}-${String(value.date.day).padStart(2, '0')} ${formattedTime}:00`;
			
			object = {
				type: value.motivo || 0,
				startTime: formattedDate || '',
				observations: value.obs || '',
				properties: value.properties || []
			}

			const response = await postEvent(customerId, object, token)
			
			if( response.status == 200){
				showToast(response.data.message, setList,'success' )

				const newEntry = {
					details: response.data.data.observations,
					id: response.data.data.id,
					type: reason[response.data.data.type - 1 ].name,
					timeDiff: "Actividad creada hace un momento",
					isEditable: true,
					timestamp: response.data.data.startTime,
					properties: response.data.data.properties ?? [] 
				   };
				   setChronology((chronology) => [newEntry, ...chronology]);

				setTimeout(() => {
					setModalStatus(!modalStatus)
				},1000)
			} else {
				showToast(response.data.errors.event.type[0], setList,'danger' )
			}
	  }
	  const sugestionFilter = (name, properties) => {
		let filteredSuggestions = [];
	
		if (!name) {
			filteredSuggestions = properties;
		} else {
			filteredSuggestions = properties.filter((suggestion) =>
				suggestion.location.toLowerCase().includes(name.toLowerCase())
			);
		}
	
		setFilteredSuggestions(filteredSuggestions);
	};

	// const fetchedData = [
	// 	{
	// 		type: 'Inmueble',
	// 		img: 'https://via.placeholder.com/25',
	// 		title: 'Dirección del inmueble 1',
	// 		content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
	// 	},
	// 	{
	// 		type: 'Inmueble',
	// 		img: 'https://via.placeholder.com/25',
	// 		title: 'Dirección del inmueble 2',
	// 		content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
	// 	},
	// 	{
	// 		type: 'Inmueble',
	// 		img: 'https://via.placeholder.com/25',
	// 		title: 'Dirección del inmueble 3',
	// 		content: 'Contrary to popular belief · Lorem Ipsum is not simply random text.',
	// 	},
	// ];

	const hours = [
		'8:00',
		'8:30',
		'9:00',
		'9:30',
		'10:00',
		'10:30',
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30',
		'17:00',
		'17:30',
		'18:00',
		'18:30',
		'19:00',
		'19:30',
		'20:00',
	];

	const renderCustomInput = ({ ref }) => (
		<div
			className={`d-flex align-items-end ${mobileDesign && 'col-12'}`}
			style={{ borderBottom: '2px solid #506cf9' }}>
			<div className={`${mobileDesign && 'col-2'}`}>
				<UilClock className='m-1' size={20} color='#506cf9' />
			</div>
			<input
				readOnly
				className={`p-2 text-color-lblue fw-normal-bold fw-normal-bold ${
					mobileDesign && 'col-9'
				}`}
				ref={ref} // necessary
				placeholder='Ingrese la fecha'
				value={`${task.date?.day || 'dd'}/${task.date?.month || 'mm'}/${
					task.date?.year || 'aaaa'
				}`}
				style={{
					border: 'none',
					outline: 'none',
				}}
			/>
		</div>
	);

	return (
		<Modal setIsOpen={setModalStatus} isOpen={modalStatus} titleId='new-todo-modal'>
			<ModalHeader setIsOpen={setModalStatus} className='bg-blue-300 p-4'>
				{/* <ModalTitle id='new-todo-modal'>New Issue</ModalTitle> */}
				<div className='w-100 d-flex justify-content-center'>
					<h3 className='m-0 text-color-white fw-bold'>Generar tarea</h3>
				</div>
			</ModalHeader>
			<ModalBody>
			<Toast toastlist={list} position="top-right" setList={setList} />
				<Formik initialValues={task}
				enableReinitialize={true}
				onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}>
					{({ touched }) => (
						<Form>
							<div className='d-flex flex-column align-items-center col-12'>
								<div className='col-10 mt-4'>
									{/* <SearchFilter
										text={'Motivo'}
										name={'Motivo'}
										nameEng={'reason'}
										filtersApplied={task}
										setFiltersApplied={setTask}
										size='2'
									/> */}
									<CustomSelect
										// inputDisabled={inputDisabled}
										text={task.motivoName}
										name={'Motivo'}
										nameEng={'motivo'}
										data={reason}
										filtersApplied={task}
										values={reason}
										setFiltersApplied={setTask}
										changeHandler={(e) => setTask((prevValues) => ({ ...prevValues, motivo: e , motivoName: reason[e -1].name }))}
										size='2'
										withSearch={true}
									/>
								</div>
								<div className='col-10 my-4'>
									<div className={'dropdown w-100'}>
										<div
											className='w-100'
											id='dropdownMenuButton1'
											data-bs-toggle='dropdown'
											aria-expanded='false'>
											<InputFloating
												label='Inmueble'
												name='propertiesName'
												type='text'
												setFunction={setTask}
												previousValue={task}
												errorMessage='Ingresa una dirección'
												required={true}
												changeHandler={handleInputChange}
												touched={touched}
											/>
										</div>
										<ul
										className={'dropdown-menu bg-white-dropdown p-4 w-100'}
											aria-labelledby='dropdownMenuButton1'>
											<li>
												<div className='d-flex flex-column align-items-start w-100'>
													{/* <div className='d-flex align-items-center'>
														<UilEstate size={25} color='#01227D' />
														<p className='ms-1 my-0 fs-6 text-color-dark'>
															Inmuebles
															</p>
														</div> */}
													{filteredSuggestions.length > 0 ? (


													<div className='d-flex flex-column justify-content-center align-items-start w-100 py-1'>
															{filteredSuggestions?.map((item) => (
																<div
																		onClick={() =>
																			setTask((task) => ({
																				...task,
																				properties: [item.id],
																				propertiesName : item.location
																			}))
																		}
																		className='d-flex align-items-center my-1 w-100 cursor-pointer custom-dropdown-item px-4 py-2 rounded-pill'>
																		<img
																			style={{width: '30px'  , height: '30px'}}
																			src={item.photo}
																			className='rounded-5px'
																		/>
																		<p className='m-0 fs-5 fw-bold mx-2 text-nowrap'>
																			{item.location}
																		</p>
																		<p className='m-0 fs-6 fw-normal text-truncate'>
																			{item.estateType}
																		</p>
																	</div>
																))}
														</div>
													) : (
														<div className="text-center my-2">No hay más datos</div>
													)
												}
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className='d-flex align-items-center justify-content-start col-12 px-5'>
									<DatePicker
										onChange={(e) =>
											setTask((prevValues) => ({ ...prevValues, date: e }))
										}
										value={task.day}
										colorPrimary='#506cf9'
										date={new Date()}
										minDate={new Date()}
										color={process.env.REACT_APP_PRIMARY_COLOR}
										renderInput={renderCustomInput}
										shouldHighlightWeekends
									/>

									<div
										className=' dropdown p-2 mx-2'
										style={{
											borderBottom: '2px solid #506cf9',
										}}>
										<div
											className='text-color-lblue fw-normal-bold cursor-pointer'
											data-bs-toggle='dropdown'
											aria-expanded='false'>
											{task.startTime || 'hh:mm'}
											{/* {task.startTime && ' - '}
									{task.startTime && hours[hours.indexOf(task.startTime) + 1]} */}
										</div>
										<div className='dropdown-menu'>
											<div
												className='d-flex flex-column'
												style={{ height: '25rem', overflowY: 'scroll' }}>
												{hours.map((hour, index, hours) => (
													<span
														onClick={() => {
															setTask((task) => ({
																...task,
																startTime: hour,
																endTime: hours[index + 2],
															}));
														}}
														className='p-4 fw-normal-bold cursor-pointer custom-dropdown-item'
														key={hour}>
														{hour}
													</span>
												))}
											</div>
										</div>
									</div>
									<div
										className='dropdown p-2 mx-2'
										style={{
											borderBottom: '2px solid #506cf9',
										}}>
										<div
											className='text-color-lblue fw-normal-bold cursor-pointer'
											data-bs-toggle='dropdown'
											aria-expanded='false'>
											{task.endTime || 'hh:mm'}
											{/* {task.time && ' - '}
									{task.time && hours[hours.indexOf(task.time) + 1]} */}
										</div>
										<div className='dropdown-menu'>
											<div
												className='d-flex flex-column'
												style={{ height: '25rem', overflowY: 'scroll' }}>
												{hours
													.slice(hours.indexOf(task.startTime) + 1)
													.map((hour) => (
														<span
															onClick={() =>
																setTask((task) => ({
																	...task,
																	endTime: hour,
																}))
															}
															className='p-4 fw-normal-bold custom-dropdown-item cursor-pointer'
															key={hour}>
															{hour}
														</span>
													))}
											</div>
										</div>
									</div>
								</div>

								<div className='d-flex flex-column w-100 mt-4'>
									<label className='fs-5 fw-normal-bold mx-5'>
										Observaciones
									</label>
									<textarea
										type='textarea'
										className='mx-4 px-3 pt-3 my-2 min-vh-10 input-filters-menu inner-shadow-blue rounded-1 textarea-chronology input-no-outline'
										onChange={(e) =>
											setTask((task) => ({ ...task, obs: e.target.value }))
										}
									/>
								</div>
								<div className='d-flex w-100 justify-content-end m-4'>
									<Button
										disabled={!task.endTime || !task.date || task.properties.length < 1}
										rounded={1}
										className='bg-blue-300 mx-4 border-0'
										type='submit'
										>
										<div className='d-flex align-items-center'>
											<FloppyDisk size={24} color='#ffffff' />
											<p className='my-0 mx-2 fs-6 fw-normal text-color-white'>
												Guardar
											</p>
										</div>
									</Button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</ModalBody>
		</Modal>
	);
};

export default ModalDatePicker;
