import React from 'react';
import InputFloating from '../../components/InputFloating';
import SearchFilter from '../../components/Owner/SearchFilter';
import SwitchThreeOptions from '../../components/SwitchThreeOptions';
import CustomSelect from '../../components/CustomSelect';

export default function InputSelector({
	data,
	touched,
	item,
	changeHandler,
	inputDisabled,
	changeHandlerMultipleSelect,
	// setFiltersApplied,
}) {

	// const clickHandler = (element) => {
	// 	if (data[item.nameEng[0]] === element) {
	// 		return setFiltersApplied((filtersApplied) => ({
	// 			...filtersApplied,
	// 			[item.nameEng[0]]: null,
	// 			page: 1,
	// 		}));
	// 	} else {
	// 		return setFiltersApplied((filtersApplied) => ({
	// 			...filtersApplied,
	// 			[item.nameEng[0]]: element,
	// 			page: 1,
	// 		}));
	// 	}
	// };

	switch (item.type) {
		case 'multipleSelect':
			return (
				<SearchFilter
					text={item.name}
					name={item.name}
					inputDisabled={inputDisabled}
					nameEng={item.nameEng[0]}
					values={item.options[item.nameEng[0]]}
					changeHandlerMultipleSelect={changeHandlerMultipleSelect}
					data={data}
				/>
			);
		case 'range':
			return (
				<div className='d-flex align-items-center w-100'>
					{/* <InputFloating
						changeHandler={changeHandler}
						label='Valor mín.'
						name='valueMin'
						type='text'
						previousValue={data}
						touched={touched}
						// withDebounce
					/> */}
					{item.name === "Valor"?
						<div className='d-flex align-items-center w-100'>
						<InputFloating
							inputDisabled={inputDisabled}
							changeHandler={changeHandler}
							label={item.nameEng[0]}
							name={item.nameEng[0]}
							type='text'
							previousValue={data}
							touched={touched}
							withDebounce
							/>
						<InputFloating
							inputDisabled={inputDisabled}
							changeHandler={changeHandler}
							label={item.nameEng[1]}
							name={item.nameEng[1]}
							type='text'
							previousValue={data}
							touched={touched}
							withDebounce
							/>
						</div>
					:
					<div className='d-flex align-items-center w-100'>
							<InputFloating
							inputDisabled={inputDisabled}
							changeHandler={changeHandler}
							label={item.name}
							name={item.nameEng[0]}
							type='text'
							previousValue={data}
							touched={touched}
							withDebounce
							/>
					</div>
					}
				</div>
			);

		case 'text':
			return (
				<InputFloating
					inputDisabled={inputDisabled}
					changeHandler={changeHandler}
					label={item.name}
					// name={item.nameEng[0] === 'maxAntiquity' && 'antiquity'}
					name={item.nameEng[0]}
					type='text'
					previousValue={data}
					touched={touched}
					withDebounce
				/>
			);

		case 'switch':
			return (
				<SwitchThreeOptions
					inputDisabled={inputDisabled}
					label={item.name}
					clickHandler={changeHandler}
					data={data}
					nameEng={item.nameEng[0]}
				/>
			);

		case 'select':
			return (
				<CustomSelect
				inputDisabled={inputDisabled}
				text={item.name}
				name={item.name}
				nameEng={item.nameEng[0]}
				data={data}
				changeHandler={changeHandler}
				values={item.options[item.nameEng[0]]}
				/>
			);
		default:
			return <h5>Type not supported</h5>;
	}
}
