import React, { useContext, useEffect, useState } from 'react'
import Check from '../../components/Check'
import {
    UilUser,
    UilAngleDown,
    UilUserLocation,
    UilTagAlt

} from '@iconscout/react-unicons';
import ThemeContext from '../../contexts/themeContext';
import InfoCustomer from './InfoCustomer';

const InfoAccordionCustomer = ({customer , key , operatorList ,changeSellersMan ,isChecked ,filters ,reason , handleCheckbox ,indexStatus , status ,statusCustomers}) => {
    const { mobileDesign, darkModeStatus } = useContext(ThemeContext);
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };



   const getPercentage = () => {
    const currentNameStatus = status?.name || customer.status.name || ''
    const indexStatus = statusCustomers.findIndex(status => status.name === currentNameStatus);
    const percentage = indexStatus !== -1 ? Math.round(100 / statusCustomers.length * (indexStatus + 1)) : 0;

    return percentage
   }

  return (
          <div key={key} className="accordion" id={customer.id}>
               <div className="accordion-item mx-4">
                   <div
                       className="texto-montserrat justify-content-between accordion-button rounded-1 text-black"
                       style={{ 'backgroundColor': '#F5F6F8' }}>
                       {!mobileDesign ? (
                           <div className='row w-100 m-0  align-content-center align-items-center '>
                               <div className='d-flex col-2 col-sm-6 col-xl-2 align-items-center gap-4 px-0 mb-3 mb-xl-0 '>
                                   <div className=' align-items-center d-flex '>
                                       <Check checked={isChecked} clickHandler={(e) => handleCheckbox({ customerId: customer.id, indexStatus })} />
                                   </div>
                                   <div className='fs-5 custom-name-accordion gap-3 align-items-center px-0 d-flex '>
                                       <div>
                                           <UilUser size={24} color='#506CF9' />
                                       </div>
                                       <div className='text-nowrap overflow-hidden '>
                                           <div className='text-truncate w-100 overflow-hidden text-nowrap'>{customer.name}</div>
                                           <div className='w-100'>
                                               <div className='custom-rounded w-auto px-2 py-1 text-uppercase fs-6 bg-warning text-nowrap text-white'
                                                   style={{
                                                       maxWidth: '100%',
                                                       display: 'inline-block',
                                                       backgroundColor: `${customer.portal?.colour || '#ffffff'}`
                                                   }}>
                                                   {customer?.portal?.name || ''}
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>

                               <div className='col-sm-6 col-xl-auto justify-content-center d-flex ps-5'>
                                   <div
                                       rounded={1}
                                       data-bs-toggle='dropdown'
                                       aria-expanded='false'
                                       onClick={(e) => e.stopPropagation()}
                                       className='cursor-pointer bg-white custom-rounded px-2 py-1'
                                       style={{ 'width': '180px' }}>
                                       <div className='d-flex align-items-center cursor-pointer justify-content-between'>
                                           <p className='user-select-none my-0 text-truncate'>
                                               {operatorList.length > 0 && customer.seller ?`${operatorList.find(operator => operator.id === customer.seller).name} ${operatorList.find(operator => operator.id === customer.seller).surname}`: ''}
                                           </p>
                                            <UilAngleDown size={24} color='#506cf9' />
                                       </div>
                                   </div>
                                   <div className='bg-lblue rounded dropdown-menu p-2 shadow text-color-black-lighter' style={{ width: '15rem' }}>
                                       {operatorList?.map((operator, index) => (
                                           <div onClick={(e) => changeSellersMan({ operatorId: operator.id, customerId: [customer.id], indexStatus: indexStatus, indexCustomer: key })} key={index} className='d-flex align-items-center p-2 fs-6 cursor-pointer custom-dropdown-item'>
                                               <p className='m-0 ps-2'>{operator.name} {operator.surname}</p>
                                           </div>
                                       ))}
                                   </div>
                               </div>

                               <div className='col-4 col-xl-2 px-0 d-flex ms-auto' >
                                   {customer.address.length > 0 &&
                                       <div
                                           className='text-nowrap overflow-hidden text-truncate '>
                                           <UilUserLocation className='me-2 ' size={26} color='#506cf9' />
                                           {customer.address[0]}
                                       </div>
                                   }
                               </div>
                               <div className='col-1 px-0 d-flex'>
                                   {customer.summary?.lastTouchElapsed && (
                                      <div
                                      className='text-nowrap'
                                      data-bs-title={customer.summary.lastTouchDate.split(' ')[0]}
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top" 
                                  >
                                      {customer.summary.lastTouchElapsed || ''}
                                  </div>
                               )}
                               </div>
                               <div className='d-flex h-100 col-xl-3 col-5 ms-auto'>
                                   <div className='d-flex flex-column justify-content-end me-5 gap-1 align-text-top w-100'>

                                       <span className='text-end fs-6 '>{status?.name || customer.status.name}-{getPercentage()}%</span>
                                       
                                       <div style={{ 'height': '9px' }} className={`progress`}>
                                           <div className="progress-bar pt-2 " style={{ 'width': `${getPercentage()}%`, 'backgroundColor': `${customer.statusData? customer.statusData.color : status?.statusData?.color }` }} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                       </div>
                                   </div>
                                   <div onClick={() =>toggleAccordion()} type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-info${key}${customer.id}`} aria-expanded="true" aria-controls={`panelsStayOpen-info${key}${customer.id}`}>
                                       <UilAngleDown size={26} color='#506cf9' />
                                   </div>
                               </div>
                           </div>)
                           : (
                                <div className='d-flex justify-content-between align-items-center p-2 w-100 text-nowrap overflow-hidden d-inline-block ' style={{ flexShrink: 1, overflow: 'hidden' }}>
                                    <div className='text-nowrap fs-4 text-truncate w-100 overflow-hidden  d-inline-block ' >
                                        {customer.name}
                                    <div className='text-truncate'>{customer.address.length > 0 && <> <UilUserLocation className='me-2 ' size={26} color='#506cf9'/> {customer.address[0]}</>}</div>
                                    <div className='gap-2'>
                                        <label className='d-flex align-items-center gap-2 '>
                                            <UilTagAlt className='me-2' style={{ flexShrink: 0 }} size={26} color='#506cf9' />
                                            <div style={{'minWidth' : "50px"}} className='custom-rounded px-2 py-1 text-uppercase fs-6 overflow-hidden text-truncate text-nowrap bg-warning text-white'>
                                                {customer?.portal?.name || ''}
                                            </div>
                                            {customer.tags?.length > 0 &&
                                            <div  style={{ 'background': '#A7A7A9' ,'minWidth' : "30px"}} className='custom-rounded  overflow-hidden text-truncate text-nowrap px-2 py-1 text-uppercase fs-6 text-ellipsis'>
                                                 {customer.tags[0]?.name}
                                            </div>
                                            }
                                            {customer.tags?.length -1 > 0 &&
                                            <div style={{ 'background': '#A7A7A9' }} className='custom-rounded px-2 py-1 text-uppercase fs-6 text-nowrap text-black '>+{customer.tags?.length -1}</div>
                                            }
                                        </label>
                                    </div>
                                    
                                    </div>
                                   <div onClick={toggleAccordion()} className='' type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-info${key}${customer.id}`} aria-expanded="true" aria-controls={`panelsStayOpen-info${key}${customer.id}`}>
                                       <UilAngleDown size={24} color='#506cf9' />
                                       dsa
                                   </div>
                               </div>
                           )}
                   </div>

                   <div style={{ 'marginTop': mobileDesign ? '-70px' : '-37px', 'marginBottom': '-47px' }} id={`panelsStayOpen-info${key}${customer.id}`} className={`accordion-collapse collapse width-custom-infoAccordion gradient-lblue rounded-top-2 ${!mobileDesign && 'bg-lblue'} rounded-0`} aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body acordion-custom m-0 p-0">
                           {isOpen && (
                                    <InfoCustomer reason={reason} filters={filters.data} customer={customer} />
                                )}
                       </div>
                   </div>
               </div>
           </div>
        
  )
}

export default InfoAccordionCustomer