import axios from 'axios';
const baseURL = process.env.REACT_APP_ENDPOINT;

export const getCustomersFiltered = async (filters = {}, token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	const data = await axios({
		method: 'POST',
		url: `${baseURL}/customers`,
		headers: header,
		data: {
			filters: filters,
		},
	}).then((res) => res.data);

	return data;
};

export const getDataCustomerId = async(id , token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	try {

		const response = await axios ({
			method:'GET',
			url:`${baseURL}/customers/${id}`,
			headers: header,
		});

		const data = response.data
		return data;
	} catch(error) {
		console.log(error);

	}

}

export const updateCustormer = async (data, id, token ) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};
	const response = await axios({
		method: 'PATCH',
		url: `${baseURL}/customers/${id}`,
		headers: header,
		data: data,
	});
	
	return response.data.message
	
}

export const deleteCustomer = async (id, token) => {
	const header = {
		Authorization: `Bearer ${token}`
	}
	const response = await axios({
		method: 'DELETE',
		url:`${baseURL}/customers/${id}`,
		headers: header
	});
	
	console.log(response)
	return response
	
}


export const getStatus = async(token) => {
	try{
		const headers = {
			Authorization: `Bearer ${token}`
        };

		const response = await axios({
			method: 'GET',
			url:`${baseURL}/system/getCustomerStatus`,
			headers: headers
		})
		const array = Object.values(response.data.data);
		return array;

	}catch(error){
		console.log(error)
	}
}


export const createNoteCustomer = async (value, customerID, token) => {
	const prueba = {

	}
    try {
		const headers = {
			Authorization: `Bearer ${token}`
        };
		
        const response = await axios({

			method: 'POST',
			url: `${baseURL}/customers/${customerID}/note/create`,
            data: value ,
            headers
			}
			);
			
			return {
				status: 200,
				data: response.data
			};

		} catch (error) {
			return {
            status: error.response ? error.response.status : 500,
            // error: error.response.data.errors.note.text || error.message,
            error: error.response.data.errors
        };
    }
};

export const deleteNoteCustomer = async( noteID,customerID, token) => {
	const headers = {
		Authorization: `Bearer ${token}`
	};
	
	try{
		const response = await axios({
			method: 'DELETE',
			url:`${baseURL}/customers/${customerID}/note/${noteID}`,
			headers
		})
		return response;


	}catch(error) {
		console.log(error)
		throw error
	}
}

export const updateNoteCustomer = async( value, noteID ,customerID, token) => {
	const headers = {
		Authorization: `Bearer ${token}`
	};

	try{
		const response = await axios({
			method: 'PATCH',
			url:`${baseURL}/customers/${customerID}/note/${noteID}`,
			headers,
			data: value
		})
		return response;


	}catch(error) {
		return {
            status: error.response ? error.response.status : 500,
            // error: error.response.data.errors.note.text || error.message,
            error: error.response.data.errors
        };
	}
}


	// export const sendCorreo = async(value ,token) => {
	// 	try {
	// 		const headers = {
	// 			Authorization: `Bearer ${token}`
	// 		};

			// const response = await axios({

			// 	method: 'POST',
			// 	url: `${baseURL}/customers/correo`,
			// 	data: value ,
			// 	headers
			// 	}
			// 	);
				
			// 	console.log(response, "esto es la respuesta de enviar un correo a customer");

	// 		const data = {
	// 			data:[],
	// 			message:"Correo enviado"
	// 		}

	// 		return data;

	// 		} catch (error) {
	// 			console.log(error);
	// 	}
	// }

export const postEvent = async ( customerID , data , token) => {

	try {

			const header = {
				Authorization: `Bearer ${token}`
			}
			
			const response = await axios({
				method: 'POST',
				url: `${baseURL}/customers/${customerID}/event/create`,
				data: data,
				headers: header
			})
				return {
					status: 200,
					data: response.data
				};
	} catch (error) {
		return {
            status: error.response ? error.response.status : 500,
            // error: error.response.data.errors.note.text || error.message,
            error: error.response.data.errors
        };
}
}

export const updateEventCustomer = async(value, eventID ,customerID, token) => {
	const headers = {
		Authorization: `Bearer ${token}`
	};

	try{
		const response = await axios({
			method: 'PATCH',
			url:`${baseURL}/customers/${customerID}/event/${eventID}`,
			headers,
			data: value
		})
		return response;


	}catch(error) {
		return {
            status: error.response ? error.response.status : 500,
            // error: error.response.data.errors.note.text || error.message,
            error: error.response.data.errors
        };
	}
}

export const deleteEventCustomer = async(customerID , eventID , token) => {
	const header = {
		Authorization: `Bearer ${token}`
	}

	const response = await axios({
		method: 'DELETE',
		url:`${baseURL}/customers/${customerID}/event/${eventID}`,
		headers:header
	})

	return response



}


export const uploadFileCustomer = async (file, id, token, setProgress) => {
	const headers = {
	  Authorization: `Bearer ${token}`,
	  'Content-Type': 'multipart/form-data',
	};
  
	const formData = new FormData();
	formData.append('file', file);
  
	const fileId = new Date().getTime();
  
	const response = await axios.post(`${baseURL}/customers/${id}/file/create`, formData, {
	  headers: headers,
	  onUploadProgress: (progressEvent) => {
		const percentLoaded = Math.round((progressEvent.loaded / progressEvent.total) * 100);
		setProgress(oldProgress => {
		  const index = oldProgress.findIndex(p => p.id === fileId);
		  if (percentLoaded === 100) {
			return oldProgress.filter(p => p.id !== fileId);
		  } else if (index === -1) {
			return [...oldProgress, { id: fileId, file: file, progress: percentLoaded }];
		  } else {
			const newProgress = [...oldProgress];
			newProgress[index].progress = percentLoaded;
			return newProgress;
		  }
		});
	  },
	});
  
	return response;
  };


export const deleteFileCustomer = async ( customerID ,fileID , token) => {

	  const headers = {
		Authorization: `Bearer ${token}`,
	  };

	  const response = await axios({
		method: 'DELETE',
		url:`${baseURL}/customers/${customerID}/file/${fileID}`,
		headers: headers
	});

	  return response
  };

 export const downloadFileCustomer = async (customerID, fileID, token) => {
	  const headers = {
		Authorization: `Bearer ${token}`,
	  };
  
	  const response = await axios({
		method: 'GET',
		url: `${baseURL}/customers/${customerID}/file/${fileID}/download`,
		headers: headers,
		responseType: 'blob',
	  });
	  return response
  
  };
  

export const createCustomer = async (data, token) => {
	const header = {
		Authorization: `Bearer ${token}`,
	};

	// Con este body anda bien
	//  const data = {
	// 		summary: {
	// 			surname: 'Fulanito',
	// 			name: 'Cosme',
	// 			email: 'cosmefulanito@gmail.com',
	// 			genre: 1,
	// 			incomeType: 1,
	// 			bday: '1995-06-28',
	// 			idType: 'DNI',
	// 			id: '38900622',
	// 			entryForm: 'Forma de ingreso 1',
	// 			salesman: 'Vendedor 1',
	// 			customerState: 'Estado 1',
	// 		},
	// 		customerTags: {
	// 			names: ['Test', 'Test 2'],
	// 		},
	// 		customerTypes: {
	// 			types: [1, 2],
	// 		},
	// 	},

	const response = await axios({
		method: 'POST',
		url: `${baseURL}/customers/create`,
		headers: header,
		data: data,
	});

	return response.data.message;
};

export const getAllCustomerStatus = async( filters = {} , token) => {
    try{
      const headers = {
        Authorization: `Bearer ${token}`
          };
		  
      const response = await axios({
        method: 'POST',
        url:`${baseURL}/customerStatus`,
        headers: headers,
		data: {
			filters
		}
      })

      const array = Object.values(response.data.data);
      return array
  
    }catch(error){
      console.log(error)
    }
  }
  
export const createNewStatus = async(status , token) => {
    try{
      const headers = {
        Authorization: `Bearer ${token}`
          };
      const response = await axios({
        method: 'POST',
        url:`${baseURL}/customerStatus/create`,
        headers: headers,
		data: status,
      })
      return response
  
    }catch(error){

		return {
            status: error.response ? error.response.status : 500,
            error: error.response.data.errors.data
        };
    }
  }

  export const changeMultipleSellers = async( data , token) => {
    try{
      const headers = {
        Authorization: `Bearer ${token}`
          };

      const response = await axios({
        method: 'POST',
        url:`${baseURL}/customers/changeMultipleSellers`,
        headers: headers,
		data: data
      })

      return response
  
    }catch(error){
      console.log(error)
    }
  }

  export const changeMultipleStatus = async (data, token) => {
	try{
		const headers = {
		  Authorization: `Bearer ${token}`
			};
  
		const response = await axios({
		  method: 'POST',
		  url:`${baseURL}/customers/changeMultipleStatus`,
		  headers: headers,
		  data: data
		})
  
		return response
	
	  }catch(error){
		console.log(error)
	  }
  }

  export const getCustomerChance = async (filters, token) => {
	try{
		const headers = {
		  Authorization: `Bearer ${token}`
			};
  
		const response = await axios({
		  method: 'POST',
		  url:`${baseURL}/oportunidades`,
		  headers: headers,
		  data: {
			filters
		  } 
		})
		return response.data
	
	  }catch(error){
		console.log(error)
	  }
  }
