import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	UilArrowUp,
	UilArrowDown,
	UilSetting,
	UilBrowser,
	UilUsersAlt,
	UilBag,
} from '@iconscout/react-unicons';
import Avatar from '../../components/Avatar';
import Button from '../../components/bootstrap/Button';
import USERS from '../../common/data/userDummyData';
import { saveOperador } from '../../services/operatorsService';
import Swal from 'sweetalert2';
import Toast from '../../components/Toast/Toast';

const PopOverTable = () => {
	return (
		<>
			<div className='d-flex w-100 h-100 border-bottom p-2  justify-content-center align-items-center w-100'>
				<div className='d-flex col-8'>
					<UilBag className='text-color-lblue' size={18} />
					<span className='text-align-left ms-2  fw-600 fs-6'>Acciones Realizadas:</span>
				</div>
				<div className='d-flex col-4'>
					<span className='me-4 text-color-violet fw-600 fs-6'>Pedro juan</span>
					<Avatar
						srcSet={USERS.CHLOE.srcSet}
						src={USERS.CHLOE.src}
						size={30}
						color={USERS.CHLOE.color}
					/>
				</div>
			</div>
			<div className='row justify-content-center align-items-center'>
				<div className='col-12 '>
					<div className='col-6'>
						<div className='col-6 d-flex '>
							<div className='mt-5 d-flex'>
								<div className='col-6 me-5'>
									<p className='fw-600 text-color-blue'>Mail</p>
									<p className='fw-600 text-color-blue'>Whatsapp</p>
									<p className='fw-600 text-color-blue'>Visita</p>
									<p className='text-color-blue'>LLamado</p>
								</div>
								<div className='col-10 ms-5 d-flex flex-column '>
									<div className='d-flex flex-row'>
										<span className='ms-5'>23</span>
									</div>

									<div className='d-flex mt-3 '>
										<span className='ms-5'>15</span>
									</div>
									<div className='d-flex mt-3 '>
										<span className='ms-5'>9</span>
									</div>
									<div className='d-flex mt-3 '>
										<span className='ms-5'>3</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PopOverTable;
